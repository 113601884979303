<ng-template
  [(tuiDialog)]="isOpen"
  [tuiDialogOptions]="{
    label: 'common.delete-title' | translate,
    size: 'm',
    dismissible: false,
    closeable: false
  }"
  let-observer
>
  <div class="hint-container">
    <tui-svg [src]="tuiIconAlertOctagonLarge"></tui-svg>
    <p>{{ "common.delete-hint" | translate }}</p>
  </div>
  <hr />
  <div class="button-wrapper">
    <button
      (click)="deleteEvent.emit()"
      appearance="''"
      class="delBtn"
      size="m"
      tuiButton
      type="button"
    >
      {{ "common.button-delete" | translate }}
    </button>

    <button
      (click)="cancelEvent.emit()"
      appearance="secondary"
      size="m"
      style="flex-grow: 1"
      tuiButton
      type="button"
    >
      {{ "common.button-cancel" | translate }}
    </button>
  </div>
</ng-template>

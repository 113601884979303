import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, forkJoin, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import TourPoint from "../models/TourPoint.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { UpdateRequestPayload } from "../interfaces/request-array.interface";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

export type TourPointQueryParams = {
  locationId?: string;
  warehouseId?: string;
  tourId?: string;
  orderId?: string;
  populateTourPointLoad?: boolean;
  onlyUnannounced?: boolean;
  populateUnannouncedInfo?: boolean;
  createdAt?: string;
  populateOrder?: boolean;
  populateTPLOrder?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class TourPointService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  private _totalTourPoints = signal<number>(0);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  _tourPoints = signal<TourPoint[]>([]);

  get tourPoints() {
    return this._tourPoints();
  }

  set tourPoints(value: TourPoint[]) {
    this._tourPoints.set(value);
  }

  get total() {
    return this._totalTourPoints();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD TourPoint
  addTourPoint(addTourPointObject: TourPoint): Observable<TourPoint> {
    return this.http
      .post<TourPoint>(this.baseUrl + "tour-point", addTourPointObject)
      .pipe(
        tap(() => {
          // Open the snackbar for successful request
          this.handleSuccess("Tour Stop wurde erstellt");

          // Call the getAllTourPoint method for updating the data + view
          this.getAllTourPoints().subscribe();
        }),
        catchError((err) => {
          // Open the snackbar for error
          this.errorHandler.sendErrorMessage("tour-point", "CREATE", err);
          return of(err);
        }),
      );
  }

  // GET ALL TourPoints
  getAllTourPoints(
    queryParams?: TourPointQueryParams,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "tour-point", { params })
      .pipe(
        tap((res: ResponseWithRecordsBody) => {
          this._tourPoints.set(
            res.records.map((tourPoint: TourPoint) => new TourPoint(tourPoint)),
          );
          this._totalTourPoints.set(res.total);
        }),
      );
  }

  // GET ONE TourPoint
  getTourPointById(id: string | number): Observable<TourPoint> {
    return this.http.get<TourPoint>(this.baseUrl + "tour-point/" + id);
  }

  // CHANGE ONE TourPoint
  updateTourPointById(
    updatePayload: UpdateRequestPayload<TourPoint>,
  ): Observable<TourPoint[]> {
    // now returns an Observable of array
    // Map to an array of observables
    const updateObservables = updatePayload.data.map(({ id, data }) =>
      this.http.patch<TourPoint>(`${this.baseUrl}tour-point/${id}`, data),
    );

    // Use forkJoin to wait for all observables
    return forkJoin(updateObservables).pipe(
      tap(() => {
        this.getAllTourPoints().subscribe();
      }),
      catchError((err) => {
        // Handle the error: this will catch the error as soon as one of the observables fails
        this.errorHandler.sendErrorMessage("tour-point", "UPDATE", err);
        // Return an observable with error
        return of(err);
      }),
    );
  }

  // DELETE ONE TourPoint
  deleteTourPointById(id: number | string): Observable<unknown> {
    return this.http.delete<unknown>(this.baseUrl + "tour-point/" + id).pipe(
      tap(() => {
        // Open the snackbar for successful request
        this.handleSuccess("Ein Tour Stop wurde gelöscht");
        // Call the getAllTourPoints method for updating the data + view
        this.getAllTourPoints().subscribe();
      }),
      catchError((err) => {
        // Open the snackbar for error
        this.errorHandler.sendErrorMessage("tour-point", "DELETE", err);
        return of(err);
      }),
    );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

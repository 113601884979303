import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { OrderService } from "../../../api/order.service";
import Order, { ORDER_STATE } from "../../../models/Order.class";
import { CustomerService } from "../../../api/customer.service";
import { LocationService } from "../../../api/location.service";
import Customer from "../../../models/Customer.class";
import LocationC from "src/app/models/Location.class";
import { tabArray } from "../../../common/tabs/tabs.component";
import {
  tuiIconArrowDownCircleLarge,
  tuiIconArrowUpCircleLarge,
} from "@taiga-ui/icons";
import Product, { PRODUCT_QUALITY } from "../../../models/Product.class";
import { ProductService } from "../../../api/product.service";
import { ProductOrderService } from "../../../api/productOrder.service";
import ProductOrder from "../../../models/ProductOrder.class";
import { forkJoin, Observable, Subscription } from "rxjs";
import { pushTypes } from "../../../other/enums/push-types";
import { PushService } from "../../../services/push.service";
import { TuiDay, TuiTime } from "@taiga-ui/cdk";
import { DateConverterService } from "../../../services/date-converter.service";
import { Mode } from "../../../other/enums/mode";
import { DynamicFormHelperService } from "../../../services/dynamic-form-helper.service";
import {
  PriceQueryParams,
  ProductPriceService,
} from "../../../api/productPrice.service";
import ProductPrice from "../../../models/ProductPrice.class";
import { BaseDialogComponent } from "../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import {
  ErrorMessagesService,
  MethodType,
} from "../../../api-error-messages/error-messages.service";
import { PermissionService } from "../../../services/permission.service";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { ProductOrderPermissionHelperService } from "../../../services/permission-helper-services/product-order-permission-helper.service";
import { DateTime } from "luxon";

@Component({
  selector: "app-add-edit-order",
  templateUrl: "./add-edit-order.component.html",
  styleUrls: ["./add-edit-order.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditOrderComponent
  extends BaseDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions = new Subscription();
  form: FormGroup;

  tabArray: tabArray[] = [
    { name: "Paletten liefern", icon: tuiIconArrowUpCircleLarge },
    { name: "Paletten abholen", icon: tuiIconArrowDownCircleLarge },
  ];

  isOpen: boolean = true;
  toUpdateId: string;
  mode: string = Mode.ADD;
  customers: Customer[] = [];
  locations: LocationC[];
  products: Product[];
  productsDropDown: DropDownItem[];
  qualityDropDown: DropDownItem[] = [];
  locationDropdown: DropDownItem[] = [];
  customerDropdown: DropDownItem[] = [];
  previousProductOrders: ProductOrder[] = [];
  timeframeBegin: TuiDay;
  today: [TuiDay, TuiTime];
  protected readonly FormControl = FormControl;
  private isProcessingValueChange = false;

  constructor(
    public permissionService: PermissionService,
    public productOrderPermissionHelper: ProductOrderPermissionHelperService,
    private fb: FormBuilder,
    private orderService: OrderService,
    private customerService: CustomerService,
    private locationService: LocationService,
    private productService: ProductService,
    private productOrderService: ProductOrderService,
    private pushService: PushService,
    public dynamicFormHelperService: DynamicFormHelperService,
    private dateConverterService: DateConverterService,
    private productPriceService: ProductPriceService,
    private errorHandler: ErrorMessagesService,
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<any>,
    dialogService: TuiDialogHelperService,
  ) {
    super(context, dialogService);

    effect(() => {
      this.productsDropDown = this.productService.products.map((product) => {
        return { id: product.id, label: product.name };
      });
    });
  }

  get isAddMode() {
    return this.mode === Mode.ADD;
  }

  get orders() {
    return this.form.get("orders") as FormArray;
  }

  get hasProductOrderPermission() {
    return (
      (this.isAddMode &&
        this.permissionService.createProductOrderPermission().hasPermission) ||
      (!this.isAddMode &&
        (this.permissionService.updateProductOrderPermission().hasPermission ||
          this.permissionService.readProductOrderPermission().hasPermission))
    );
  }

  generateDropDownItems() {
    if (!this.customers) return;

    this.customerDropdown = this.customers.map((customer) => {
      return { id: customer.id, label: customer.MATCHC ?? "MATCHC unbekannt" };
    });

    if (!this.locations) return;
    this.createLocationDropdown();
  }

  ngOnInit() {
    this.getMode();
    this.today = this.dateConverterService.formatIsoDateToTaigaDate(
      new Date().toISOString(),
    );
    this.iniForm();
    this.productService.getAllProducts().subscribe((res) => {
      this.products = res.records;
    });
    this.customerService.setSorting({ sortColumn: "name", sortDirection: 1 });
    this.initCustomersAndLocations();
    this.initFormSubscriptions();
    this.handleProductOrderPermission();
  }

  iniForm() {
    this.form = this.fb.group({
      customerId: [null, Validators.required],
      locationId: [null, Validators.required],
      timeframeBegin: [null, Validators.required],
      timeframeEnd: [null, Validators.required],
      orders: this.fb.array([
        this.fb.group({
          amount: [null, [Validators.required, Validators.min(1)]],
          productId: ["", Validators.required],
          quality: ["", Validators.required],
          tabIndex: [0],
          pricePerProduct: [null, [Validators.required, Validators.min(0)]],
        }),
      ]),
    });
  }

  initCustomersAndLocations() {
    this.customerService.getAllCustomers({}, false).subscribe((res) => {
      this.customers = res.records;

      this.locationService.getAllLocations().subscribe((locations) => {
        this.locations = locations.records;

        // Filter out customers that have no locations
        this.customers = this.customers.filter((customer) =>
          this.locations.some(
            (location) => location.customerId === customer.id,
          ),
        );

        this.generateDropDownItems();

        // load product data into the form in edit mode
        if (this.isAddMode || !this.toUpdateId) return;
        this.initCaseEdit(this.toUpdateId);
      });
    });
  }

  initFormSubscriptions() {
    this.initCustomerSub();
    this.initLocationSub();
  }

  initCustomerSub() {
    this.subscriptions.add(
      this.form
        .get("customerId")
        ?.valueChanges.subscribe((value: DropDownItem | string | null) => {
          if (typeof value === "string") {
            // If a user types something that isn't in the dropdown items, clear the customerId and locationId fields
            const dropdownItemExists = this.customerDropdown.some(
              (item) => item.label === value.trim(),
            );
            if (!dropdownItemExists) {
              this.form.controls["customerId"].setValue(null);
              this.form.controls["locationId"].setValue(null);
            }
          }
        }),
    );
  }

  handleProductOrderPermission() {
    // remove first productOrder if user has no permission to create product orders
    if (
      this.isAddMode &&
      !this.permissionService.createProductOrderPermission().hasPermission
    ) {
      this.orders.removeAt(0);
    }
  }

  initLocationSub() {
    this.subscriptions.add(
      this.form
        .get("locationId")
        ?.valueChanges.subscribe((value: DropDownItem | string | null) => {
          if (typeof value === "string") {
            // If a user types something that isn't in the dropdown items, clear locationId field
            const dropdownItemExists = this.locationDropdown.some(
              (item) => item.label === value.trim(),
            );
            if (!dropdownItemExists) {
              this.form.controls["locationId"].setValue(null);
            }
          }
        }),
    );
  }

  initCaseEdit(id: string) {
    this.orderService.getOrderById(id).subscribe((order: Order) => {
      const locationName = this.locationDropdown.find(
        (location) => location.id === order.locationId,
      )?.label;

      this.form.controls["locationId"].setValue({
        id: order.locationId,
        label: locationName,
      });

      this.form.controls["timeframeBegin"].setValue(
        this.dateConverterService.formatIsoDateToTaigaDate(
          order.timeframeBegin,
        ),
      );
      this.form.controls["timeframeEnd"].setValue(
        this.dateConverterService.formatIsoDateToTaigaDate(order.timeframeEnd),
      );

      this.previousProductOrders = [...order.productOrders];

      // Make a copy of orders array
      let ordersCopy = this.form.get("orders") as FormArray;

      // Clear the form array
      ordersCopy.clear();

      // Create a new form array after pushing the orders into it
      order.productOrders.forEach((productOrder: ProductOrder) =>
        this.pushOrder(ordersCopy, productOrder),
      );

      // Reassigning the new form array instance to 'orders' control in the form
      this.form.setControl("orders", ordersCopy);
    });
  }

  pushOrder(ordersArray: FormArray, productOrder: ProductOrder) {
    const product = productOrder.product;
    if (!product) return;

    const quality = productOrder.acceptedQuality;
    if (!quality) return;

    const qualityLabel = this.getLabelOfQualities(quality);

    ordersArray.push(
      this.fb.group({
        id: [productOrder.id],
        amount: [
          {
            value:
              productOrder.amount > 0
                ? productOrder.amount
                : productOrder.amount * -1,
            disabled:
              !this.permissionService.updateProductOrderPermission()
                .hasPermission,
          },
          [Validators.required, Validators.min(1)],
        ],
        productId: [
          {
            value: { id: product.id, label: product.name },
            disabled: true,
          },
          Validators.required,
        ],
        quality: [
          {
            value: { id: quality, label: qualityLabel },
            disabled: true,
          },
          Validators.required,
        ],
        tabIndex: [productOrder.amount > 0 ? 1 : 0],
        pricePerProduct: [
          {
            value: productOrder.pricePerProduct,
            disabled:
              !this.permissionService.updateProductOrderPermission()
                .hasPermission,
          },
          [Validators.required, Validators.min(0)],
        ],
      }),
    );
  }

  getMode() {
    if (this.context.data) {
      this.mode = Mode.EDIT;
      this.toUpdateId = this.context.data;
    } else {
      this.mode = Mode.ADD;
    }
  }

  submit() {
    if (this.form.invalid) return;

    const start = this.dateConverterService.formatTaigaDateToIsoDate(
      this.form.get("timeframeBegin")?.value,
    );
    const end = this.dateConverterService.formatTaigaDateToIsoDate(
      this.form.get("timeframeEnd")?.value,
      false,
    );

    const order: Order = new Order({
      locationId: this.form.controls["locationId"]?.value.id,
      timeframeBegin: start,
      timeframeEnd: end,
    });
    if (this.isAddMode) {
      order.orderState = ORDER_STATE.UNPLANNED;
      this.addOrder(order);
    } else if (!this.isAddMode) {
      this.updateOrder(order);
    }
  }

  addOrder(order: Order) {
    this.orderService.addOrder(order).subscribe({
      next: (order: Order) => {
        if (
          this.orders.controls.length < 1 ||
          !this.permissionService.createProductOrderPermission().hasPermission
        ) {
          this.showSuccessMessage();
          return;
        }
        const requestObservables: Observable<any>[] = [];

        this.orders.controls.forEach((orderControl: AbstractControl) => {
          const productOrder = new ProductOrder({
            orderId: order.id,
            productId: orderControl.value.productId.id,
            acceptedQuality: orderControl.getRawValue().quality.id,
            amount: orderControl.value.amount,
            pricePerProduct: orderControl.value.pricePerProduct,
          });

          // if the palettes are unload make the amount negative
          if (orderControl.value.tabIndex === 0)
            productOrder.amount = -productOrder.amount;

          requestObservables.push(
            this.productOrderService.addProductOrder(productOrder, false),
          );
        });

        this.handleMultipleRequests(requestObservables, "CREATE");
      },
    });
  }

  updateOrder(order: Order) {
    this.orderService.updateOrderById(this.toUpdateId, order).subscribe({
      next: (order: Order) => {
        const requestObservables: Observable<any>[] = [];

        if (
          this.permissionService.updateProductOrderPermission().hasPermission ||
          this.permissionService.createProductOrderPermission().hasPermission
        ) {
          // go through all productOrder Form Controls and update/add/delete them
          for (const element of this.orders.controls) {
            // enable quality dropdown if its disable to read the value
            const productOrder = new ProductOrder({
              orderId: order.id,
              productId: element.getRawValue().productId.id, // getRawValue to get the disabled value
              acceptedQuality: element.getRawValue().quality.id,
              amount: element.value.amount,
              pricePerProduct: element.value.pricePerProduct,
            });

            // if the palettes are unload make the amount negative
            if (element.value.tabIndex === 0)
              productOrder.amount = -productOrder.amount;

            // find the productOrder in previousProductOrders
            const existingProductOrder = this.previousProductOrders.find(
              (prevProductOrder: ProductOrder) =>
                prevProductOrder.id === element.value.id,
            );

            // If the productOrder was found, update it
            // If not, add it
            const addOrUpdateObservable =
              existingProductOrder != null
                ? this.productOrderService.updateProductOrderById(
                    existingProductOrder.id,
                    productOrder,
                    false,
                  )
                : this.productOrderService.addProductOrder(productOrder, false);

            // add the observable to the requestObservables array if the user has the permission
            if (
              (existingProductOrder !== null &&
                this.permissionService.updateOrderPermission().hasPermission) ||
              (existingProductOrder === null &&
                !this.permissionService.createProductOrderPermission()
                  .hasPermission)
            ) {
              requestObservables.push(addOrUpdateObservable);
            }
          }
        }

        if (
          this.permissionService.deleteProductOrderPermission().hasPermission
        ) {
          // productOrder Form Controls was deleted
          // go through all previous formControls and delete the productOrders that are not selected anymore
          for (const element of this.previousProductOrders) {
            const prevProductOrder = element;
            if (!prevProductOrder.id) {
              continue;
            }
            const deleteObservable = !this.orders.controls.some(
              (orderControl: AbstractControl) => {
                return orderControl.value.id === prevProductOrder.id;
              },
            )
              ? this.productOrderService.deleteProductOrderById(
                  prevProductOrder.id,
                  false,
                )
              : null;

            if (deleteObservable != null) {
              requestObservables.push(deleteObservable);
            }
          }
        }

        this.handleMultipleRequests(requestObservables, "UPDATE");
      },
    });
  }

  getAllPossibleCustomerPrices() {
    const formData = this.form.value;

    // needed to be deleted to work properly.... check what this was for
    // if (this.getCustomerPriceCondition(this.form)) return;

    formData.orders.forEach((order: any, index: number) => {
      if (this.getCustomerPriceCondition(this.form, index)) return;
      this.getCustomerProductPriceForOrder(order, index);
    });
  }

  getCustomerPriceCondition(form: FormGroup, index?: number): boolean {
    return (
      !form.value ||
      !form.value.customerId ||
      form.value.orders.length <= 0 ||
      !form.getRawValue().orders[index ?? 0].quality ||
      !form.value.orders[index ?? 0].productId
    );
  }

  getCustomerProductPriceForOrder(order: any, index: number) {
    // if not all attributes got a value, return
    if (
      !this.form.value.customerId.id ||
      !order.productId.id ||
      !this.form.getRawValue().orders[index ?? 0].quality
    )
      return;

    const queryParams: PriceQueryParams = {
      customerId: this.form.value.customerId.id,
      productId: order.productId.id,
      quality: this.form.getRawValue().orders[index ?? 0].quality.id,
    };

    this.productPriceService
      .getAllProductPrices(queryParams)
      .subscribe((res) => {
        const latestRecord = this.getLatestValidRecord(res.records);

        // no valid customer price > set default value or null
        if (res.total <= 0 || !latestRecord) {
          this.getBaseProductPrice(queryParams, index);
        } else {
          //latest valid customer price
          (this.orders.at(index) as FormGroup).controls[
            "pricePerProduct"
          ].setValue(latestRecord.price);
        }
      });
  }

  getLatestValidRecord(records: ProductPrice[]): any {
    const formData = this.form.value;

    // Define default begin and end time as current time.
    let beginTime = DateTime.now().setZone("Europe/Berlin");
    let endTime = DateTime.now().setZone("Europe/Berlin");

    // If formData has begin time and end time, use those instead.
    if (formData.timeframeBegin && formData.timeframeEnd) {
      beginTime = DateTime.fromISO(
        this.dateConverterService.formatTaigaDateToIsoDate(
          this.form.controls["timeframeBegin"].value,
        ),
      );

      endTime = DateTime.fromISO(
        this.dateConverterService.formatTaigaDateToIsoDate(
          this.form.controls["timeframeEnd"].value,
        ),
      );
    }

    // Filter records where the order timeframe overlaps with the record timeframe
    const validRecords = records.filter((record) => {
      const recordBegin = DateTime.fromISO(record.timeframeBegin);
      const recordEnd = DateTime.fromISO(record.timeframeEnd);
      return beginTime <= recordEnd && endTime >= recordBegin;
    });

    // If there are no valid records, return null or the default price.
    if (!validRecords.length) {
      return null; // replace this with the default price if needed
    }

    // Find the record with the latest timeframeEnd.
    return validRecords.reduce((latest, curr) => {
      return DateTime.fromISO(latest.timeframeEnd) >
        DateTime.fromISO(curr.timeframeEnd)
        ? latest
        : curr;
    });
  }

  getBaseProductPrice(queryParams: PriceQueryParams, index: number) {
    const params = {
      customerId: null,
      productId: queryParams.productId,
      quality: queryParams.quality,
    };

    this.productPriceService.getAllProductPrices(params).subscribe((res) => {
      if (res.total <= 0) {
        (this.orders.at(index) as FormGroup).controls[
          "pricePerProduct"
        ].setValue(null);
      } else {
        (this.orders.at(index) as FormGroup).controls[
          "pricePerProduct"
        ].setValue(res.records[0].price);
      }
    });
  }

  createLocationDropdown() {
    this.locationDropdown = this.locations.map((location) => {
      const address = `${location.address.street} ${location.address.houseNo}, ${location.address.postalCode} ${location.address.city}`;
      return { id: location.id, label: address };
    });
  }

  onCustomerChange(customerDropdown: DropDownItem) {
    if (this.isProcessingValueChange) return;
    this.isProcessingValueChange = true;

    if (!customerDropdown) {
      // If the customer is null reset the location
      this.form.controls["locationId"].setValue(null);
      this.locationDropdown = [];
      this.isProcessingValueChange = false;
      return;
    }

    // filter locations by customer id and map them to dropdown items
    const filteredLocations = this.locations.filter(
      (location) => location.customerId === customerDropdown.id,
    );

    this.locationDropdown = filteredLocations.map((location) => {
      const address = `${location.address.street} ${location.address.houseNo}, ${location.address.postalCode} ${location.address.city}`;
      return { id: location.id, label: address };
    });

    if (this.locationDropdown.length === 1) {
      this.form.controls["locationId"].setValue(this.locationDropdown[0]);
    } else {
      this.form.controls["locationId"].setValue(null);
    }
    this.getAllPossibleCustomerPrices();
    this.isProcessingValueChange = false;
  }

  onLocationChange(locationDropdown: DropDownItem) {
    if (this.isProcessingValueChange) return;
    this.isProcessingValueChange = true;

    if (!locationDropdown) {
      this.form.controls["customerId"].setValue(null);
      this.isProcessingValueChange = false;
      return;
    }

    const foundLocation = this.locations.find(
      (location) => location.id === locationDropdown.id,
    );

    if (!foundLocation) {
      this.isProcessingValueChange = false;
      return;
    }

    const foundCustomer = this.customers.find(
      (customer) => customer.id === foundLocation.customerId,
    );

    if (!foundCustomer) {
      this.isProcessingValueChange = false;
      return;
    }

    this.form.controls["customerId"].setValue({
      id: foundCustomer.id,
      label: foundCustomer.MATCHC,
    });

    this.isProcessingValueChange = false;
  }

  cancel() {
    this.closeDialog();
  }

  productChange($event: any, index: number) {
    const foundProduct = this.products.find(
      (product) => product.id === $event.id,
    );

    if (!foundProduct) return;

    // Copy and sort the array
    let sortedQualities = [...foundProduct.possibleQualities];
    sortedQualities.sort(this.sortQualities);

    // Map the sorted array
    this.qualityDropDown = sortedQualities.map((quality) => {
      return { id: quality, label: this.getLabelOfQualities(quality) };
    });

    //  if there is only one quality, set it as default otherwise reset the form
    (this.orders.at(index) as FormGroup).controls["quality"].enable();
    if (this.qualityDropDown.length === 1) {
      (this.orders.at(index) as FormGroup).controls["quality"].setValue(
        this.qualityDropDown[0],
      );
      (this.orders.at(index) as FormGroup).controls["quality"].disable();
    } else {
      (this.orders.at(index) as FormGroup).controls["quality"].setValue(null);
    }

    // small workaround to fix that getAllPossibleCustomerPrices uses the previous value
    setTimeout(() => {
      this.getAllPossibleCustomerPrices();
    }, 0);
  }

  getLabelOfQualities(quality: PRODUCT_QUALITY) {
    switch (quality) {
      case PRODUCT_QUALITY.QUALITY_NEU:
        return "Neu";
      case PRODUCT_QUALITY.QUALITY_A:
        return "A";
      case PRODUCT_QUALITY.QUALITY_B:
        return "B";
      case PRODUCT_QUALITY.QUALITY_C:
        return "C";
      case PRODUCT_QUALITY.QUALITY_DEFEKT:
        return "Defekt";
    }
  }

  sortQualities = (a: PRODUCT_QUALITY, b: PRODUCT_QUALITY) => {
    const order = [
      PRODUCT_QUALITY.QUALITY_NEU,
      PRODUCT_QUALITY.QUALITY_A,
      PRODUCT_QUALITY.QUALITY_B,
      PRODUCT_QUALITY.QUALITY_C,
      PRODUCT_QUALITY.QUALITY_DEFEKT,
    ];
    return order.indexOf(a) - order.indexOf(b);
  };

  addOrderFormRow() {
    this.orders.push(
      this.fb.group({
        amount: [null, [Validators.required, Validators.min(1)]],
        productId: ["", Validators.required],
        quality: ["", Validators.required],
        tabIndex: [0],
        pricePerProduct: [null, [Validators.required, Validators.min(0)]],
      }),
    );
  }

  deleteOrderFormRow(index: number) {
    this.orders.removeAt(index);
  }

  showErrorMessage() {
    this.closeDialog();
    this.pushService.sendPush(
      pushTypes.ERROR,
      "",
      "Beim speichern der Bestellung ist ein Fehler aufgetreten.",
    );
  }

  showSuccessMessage() {
    this.closeDialog();
    this.pushService.sendPush(
      pushTypes.SUCCESS,
      "",
      "Die Bestellung wurde erfolgreich gespeichert.",
    );
  }

  handleMultipleRequests(
    requestObservables: Observable<any>[],
    methodType?: MethodType,
    err: boolean = false, // indicates if the request that was made before "handleMultipleRequests" was an error
  ) {
    if (!requestObservables || requestObservables.length === 0) {
      if (!err) {
        this.showSuccessMessage();
      }
      return;
    }

    // combines all observable for all requests, the subscription is only called when all requests are finished
    forkJoin(requestObservables).subscribe({
      next: () => {
        // Success handling
        this.showSuccessMessage();
      },
      error: (err) => {
        if (methodType) {
          this.errorHandler.sendErrorMessage("order", methodType, err);
        } else {
          this.showErrorMessage();
        }
      },
    });
  }

  // update the min date of timeframeEnd, whenever timeframeBegin changes (endDate cant be earlier than startDate)
  timeframeBeginChange($event: any) {
    this.timeframeBegin = $event[0];
  }

  qualityChange() {
    setTimeout(() => {
      this.getAllPossibleCustomerPrices();
    }, 0);
  }

  // Checks if the product order is loaded from the api or if it is a new product order created in the frontend form

  isExistingProductOrder(index: number) {
    return this.orders.controls[index].get("id")?.value != null;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { GoogleMap } from "@angular/google-maps";
import Warehouse from "../../models/Warehouse.class";
import LocationC from "../../models/Location.class";
import { MapBaseDataService } from "./map-helper-services/map-base-data.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TourService } from "../../api/tour.service";
import {
  tuiIconDragLarge,
  tuiIconFileTextLarge,
  tuiIconMinusLarge,
} from "@taiga-ui/icons";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { MapMarkersService } from "./map-helper-services/map-markers.service";
import { MapTpTplService } from "./map-helper-services/map-tp-tpl.service";
import { tuiCreateTimePeriods } from "@taiga-ui/kit";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TuiTime } from "@taiga-ui/cdk";
import Tour, { TOUR_STATE } from "../../models/Tour.class";
import { MapOrdersService } from "./map-helper-services/map-orders.service";
import { MapPermissionHelperService } from "../../services/permission-helper-services/map-permission-helper.service";

export interface MarkerWithItem {
  marker: google.maps.Marker;
  item: Warehouse | LocationC;
}

@Component({
  selector: "app-map-page",
  templateUrl: "./map-page.component.html",
  styleUrls: ["./map-page.component.scss"],
})

/**
 * Displays a map provided by Google
 */
export class MapPageComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap) googleMap: GoogleMap;
  mapOptions: google.maps.MapOptions = this.mapBaseService.mapOptions;
  showOrders = signal(true);
  tuiTimeValues = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);
  timeForm: FormGroup;
  mapPermissionHelper = inject(MapPermissionHelperService);
  protected readonly tuiIconDragLarge = tuiIconDragLarge;
  protected readonly tuiIconFileTextLarge = tuiIconFileTextLarge;
  protected readonly tuiIconMinusLarge = tuiIconMinusLarge;
  protected readonly TOUR_STATE = TOUR_STATE;

  constructor(
    private mapBaseService: MapBaseDataService,
    private markerService: MapMarkersService,
    public mapTPService: MapTpTplService,
    private route: ActivatedRoute,
    private router: Router,
    private tourService: TourService,
    private fb: FormBuilder,
    private mapOrderService: MapOrdersService,
  ) {}

  get tour() {
    return this.mapTPService.tour;
  }

  get tourStateValidation() {
    return (
      this.tour.tourState === TOUR_STATE.DRAFT ||
      this.tour.tourState === TOUR_STATE.READY
    );
  }

  ngOnInit(): void {
    this.resetOrderFilter();
    this.initTimeForm();

    this.getTourFromUrl(() => {
      this.mapTPService.getTourPointsWithCallback(() => {
        this.initTimeForm(this.mapTPService.tour);
        this.markerService.getLocationsAndCreateMarkers();
        this.markerService.getWarehousesAndCreateMarkers();
      });
    });
  }

  ngAfterViewInit(): void {
    // set the map in the base-service, so it is accessible in other components
    this.mapBaseService.map = this.googleMap;
    this.markerService.showAllMarkers();
  }

  //   normal methods ------------------------------------------------------------------------------ //
  getTourFromUrl(callback?: Function) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      if (param.has("id")) {
        const tourId = param.get("id")!;
        this.tourService.getTourById(tourId).subscribe((tour) => {
          this.mapTPService.tour = tour;
          this.mapBaseService.tour.set(tour);

          if (callback) callback();
        });
      } else if (callback) {
        callback();
      }
    });
  }

  resetOrderFilter() {
    this.mapOrderService.filters = {};
  }

  getTuiTime(inputDateString: string): TuiTime {
    const date = new Date(inputDateString);

    return new TuiTime(date.getHours(), date.getMinutes());
  }

  initTimeForm(tour?: Tour) {
    this.timeForm = this.fb.group({
      startingTime: [
        tour ? this.getTuiTime(tour.date) : null,
        Validators.required,
      ],
    });
  }

  drop(event: any) {
    let updatedData = [...event.container.data];

    // reorder the array depending on the drop
    moveItemInArray(updatedData, event.previousIndex, event.currentIndex);

    // update the tp array to the new order
    this.mapTPService.tourPoints = updatedData;

    // update the sequence numbers and routes + arrival times
    this.mapTPService.updateSequenceNumbers(() => {
      this.mapTPService.setTourPointsAndCreateRoute();
      this.markerService.getLocationsAndCreateMarkers();
      this.markerService.getWarehousesAndCreateMarkers();
    });
  }

  navigateBack() {
    this.router.navigate(["/tours"]).then();
  }

  toggleOrders() {
    this.showOrders.set(!this.showOrders());
  }

  onStartingTimeChange(time: TuiTime) {
    this.updateTourTime(this.updateTourIsoDate(time));
  }

  updateTourIsoDate(time: TuiTime) {
    // convert the tour date ina an actual date
    const date = new Date(this.mapTPService.tour.date);

    // update the date with the new taiga ui time, from timepicker
    date.setHours(time.hours);
    date.setMinutes(time.minutes);

    // return new iso date, with updated time
    return date.toISOString();
  }

  updateTourTime(isoDate: string) {
    if (!this.mapTPService.tour.id || !this.mapTPService.tour.date) return;

    const updatedTour = new Tour({
      date: isoDate,
    });

    this.tourService
      .updateTourById(this.mapTPService.tour.id, updatedTour)
      .subscribe((res: Tour) => {
        //   refresh data
        this.mapTPService.tour = res;
        this.mapTPService.setArrivalTimes();
      });
  }

  formatDuration(durations: number[]): string {
    // Sum up all durations
    const duration = durations.reduce((total, current) => total + current, 0);

    if (duration < 60) {
      // Duration is less than an hour
      return `${duration} Minuten`;
    } else {
      // Duration is more than an hour
      const hours = Math.floor(duration / 60);
      const remainingMinutes = duration % 60;
      return `${hours} ${
        hours === 1 ? "Stunde" : "Stunden"
      }, ${remainingMinutes} Minuten`;
    }
  }

  toggleTourStatus() {
    this.updateTourStatus();
  }

  updateTourStatus() {
    if (
      !this.mapTPService.tour.id ||
      (this.mapTPService.tour.tourState !== TOUR_STATE.DRAFT &&
        this.mapTPService.tour.tourState !== TOUR_STATE.READY)
    )
      return;

    const updateTourStateObject = new Tour({
      tourState:
        this.mapTPService.tour.tourState === TOUR_STATE.DRAFT
          ? TOUR_STATE.READY
          : TOUR_STATE.DRAFT,
    });

    this.tourService
      .updateTourById(this.mapTPService.tour.id, updateTourStateObject)
      .subscribe((res) => {
        //   refresh data
        this.mapTPService.tour = res;
      });
  }
}

<nav class="topbar-wrapper">
  <div class="title-wrapper">
    <h1>28apps</h1>
  </div>

  <div class="interaction-wrapper">
    <form [formGroup]="searchFieldForm" class="search-wrapper">
      <tui-input
        formControlName="searchField"
        tuiTextfieldIconLeft="tuiIconSearch"
        class="input-search"
        tuiTextfieldSize="m"
      >
        Suchen
        <input tuiTextfield />
      </tui-input>
    </form>

    <tui-hosted-dropdown
      tuiDropdownLimitWidth="fixed"
      [content]="dropdownContent"
    >
      <button
        tuiButton
        appearance="flat"
        type="button"
        size="m"
        [iconRight]="arrow"
      >
        <tui-svg src="tuiIconUser" class="button-icon"></tui-svg>
        Super Admin
      </button>
    </tui-hosted-dropdown>
  </div>
</nav>

<!-- Template which describes the structure of the dropdown element -->
<ng-template #dropdownContent>
  <div
    tuiGroup
    tuiDropdownLimitWidth="auto"
    orientation="vertical"
    class="dropdown-wrapper"
  >
    <div class="text-dropdown-wrapper">
      <label class="tui-text_body-l-2">Super Admin</label>
      <label class="tui-text_body-s">28Apps</label>
      <hr />
    </div>
    <button
      tuiButton
      type="button"
      appearance="flat"
      size="m"
      [routerLink]="'/' + NavRoutes.SETTINGS + '/' + NavRoutes.PROFILE"
    >
      Einstellungen
    </button>
    <button tuiButton type="button" appearance="flat" size="m" routerLink="/">
      Abmelden
    </button>
  </div>
</ng-template>

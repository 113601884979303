import { EventEmitter, Injectable, Output } from "@angular/core";
import LocationC from "../../../models/Location.class";
import Warehouse from "../../../models/Warehouse.class";
import { LocationService } from "../../../api/location.service";
import { WarehouseService } from "../../../api/warehouse.service";
import { MapBaseDataService } from "./map-base-data.service";
import { MapTpTplService } from "./map-tp-tpl.service";
import { MapOrdersService } from "./map-orders.service";
import { pushTypes } from "../../../other/enums/push-types";
import { PushService } from "../../../services/push.service";
import { MarkerWithItem } from "../map-page.component";

type ItemMarker<T extends LocationC | Warehouse> = {
  marker: google.maps.Marker;
  item: T;
};

@Injectable({
  providedIn: "root",
})
export class MapMarkersService {
  locations: LocationC[] = [];
  warehouses: Warehouse[] = [];
  allMarkers: ItemMarker<LocationC | Warehouse>[] = [];

  @Output() hoverLocation = new EventEmitter<string>();
  @Output() leaveHoverLocation = new EventEmitter<string>();

  constructor(
    private locationService: LocationService,
    private warehouseService: WarehouseService,
    private mapBaseService: MapBaseDataService,
    private mapTPService: MapTpTplService,
    private mapOrderService: MapOrdersService,
    private pushService: PushService,
  ) {}

  getWarehousesAndCreateMarkers() {
    this.warehouseService.getAllWarehouses().subscribe((warehouses) => {
      this.warehouses = warehouses.records;
      this.createWarehouseMarkers();
    });
  }

  // create a google marker for every warehouse
  createWarehouseMarkers() {
    if (!this.mapBaseService.map.googleMap) return;

    this.warehouses.forEach((warehouse) => {
      const warehouseInstance = new Warehouse(warehouse);
      const sequenceNumber = this.getSequenceNumber(warehouseInstance);
      const numberedIcon = this.mapBaseService.createNumberedMarkerIcon(
        this.mapBaseService.logoSvg,
        sequenceNumber !== undefined ? sequenceNumber + 1 : undefined,
      );
      const markerExists = this.doesMarkerExist(warehouseInstance);

      this.updateOrCreateMarker(markerExists, numberedIcon, warehouseInstance);
    });
  }

  doesMarkerExist<T extends Warehouse | LocationC>(item: T) {
    return this.allMarkers.some((markerItem) => markerItem.item.id === item.id);
  }

  updateOrCreateMarker<T extends Warehouse | LocationC>(
    markerExists: boolean,
    numberedIcon: string,
    item: T,
  ): google.maps.Marker {
    if (markerExists) {
      // address changed >> remove old marker, create a new one
      const previousItem = this.allMarkers.find(
        (markerItem) => markerItem.item.id === item.id,
      )?.item;
      if (previousItem && previousItem.address !== item.address) {
        this.removeMarker(item);
        return this.createMarker(item, numberedIcon);
      }

      // default case
      return this.updateMarker(item, numberedIcon);
    } else {
      return this.createMarker(item, numberedIcon);
    }
  }

  updateMarker<T extends Warehouse | LocationC>(item: T, numberedIcon: string) {
    const marker = this.allMarkers.find(
      (markerItem) => markerItem.item.id === item.id,
    )!.marker;
    const defaultIconSize = new google.maps.Size(60, 60);
    marker.setIcon({ url: numberedIcon, scaledSize: defaultIconSize });

    return marker;
  }

  createMarker<T extends Warehouse | LocationC>(item: T, numberedIcon: string) {
    const marker = this.createGoogleMarkers(
      item.address.latitude,
      item.address.longitude,
      item.name ?? "",
      numberedIcon,
      item,
    );
    const markerWithItem = this.createItemMarker(marker, item);

    this.allMarkers.push(markerWithItem);

    return marker;
  }

  createItemMarker<T extends Warehouse | LocationC>(
    marker: google.maps.Marker,
    item: T,
  ): ItemMarker<T> {
    return { marker, item };
  }

  getLocationsAndCreateMarkers() {
    this.locationService.getAllLocations().subscribe((locations) => {
      this.locations = locations.records;
      this.filterLocationsByHasOrder(() => {
        this.createLocationMarkers();
      });
    });
  }

  // create a google marker for every location
  createLocationMarkers() {
    if (!this.mapBaseService.map.googleMap) return;

    this.locations.forEach((location) => {
      const locationInstance = new LocationC(location);
      const sequenceNumber = this.getSequenceNumber(locationInstance);
      const numberedIcon = this.mapBaseService.createNumberedMarkerIcon(
        this.mapBaseService.locationSvg,
        sequenceNumber !== undefined ? sequenceNumber + 1 : undefined,
      );
      const markerExists = this.doesMarkerExist(locationInstance);

      this.updateOrCreateMarker(markerExists, numberedIcon, locationInstance);
    });
  }

  // create and return a basic google Marker
  createGoogleMarkers<T extends Warehouse | LocationC>(
    lat: number,
    lng: number,
    title: string,
    iconUrl: string,
    item: T,
  ) {
    const marker = new google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: this.mapBaseService.map.googleMap,
      title: title,
      icon: { url: iconUrl, scaledSize: new google.maps.Size(60, 60) },
    });

    const markerWithItem: MarkerWithItem = {
      item: item,
      marker: marker,
    };
    this.addMarkerHoverListeners(markerWithItem, iconUrl, item);

    return marker;
  }

  // make the marker bigger by 20px on hover
  addMarkerHoverListeners<T extends Warehouse | LocationC>(
    marker: MarkerWithItem,
    iconUrl: string,
    item: T,
  ) {
    const defaultIconSize = new google.maps.Size(60, 60);
    const hoveredIconSize = new google.maps.Size(80, 80);

    marker.marker.addListener("mouseover", () => {
      marker.marker.setTitle(marker.marker.getTitle());

      const sequenceNumber = this.getSequenceNumber(item);
      const newNumberedIcon = this.mapBaseService.createNumberedMarkerIcon(
        item instanceof Warehouse
          ? this.mapBaseService.logoSvg
          : this.mapBaseService.locationSvg,
        sequenceNumber !== undefined ? sequenceNumber + 1 : undefined,
      );

      marker.marker.setIcon({
        url: newNumberedIcon,
        scaledSize: hoveredIconSize,
      });

      if (item instanceof LocationC) {
        this.hoverLocation.emit(item.id);
      }
    });

    marker.marker.addListener("mouseout", () => {
      // Fetch the new sequence number on mouseout
      const sequenceNumber = this.getSequenceNumber(item);
      const newNumberedIcon = this.mapBaseService.createNumberedMarkerIcon(
        item instanceof Warehouse
          ? this.mapBaseService.logoSvg
          : this.mapBaseService.locationSvg,
        sequenceNumber !== undefined ? sequenceNumber + 1 : undefined,
      );

      marker.marker.setIcon({
        url: newNumberedIcon,
        scaledSize: defaultIconSize,
      });

      if (item instanceof LocationC) {
        this.leaveHoverLocation.emit(item.id);
      }
    });

    marker.marker.addListener("click", () => {
      if (item) {
        if (item instanceof LocationC) {
          this.mapOrderService.getOrdersByLocationId(item.id);
        } else if (item instanceof Warehouse) {
          if (!this.mapTPService.addTourPoint(item)) {
            this.pushService.sendPush(
              pushTypes.INFO,
              "",
              "Tour Status muss 'Entwurf' sein, um Änderungen vorzunehmen!",
            );
          } else {
            this.mapTPService.addTourPoint(item)?.subscribe(() => {
              this.mapTPService.getTourPointsWithCallback(() => {
                this.getLocationsAndCreateMarkers();
                this.getWarehousesAndCreateMarkers();
              });
            });
          }
        }
      }
    });
  }

  //   add the hover logic for the hover over an order card
  mouseEnterOrderCard(marker: MarkerWithItem) {
    const hoveredIconSize = new google.maps.Size(70, 70);

    const sequenceNumber = this.getSequenceNumber(new LocationC(marker.item));

    marker.marker.setIcon({
      url: this.mapBaseService.createNumberedMarkerIcon(
        this.mapBaseService.locationSvg,
        sequenceNumber !== undefined ? sequenceNumber + 1 : undefined,
      ),
      scaledSize: hoveredIconSize,
    });
  }

  mouseLeaveOrderCard(marker: MarkerWithItem) {
    const defaultIconSize = new google.maps.Size(60, 60);

    const sequenceNumber = this.getSequenceNumber(new LocationC(marker.item));

    marker.marker.setIcon({
      url: this.mapBaseService.createNumberedMarkerIcon(
        this.mapBaseService.locationSvg,
        sequenceNumber !== undefined ? sequenceNumber + 1 : undefined,
      ),
      scaledSize: defaultIconSize,
    });
  }

  //   HIDE / SHOW MARKER
  // hide marker
  hideMarkers() {
    this.allMarkers.forEach((markerItem) => {
      markerItem.marker.setMap(null);
    });
  }

  showAllMarkers() {
    this.allMarkers.forEach((markerItem) => {
      if (this.mapBaseService.map.googleMap)
        markerItem.marker.setMap(this.mapBaseService.map.googleMap); // set to Google map object.
    });
  }

  // show filtered marker
  showFilteredMarkers(locationIds: string[], tourPointsIds: string[]) {
    this.allMarkers.forEach((markerItem) => {
      if (
        locationIds.includes(markerItem.item.id) ||
        tourPointsIds.includes(markerItem.item.id) ||
        markerItem.item instanceof Warehouse
      ) {
        if (this.mapBaseService.map.googleMap)
          markerItem.marker.setMap(this.mapBaseService.map.googleMap); // set to Google map object.
      }
    });
  }

  // clear marker and apply the filtered marker
  applyFilter(locationIds: string[], tourPointsIds: string[]) {
    this.hideMarkers();
    this.showFilteredMarkers(locationIds, tourPointsIds);
  }

  filterLocationsByHasOrder(callback?: Function) {
    this.mapOrderService.getOrdersWithCallback(() => {
      const orders = this.mapOrderService.allOrders;

      // Before filtering, remove markers of each location
      this.locations.forEach((location) => this.removeMarker(location));

      this.locations = this.locations.filter((location) => {
        return orders.some((order) => order.locationId === location.id);
      });
      if (callback) callback();
    });
  }

  removeMarker<T extends Warehouse | LocationC>(item: T) {
    // Find the marker in the array
    let index = this.allMarkers.findIndex(
      (markerItem) => markerItem.item.id === item.id,
    );

    // If the marker exists
    if (index !== -1) {
      // Remove the marker from the Google Maps
      this.allMarkers[index].marker.setMap(null);
      // Remove the marker from the allMarkers array
      this.allMarkers.splice(index, 1);
    }
  }

  // the similar removal should be done for warehouses before updating them

  getSequenceNumber(item: LocationC | Warehouse): number | undefined {
    let tourPoint = this.mapTPService.tourPoints.find(
      (tp) =>
        (tp.location &&
          item instanceof LocationC &&
          tp.location.id === item.id) ||
        (tp.warehouse &&
          item instanceof Warehouse &&
          tp.warehouse.id === item.id),
    );

    return tourPoint?.sequenceNumber;
  }
}

<!-- tui-root required see: https://indepth.dev/posts/1417/demystifying-taiga-ui-root-component-portals-pattern-in-angular -->
<tui-root style="background-color: black">
  <!-- ellipse gradients -->
  <div class="bubble-gradient-top-left"></div>
  <div class="bubble-gradient-top-right"></div>
  <div class="bubble-gradient-bottom"></div>

  <!-- component content -->
  <router-outlet></router-outlet>
</tui-root>

<!-- globally used http loading spinner -->
<app-loading-spinner />

<app-table-layout
  (btnClick)="createUser()"
  (tabClick)="onFilterTabClick($event)"
  [fullWidth]="false"
  [missingReadPermissions]="
    permissionService.readUserPermission().missingPermissions
  "
  [showAddButton]="permissionService.createUserPermission().hasPermission"
  [showTable]="permissionService.readUserPermission().hasPermission"
  [tabs]="userFilterTabs"
  newButtonTxt="user.new-title"
  ngxTitle="user.coworker"
>
  <!--  search field  -->
  <div class="search-form" header-item style="min-width: 14em !important">
    <app-table-search-input
      (searchChangedDebounced)="search($event)"
    ></app-table-search-input>
  </div>

  <app-table
    (deleteEvent)="onDelete($event)"
    (editEvent)="editUser($event)"
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (sortEvent)="tableHelperService.sortTable($event)"
    [columns]="userTableShownColumns"
    [currentPage]="tablePage"
    [itemsDeletable]="permissionService.deleteUserPermission().hasPermission"
    [itemsEditable]="permissionService.updateUserPermission().hasPermission"
    [noSortColumns]="noSortingColumns"
    [pageSize]="tablePageSize"
    [tableColumnNames]="[
      'role.role' | translate,
      'user.table-first-name' | translate,
      'user.table-last-name' | translate,
      'user.table-telephone' | translate,
      'user.table-mail' | translate,
      'user.table-active' | translate,
      'user.role' | translate
    ]"
    [tableData]="userTableData"
    [totalElements]="userService.total"
  ></app-table>
</app-table-layout>

<router-outlet></router-outlet>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="deleteUser()"
  [isOpen]="showDeleteDialog"
/>

import { BaseModel } from "./BaseModel";
import Tour from "./Tour.class";
import TourPointLoad from "./TourPointLoad.class";
import DeliveryReceipt from "./DeliveryReceipt.class";
import LocationC from "./Location.class";
import Warehouse from "./Warehouse.class";
import Address from "./Address.class";
import ProductOrder from "./ProductOrder.class";
import Product from "./Product.class";
import Customer from "./Customer.class";
import Order from "./Order.class";

interface UnannouncedInfo extends BaseModel<UnannouncedInfo> {
  truckNumber: string;
  infoReceiptNumber: string;
  carrier: string;
}

export default class TourPoint extends BaseModel<TourPoint> {
  // tenantId: string;
  tour: Tour | null;
  tourId: string;
  location?: LocationC;
  warehouse?: Warehouse;
  sequenceNumber: number;
  estimatedArrival: Date | string;
  estimatedDistance: number;
  estimatedStopDuration: number;
  locationId: string;
  warehouseId: string;
  address: Address;
  // tourPointLoad: TourPointLoad[];
  tourPointLoads: TourPointLoad[];
  productOrders: ProductOrder[];
  // status: unknown
  deliveryReceipts: DeliveryReceipt[];
  customerId: string;
  customer: Customer;
  signedByWorker: string | null;
  signedByCustomer: string | null;

  // used for creating an unannounced delivery
  tplDtos: Partial<TourPointLoad>[];
  truckNumber: string;
  unannouncedCompany: string;
  unannouncedInfos: UnannouncedInfo[];

  hasDR?: boolean; // delivery-receipt
  product?: Product;
  order?: Order;

  constructor(params: Partial<TourPoint>) {
    super(params);
    Object.assign(this, params);
  }
}

import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";

@Injectable({
  providedIn: "root",
})
export class RefundsPermissionHelperService {
  permissionService = inject(PermissionService);

  allPermissionsForReadAllRefundInfos() {
    return (
      this.permissionService.readTourPointPermission().hasPermission &&
      this.permissionService.readTourPermission().hasPermission &&
      this.permissionService.readProductOrderPermission().hasPermission &&
      this.permissionService.readProductPermission().hasPermission &&
      this.permissionService.readLocationPermission().hasPermission &&
      this.permissionService.readOrderPermission().hasPermission &&
      this.permissionService.readUserPermission().hasPermission &&
      this.permissionService.readRefundPermission().hasPermission
    );
  }
}

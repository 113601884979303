<div class="header">
  <h1 style="padding-left: 1rem; margin-top: 0.5rem">
    {{ "sidenav.products" | translate }}
  </h1>
  @if (permissionService.createProductPermission().hasPermission) {
    <app-transparent-btn
      (btnClickEvent)="createProduct()"
      i18StringText="product.new.title"
      iconValue="tuiIconPlus"
    />
  }
</div>

@if (permissionService.readProductPermission().hasPermission) {
  <app-product-card
    [products]="products"
    [productPrices]="productPrices"
    (deleteEvent)="onDelete($event)"
    (editEvent)="onEdit($event)"
  />
} @else {
  <app-no-read-permission
    [missingPermissions]="
      permissionService.readProductPermission().missingPermissions
    "
  ></app-no-read-permission>
}

<!-- sure want to delete dialog -->
<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="deleteProduct()"
  [isOpen]="showDeleteDialog"
/>

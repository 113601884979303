import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import Customer from "../../../../models/Customer.class";
import { ProductPriceService } from "../../../../api/productPrice.service";
import ProductPrice from "../../../../models/ProductPrice.class";
import { TuiDialogHelperService } from "../../../../services/tui-dialog-helper.service";
import { AddEditCustomerPriceComponent } from "../../add-edit-customer-price/add-edit-customer-price.component";
import { CustomerPriceDeleteDialogComponent } from "./customer-price-delete-dialog/customer-price-delete-dialog.component";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { PermissionService } from "../../../../services/permission.service";
import { ProductPricePermissionHelperService } from "../../../../services/permission-helper-services/product-price-permission-helper.service";
import {
  BaseMissingPermissionsDialogComponent,
  ContextData,
} from "../../../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";

@Component({
  selector: "app-price-overview",
  templateUrl: "./price-overview.component.html",
  styleUrl: "./price-overview.component.scss",
})
export class PriceOverviewComponent implements OnChanges {
  @Input() customer: Customer;
  productPrices: ProductPrice[];
  priceService = inject(ProductPriceService);
  dialogService = inject(TuiDialogHelperService);
  headers: string[] = [
    "Produkt",
    "Qualität",
    "Preis",
    "Gültigkeitszeitraum",
    "Erstellt am",
    "Zuletzt verändert",
    "",
    "",
  ];
  columns: string[] = [
    "product.name",
    "quality",
    "price",
    "timeframeBegin",
    "createdAt",
    "updatedAt",
    "edit",
    "delete",
  ];
  // pagination
  total: number = 0;
  tablePage: number = 0;
  tablePageSize: number = 10;

  constructor(
    public permissionService: PermissionService,
    private productPricePermissionHelper: ProductPricePermissionHelperService,
  ) {}

  getPrices() {
    this.priceService
      .getAllProductPrices({ customerId: this.customer.id })
      .subscribe((prices) => {
        this.productPrices = prices.records;
        this.total = prices.total;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["customer"] &&
      changes["customer"].previousValue !== changes["customer"].currentValue
    ) {
      this.getPrices();
    }
  }

  editPrice(productPriceId: string) {
    if (this.productPricePermissionHelper.hasAllUpdateProductPricePermissions) {
      this.priceService
        .getProductPriceById(productPriceId)
        .subscribe((pPrice) => {
          const price = new ProductPrice(pPrice);
          this.dialogService.openDialog(
            AddEditCustomerPriceComponent,
            price,
            () => {
              // refresh data after dialog is closed
              this.getPrices();
            },
          );
        });
    } else {
      this.showMissingUpdatePermissions();
    }
  }

  showMissingUpdatePermissions() {
    const permissions: ContextData = {
      mode: "delete",
      elementName: "Kundenpreise",
      permissions: [
        {
          name: "Produkte ansehen",
          hasPermission:
            this.permissionService.readProductPermission().hasPermission,
        },
        {
          name: "Produktpreise ansehen",
          hasPermission:
            this.permissionService.readProductPricePermission().hasPermission,
        },
        {
          name: "Produktpreise aktualisieren",
          hasPermission:
            this.permissionService.updateProductPricePermission().hasPermission,
        },
      ],
    };

    this.dialogService.openDialog(
      BaseMissingPermissionsDialogComponent,
      permissions,
    );
  }

  deleteCustomerPrice(deleteId: string) {
    // sure want to delete dialog
    this.dialogService.openDialog(
      CustomerPriceDeleteDialogComponent,
      deleteId,
      () => {
        this.getPrices();
      },
    );
  }

  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.priceService.setPaginationIndices({
      start: this.tablePage * this.tablePageSize,
      limit: this.tablePageSize,
    });
    this.getPrices();
  }
}

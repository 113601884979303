<svg
  fill="none"
  height="60"
  viewBox="0 0 60 60"
  width="60"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M52.5 25C52.5 42.5 30 57.5 30 57.5C30 57.5 7.5 42.5 7.5 25C7.5 19.0326 9.87053 13.3097 14.0901 9.0901C18.3097 4.87053 24.0326 2.5 30 2.5C35.9674 2.5 41.6903 4.87053 45.9099 9.0901C50.1295 13.3097 52.5 19.0326 52.5 25Z"
    fill="#333333"
  />
  <g filter="url(#filter0_d_1329_13535)">
    <path
      d="M30 32.5C34.1421 32.5 37.5 29.1421 37.5 25C37.5 20.8579 34.1421 17.5 30 17.5C25.8579 17.5 22.5 20.8579 22.5 25C22.5 29.1421 25.8579 32.5 30 32.5Z"
      fill="#1FC47D"
    />
  </g>
  <defs>
    <filter
      color-interpolation-filters="sRGB"
      filterUnits="userSpaceOnUse"
      height="23"
      id="filter0_d_1329_13535"
      width="23"
      x="18.5"
      y="17.5"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="2" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
      />
      <feBlend
        in2="BackgroundImageFix"
        mode="normal"
        result="effect1_dropShadow_1329_13535"
      />
      <feBlend
        in="SourceGraphic"
        in2="effect1_dropShadow_1329_13535"
        mode="normal"
        result="shape"
      />
    </filter>
  </defs>
</svg>

import { Component } from "@angular/core";
import { NavRoutes } from "../../other/enums/nav-routes";
import { PermissionService } from "../../services/permission.service";

@Component({
  selector: "app-settings-page",
  templateUrl: "./settings-page.component.html",
  styleUrls: ["./settings-page.component.scss"],
})

/**
 * Settings page component
 * It serves as a container for the settings page.
 * All subpages (currently: profile, roles-old, password) are displayed in the router-outlet as tabs.
 */
export class SettingsPageComponent {
  protected readonly NavRoutes = NavRoutes;

  constructor(public permissionService: PermissionService) {}

  hasSettingsPermissions() {
    return (
      this.permissionService.readRolePermission().hasPermission ||
      this.permissionService.createRolePermission().hasPermission ||
      this.permissionService.readProductPermission().hasPermission ||
      this.permissionService.createProductPermission().hasPermission ||
      this.permissionService.readWarehousePermission().hasPermission ||
      this.permissionService.createWarehousePermission().hasPermission
    );
  }
}

<tui-multi-select
  (searchChange)="onSearch($event)"
  *tuiLet="items$ | async as items"
  [formControlName]="fControlName"
  [stringify]="(stringify$ | async)!"
  [tuiTextfieldLabelOutside]="true"
  class="b-form"
>
  <tui-data-list-wrapper
    *tuiDataList
    [itemContent]="(stringify$ | async)!"
    [items]="items"
    tuiMultiSelectGroup
  ></tui-data-list-wrapper>
</tui-multi-select>

import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { SettingsGuardHelperService } from "../../services/permission-helper-services/settings-guard-helper.service";

export const settingsGuard: CanActivateFn = (route) => {
  const permissionService = inject(SettingsGuardHelperService);
  const router: Router = inject(Router);

  const view = route.data["view"];

  if (permissionService.checkPermission(view)) {
    return true;
  } else {
    // If permission check fails, redirect to the next view (with permission) or to a default view
    router.navigate([permissionService.getNextViewWithPermission(view)]).then();
    return false;
  }
};

import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";

/**
 * @example
 * <app-input-text
 *    [fieldName]="'customerId'"
 *    [hint]="'Kundennr. eingeben'"
 *    [required]="false"
 *  ></app-input-text>
 */
@Component({
  selector: "app-input-text",
  templateUrl: "./input-text.component.html",
  styleUrls: ["./input-text.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
/**
 * A component that displays an input field for entering a custom text
 * @Input hint - The hint that is displayed in the input field
 * @Input fieldName - The name of the field that is used in the form
 */
export class InputTextComponent {
  @Input() hint: string = "E-Mail";
  @Input() fieldName?: string = "currentPassword";
  @Input() required?: boolean = true;
  @Input() hintColor?: string = "black";
  @Input() bRadius?: string = "";
  @Input() showAutocomplete: boolean = false;

  // radius: string = ;
  protected readonly length = length;
}

import { inject, Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { PushService } from "../../services/push.service";
import { pushTypes } from "../enums/push-types";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
  private forbiddenErrorTranslation: string;

  constructor() {
    const translateService = inject(TranslateService);
    translateService.get("common.forbidden-error").subscribe((translation) => {
      this.forbiddenErrorTranslation = translation;
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const pushService = inject(PushService);

    const requestMethod = request.method;

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status !== 403) {
          return throwError(() => error);
        }

        if (requestMethod !== "GET") {
          pushService.sendPush(
            pushTypes.ERROR,
            "",
            this.forbiddenErrorTranslation,
          );
        }
        return throwError(() => error);
      }),
    );
  }
}

import { BaseModel } from "./BaseModel";
import { Permissions } from "../other/enums/permissions";
import { User } from "./User";
import { PermissionTranslations } from "../other/enums/permission-translations";

export enum USER_ROLE {
  DISPONENT = "DISPONENT",
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  STORE_MAN = "STORE_MAN",
  DRIVER = "DRIVER",
}

/**
 * Model Class for creating Role instances
 * extends the BaseModel
 */
export class Role extends BaseModel<Role> {
  tenantId: string;
  name: string;
  description: string;
  global: boolean;
  draft: boolean;
  superAdmin: boolean;
  tenantAdmin: boolean;
  permissions: (Permissions[] & PermissionTranslations[]) | string[];
  users?: User[];

  constructor(params: Partial<Role>) {
    super(params);
    this.createdAt = new Date(this.createdAt);
    this.updatedAt = new Date(this.updatedAt);
  }

  toJson(): any {
    return {
      ...this,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    };
  }
}

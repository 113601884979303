import { Component, Inject } from "@angular/core";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiButtonModule, TuiDialogContext } from "@taiga-ui/core";
import { TranslateModule } from "@ngx-translate/core";
import { ProductPriceService } from "../../../../../api/productPrice.service";
import { TuiDialogHelperService } from "../../../../../services/tui-dialog-helper.service";
import { PushService } from "../../../../../services/push.service";
import { pushTypes } from "../../../../../other/enums/push-types";

@Component({
  selector: "app-customer-price-delete-dialog",
  standalone: true,
  imports: [TranslateModule, TuiButtonModule],
  templateUrl: "./customer-price-delete-dialog.component.html",
  styleUrl: "./customer-price-delete-dialog.component.scss",
})
export class CustomerPriceDeleteDialogComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean>,
    private priceService: ProductPriceService,
    private dialogService: TuiDialogHelperService,
    private pushService: PushService,
  ) {}

  deleteCustomerPrice() {
    if (!this.context.data) return;

    this.priceService.deleteProductPrice(this.context.data).subscribe(() => {
      this.pushService.sendPush(pushTypes.SUCCESS, "", "Kundenpreis gelöscht!");
      this.cancel();
    });
  }

  cancel() {
    this.dialogService.close(this.context);
  }
}

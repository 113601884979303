<svg
  fill="none"
  height="116"
  viewBox="0 0 170 116"
  width="170"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M36.5161 138.191C25.9766 138.191 17.4327 129.723 17.4327 119.276C17.4327 108.829 25.9766 100.361 36.5161 100.361C47.0557 100.361 55.5996 108.829 55.5996 119.276C55.5996 129.723 47.0557 138.191 36.5161 138.191Z"
    stroke="#F3F4F5"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2.19663"
  />
  <path
    d="M139.829 138.191C129.289 138.191 120.745 129.723 120.745 119.276C120.745 108.829 129.289 100.361 139.829 100.361C150.368 100.361 158.912 108.829 158.912 119.276C158.912 129.723 150.368 138.191 139.829 138.191Z"
    stroke="#F3F4F5"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2.19663"
  />
  <path
    d="M55.7659 39.0408L25.0426 39.0408L2.00012 61.8804L2.00012 99.9464H55.7659"
    stroke="#F3F4F5"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2.21029"
  />
  <path
    d="M55.809 2L193.21 2L193.21 99.9458L55.809 99.9458L55.809 2Z"
    stroke="#F3F4F5"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2.19663"
  />
  <rect
    fill="white"
    height="13.1798"
    rx="6.5899"
    width="13.721"
    x="72.5474"
    y="14.0815"
  />
</svg>

@if (mapPermissionHelper.hasReadOrderPermissions) {
  <tui-island style="background-color: #f1f2f4 !important">
    <app-order-filter />

    <div class="grid">
      @for (order of mapOrderService.orders; track order) {
        @if (!allAmountsPlanned(order)) {
          <app-one-order
            (click)="createTPLFromOrder(order)"
            (mouseenter)="onHover(order)"
            (mouseleave)="onLeave(order)"
            [order]="order"
          />
        }
      } @empty {
        {{ "order.empty" | translate }}
      }
    </div>
  </tui-island>
}

import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import Product, { PRODUCT_QUALITY } from "../../../../models/Product.class";
import { ProductService } from "../../../../api/product.service";
import { tuiIconFile } from "@taiga-ui/icons";
import { PushService } from "../../../../services/push.service";
import { pushTypes } from "../../../../other/enums/push-types";
import ProductPrice from "../../../../models/ProductPrice.class";
import { forkJoin, Observable } from "rxjs";
import { ProductPriceService } from "../../../../api/productPrice.service";
import { ProductHelperService } from "../product-helper.service";
import { Mode } from "../../../../other/enums/mode";
import { BaseDialogComponent } from "../../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../../services/tui-dialog-helper.service";
import {
  ErrorMessagesService,
  MethodType,
} from "../../../../api-error-messages/error-messages.service";
import { PermissionService } from "../../../../services/permission.service";

type ProductImage = {
  id: string;
  fileName: string;
  image: any;
};

@Component({
  selector: "app-add-edit-product",
  templateUrl: "./add-edit-product.component.html",
  styleUrls: ["./add-edit-product.component.scss"],
})
export class AddEditProductComponent
  extends BaseDialogComponent
  implements OnInit
{
  form: FormGroup;
  qualities = PRODUCT_QUALITY;
  selectedQualities: PRODUCT_QUALITY[] = [];
  previousQualities: PRODUCT_QUALITY[] = [];
  fileControl = new FormControl();
  productPrices: ProductPrice[] = [];

  productImage: ProductImage = { id: "", fileName: "", image: null };
  deleteImageId: string = "";

  toUpdateId: string;
  mode: string = Mode.ADD;
  isOpen: boolean = true;
  protected readonly tuiIconFile = tuiIconFile;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private productPriceService: ProductPriceService,
    private pushService: PushService,
    private productHelper: ProductHelperService,
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<any>,
    protected override dialogService: TuiDialogHelperService,
    private errorService: ErrorMessagesService,
    public permissionsService: PermissionService,
  ) {
    super(context, dialogService);
  }

  get isAddMode() {
    return this.mode === Mode.ADD;
  }

  get productHasImage() {
    return this.productImage.id != "" && this.productImage.image != null;
  }

  get qualityArray() {
    return Object.values(this.qualities);
  }

  get hasProductPriceCreatePermission() {
    return this.permissionsService.createProductPricePermission().hasPermission;
  }

  get hasProductPriceReadPermission() {
    return this.permissionsService.readProductPricePermission().hasPermission;
  }

  get hasProductPriceEditPermission() {
    return this.permissionsService.updateProductPricePermission().hasPermission;
  }

  get hasProductCreatePermission() {
    return this.permissionsService.createProductPermission().hasPermission;
  }

  get hasProductReadPermission() {
    return this.permissionsService.readProductPermission().hasPermission;
  }

  get hasProductEditPermission() {
    return this.permissionsService.updateProductPermission().hasPermission;
  }

  get hasCreateProductPermission() {
    return (
      this.isAddMode &&
      this.hasProductPriceCreatePermission &&
      this.hasProductPriceReadPermission &&
      this.hasProductCreatePermission &&
      this.hasProductReadPermission
    );
  }

  get hasEditProductPermission() {
    return (
      !this.isAddMode &&
      this.hasProductPriceCreatePermission &&
      this.hasProductPriceReadPermission &&
      this.hasProductPriceEditPermission &&
      this.hasProductEditPermission &&
      this.hasProductReadPermission
    );
  }

  get productFromForm(): Product {
    return new Product({
      name: this.form.get("name")?.value,
      possibleQualities: this.selectedQualities,
      description: this.form.get("note")?.value,
    });
  }

  ngOnInit(): void {
    // access url to check if the user wants to add or edit a customer
    this.getMode();

    // init forms
    this.form = this.fb.group(
      {
        name: ["", Validators.required],
        note: [""],
      },
      {
        validators:
          !this.isAddMode ||
          (this.isAddMode &&
            this.permissionsService.createProductOrderPermission()
              .hasPermission)
            ? this.oneQualitySelected
            : null,
      },
    );

    if (
      this.hasProductPriceEditPermission ||
      this.hasProductPriceCreatePermission
    ) {
      // add form controls for each product quality and price
      Object.values(PRODUCT_QUALITY).forEach((quality) => {
        this.form.addControl(
          quality,
          new FormControl(
            {
              value: 0,
              disabled:
                !this.isAddMode &&
                this.permissionsService.updateProductPricePermission()
                  .hasPermission,
            },
            [Validators.required, Validators.min(1)],
          ),
        );
        this.form.controls[quality].disable();
      });
    }

    // load product data into the form in edit mode
    if (this.isAddMode || !this.toUpdateId) return;
    this.initCaseEdit(this.toUpdateId);
  }

  initCaseEdit(id: string) {
    this.productService.getProductById(id).subscribe((res: Product) => {
      this.form.controls["name"].setValue(res.name);
      this.form.controls["note"].setValue(res.description);

      if (res.dbFileId == null) return;
      this.productImage.id = res.dbFileId;
      this.productService.getProductImage(res.dbFileId).subscribe((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.productImage.image = reader.result as string;
        };
        this.productImage.fileName = res.fileName;

        reader.readAsDataURL(res);
      });
    });

    if (!this.permissionsService.readProductPricePermission().hasPermission)
      return;
    this.productPriceService
      .getProductPriceByProductId(id)
      .subscribe((res: any) => {
        const qualities: PRODUCT_QUALITY[] = [];
        this.productPrices = res.records;

        this.productPrices.forEach((price: ProductPrice) => {
          if (
            this.permissionsService.updateProductPricePermission().hasPermission
          ) {
            this.form.controls[price.quality].enable();
          }
          qualities.push(price.quality);
        });

        this.selectedQualities = [...qualities];
        this.previousQualities = [...qualities];

        this.selectedQualities.forEach((quality) => {
          this.productPrices.forEach((price: ProductPrice) => {
            if (price.quality === quality)
              this.form.controls[quality].setValue(price.price);
          });
        });
      });
  }

  resetImage() {
    this.deleteImageId = this.productImage.id;
    this.productImage = { id: "", fileName: "", image: null };
  }

  getMode() {
    if (!this.context.data) {
      this.mode = Mode.ADD;
    } else {
      this.mode = Mode.EDIT;
      this.toUpdateId = this.context.data;
    }
  }

  oneQualitySelected = (
    formGroup: FormGroup,
  ): { [key: string]: any } | null => {
    if (this.isAddMode) {
      let selected = false;
      Object.values(this.qualities).forEach((quality) => {
        if (formGroup.get(quality)?.value > 0) {
          selected = true;
        }
      });
      return selected ? null : { atLeastOneQuality: true };
    } else {
      return null;
    }
  };

  submit() {
    if (this.form.invalid) return;

    // adding product (post)
    if (this.isAddMode) {
      this.addProduct(this.productFromForm);
      // updating product (patch)
    } else if (!this.isAddMode) {
      this.updateProduct(this.productFromForm);
    }
  }

  addProduct(product: Product) {
    if (!this.permissionsService.createProductPricePermission().hasPermission) {
      product.possibleQualities = [PRODUCT_QUALITY.QUALITY_NEU];
    }
    this.productService.addProduct(product).subscribe({
      next: (response) => {
        const productId = response.id;
        const requestObservables: Observable<any>[] = [];
        // add product prices
        if (
          this.permissionsService.createProductPricePermission().hasPermission
        ) {
          this.selectedQualities.forEach((quality) => {
            const productPrice = new ProductPrice({
              productId: productId,
              quality: quality,
              price: this.form.get(quality)?.value,
            });
            requestObservables.push(
              this.productPriceService.addProductPrice(productPrice),
            );
          });
        }
        this.handleMultipleRequests(requestObservables, "CREATE");
        // upload files
        this.uploadFiles(productId);

        this.productHelper.fetchProductsWithImages();
      },
    });
  }

  updateProduct(product: Product) {
    this.productService.updateProductById(this.toUpdateId, product).subscribe({
      next: (res) => {
        // Fetch all products again
        this.productHelper.fetchProductsWithImages();

        // Get product ID
        const productId = res.id;

        // Handle price updating, adding, and deleting
        const requestObservables = this.addUpdateDeletePrices(productId);

        // Handle all requests
        this.handleMultipleRequests(requestObservables, "UPDATE");

        // Handle product image deletion
        this.deleteProductImage(productId);
      },
    });
  }

  deleteProductImage(productId: string) {
    if (this.deleteImageId !== "") {
      this.productService
        .deleteProductImage(productId, this.deleteImageId)
        .subscribe({
          next: () => {
            this.uploadFiles(this.toUpdateId);
            this.productHelper.fetchProductsWithImages();
          },
          error: () => {
            this.showErrorMessage();
          },
        });
    } else {
      this.uploadFiles(this.toUpdateId);
    }
  }

  uploadFiles(productId: string) {
    // upload files
    if (!this.fileControl.value || this.fileControl.value?.length === 0) {
      this.showSuccessMessage();
      return;
    }
    const formData = new FormData();
    formData.append(
      "file",
      this.fileControl.value,
      this.fileControl.value.name,
    );

    this.productService.addProductImage(productId, formData).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.productHelper.fetchProductsWithImages();
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  onQualitySelectionChange(productQualities: PRODUCT_QUALITY[]) {
    // enable price-input for activated qualities (if user has update permission)
    if (
      this.isAddMode ||
      this.permissionsService.updateProductPricePermission().hasPermission
    ) {
      productQualities.forEach((quality) => {
        this.form.controls[quality].enable();
      });
    }

    const missingQualities = this.qualityArray.filter(
      (quality) => !productQualities.includes(quality),
    );

    // reset prices for deactivated qualities
    missingQualities.forEach((missingQuality) => {
      this.form.controls[missingQuality].disable();
      this.form.controls[missingQuality].reset();
      this.form.controls[missingQuality].setValue(0);
    });
  }

  cancel() {
    this.closeDialog();
  }

  removeFile() {
    this.fileControl.setValue(null);
  }

  showSuccessMessage() {
    this.pushService.sendPush(
      pushTypes.SUCCESS,
      "",
      "Das Produkt wurde erfolgreich gespeichert.",
    );
  }

  showErrorMessage() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      "",
      "Beim speichern des Produktes ist ein Fehler aufgetreten.",
    );
  }

  handleMultipleRequests(
    requestObservables: Observable<any>[],
    methodType?: MethodType,
  ) {
    if (requestObservables.length === 0) {
      this.cancel();
      return;
    }

    // combines all observable for all requests, the subscription is only called when all requests are finished
    forkJoin(requestObservables).subscribe({
      next: () => {
        // Success handling
        this.productHelper.fetchProductsWithImages();
        this.cancel();
      },
      error: (err) => {
        // Error handling
        if (methodType) {
          this.errorService.sendErrorMessage("product", methodType, err);
          this.cancel();
        } else {
          this.showErrorMessage();
        }
      },
    });
  }

  private createPriceAddObservable(
    quality: string,
    productPrice: ProductPrice,
  ): Observable<any> | null {
    const priceExists = this.productPrices.find(
      (price: ProductPrice) => price.quality === quality,
    );
    return !priceExists
      ? this.productPriceService.addProductPrice(productPrice)
      : null;
  }

  private createPriceUpdateObservable(
    quality: string,
    productPrice: ProductPrice,
  ): Observable<any> | null {
    const existingPrice = this.productPrices.find(
      (price: ProductPrice) => price.quality === quality,
    );
    return existingPrice &&
      existingPrice.price !== this.form.get(quality)?.value
      ? this.productPriceService.updateProductPrice(
          existingPrice.id,
          productPrice,
        )
      : null;
  }

  private priceIdOfQuality(quality: string): string | undefined {
    return this.productPrices.find(
      (price: ProductPrice) => price.quality === quality,
    )?.id;
  }

  private addUpdateDeletePrices(productId: string): Observable<any>[] {
    const requestObservables: Observable<any>[] = [];
    for (const quality of this.selectedQualities) {
      const productPrice = new ProductPrice({
        productId: productId,
        quality: quality,
        price: this.form.get(quality)?.value,
      });

      const addObservable = this.createPriceAddObservable(
        quality,
        productPrice,
      );
      if (addObservable) requestObservables.push(addObservable);

      const updateObservable = this.createPriceUpdateObservable(
        quality,
        productPrice,
      );
      if (updateObservable) requestObservables.push(updateObservable);
    }

    for (const quality of this.previousQualities) {
      if (!this.selectedQualities.includes(quality)) {
        const priceId = this.priceIdOfQuality(quality);
        const deleteObservable = priceId
          ? this.productPriceService.deleteProductPrice(priceId)
          : null;
        if (deleteObservable) requestObservables.push(deleteObservable);
      }
    }
    return requestObservables;
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { tuiIconSave, tuiIconTrash } from "@taiga-ui/icons";

@Component({
  selector: "app-edit-button-bar",
  templateUrl: "./edit-button-bar.component.html",
  styleUrls: ["./edit-button-bar.component.scss"],
})
export class EditButtonBarComponent {
  @Input() saveButtonText: string = "common.button-save";
  @Input() cancelButtonText: string = "common.button-cancel";
  @Input() deleteButtonText: string = "common.button-delete";
  @Input() saveButtonDisabled: boolean = false;
  @Input() showDeleteButton: boolean = true;
  @Input() isInAddMode: boolean = false;
  @Input() pageTitlePrefix: string = "user-profile.title";
  @Input() pageTitleSuffix: string = "";
  @Output() saveClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  protected readonly tuiIconSave = tuiIconSave;
  protected readonly tuiIconTrash = tuiIconTrash;
}

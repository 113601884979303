import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import Tour from "../models/Tour.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

export type tourQueryParams = {
  sort?: string;
  dateFrom?: string;
  dateTo?: string;
  withTourPointLoads?: boolean;
  withAuthor?: boolean;
  withProduct?: boolean;
  createdAt?: string;
  date?: string;
  searchDate?: string;
  populateDeleter?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class TourService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  _tours = signal<Tour[]>([]);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  _totalTours = signal<number>(0);

  get totalTours() {
    return this._totalTours();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD Tour
  addTour(addTourObject: Tour): Observable<Tour> {
    return this.http.post<Tour>(this.baseUrl + "tour", addTourObject).pipe(
      tap(() => {
        // Open the snackbar for successful request
        this.handleSuccess("Tour wurde erstellt");

        // Call the getAllTour method for updating the data + view
        this.getAllTours().subscribe();
      }),
      catchError((err) => {
        // Open the snackbar for error
        this.errorHandler.sendErrorMessage("tour", "CREATE", err);
        return of(err);
      }),
    );
  }

  // GET ALL Tours
  getAllTours(
    tourQueryParams?: tourQueryParams,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (tourQueryParams) {
      for (const [key, value] of Object.entries(tourQueryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "tour", { params: params })
      .pipe(
        tap((res: ResponseWithRecordsBody) => {
          this._tours.set(res.records.map((tour: Tour) => new Tour(tour)));
          this._totalTours.set(res.total);
        }),
      );
  }

  // GET ONE Tour
  getTourById(id: string | number): Observable<Tour> {
    return this.http.get<Tour>(this.baseUrl + "tour/" + id);
  }

  // CHANGE ONE Tour
  updateTourById(
    id: string | number,
    updateTourObject: Tour,
  ): Observable<Tour> {
    return this.http
      .patch<Tour>(this.baseUrl + "tour/" + id, updateTourObject)
      .pipe(
        tap(() => {
          // Open the snackbar for successful request
          this.handleSuccess("Tour wurde aktualisiert");
          // Call the getAllTours method for updating the data + view
          this.getAllTours().subscribe();
        }),
        catchError((err) => {
          // Open the snackbar for error
          this.errorHandler.sendErrorMessage("tour", "UPDATE", err);
          return of(err);
        }),
      );
  }

  // DELETE ONE Tour
  deleteTourById(id: number | string): Observable<unknown> {
    return this.http.delete<unknown>(this.baseUrl + "tour/" + id).pipe(
      tap(() => {
        // Open the snackbar for successful request
        this.handleSuccess("Tour wurde gelöscht");
        // Call the getAllTours method for updating the data + view
        this.getAllTours().subscribe();
      }),
      catchError((err) => {
        // Open the snackbar for error
        this.errorHandler.sendErrorMessage("tour", "DELETE", err);
        return of(err);
      }),
    );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

<svg
  fill="none"
  height="110"
  viewBox="0 0 92 110"
  width="92"
  xmlns="http://www.w3.org/2000/svg"
  id="logo-svg"
>
  <path
    d="M45.972 43.0813L0.400391 69.5993L45.972 95.7244L91.3472 69.4028L45.972 43.0813Z"
    fill="#3BB576"
  />
  <path
    d="M91.1514 82.7599V69.5991L45.9727 95.7242V109.278L91.1514 82.7599Z"
    fill="#1C1B1C"
  />
  <path
    d="M0.400391 82.8451V69.5991L45.972 95.7242V109.278L0.400391 82.8451Z"
    fill="#CCF8DD"
  />
  <path
    d="M45.972 21.8406L0.400391 48.3585L45.972 74.4837L91.3472 48.1621L45.972 21.8406Z"
    fill="#3BB576"
  />
  <path
    d="M91.1514 61.5194V48.3586L45.9727 74.4837V88.0374L91.1514 61.5194Z"
    fill="#1C1B1C"
  />
  <path
    d="M0.400391 61.6046V48.3586L45.972 74.4837V88.0374L0.400391 61.6046Z"
    fill="#CCF8DD"
  />
  <path
    d="M45.972 0.600098L0.400391 27.1181L45.972 53.2432L91.3472 26.9216L45.972 0.600098Z"
    fill="#3BB576"
  />
  <path
    d="M91.1514 40.2789V27.1182L45.9727 53.2433V66.7969L91.1514 40.2789Z"
    fill="#1C1B1C"
  />
  <path
    d="M0.400391 40.3641V27.1182L45.972 53.2433V66.7969L0.400391 40.3641Z"
    fill="#CCF8DD"
  />
</svg>

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { tuiIconAlertOctagonLarge } from "@taiga-ui/icons";

@Component({
  selector: "app-delete-dialog",
  templateUrl: "./delete-dialog.component.html",
  styleUrls: ["./delete-dialog.component.scss"],
})
export class DeleteDialogComponent {
  @Input() isOpen: boolean = true;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() deleteEvent = new EventEmitter<boolean>();

  protected readonly tuiIconAlertOctagonLarge = tuiIconAlertOctagonLarge;
}

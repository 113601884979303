<div class="grid">
  @for (product of products; track product) {
    <!-- Main Container -->
    <tui-island>
      <!-- Inner Container -->
      <div class="innerContainer">
        <!-- Header Section -->
        <div class="header">
          <!-- Product Image -->
          <img
            [src]="productHelper.productImages.get(product.id)"
            alt="Product Image"
            class="image"
            height="130"
            width="130"
          />

          <div>
            <!-- Product Name -->
            <h2 style="margin-bottom: 0">
              {{ product.name }}
            </h2>

            @if (
              product.possibleQualities.length > 0 &&
              permissionService.readProductPricePermission().hasPermission
            ) {
              <!-- Product Qualities -->
              <div class="quality-container">
                @for (
                  quality of product.possibleQualities;
                  let last = $last;
                  track quality
                ) {
                  <div class="inner-quality-container">
                    <div>
                      <h3 style="margin-bottom: 0.5rem">
                        {{ productService.getLabelOfQualities(quality) }}
                      </h3>
                    </div>

                    @if (
                      productService.getLabelOfQualities(quality) &&
                      permissionService.readProductPricePermission()
                        .hasPermission
                    ) {
                      <div>{{ getPriceToQuality(product.id, quality) }} €</div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        </div>

        <!-- Icons Block -->
        <div class="icons">
          <!-- Edit Icon -->
          @if (permissionService.updateProductPermission().hasPermission) {
            <tui-svg
              (click)="onEdit(product.id)"
              class="editIcon"
              src="tuiIconEdit2Large"
              style="color: var(--tui-primary)"
            />
          }
          <!-- Delete Icon -->
          @if (
            permissionService.deleteProductPermission().hasPermission &&
            permissionService.deleteProductPricePermission().hasPermission
          ) {
            <tui-svg
              (click)="onDelete(product.id)"
              class="deleteIcon"
              src="tuiIconTrash2Large"
              style="color: var(--tui-error-bg)"
            />
          } @else if (
            permissionService.deleteProductPermission().hasPermission &&
            !permissionService.deleteProductPricePermission().hasPermission
          ) {
            <tui-svg
              (click)="openMissingPermissionsDialog()"
              src="tuiIconInfoLarge"
              class="deleteIcon"
              style="color: var(--tui-error-bg)"
            />
          }
        </div>
      </div>

      <!-- Palette SVG -->
      <app-palette-svg class="palette-svg" />

      <!-- First Ellipse -->
      <div class="ellipse"></div>

      <!-- Second Ellipse -->
      <div class="ellipse2"></div>
    </tui-island>
  }
</div>

import { Component } from "@angular/core";
import { TableFilterEvent } from "../../interfaces/table-filter-event";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { Router } from "@angular/router";
import { TenantService } from "../../api/tenant.service";
import { Tenant } from "../../models/Tenant";
import { NavRoutes } from "../../other/enums/nav-routes";
import { TableSortEvent } from "../../interfaces/table-sort-event";

@Component({
  selector: "app-tenant-page",
  templateUrl: "./tenant-page.component.html",
  styleUrls: ["./tenant-page.component.scss"],
})

/**
 * The page component for the tenant page.
 * Serves as a container for the tenant content and the new tenant slide bar.
 */
export class TenantPageComponent {
  tenantTableData: any[] = [];
  readonly tenantTableShownColumns: string[] = ["name"]; // names for corresponding object properties
  tablePage: number = 0;
  tablePageSize: number = 10;
  totalTenants: number = 0;
  isOpen: boolean = false;

  constructor(
    private tenantService: TenantService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.populateTable();
  }

  ngOnDestroy(): void {
    this.resetTablePagination();
  }

  /**
   * populate the table with data from the api
   */
  public populateTable(): void {
    this.tenantService.setPaginationIndices({ start: 0, limit: 10 });
    this.tenantService.getTenants().subscribe((tenants: Tenant[]) => {
      this.tenantTableData = tenants;
      this.totalTenants = this.tenantService.getAmount();
    });
  }

  /**
   * todo: implement filtering for api table data
   */
  public filterTable(filterEvent: TableFilterEvent): void {}

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination): void {
    this.tablePage = pagination.page;
    this.tablePageSize = pagination.size;

    this.tenantService.setPaginationIndices({
      start: pagination.page * pagination.size,
      limit: pagination.page * pagination.size + pagination.size,
    });

    this.populateTable();
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.tenantService.setSorting(tableSort);
    this.populateTable();
  }

  /**
   * resets the table pagination to the first page with 10 entries
   */
  public resetTablePagination() {
    this.tenantService.setPaginationIndices({ start: 0, limit: 10 });
    this.tablePage = 0;
    this.tablePageSize = 10;
  }

  /**
   * navigates to the tenant dashboard page
   */
  tenantRowClickEvent(id: string) {
    this.router.navigateByUrl(
      `${NavRoutes.TENANT}/${id}/${NavRoutes.DASHBOARD}`,
    );
  }

  public createdTenantEvent() {
    this.populateTable();
    this.isOpen = false;
  }
}

import { Component, computed, signal } from "@angular/core";
import {
  ChartData,
  DashboardEntity,
  KpiService,
} from "../../../api/kpi.service";
import { Months } from "../../../other/enums/months";
import { Observer } from "rxjs";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { TuiContextWithImplicit } from "@taiga-ui/cdk";
import { tuiFormatNumber, TuiPoint } from "@taiga-ui/core";
import { TUI_ALWAYS_DASHED } from "@taiga-ui/addon-charts";

@Component({
  selector: "app-dashboard-chart",
  templateUrl: "./dashboard-chart.component.html",
  styleUrl: "./dashboard-chart.component.scss",
})
export class DashboardChartComponent {
  readonly today = signal(new Date());
  // bar chart data
  readonly chartLabelX = Object.values(Months);
  readonly horizontalLinesHandler = TUI_ALWAYS_DASHED;
  chartDataSetFirst = signal<number[]>([]);
  chartDataSetSecond = signal<number[]>([]);
  maxValue = computed<number>(() => {
    if (
      this.chartDataSetFirst().length === 0 &&
      this.chartDataSetSecond().length === 0
    ) {
      return 0;
    }
    return Math.max(...this.chartDataSetFirst(), ...this.chartDataSetSecond());
  });
  readonly chartLabelY = computed(() => {
    // split max value into 5 parts
    const max = this.maxValue();

    // return array with digits from 0 to max if max is smaller than 5
    if (max < 5) {
      return Array.from({ length: max + 1 }, (_, i) => i.toString());
    }

    const step = max / 5;

    return Array.from({ length: 6 }, (_, i) =>
      (i * step) % 1 !== 0
        ? Math.round(i * step).toString()
        : (i * step).toString(),
    );
  });
  // observer (can be reused for subscriptions for chart data)
  readonly chartDataObserver: Partial<Observer<any>> = {
    next: (data: ChartData) => {
      // workaround: because the TuiBarChartComponent needs at least two number arrays
      const secondDataSet = !this.hasTwoDataSets()
        ? new Array<number>(12).fill(0)
        : data.additionalChartData;

      // set values for bar chart
      this.chartDataSetFirst.set(data?.chartData ?? []);
      this.chartDataSetSecond.set(secondDataSet);
    },
  };
  // selection for chart data
  selectedDashboardEntity = signal<DropDownItem>({
    id: DashboardEntity.ORDER,
    label: "order.title",
  });
  chartLabels = computed(() => {
    switch (this.selectedDashboardEntity().id) {
      case DashboardEntity.ORDER:
        return ["Erfasste Aufträge", "Erledigte Aufträge"];
      case DashboardEntity.REFUND:
        return ["Erledigte Aufträge", "Aufträge mit Reklamationen"];
      case DashboardEntity.DRIVER:
        return ["aktive Fahrer"];
      case DashboardEntity.TOUR:
        return ["Tourplanungen"];
      default:
        return [];
    }
  });
  hasTwoDataSets = computed<boolean>(() => {
    return (
      this.selectedDashboardEntity().id === DashboardEntity.ORDER ||
      this.selectedDashboardEntity().id === DashboardEntity.REFUND
    );
  });
  arrowIcon = signal<string>("tuiIconChevronDown");
  dashBoardEntityDropdownItems = [
    { id: DashboardEntity.ORDER, label: "order.title" },
    { id: DashboardEntity.DRIVER, label: "driver.title" },
    { id: DashboardEntity.REFUND, label: "dashboard.title-refund" },
    { id: DashboardEntity.TOUR, label: "tour.planning" },
  ];
  showSelection = false;

  constructor(private kpiService: KpiService) {}

  ngOnInit() {
    this.kpiService
      .getChartData(DashboardEntity.ORDER)
      .subscribe(this.chartDataObserver);
  }

  readonly hintContent = ({
    $implicit,
  }: TuiContextWithImplicit<readonly TuiPoint[]>): string => {
    const index = $implicit as unknown as number;

    const firstValue = this.chartDataSetFirst()[index];
    const firstChartLabel = this.chartLabels()[0] ?? "";

    if (this.hasTwoDataSets()) {
      const secondValue = this.chartDataSetSecond()[index];
      const secondChartLabel = this.chartLabels()[1] ?? "";

      return `<b>${tuiFormatNumber(firstValue)}</b> ${firstChartLabel} \n <b>${tuiFormatNumber(secondValue)}</b> ${secondChartLabel}`;
    }

    return `<b>${tuiFormatNumber(firstValue)}</b> ${firstChartLabel}`;
  };

  showSelectionClick(): void {
    if (this.showSelection) {
      this.arrowIcon.set("tuiIconChevronDown");
      this.showSelection = false;
    } else {
      this.arrowIcon.set("tuiIconChevronUp");
      this.showSelection = true;
    }
  }

  onSelectionChanged(item: DropDownItem): void {
    this.selectedDashboardEntity.set(item);
    this.arrowIcon.set("tuiIconChevronDown");
    this.showSelection = false;
    const selectedDashboardEntity = item.id as DashboardEntity;
    this.kpiService
      .getChartData(selectedDashboardEntity)
      .subscribe(this.chartDataObserver);
  }

  getColor(index: number): string {
    return `var(--tui-chart-${index})`;
  }

  /**
   * check if the number that is dividable by 5, 4, 3, 2, 1
   * @param number
   * */
  isDividableByFive(number: number): boolean {
    return number % 5 === 0;
  }
}

import { Component, Inject, inject, Input, OnInit } from "@angular/core";
import { NgIf } from "@angular/common";
import { BaseDialogComponent } from "../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { PermissionService } from "../../../services/permission.service";

@Component({
  selector: "app-missing-order-permissions-dialog",
  standalone: true,
  imports: [NgIf],
  templateUrl: "./missing-order-permissions-dialog.component.html",
  styleUrl: "./missing-order-permissions-dialog.component.scss",
})
export class MissingOrderPermissionsDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  permissionService = inject(PermissionService);
  @Input({ required: true }) isAddmode: boolean = true;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<any>,
    protected override dialogService: TuiDialogHelperService,
  ) {
    super(context, dialogService);
  }

  ngOnInit(): void {
    if (this.context.data) this.isAddmode = false;
  }
}

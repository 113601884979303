import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SettingsPageComponent } from "./views/settings-page/settings-page.component";
import { MapPageComponent } from "./views/map-page/map-page.component";
import { UserPageComponent } from "./views/user-page/user-page.component";
import { CustomerPageComponent } from "./views/customer-page/customer-page.component";
import { LogInPageComponent } from "./views/log-in-page/log-in-page.component";
import { DashboardPageComponent } from "./views/dashboard-page/dashboard-page.component";
import { authGuard } from "./other/guards/auth.guard";
import { BaseLayoutComponent } from "./layouts/base-layout/base-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { loginPageAccessGuard } from "./other/guards/login-page-access.guard";
import { ResetPasswordPageComponent } from "./views/reset-password-page/reset-password-page.component";
import { ErrorPageComponent } from "./views/error-page/error-page.component";
import { NavRoutes } from "./other/enums/nav-routes";
import { ProductsComponent } from "./views/settings-page/products/products.component";
import { WarehousePageComponent } from "./views/settings-page/warehouse-page/warehouse-page.component";
import { OrdersPageComponent } from "./views/orders-page/orders-page.component";
import { CustomerDetailsComponent } from "./views/customer-page/customer-details/customer-details.component";
import { OrderDetailsComponent } from "./views/orders-page/order-details/order-details.component";
import { TourPageComponent } from "./views/tour-page/tour-page.component";
import { RolesComponent } from "./views/settings-page/roles/roles.component";
import { AddEditRoleComponent } from "./views/settings-page/roles/add-edit-role/add-edit-role.component";
import { DeliveryReceiptPageComponent } from "./views/delivery-receipt-page/delivery-receipt-page.component";
import { UnannouncedDeliveryPageComponent } from "./views/unannounced-delivery-page/unannounced-delivery-page.component";
import { GoogleMapsScriptResolverService } from "./services/google-maps-script-resolver.service";
import { RefundsPageComponent } from "./views/refunds-table-page/refunds-page.component";
import { hasReadPermissionGuard } from "./other/guards/has-read-permission.guard";
import { settingsGuard } from "./other/guards/settings.guard";

const routes: Routes = [
  // standard route
  { path: "", redirectTo: NavRoutes.DASHBOARD, pathMatch: "full" },

  // auth protected routes
  {
    path: "",
    canActivate: [authGuard],
    component: BaseLayoutComponent,
    children: [
      // super-admin protected routes
      {
        path: "",
        children: [
          {
            path: NavRoutes.DASHBOARD,
            children: [
              {
                path: "",
                component: DashboardPageComponent,
                canActivate: [hasReadPermissionGuard],
                data: { view: NavRoutes.DASHBOARD },
              },
            ],
          },
        ],
      },
      // | TEST ROUTE | --------------------------------------------------------------- | REFUNDS ROUTES | >>
      // { path: "test", component: TestViewComponent },

      // | REFUNDS ROUTES | --------------------------------------------------------------- | REFUNDS ROUTES | >>
      { path: NavRoutes.REFUNDS, component: RefundsPageComponent },

      // | SETTINGS ROUTES | --------------------------------------------------------------- | SETTINGS ROUTES | >>
      {
        path: NavRoutes.SETTINGS,
        component: SettingsPageComponent,
        children: [
          {
            path: "",
            redirectTo: NavRoutes.ROLES,
            pathMatch: "full",
          },
          {
            path: NavRoutes.ROLES,
            data: { view: NavRoutes.ROLES },
            canActivate: [settingsGuard],
            children: [
              {
                path: "",
                component: RolesComponent,
                canActivate: [settingsGuard],
              },
              {
                path: "add-role",
                component: AddEditRoleComponent,
              },
              {
                path: "edit-role/:id",
                component: AddEditRoleComponent,
              },
            ],
          },

          {
            path: NavRoutes.PRODUCTS,
            children: [
              {
                path: "",
                component: ProductsComponent,
              },
            ],
          },
          {
            path: NavRoutes.WAREHOUSE,
            children: [
              {
                path: "",
                component: WarehousePageComponent,
                resolve: { googleMapsScript: GoogleMapsScriptResolverService },
              },
            ],
          },
        ],
      },
      // | DELIVERY RECEIPT ROUTES | --------------------------------------------------------------- | DELIVERY RECEIPT ROUTES | >>
      {
        path: NavRoutes.DELIVERY_RECEIPT,
        children: [
          {
            path: "",
            component: DeliveryReceiptPageComponent,
          },
        ],
      },
      // | UNANNOUNCED DELIVERY ROUTES | --------------------------------------------------------------- | UNANNOUNCED DELIVERY ROUTES | >>
      {
        path: NavRoutes.UNANNOUNCED_DELIVERIES,
        children: [
          {
            path: "",
            component: UnannouncedDeliveryPageComponent,
          },
        ],
      },
      // | TOURS ROUTES | --------------------------------------------------------------- | TOURS ROUTES | >>
      {
        path: NavRoutes.TOURS,
        component: TourPageComponent,
      },
      // | ORDER ROUTES | --------------------------------------------------------------- | ORDER ROUTES | >>
      {
        path: NavRoutes.ORDER,
        children: [
          {
            path: "",
            component: OrdersPageComponent,
          },
          {
            path: "order-detail/:id",
            component: OrderDetailsComponent,
          },
        ],
      },
      // | USER ROUTES | --------------------------------------------------------------- | USER ROUTES | >>
      {
        path: NavRoutes.USER,
        children: [
          {
            path: "",
            component: UserPageComponent,
          },
        ],
      },
      // | MAP ROUTES | --------------------------------------------------------------- | MAP ROUTES | >>
      {
        path: NavRoutes.MAP + "/:id",
        component: MapPageComponent,
        resolve: { googleMapsScript: GoogleMapsScriptResolverService },
      },
      // | CUSTOMERS ROUTES | --------------------------------------------------------------- | CUSTOMERS ROUTES | >>
      {
        path: NavRoutes.CUSTOMERS,
        children: [
          {
            path: "",
            component: CustomerPageComponent,
          },
          {
            path: ":id",
            component: CustomerDetailsComponent,
            resolve: { googleMapsScript: GoogleMapsScriptResolverService },
          },
        ],
      },
      // | ERROR ROUTES | --------------------------------------------------------------- | ERROR ROUTES | >>
      {
        path: NavRoutes.ERROR,
        component: ErrorPageComponent,
      },
    ],
  },

  // non-auth protected routes
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: NavRoutes.LOGIN,
        component: LogInPageComponent,
        canActivate: [loginPageAccessGuard],
      },
      {
        path: `${NavRoutes.RESET_PASSWORD}/:hash`,
        component: ResetPasswordPageComponent,
        canActivate: [loginPageAccessGuard],
      },
      {
        path: NavRoutes.ERROR_OUT,
        component: ErrorPageComponent,
        canActivate: [loginPageAccessGuard],
      },
    ],
  },

  // wildcard route
  { path: "**", redirectTo: NavRoutes.ERROR, pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [GoogleMapsScriptResolverService],
})
export class AppRoutingModule {}

<svg
  fill="none"
  height="139"
  viewBox="0 0 185 139"
  width="185"
  xmlns="http://www.w3.org/2000/svg"
>
  <g filter="url(#filter0_b_0_1)" opacity="0.8">
    <path
      clip-rule="evenodd"
      d="M104.232 2.74078C96.8424 -0.91359 88.1575 -0.913598 80.7685 2.74078L7.17203 39.1411C-2.39065 43.8706 -2.39071 57.4988 7.17203 62.2285L80.7685 98.6283C88.1575 102.284 96.8424 102.284 104.232 98.6292L177.828 62.2285C187.391 57.4989 187.391 43.8707 177.828 39.1411L104.232 2.74078Z"
      fill="url(#paint0_linear_0_1)"
      fill-opacity="0.5"
      fill-rule="evenodd"
    />
    <path
      d="M80.9901 3.18897C88.2395 -0.396326 96.7605 -0.396318 104.01 3.18897L177.606 39.5892C186.798 44.1353 186.798 57.2343 177.606 61.7803L104.01 98.181C96.7605 101.766 88.2395 101.766 80.9902 98.1802L80.7685 98.6283L80.9901 98.1802L7.39369 61.7803C-1.79793 57.2342 -1.79787 44.1353 7.39369 39.5892L7.17203 39.1411L7.39369 39.5892L80.9901 3.18897Z"
      stroke="url(#paint1_linear_0_1)"
      stroke-opacity="0.8"
    />
  </g>
  <path
    clip-rule="evenodd"
    d="M104.232 40.3572C96.8424 36.7029 88.1575 36.7029 80.7685 40.3572L7.17203 76.7575C-2.39065 81.4871 -2.39071 95.1153 7.17203 99.8449L80.7685 136.245C88.1575 139.9 96.8424 139.9 104.232 136.246L177.828 99.8449C187.391 95.1154 187.391 81.4872 177.828 76.7575L104.232 40.3572Z"
    fill="url(#paint2_linear_0_1)"
    fill-opacity="0.5"
    fill-rule="evenodd"
    opacity="0.4"
  />
  <defs>
    <filter
      color-interpolation-filters="sRGB"
      filterUnits="userSpaceOnUse"
      height="109.37"
      id="filter0_b_0_1"
      width="193"
      x="-4"
      y="-4"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
      <feComposite
        in2="SourceAlpha"
        operator="in"
        result="effect1_backgroundBlur_0_1"
      />
      <feBlend
        in="SourceGraphic"
        in2="effect1_backgroundBlur_0_1"
        mode="normal"
        result="shape"
      />
    </filter>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint0_linear_0_1"
      x1="134.315"
      x2="58.2877"
      y1="21.5411"
      y2="140.651"
    >
      <stop stop-color="#CBF9DD" />
      <stop offset="1" stop-color="#CBF9DD" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint1_linear_0_1"
      x1="91.2329"
      x2="91.2329"
      y1="-3.28488e-09"
      y2="101.37"
    >
      <stop stop-color="#CBF9DD" />
      <stop offset="1" stop-color="#CBF9DD" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint2_linear_0_1"
      x1="140.651"
      x2="43.0822"
      y1="59.1576"
      y2="114.911"
    >
      <stop stop-color="#CBF9DD" />
      <stop offset="1" stop-color="#CBF9DD" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>

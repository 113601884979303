import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";

export type RadioItem = {
  name: string;
  description?: string;
  id: string | boolean;
};

@Component({
  selector: "app-radio-list",
  templateUrl: "./radio-list.component.html",
  styleUrls: ["./radio-list.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
/**
 * Component to display a list of radio buttons with a title and description
 * by iterating over an array of items
 *
 * @Input items: Array of items to display, e.g. a role list
 * @Input fieldName: formControlName to bind the radio buttons to the parent form
 */
export class RadioListComponent {
  @Input() items: RadioItem[] = [];
  @Input() fieldName: string = "";
  @Input() rowDirection: boolean = false;
}

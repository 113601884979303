import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { catchError, delay, finalize, Observable, of } from "rxjs";
import { LoadingService } from "../../services/loading.service";

@Injectable()
/**
 * Intercepts all Http requests and sets the loading state to true when a request is taking longer than 250ms
 * Otherwise the loading state is set to false
 *
 * Is used in combination with the loading service to display a loading spinner in different components
 */
export class LoadingInterceptor implements HttpInterceptor {
  private requestsCount = 0;

  constructor(private loadingService: LoadingService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.requestsCount++;

    const loadingTimer$ = of(null)
      .pipe(delay(300))
      .subscribe(() => {
        if (this.requestsCount > 0) {
          // Only show spinner after delay if there are still ongoing requests
          this.loadingService.setLoadingState(true);
        }
      });

    return next.handle(request).pipe(
      catchError((error) => {
        throw error;
      }),
      finalize(() => {
        this.requestsCount--;
        if (this.requestsCount === 0) {
          this.loadingService.setLoadingState(false);
        }
        loadingTimer$.unsubscribe();
      }),
    );
  }
}

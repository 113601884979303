import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import Product, { PRODUCT_QUALITY } from "../models/Product.class";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

@Injectable({
  providedIn: "root",
})
export class ProductService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  totalProducts = signal<number>(0);

  constructor(
    private http: HttpClient,
    private productErrors: ErrorMessagesService,
  ) {
    super();
  }

  _products = signal<Product[]>([]);

  get products() {
    return this._products();
  }

  set products(value: Product[]) {
    this._products.set(value);
  }

  get total() {
    return this.totalProducts();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD Product
  addProduct(addProductObject: Product): Observable<Product> {
    return this.http
      .post<Product>(this.baseUrl + "product", addProductObject)
      .pipe(
        tap(() => this.getAllProducts().subscribe()),
        catchError((err) => {
          this.productErrors.sendErrorMessage("product", "CREATE", err.error);
          return of(new Product({}));
        }),
      );
  }

  // GET ALL Products
  getAllProducts(
    queryParams?: any,
  ): Observable<ResponseWithRecordsBody<Product>> {
    let params = new HttpParams();

    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody<Product>>(this.baseUrl + "product", {
        params: params,
      })
      .pipe(
        tap((res: ResponseWithRecordsBody<Product>) => {
          this._products.set(
            res.records.map((product: Product) => new Product(product)),
          );
          this.totalProducts.set(res.total);
        }),
      );
  }

  // GET ONE Product
  getProductById(id: string | number): Observable<Product> {
    return this.http.get<Product>(this.baseUrl + "product/" + id);
  }

  // CHANGE ONE Product
  updateProductById(
    id: string | number,
    updateProductObject: Product,
  ): Observable<Product> {
    return this.http
      .patch<Product>(this.baseUrl + "product/" + id, updateProductObject)
      .pipe(
        tap(() => this.getAllProducts().subscribe()),
        catchError((err) => {
          this.productErrors.sendErrorMessage("product", "UPDATE", err);
          return of(new Product({}));
        }),
      );
  }

  // DELETE ONE Product
  deleteProductById(id: number | string): Observable<unknown> {
    return this.http.delete<unknown>(this.baseUrl + "product/" + id).pipe(
      tap(() => this.getAllProducts().subscribe()),
      catchError((err) => {
        this.productErrors.sendErrorMessage("product", "DELETE", err);
        return of(null);
      }),
    );
  }

  // ADD Product image
  addProductImage(id: string, formData: FormData): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "product/" + id + "/file",
      formData,
      {
        observe: "response",
      },
    );
  }

  getProductImage(fileId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + "product/{id}/files/" + fileId, {
      responseType: "blob" as "json",
    });
  }

  deleteProductImage(productId: string, fileId: string): Observable<any> {
    return this.http.delete<any>(
      this.baseUrl + "product/" + productId + "/file/" + fileId,
      {
        observe: "response",
      },
    );
  }

  getLabelOfQualities(quality: PRODUCT_QUALITY) {
    switch (quality) {
      case PRODUCT_QUALITY.QUALITY_NEU:
        return "Neu";
      case PRODUCT_QUALITY.QUALITY_A:
        return "A";
      case PRODUCT_QUALITY.QUALITY_B:
        return "B";
      case PRODUCT_QUALITY.QUALITY_C:
        return "C";
      case PRODUCT_QUALITY.QUALITY_DEFEKT:
        return "Defekt";
    }
  }
}

import { Injectable } from "@angular/core";
import { PaginationFilterService } from "./pagination-filter.service";
import { TablePageComponent } from "../interfaces/table-page-component";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { TableSortEvent } from "../interfaces/table-sort-event";

/**
 *  Can be used to provide helper functions for components that display tables with pagination, filtering and sorting
 * */
@Injectable({
  providedIn: "root",
})
export class TableHelperService {
  tableComponent: TablePageComponent;
  paginationFilterService: PaginationFilterService;

  constructor() {}

  // todo: inject with providers (currently not working, because of circular dependency)
  setComponentAndService(
    tableComponent: TablePageComponent,
    paginationFilterService: PaginationFilterService,
  ) {
    this.tableComponent = tableComponent;
    this.paginationFilterService = paginationFilterService;
  }

  /**
   * resets the table pagination to the first page with 10 entries and resets the search to an empty string
   */
  public resetTablePaginationAndSearch() {
    this.throwErrorIfNotProvided();
    this.paginationFilterService.search = "";
    this.resetTablePagination();
  }

  /**
   * Resets the table pagination to the first page with 10 entries on the component and service
   */
  public resetTablePagination() {
    this.throwErrorIfNotProvided();

    this.paginationFilterService.setPaginationIndices({ start: 0, limit: 10 });
    this.tableComponent.tablePage = 0;
    this.tableComponent.tablePageSize = 10;
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  // resets the search and disables the pagination by setting the start and end index to undefined
  // should be implemented in every ngOnDestroy method of a component that uses the table where
  public deletePaginationIndicesAndSearch() {
    this.throwErrorIfNotProvided();
    this.paginationFilterService.search = "";
    this.paginationFilterService.deletePaginationIndices();
  }

  /**
   * when the pagination event is triggered:
   * sets the table pagination to the selected page and size and updates the table data
   */
  public paginationEvent(pagination: TuiTablePagination) {
    this.throwErrorIfNotProvided();

    this.tableComponent.tablePage = pagination.page;
    this.tableComponent.tablePageSize = pagination.size;

    this.paginationFilterService.setPaginationIndices({
      start: this.tableComponent.tablePage * this.tableComponent.tablePageSize,
      limit: this.tableComponent.tablePageSize,
    });
    this.tableComponent.populateTable();
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.throwErrorIfNotProvided();

    this.paginationFilterService.setSorting(tableSort);
    this.tableComponent.populateTable();
  }

  public throwErrorIfNotProvided() {
    if (!this.tableComponent || !this.paginationFilterService)
      throw new Error(
        "TableHelperService: tableComponent and paginationFilterService need to be provided",
      );
  }
}

@if (order.productOrders && order.productOrders.length > 0) {
  <div class="scoped">
    <tui-island class="tuiIsland" [class.highlight]="sameLocation">
      <!--    header    -->
      <div class="header">
        <span
          class="title max-width-txt"
          style="--max-width: 10rem"
          [title]="order.location.customer.MATCHC"
        >
          {{
            order.location.customer.MATCHC ??
              order.location.name ??
              "keine MATCHC gefunden"
          }}

          <div class="subtitle-light" style="padding-top: 0.25rem">
            {{ order.location.address.street }}
            {{ order.location.address.houseNo }},
            {{ order.location.address.postalCode }}
            {{ order.location.address.city }}
          </div>
        </span>

        <div class="header-subtitle">
          <span class="subtitle-light">
            {{
              order.timeframeBegin && order.timeframeEnd
                ? dateHelper.formatDateStringRange(
                    order.timeframeBegin,
                    order.timeframeEnd
                  )
                : ""
            }}

            <!-- display delivery timeframe if it is not 0:00 - 23:59 (full day) -->
            @if (
              !(
                order.timeframeBegin.includes("00:00:00") &&
                order.timeframeEnd.includes("23:59:59")
              )
            ) {
              <div>
                {{ order.timeframeBegin | date: "HH:mm" : "UTC" }} -
                {{ order.timeframeEnd | date: "HH:mm" : "UTC" }}
                {{ "clock" | translate }}
              </div>
            }
          </span>
          <span class="subtitle-light"> #{{ order.orderNumber }} </span>
        </div>
      </div>

      <!-- order info  -->
      <div class="bottom-container">
        @for (pOrder of order.productOrders; track pOrder) {
          @if (pOrder.calculatedAmount !== 0) {
            <div
              class="inner-container"
              [ngStyle]="{
                'background-color':
                  pOrder.amount < 0
                    ? 'rgba(57,79,74,0.2)'
                    : pOrder.amount > 0
                      ? 'rgba(60, 30, 30, 0.2)'
                      : 'rgba(34, 44, 40, 0.1)'
              }"
            >
              <!--  amount  -->
              <div class="order-amount">
                @if (pOrder.amount < 0) {
                  <img
                    alt="arrow up"
                    height="18"
                    ngSrc="../../../../../assets/svg/arrow-up.svg"
                    width="18"
                    [title]="'order.charge' | translate"
                  />
                } @else if (pOrder.amount > 0) {
                  <img
                    alt="arrow up"
                    height="18"
                    ngSrc="../../../../../assets/svg/arrow-down.svg"
                    width="18"
                    [title]="'order.unload' | translate"
                  />
                }

                {{ pOrder.calculatedAmount }}
              </div>

              @if (pOrder.product && pOrder.product.name) {
                <div class="order-type">
                  <!--    product name  -->
                  <span
                    [class.max-width-txt]="order.productOrders.length > 1"
                    [class.single-line]="order.productOrders.length < 2"
                    [title]="pOrder.product.name"
                    class="medium-txt"
                    style="--max-width: 7rem"
                  >
                    {{ pOrder.product.name }}
                  </span>
                </div>
              }

              <div
                class="ellipse"
                [ngStyle]="{
                  'background-color':
                    pOrder.amount < 0
                      ? '#1FC47D'
                      : pOrder.amount > 0
                        ? '#c41f5e'
                        : '#1FC47D'
                }"
              ></div>

              <div
                class="ellipse2"
                [ngStyle]="{
                  'background-color':
                    pOrder.amount < 0
                      ? '#1FC47D'
                      : pOrder.amount > 0
                        ? '#c41f5e'
                        : '#1FC47D'
                }"
              ></div>
            </div>
          }
        }
      </div>
    </tui-island>
  </div>
}

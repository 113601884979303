import { Component, effect } from "@angular/core";
import { DeliveryReceiptService } from "../../api/deliveryReceipt.service";
import DeliveryReceipt from "../../models/DeliveryReceipt.class";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import { DeliveryReceiptDetailsDialogComponent } from "./delivery-receipt-details-dialog/delivery-receipt-details-dialog.component";
import { TablePageComponent } from "../../interfaces/table-page-component";
import { TableHelperService } from "../../services/table-helper.service";
import { HelperService } from "../../services/helper.service";
import { Permissions } from "../../other/enums/permissions";
import { PermissionService } from "../../services/permission.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TuiDay } from "@taiga-ui/cdk";

@Component({
  selector: "app-delivery-receipt-page",
  templateUrl: "./delivery-receipt-page.component.html",
  styleUrl: "./delivery-receipt-page.component.scss",
})
export class DeliveryReceiptPageComponent implements TablePageComponent {
  tablePage: number = 0;
  tablePageSize: number = 10;

  deliveryReceiptTableColumnNames: string[] = [
    "Erstellt am",
    "Lieferscheinnummer",
    // "Kunde",
    "Kundenstandort / Lager",
    "Fahrer",
    "Angelegt von Mitarbeiter",
  ];
  deliveryReceiptsTableShownColumns: string[] = [
    "createdAt",
    "deliveryReceiptNumber",
    // "tourPoint.location.customer.name", //todo: check if working (currently null)
    "tourPointLocationOrWarehouse",
    "driverName",
    "authorName",
  ];
  noSortColumns: string[] = [
    "tourPointLocationOrWarehouse",
    "deliveryReceiptNumber",
    // "tourPoint.location.customer.name",
    "driverName",
    "authorName",
  ];
  deliveryReceipts: DeliveryReceipt[];
  showDeleteDialog: boolean = false;
  dateForm: FormGroup;
  selectedCreatedAtDate: string | undefined;
  protected readonly Permissions = Permissions;
  private dateChangeTriggered = false;

  constructor(
    public deliveryReceiptService: DeliveryReceiptService,
    private dialogService: TuiDialogHelperService,
    public tableHelperService: TableHelperService,
    public copyHelperService: HelperService,
    private fb: FormBuilder,
    public permissionService: PermissionService,
  ) {
    this.tableHelperService.setComponentAndService(
      this,
      this.deliveryReceiptService,
    );

    effect(() => {
      this.deliveryReceipts = this.deliveryReceiptService.deliveryReceipts;
    });
  }

  ngOnInit(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.sorting();
    this.deliveryReceiptService.setPaginationIndices({ start: 0, limit: 10 });
    this.populateTable();
    this.dateChangeTriggered = true;
    this.initForm();
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  initForm() {
    this.dateForm = this.fb.group({
      searchDate: null,
    });
  }

  sorting() {
    this.deliveryReceiptService.setSorting({
      sortColumn: "createdAt",
      sortDirection: -1,
    });
  }

  search(searchInput: string) {
    this.tableHelperService.resetTablePagination();
    this.deliveryReceiptService.search = searchInput;
    this.populateTable();
  }

  public deliveryReceiptRowClickEvent(id: string): void {
    this.dialogService.openDialog(DeliveryReceiptDetailsDialogComponent, id);
  }

  populateTable(): void {
    const queryParams: {
      withAuthor: boolean;
      withCustomer: boolean;
      withDriver: boolean;
      createdAt?: string;
    } = {
      withAuthor: true,
      withCustomer: true,
      withDriver: true,
    };

    if (this.selectedCreatedAtDate) {
      queryParams.createdAt = this.selectedCreatedAtDate;
    }

    this.deliveryReceiptService.getAllDeliveryReceipts(queryParams).subscribe();
  }

  public extractDate(value: TuiDay | null) {
    return value?.toUtcNativeDate()?.toISOString();
  }

  changeDateEvent(createdAtAsIsoDate: string | undefined) {
    if (!this.dateChangeTriggered) {
      this.tableHelperService.resetTablePagination();
      this.selectedCreatedAtDate = createdAtAsIsoDate;
      this.populateTable();
    } else {
      // reset actionTriggered back to false for normal operations
      this.dateChangeTriggered = false;
    }
  }
}

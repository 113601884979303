import { Component, OnInit } from "@angular/core";
import { MenuItem, MenuItems } from "../../other/enums/menu-items";
import { AuthService } from "../../api/auth.service";
import { Router } from "@angular/router";
import { tuiIconLogOut } from "@taiga-ui/icons";
import { TranslateService } from "@ngx-translate/core";
import { NavRoutes } from "../../other/enums/nav-routes";
import { SuperAdminService } from "../../api/super-admin.service";
// @ts-ignore
import packageJson from "../../../../package.json";
import { VersionService } from "../../api/version.service";
import { PermissionService } from "../../services/permission.service";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})

/**
 * The sidenavigation on left side of the page.
 * Contains the menu items and the logout button on the bottom.
 */
export class SidenavComponent implements OnInit {
  isOpen: boolean = false;
  userName: string = "";
  navItemsArray = Object.values(MenuItems);
  webVersion: string = packageJson.version;
  apiVersion: string;
  protected readonly NavRoutes = NavRoutes;
  protected readonly tuiIconLogOut = tuiIconLogOut;
  protected readonly MenuItems = MenuItems;
  private overview: string = "";
  // Initializes the selected item with the overview item (currently not used, but maybe handy in the future).
  selectedItem: MenuItem = new MenuItem(this.overview, "tuiIconHomeLarge", "/");

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private superAdminService: SuperAdminService,
    private versionService: VersionService,
    private permissionService: PermissionService,
  ) {}

  ngOnInit() {
    this.translate.get(["sidenav.overview"]).subscribe((translations) => {
      this.overview = translations["sidenav.overview"];
    });
    this.getUserName();

    this.getAPIVersion();
  }

  getAPIVersion() {
    this.versionService.getAllVersions().subscribe((version: any) => {
      this.apiVersion = version.version;
    });
  }

  /**
   * Sets the selected item to the clicked item.
   * @param clickedItem The clicked item.
   */
  setSelectedItem(clickedItem: MenuItem) {
    this.selectedItem = clickedItem;
  }

  /**
   * Logs the user out and navigates to the login page.
   */
  logOut() {
    this.authService.logout();
    this.router.navigateByUrl(NavRoutes.LOGIN);
  }

  // To hide the menu if permission is not high enough.
  isSuperAdmin(): boolean | undefined {
    return this.superAdminService.isSuperAdmin();
  }

  getUserName() {
    const user = this.authService.getLoggedInUser();
    if (user) {
      this.userName = user.firstName;
    }
  }

  hasPermissionForMenuItem(menuItem: string): boolean {
    switch (menuItem) {
      case MenuItems.DASHBOARD:
        return this.permissionService.readDashboardPermission().hasPermission;
      case MenuItems.ORDER:
        return this.permissionService.readOrderPermission().hasPermission;
      case MenuItems.REFUND:
        return this.permissionService.readRefundPermission().hasPermission;
      case MenuItems.TOURS:
        return this.permissionService.readTourPermission().hasPermission;
      case MenuItems.UNANNOUNCED_DELIVERIES:
        return this.permissionService.readTourPointPermission().hasPermission;
      case MenuItems.DELIVERY_RECEIPT:
        return this.permissionService.readDeliveryReceiptPermission()
          .hasPermission;
      case MenuItems.CUSTOMERS:
        return this.permissionService.readCustomerPermission().hasPermission;
      case MenuItems.USER:
        return this.permissionService.readUserPermission().hasPermission;
      case MenuItems.SETTINGS:
        return this.hasSettingsPermissions();
      default:
        return true;
    }
  }

  hasSettingsPermissions() {
    return (
      this.permissionService.readRolePermission().hasPermission ||
      this.permissionService.createRolePermission().hasPermission ||
      this.permissionService.readProductPermission().hasPermission ||
      this.permissionService.createProductPermission().hasPermission ||
      this.permissionService.readWarehousePermission().hasPermission ||
      this.permissionService.createWarehousePermission().hasPermission
    );
  }
}

import { Injectable, signal } from "@angular/core";
import { MapLocation } from "../../../interfaces/map-location";
import { GoogleMap } from "@angular/google-maps";
import Tour from "../../../models/Tour.class";
import { toObservable } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class MapBaseDataService {
  map: GoogleMap;
  tour = signal<Tour>(new Tour({}));
  tour$ = toObservable(this.tour);

  // use svg as displayed marker for warehouses
  logoSvg =
    '<svg fill="none" height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">\n' +
    '  <rect fill="white" height="59" rx="29.5" width="59" x="0.5" y="0.5" />\n' +
    '  <rect height="59" rx="29.5" stroke="#333333" width="59" x="0.5" y="0.5" />\n' +
    '  <path d="M30.5231 25.6357L13.75 35.396L30.5231 45.0116L47.224 35.3237L30.5231 25.6357Z" fill="#3BB576" />\n' +
    '  <path d="M47.152 40.24V35.396L30.5234 45.0116V50.0002L47.152 40.24Z" fill="#1C1B1C" />\n' +
    '  <path d="M13.75 40.2713V35.396L30.5231 45.0116V50.0002L13.75 40.2713Z" fill="#CCF8DD" />\n' +
    '  <path d="M30.5231 17.8179L13.75 27.5781L30.5231 37.1937L47.224 27.5058L30.5231 17.8179Z" fill="#3BB576" />\n' +
    '  <path d="M47.152 32.4221V27.5781L30.5234 37.1938V42.1823L47.152 32.4221Z" fill="#1C1B1C" />\n' +
    '  <path d="M13.75 32.4534V27.5781L30.5231 37.1938V42.1823L13.75 32.4534Z" fill="#CCF8DD" />\n' +
    '  <path d="M30.5231 10L13.75 19.7602L30.5231 29.3759L47.224 19.6879L30.5231 10Z" fill="#3BB576" />\n' +
    '  <path d="M47.152 24.6042V19.7603L30.5234 29.3759V34.3644L47.152 24.6042Z" fill="#1C1B1C" />\n' +
    '  <path d="M13.75 24.6356V19.7603L30.5231 29.3759V34.3644L13.75 24.6356Z" fill="#CCF8DD" />\n' +
    "</svg>";
  // use svg as displayed marker for locations
  locationSvg =
    '<svg fill="none" height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">\n' +
    "  <path\n" +
    '    d="M52.5 25C52.5 42.5 30 57.5 30 57.5C30 57.5 7.5 42.5 7.5 25C7.5 19.0326 9.87053 13.3097 14.0901 9.0901C18.3097 4.87053 24.0326 2.5 30 2.5C35.9674 2.5 41.6903 4.87053 45.9099 9.0901C50.1295 13.3097 52.5 19.0326 52.5 25Z"\n' +
    '    fill="#333333" />\n' +
    '  <g filter="url(#filter0_d_1329_13535)">\n' +
    "    <path\n" +
    '      d="M30 32.5C34.1421 32.5 37.5 29.1421 37.5 25C37.5 20.8579 34.1421 17.5 30 17.5C25.8579 17.5 22.5 20.8579 22.5 25C22.5 29.1421 25.8579 32.5 30 32.5Z"\n' +
    '      fill="#1FC47D" />\n' +
    "  </g>\n" +
    "  <defs>\n" +
    '    <filter color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse" height="23" id="filter0_d_1329_13535" width="23" x="18.5"\n' +
    '            y="17.5">\n' +
    '      <feFlood flood-opacity="0" result="BackgroundImageFix" />\n' +
    '      <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix"\n' +
    '                     values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />\n' +
    '      <feOffset dy="4" />\n' +
    '      <feGaussianBlur stdDeviation="2" />\n' +
    '      <feComposite in2="hardAlpha" operator="out" />\n' +
    '      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />\n' +
    '      <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_1329_13535" />\n' +
    '      <feBlend in="SourceGraphic" in2="effect1_dropShadow_1329_13535" mode="normal" result="shape" />\n' +
    "    </filter>\n" +
    "  </defs>\n" +
    "</svg>\n";

  redMarker =
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" fill="#FF0000" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '<path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" fill="white"/>\n' +
    "</svg>\n";

  // defines the starting point (center of germany)
  startingPoint: MapLocation = {
    lat: 51.012672,
    lng: 10.285095,
  };

  // All configuration related stuff is going in here
  mapOptions: google.maps.MapOptions = {
    center: this.startingPoint,
    disableDefaultUI: true,
    zoom: 7,
    maxZoom: 19,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  // HPZ HQ coords
  // lat: 49.054179,
  // lng: 9.265516,

  createNumberedMarkerIcon(iconSvg: string, number?: number): string {
    let numberedPart = "";
    if (number !== undefined) {
      numberedPart = `
        <circle cx="70" cy="20" r="20" fill="white" />
        <text x="70" y="28" font-size="24px" text-anchor="middle" fill="black">${number}</text>
        `;
    }
    const numberedIconSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 40">
       ${iconSvg}
       ${numberedPart}
    </svg>
    `;

    return (
      "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(numberedIconSvg)
    );
  }
}

import { Component, inject, Input } from "@angular/core";
import { NgIf } from "@angular/common";
import { RolePermissionHelperService } from "../../../../services/permission-helper-services/role-permission-helper.service";

@Component({
  selector: "app-missing-role-permission-dialog",
  standalone: true,
  imports: [NgIf],
  templateUrl: "./missing-role-permission-dialog.component.html",
  styleUrl: "./missing-role-permission-dialog.component.scss",
})
export class MissingRolePermissionDialogComponent {
  rolePermissions = inject(RolePermissionHelperService);
  @Input({ required: true }) isAddmode: boolean = true;
}

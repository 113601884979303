<div class="dialog-content" xmlns="http://www.w3.org/1999/html">
  <h1 style="color: var(--tui-text-dark-02); margin-top: 0">
    {{
      isAddMode
        ? ("user.new-title" | translate)
        : ("user.edit-title" | translate)
    }}
  </h1>

  <div
    *ngIf="!isAddMode && !hasAcceptedInvite"
    style="color: var(--tui-text-dark-01); margin-bottom: 1rem"
  >
    {{ "user.invite-not-accepted" | translate }}
    <a
      (click)="sendInviteMailAgain(user.id)"
      style="cursor: pointer; text-decoration: underline"
    >
      {{ "user.resend-invite" | translate }}
    </a>
  </div>

  <!--   form   -->
  <form [formGroup]="form" class="flex-col-g1" style="margin-top: 1.5rem">
    <div class="same-row-inputs">
      <!--   first name   -->
      <app-input-text
        [hint]="'create-user.input-label-first-name'"
        [required]="false"
        fieldName="firstName"
      />

      <!--   last name   -->
      <app-input-text
        [hint]="'create-user.input-label-last-name'"
        [required]="false"
        fieldName="lastName"
      />
    </div>

    <div class="same-row-inputs">
      <!-- email -->
      <app-input-email fieldName="email" />

      <!--   phone number   -->
      <app-input-phone [required]="false" fieldName="phone" />
    </div>

    <!--   roleId   -->
    <app-dropdown
      [dropDownItems]="roleDropdownItems"
      [formFieldName]="'roleId'"
      [hint]="'role.role'"
    ></app-dropdown>

    <!-- viewAccess     -->
    <div
      class="flex-row"
      style="
        color: var(--tui-text-dark-03);
        margin-top: 1rem;
        margin-left: 0.5rem;
      "
    >
      <tui-checkbox-labeled formControlName="isDriver" size="m">
        <b> {{ "user.is-driver" | translate }}</b>
      </tui-checkbox-labeled>
      <tui-checkbox-labeled formControlName="isWarehouseWorker" size="m">
        <b>{{ "user.is-warehouse-worker" | translate }}</b>
      </tui-checkbox-labeled>
    </div>

    <!--  active    -->
    <ng-container *ngIf="!isAddMode">
      <h4>{{ "user.status" | translate }}</h4>
      <app-radio-list
        [fieldName]="'active'"
        [items]="radioItems"
        [rowDirection]="true"
      >
      </app-radio-list>
    </ng-container>

    <!--  is driver  -->
    @if (form.controls["isDriver"].value) {
      <app-input-text
        [required]="false"
        fieldName="truckNumber"
        hint="tour.truckNumber"
      />
    }

    <!--  is warehouse worker  -->
    @if (
      warehousePermissions.hasReadWarehousePermission &&
      warehousePermissions.hasUpdateWarehousePermission
    ) {
      @if (form.controls["isWarehouseWorker"].value) {
        <h4 style="margin: 0 0 0 0.5rem">
          {{ "warehouse.worker-relation" | translate }}
        </h4>

        <app-warehouseworker fControlName="workerWarehouses" />
      }
    } @else {
      @if (form.controls["isWarehouseWorker"].value) {
        <div class="permission-container">
          <h3 style="margin-top: 0">
            Es fehlen folgende Berechtigungen, um eine Lagerzuweisung
            {{ isAddMode ? "erstellen" : "aktualisieren" }} zu können:
          </h3>

          <ul>
            <!--  WAREHOUSE -->
            <li *ngIf="!warehousePermissions.hasReadWarehousePermission">
              <b>Lager ansehen</b>
            </li>

            <li *ngIf="!warehousePermissions.hasUpdateWarehousePermission">
              <b>Lager aktualisieren</b>
            </li>
          </ul>
        </div>
      }
    }

    <!-- cancel, save buttons -->
    <app-save-cancel-btns
      (onCancel)="closeDialog()"
      (onSubmit)="submit()"
      [form]="form"
      style="margin-top: 1.5rem"
    />
  </form>
</div>

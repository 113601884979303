import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import {
  TuiTablePagination,
  tuiTablePaginationOptionsProvider,
} from "@taiga-ui/addon-table";
import { TableSortEvent } from "../../interfaces/table-sort-event";
import {
  tuiIconAlertOctagon,
  tuiIconEdit2Large,
  tuiIconTrash2Large,
  tuiIconTruckLarge,
} from "@taiga-ui/icons";
import { tuiHintOptionsProvider } from "@taiga-ui/core";
import { ORDER_STATE, orderStateTranslations } from "../../models/Order.class";
import { TOUR_STATE, tourStateTranslations } from "../../models/Tour.class";
import { ViewAccess } from "../../models/User";
import getBadgeStatus from "../../other/helper-functions/get-badge-status";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  providers: [
    tuiTablePaginationOptionsProvider({
      showPages: false,
    }),

    tuiHintOptionsProvider({
      icon: "tuiIconAlertTriangle",
    }),
  ],
  styleUrls: ["./table.component.scss"],
})

/**
 * A component that displays a data table with pagination at the bottom and sorting in the table header:
 *
 * @param tableData The data that is displayed in the table
 * @param tableColumnNames The names of the columns that are displayed in the table header
 * @param columns The names of the attributes of the data objects that are displayed in the table
 * @param totalElements The total number of elements that are displayed in the table
 * @param currentPage The current page that should be displayed of the table (default is 0)
 * @param pageSize The number of elements that should be displayed per page (default is 10)
 *
 * @Output paginationEvent An event that is emitted when the user clicks on a pagination button (next, previous, page size)
 * @Output rowClickEvent An event that is emitted when the user clicks on a row of the table
 * @Output sortEvent An event that is emitted when the user clicks on a column header to sort the table
 */
export class TableComponent {
  // Input
  @Input({ required: true }) tableData: any[] = [];
  @Input({ required: true }) tableColumnNames: string[];
  @Input({ required: true }) columns: string[];
  @Input() totalElements: number = 0;
  @Input() pageSize: number = 10;
  @Input() showPagination: boolean = true;
  @Input() itemsDeletable: boolean = false;
  @Input() itemsEditable: boolean = false;
  @Input() hasTruckLoads: boolean = false;
  @Input() noSortColumns: string[] = [];
  @Input() cellTemplatesMap: { [key: string]: TemplateRef<any> } = {}; // for custom columns
  globalNoSortColumns: string[] = ["phone"];
  // Output
  @Output() paginationEvent = new EventEmitter<TuiTablePagination>();
  @Output() rowClickEvent = new EventEmitter<any>();
  @Output() sortEvent = new EventEmitter<TableSortEvent>();
  @Output() deleteEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() showTruckLoadEvent = new EventEmitter();
  sizeOptions = [5, 10, 20, 50, 100];
  protected cursor: string = "";
  protected readonly tuiIconTrash2Large = tuiIconTrash2Large;
  protected readonly tuiIconEdit2Large = tuiIconEdit2Large;
  protected readonly tuiIconTruckLarge = tuiIconTruckLarge;
  protected readonly Math = Math;
  protected readonly orderStateTranslations = orderStateTranslations;
  protected readonly tuiIconAlertOctagon = tuiIconAlertOctagon;
  protected readonly tourStateTranslations = tourStateTranslations;
  protected readonly ORDER_STATE = ORDER_STATE;
  protected readonly TOUR_STATE = TOUR_STATE;
  protected readonly ViewAccess = ViewAccess;
  protected readonly getBadgeStatus = getBadgeStatus;
  // Like user.tenant.name
  private sortDirection: number = 0;

  private _currentPage: number | null = null;

  @Input()
  get currentPage(): number | null {
    return this._currentPage;
  }

  set currentPage(value: number | null) {
    if ((this.showPagination && value === null) || value === undefined) {
      throw new Error(
        "currentPage input is required when showPagination is true.",
      );
    }

    this._currentPage = value;
  }

  ngOnInit() {
    if (!this.itemsDeletable && this.columns.includes("delete")) {
      this.columns = this.columns.filter((column) => column !== "delete");
    }
    if (!this.itemsEditable && this.columns.includes("edit")) {
      this.columns = this.columns.filter((column) => column !== "edit");
    }

    if (
      this.showPagination &&
      (this.currentPage === null || this.currentPage === undefined)
    ) {
      throw new Error(
        "currentPage input is required when showPagination is true.",
      );
    }
  }

  extractNestedProperty(item: any, key: string): any {
    const keys = key.split(".");
    let value = item;

    for (const k of keys) {
      if (value && value.hasOwnProperty(k)) {
        value = value[k];
      } else {
        return null;
      }
    }

    return value;
  }

  shouldNotSort(column: string): boolean {
    return (
      this.noSortColumns.includes(column) ||
      this.globalNoSortColumns.includes(column)
    );
  }

  isIsoDateString(value: any): boolean {
    const isoDatePattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})?$/;
    return typeof value === "string" && isoDatePattern.test(value);
  }

  /**
   * When the user clicks on the header name  a sorting event is emitted with the column name and the sorting direction
   * @param column The name of the column that is clicked
   */
  onSortChange(column: string) {
    this.sortEvent.emit({
      sortColumn: column,
      sortDirection: this.sortDirection,
    });
  }

  /**
   * When the user clicks on the header name the sorting direction is changing
   * @param direction The direction of the sorting (1 = ascending, -1 = descending)
   */
  onDirectionChange(direction: number) {
    this.sortDirection = direction;
  }

  /**
   * Checks if the rowClickEvent is observed (= implemented in the parent component) to determine
   * if the table rows are clickable and thus the cursor should be a pointer or not.
   * @returns true if the rowClickEvent is observed, false otherwise
   */
  isClickable(): boolean {
    return this.rowClickEvent.observers.length > 0;
  }

  delete($event: string, event: Event) {
    event.stopPropagation();
    this.deleteEvent.emit($event);
  }

  edit(id: string, event: Event) {
    event.stopPropagation();

    this.editEvent.emit(id);
  }

  displayTruckLoads(id: string, event: Event) {
    event.stopPropagation();
    this.showTruckLoadEvent.emit(id);
  }
}

<div class="permission-wrapper" style="color: var(--tui-warn-txt)">
  <ng-container [ngSwitch]="mode">
    <h3 *ngSwitchCase="'add'">
      Es fehlen folgende Berechtigungen, um {{ elementName }} erstellen zu
      können:
    </h3>
    <h3 *ngSwitchCase="'update'">
      Es fehlen folgende Berechtigungen, um {{ elementName }} aktualisieren zu
      können:
    </h3>
    <h3 *ngSwitchCase="'delete'">
      Es fehlen folgende Berechtigungen, um {{ elementName }} löschen zu können:
    </h3>
  </ng-container>

  <ul>
    @for (permission of permissions; track permission) {
      @if (!permission.hasPermission) {
        <li>
          <b>{{ permission.name }}</b>
        </li>
      }
    }
  </ul>
</div>

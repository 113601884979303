@if (
  warehousePermissions.hasCreateReadWarehousePermission(isAddMode) ||
  warehousePermissions.hasEditReadWarehousePermission(!isAddMode)
) {
  <div class="dialog-content">
    <h1
      style="color: var(--tui-text-dark-02); margin-top: 0; font-size: 1.5rem"
    >
      {{
        isAddMode
          ? ("warehouse.new-title" | translate)
          : ("warehouse.edit-title" | translate)
      }}
    </h1>

    <!--   title   -->
    <h3 style="margin-top: 0">{{ "warehouse.title" | translate }}</h3>

    <!--   form   -->
    <form [formGroup]="form">
      <!--   customer name   -->
      <app-input-text fieldName="name" hint="warehouse.name" />

      <!--   address title   -->
      <h3>{{ "warehouse.address" | translate }}</h3>

      <!--   address form   -->
      <app-add-edit-address [form]="form" />

      <!-- custom spacer  -->
      <div style="padding-block: 1rem"></div>

      <!-- cancel, save buttons -->
      <app-save-cancel-btns
        (onCancel)="cancel()"
        (onSubmit)="submit()"
        [form]="form"
      />
    </form>
  </div>
}

<h1 style="margin-top: 0">
  {{
    isAddMode ? ("tour.add-title" | translate) : ("tour.edit-title" | translate)
  }}
</h1>

<div class="dialog-content">
  <!--   form   -->
  <form [formGroup]="form" class="flex-col-g1">
    <div class="tour-container flex-col-g1">
      <div class="same-row-inputs">
        <!--    drivers    -->
        <app-dropdown
          (valueChange)="changeDriver($event)"
          [dropDownItems]="drivers"
          formFieldName="driverId"
          hint="driver.title"
        />

        <!-- truck number -->
        <app-input-text
          [required]="false"
          fieldName="truckNumber"
          hint="tour.truckNumber"
        />

        <!--    date  -->
        <app-input-date
          [readOnly]="!isAddMode"
          fieldName="date"
          hint="map.tour-date"
        />

        <!--    start time  -->
        <tui-input-time
          [items]="tuiTimeValues"
          [strict]="true"
          formControlName="startingTime"
        >
          <span style="color: black">
            {{ "new-appointment.input-start-time" | translate }}
          </span>
          <span [class.tui-required]="true"></span>
        </tui-input-time>
      </div>

      <!-- tour status -->
      <app-dropdown
        *ngIf="!isAddMode"
        [dropDownItems]="isAddMode ? statusDropdownAdd : statusDropdownEdit"
        formFieldName="tourState"
        hint="order.status"
      />
    </div>

    <!-- cancel, save buttons -->
    <app-save-cancel-btns
      (onCancel)="close()"
      (onSubmit)="submit()"
      [form]="form"
    />
  </form>
</div>

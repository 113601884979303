import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import Order, { ORDER_STATE } from "../models/Order.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

export type orderQueryParams = {
  locationId?: string;
  customerId?: string;
  timeframeEnd?: string;
  timeframeBegin?: string;
  withCancelled?: boolean;
  dateFrom?: string;
  dateTo?: string;
  date?: string;
  populateRefund?: boolean;
  onlyCancelled?: boolean;
  onlyRefund?: boolean;
  createdAt?: string;
  updatedAt?: string;
  withoutFinished?: boolean;
  excludeOrderState?: ORDER_STATE;
  orderState?: ORDER_STATE;
  populateDeleter?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class OrderService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  private totalOrders = signal<number>(0);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  private _orders = signal<Order[]>([]);

  get orders() {
    return this._orders();
  }

  set orders(value: Order[]) {
    this._orders.set(value);
  }

  get total() {
    return this.totalOrders();
  }

  get chartData() {
    const state = Object.values(ORDER_STATE);
    let orderData: number[] = [];

    state.forEach((state, index) => {
      orderData[index] = 0;
      this.orders.forEach((order) => {
        if (order.orderState === state) {
          orderData[index] = orderData[index] ? orderData[index] + 1 : 1;
        }
      });
    });

    return orderData;
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD Order
  addOrder(addOrderObject: Order): Observable<Order> {
    return this.http.post<Order>(this.baseUrl + "order", addOrderObject).pipe(
      tap(() => {
        // Call the getAllOrders method for updating the data + view
        this.getAllOrders().subscribe();
      }),
      catchError((err) => {
        this.errorHandler.sendErrorMessage("order", "CREATE", err);
        return of(err); // Return null or a default value
      }),
    );
  }

  // GET ALL Orders
  getAllOrders(
    orderQueryParams?: orderQueryParams,
    includePopulate?: boolean,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (orderQueryParams) {
      for (const [key, value] of Object.entries(orderQueryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    params = super.setPaginationSortingParams(params);

    if (includePopulate) {
      params = params.set("populateProductOrder", true);
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "order", { params: params })
      .pipe(
        tap((res: ResponseWithRecordsBody) => {
          this._orders.set(res.records.map((order: Order) => new Order(order)));
          this.totalOrders.set(res.total);
        }),
      );
  }

  // GET ONE Order
  getOrderById(id: string | number): Observable<Order> {
    return this.http.get<Order>(this.baseUrl + "order/" + id);
  }

  // CHANGE ONE Order
  updateOrderById(
    id: string | number,
    updateOrderObject: Order,
  ): Observable<Order> {
    return this.http
      .patch<Order>(this.baseUrl + "order/" + id, updateOrderObject)
      .pipe(
        tap(() => {
          // Call the getAllOrders method for updating the data + view
          this.getAllOrders().subscribe();
        }),
        catchError((err) => {
          this.errorHandler.sendErrorMessage("order", "UPDATE", err);
          return of(err); // Return null or a default value
        }),
      );
  }

  // DELETE ONE Order
  deleteOrderById(id: number | string): Observable<unknown> {
    return this.http.delete<unknown>(this.baseUrl + "order/" + id).pipe(
      tap(() => {
        // Open the snackbar for successful request
        this.handleSuccess("Auftrag wurde gelöscht");
        // Call the getAllOrders method for updating the data + view
        this.getAllOrders().subscribe();
      }),
      catchError((err) => {
        this.errorHandler.sendErrorMessage("order", "DELETE", err);
        return of(err); // Return null or a default value
      }),
    );
  }

  // get pdf
  getPdfByOrderId(id: string): Observable<any> {
    return this.http
      .get(this.baseUrl + `order/${id}/pdf`, {
        responseType: "blob" as "json",
      })
      .pipe(
        catchError((err) => {
          this.push.sendPush(
            pushTypes.ERROR,
            "",
            "PDF konnte nicht geladen werden, da der Kunde voraussichtlich keine Kundennummer hat!",
          );
          return of(err);
        }),
      );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";
import { ContextData } from "../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";

@Injectable({
  providedIn: "root",
})
export class OrderPermissionsHelperService {
  permissionService = inject(PermissionService);

  missingDeletePermissions: ContextData = {
    mode: "delete",
    elementName: "Aufträge",
    permissions: [
      {
        hasPermission:
          this.permissionService.deleteOrderPermission().hasPermission,
        name: "Aufträge löschen",
      },
      {
        hasPermission:
          this.permissionService.deleteProductOrderPermission().hasPermission,
        name: "Produktbestellungen löschen",
      },
      {
        hasPermission:
          this.permissionService.deleteTourPermission().hasPermission,
        name: "Touren löschen",
      },
      {
        hasPermission:
          this.permissionService.deleteTourPointPermission().hasPermission,
        name: "Tourpunkte löschen",
      },
    ],
  };

  get collidingCreateAndEditOrderPermissions() {
    return (
      // Read Order
      this.permissionService.readOrderPermission().hasPermission &&
      // Read Customer
      this.permissionService.readCustomerPermission().hasPermission &&
      // Read Location
      this.permissionService.readLocationPermission().hasPermission &&
      // Read Product
      this.permissionService.readProductPermission().hasPermission
    );
  }

  get hasAllCreateOrderPermissions(): boolean {
    return (
      // Create Order
      this.permissionService.createOrderPermission().hasPermission &&
      // Create Product Order
      this.permissionService.createProductOrderPermission().hasPermission &&
      this.collidingCreateAndEditOrderPermissions
    );
  }

  get hasAllEditOrderPermissions(): boolean {
    return (
      // Update Order
      this.permissionService.updateOrderPermission().hasPermission &&
      // Update Product Order
      this.permissionService.updateProductOrderPermission().hasPermission &&
      this.collidingCreateAndEditOrderPermissions
    );
  }

  get hasAllDeleteOrderPermissions(): boolean {
    return (
      // Delete Order
      this.permissionService.deleteOrderPermission().hasPermission &&
      // Delete ProductOrder
      this.permissionService.deleteProductOrderPermission().hasPermission &&
      // Delete TPL
      this.permissionService.deleteTourPermission().hasPermission &&
      // Delete TP
      this.permissionService.deleteTourPointPermission().hasPermission
    );
  }
}

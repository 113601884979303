import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { tuiIconPhone } from "@taiga-ui/icons";
import { TUI_VALIDATION_ERRORS } from "@taiga-ui/kit";

@Component({
  selector: "app-input-phone",
  templateUrl: "./input-phone.component.html",
  styleUrls: ["./input-phone.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        startsWithValidCharacter:
          "Die Telefonnummer muss mit '+', '0' oder ( beginnen.", // todo translate
        containsOnlyValidCharacters:
          "Die Telefonnummer darf nur 0 - 9, - und () enthalten.", // todo translate
      },
    },
  ],
})

/**
 * A component that displays an input field for entering a phone number
 * @Input hint - The hint to display on top of the input field
 * @Input fieldName - The name of the field in the FormGroup
 */
export class InputPhoneComponent {
  @Input() hint: string = "Telefonnummer";
  @Input() fieldName?: string = "telephone";
  @Input() required?: boolean = true;
  @Input() hintColor: string = "black";
  @Input() bRadius?: string = "";
  protected readonly tuiIconPhone = tuiIconPhone;
}

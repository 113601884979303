<app-table-layout
  (btnClick)="createNewRole()"
  [fullWidth]="false"
  [showPadding]="false"
  [showTable]="permissionService.readRolePermission().hasPermission"
  [missingReadPermissions]="permissionService.readRolePermission().missingPermissions"
  [showAddButton]="permissionService.createRolePermission().hasPermission"
  newButtonTxt="role.create-role"
  ngxTitle="role.page-title"
>
  <!-- table -->
  <app-table
    (deleteEvent)="deleteRoleDialog($event)"
    (editEvent)="editRole($event)"
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (sortEvent)="tableHelperService.sortTable($event)"
    [columns]="roleTableShownColumns"
    [currentPage]="tablePage"
    [itemsDeletable]="permissionService.deleteRolePermission().hasPermission"
    [itemsEditable]="permissionService.updateRolePermission().hasPermission"
    [pageSize]="tablePageSize"
    [tableColumnNames]="roleTableColumnNames"
    [tableData]="roleService.roles"
    [totalElements]="roleService.total"
  ></app-table>
</app-table-layout>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="deleteRole()"
  [isOpen]="showDeleteDialog"
/>

<router-outlet></router-outlet>

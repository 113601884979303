import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from "@angular/core";
import { ORDER_STATE } from "../../../models/Order.class";
import { KpiService } from "../../../api/kpi.service";
import { PermissionService } from "../../../services/permission.service";

@Component({
  selector: "app-dashboard-widgets-outside",
  templateUrl: "./dashboard-widgets-outside.component.html",
  styleUrl: "./dashboard-widgets-outside.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetsOutsideComponent {
  // kpi data right side
  unplannedDrivers = signal<number>(0);
  openOrders = signal<number>(0);
  orders = signal<[]>([]);

  orderState: ORDER_STATE[] = Object.values(ORDER_STATE);
  date = new Date().toLocaleDateString();
  permissionService = inject(PermissionService);
  private kpiService: KpiService = inject(KpiService);

  ngOnInit() {
    this.setUnplannedDrivers();
    this.setOpenOrders();
  }

  setUnplannedDrivers() {
    this.kpiService.getUnplannedDrivers().subscribe((data) => {
      this.unplannedDrivers.set(data);
    });
  }

  setOpenOrders() {
    this.kpiService.getOpenOrders().subscribe((data) => {
      this.openOrders.set(data);
    });
  }
}

<div style="display: flex; gap: 1rem">
  @if (!editDisabled) {
    <button
      (click)="edit(); $event.stopPropagation()"
      [icon]="tuiIconEdit2Large"
      appearance="secondary"
      size="m"
      tuiIconButton
    ></button>
  }

  @if (!deleteDisabled) {
    <button
      (click)="openDeleteDialog(); $event.stopPropagation()"
      [icon]="tuiIconTrash2Large"
      appearance="secondary-destructive"
      size="m"
      tuiIconButton
    ></button>
  }
</div>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DropDownItem } from "../../../../interfaces/drop-down-item";
import Customer from "../../../../models/Customer.class";
import LocationC from "../../../../models/Location.class";
import { LocationService } from "../../../../api/location.service";
import { CustomerService } from "../../../../api/customer.service";
import { MapOrdersService } from "../../map-helper-services/map-orders.service";
import { MapMarkersService } from "../../map-helper-services/map-markers.service";
import { MapTpTplService } from "../../map-helper-services/map-tp-tpl.service";
import { OrderService } from "../../../../api/order.service";
import { MapBaseDataService } from "../../map-helper-services/map-base-data.service";
import Order from "../../../../models/Order.class";
import { Subscription } from "rxjs";
import Tour from "../../../../models/Tour.class";

@Component({
  selector: "app-order-filter",
  templateUrl: "./order-filter.component.html",
  styleUrls: ["./order-filter.component.scss"],
})
export class OrderFilterComponent implements OnInit, OnDestroy {
  form: FormGroup = this.fb.group({
    customer: [null],
    location: [null],
  });

  allCustomers: Customer[] = [];
  customers: Customer[] = [];
  customerDropdown: DropDownItem[];

  orders?: Order[];

  allLocations: LocationC[] = [];
  locations: LocationC[] = [];
  locationDropdown: DropDownItem[];

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private mapOrderService: MapOrdersService,
    private locationService: LocationService,
    private customerService: CustomerService,
    private markerService: MapMarkersService,
    private mapTPService: MapTpTplService,
    private orderService: OrderService,
    private mapBaseService: MapBaseDataService,
  ) {}

  ngOnInit(): void {
    this.getCustomers();
    this.getLocations();
    this.subscription = this.mapBaseService.tour$.subscribe((tour) => {
      this.getOrdersWithTourDate(tour);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  resetForm() {
    this.form.controls["customer"].setValue(null, { emitEvent: false });
    this.form.controls["location"].setValue(null, { emitEvent: false });
  }

  resetFilterData() {
    this.locations = this.allLocations;
    this.customers = this.allCustomers;
    this.locationDropdown = this.createDropdownItems(this.locations);
    this.customerDropdown = this.createDropdownItems(this.customers, "MATCHC");
  }

  allOrders() {
    this.resetForm();
    this.mapOrderService.filters = {};
    this.resetFilterData();
    this.markerService.showAllMarkers();
    this.mapOrderService.getOrdersWithCallback();
  }

  getOrdersWithTourDate(tour: Tour) {
    if (!tour.date) return;
    this.orderService
      .getAllOrders(
        { date: this.mapBaseService.tour().date, populateRefund: true },
        true,
      )
      .subscribe((orders) => {
        this.updateOrdersData(orders.records);
      });
  }

  updateOrdersData(orders: Order[]) {
    this.orders = orders;
    let customers = this.createCustomers(this.orders);
    let locations = this.createLocations(this.orders);

    this.customers = this.allCustomers = customers;
    this.customerDropdown = this.createDropdownItems(this.customers, "MATCHC");

    this.locations = this.allLocations = locations;
    this.locationDropdown = this.createDropdownItems(this.locations);
  }

  createCustomers(orders: Order[]): Customer<any>[] {
    return orders
      .filter((order) => !order.location.customer.vcIsLogEntity)
      .map((order) => new Customer(order.location.customer))
      .reduce((accum: Customer<any>[], currValue: Customer<any>) => {
        return accum.some((item) => item.id === currValue.id)
          ? accum
          : [...accum, currValue];
      }, []);
  }

  createLocations(orders: Order[]): LocationC[] {
    return orders
      .filter((order) => !order.location.vcIsLogEntity)
      .map((order) => new LocationC(order.location))
      .reduce((accum: LocationC[], currValue: LocationC) => {
        return accum.some((item) => item.id === currValue.id)
          ? accum
          : [...accum, currValue];
      }, []);
  }

  getCustomers() {
    this.customerService.getAllCustomers().subscribe((customer) => {
      this.customers = customer.records;
      this.allCustomers = customer.records;

      this.customerDropdown = this.createDropdownItems(
        this.customers,
        "MATCHC",
      );
    });
  }

  onCustomerChange(customer: DropDownItem) {
    const findLocations = this.allLocations.filter(
      (l) => l.customerId === customer.id,
    );

    this.mapOrderService.getOrdersByCustomerId(customer.id as string);
    this.filterLocationsByCustomerId(customer.id as string);

    // If exactly one location is found, set that location value.
    if (findLocations.length === 1) {
      const location = new LocationC(findLocations[0]);
      this.form.controls["location"].setValue(
        {
          id: location.id,
          label: this.getAddress(location),
        },
        { emitEvent: false },
      );
      this.mapOrderService.filters["locationId"] = location.id;
    } else {
      this.form.controls["location"].setValue(null, { emitEvent: false });
      this.mapOrderService.filters = {};
    }

    this.showFilteredLocationMarker(
      this.getLocationsByCustomerId(customer.id as string),
    );
  }

  filterLocationsByCustomerId(customerId: string) {
    this.locations = this.allLocations.filter((location) => {
      return location.customerId === customerId;
    });
    this.locationDropdown = this.createDropdownItems(this.locations);
  }

  getLocations() {
    this.locationService.getAllLocations().subscribe((locations) => {
      this.locations = locations.records.map((l) => new LocationC(l));
      this.allLocations = locations.records.map((l) => new LocationC(l));
      this.locationDropdown = this.createDropdownItems(this.locations);
    });
  }

  onLocationChange(location: DropDownItem) {
    this.mapOrderService.getOrdersByLocationId(location.id as string);
    this.setCustomerDropdownByLocationId(location.id as string);
    this.showFilteredLocationMarker([location.id as string]);
  }

  showFilteredLocationMarker(locationIds: string[]) {
    const locationAndWarehouseIds = this.mapTPService.tourPoints.map((tp) => {
      return tp.locationId || tp.warehouseId;
    });
    this.markerService.applyFilter(locationIds, locationAndWarehouseIds);
  }

  getAddress(location: LocationC): string {
    return `${location.address.street} ${location.address.houseNo}, ${location.address.postalCode} ${location.address.city}`;
  }

  setCustomerDropdownByLocationId(locationId: string) {
    const foundCustomer = this.findCustomerByLocationId(locationId);
    this.form.controls["customer"].setValue(
      {
        id: foundCustomer?.id,
        label: foundCustomer?.MATCHC,
      },
      { emitEvent: false },
    );
  }

  findCustomerByLocationId(locationId: string): Customer | undefined {
    const location = this.locations.find((location) => {
      return location.id === locationId;
    });
    const customer: Customer | undefined = this.customers.find((customer) => {
      return customer.id === location?.customerId;
    });
    if (!customer) return;
    return new Customer(customer);
  }

  createDropdownItems(
    array: LocationC[] | Customer[],
    keyForLabel: string = "name",
    keyForId: string = "id",
  ): DropDownItem[] {
    return array.map((item: any) => {
      let label = item[keyForLabel];
      if (item instanceof LocationC) {
        label = this.getAddress(item);
      }
      return {
        id: item[keyForId],
        label: label,
      };
    });
  }

  getLocationsByCustomerId(customerId: string): string[] {
    return this.locations
      .filter((location) => location.customerId === customerId)
      .map((location) => location.id);
  }
}

import { BaseModel } from "./BaseModel";
import Product, { PRODUCT_QUALITY } from "./Product.class";
import TourPointLoad from "./TourPointLoad.class";
import Order from "./Order.class";

export default class ProductOrder extends BaseModel<ProductOrder> {
  tenantId: string;
  orderId: string;
  productId: string;
  acceptedQuality: PRODUCT_QUALITY;
  tourPointLoads: TourPointLoad[];
  product: Product | null;
  amount!: number;
  pricePerProduct!: number;
  calculatedAmount: number;
  order?: Order;

  constructor(params: Partial<ProductOrder>) {
    super(params);
    Object.assign(this, params);
  }
}

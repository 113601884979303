<ng-template
  [(tuiDialog)]="isOpen"
  [tuiDialogOptions]="{
    label: isAddMode ? (ngxAddTitle | translate) : (ngxEditTitle | translate),
    size: 'm',
    dismissible: false,
    closeable: false
  }"
  let-observer
>
  <ng-content />
</ng-template>

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Location } from "@angular/common";

@Component({
  selector: "app-save-cancel-btns",
  templateUrl: "./save-cancel-btns.component.html",
  styleUrls: ["./save-cancel-btns.component.scss"],
})
export class SaveCancelBtnsComponent {
  @Input() form: FormGroup;
  @Input() customValidatorBoolean: boolean = false;
  @Input() showDeleteBtn: boolean = false;

  @Output() onSubmit = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  submitting = false;

  constructor(private location: Location) {}

  cancel() {
    if (this.onCancel.observers.length === 0) {
      // No subscribers, execute default cancel behavior
      this.defaultCancelBehavior();
    } else {
      // Emit cancel event for parent to handle
      this.onCancel.emit();
    }
  }

  defaultCancelBehavior() {
    this.location.back();
  }

  submit($event: MouseEvent) {
    if (this.submitting || this.customValidatorBoolean) return;

    this.submitting = true;

    this.onSubmit.emit($event);

    setTimeout(() => {
      this.submitting = false;
    }, 1000);
  }
}

<app-table
  (paginationEvent)="paginationEvent.emit($event)"
  (rowClickEvent)="routeToOrderDetails($event)"
  (sortEvent)="sortEvent.emit($event)"
  [cellTemplatesMap]="{
    refunds: refunds,
    timeframeBegin: timeFrame,
    timeframeEnd: timeFrame,
    orderNumber: orderNumber,
    products: products,
    totalAmount: totalAmount,
    invoiceAmount: invoiceAmount,
    deleter,
    customEdit
  }"
  [columns]="dataColumns"
  [currentPage]="tablePage"
  [noSortColumns]="noSortColumns"
  [pageSize]="tablePageSize"
  [tableColumnNames]="headerColumns"
  [tableData]="orders"
  [totalElements]="orderService.total"
>
  <!--   custom cell refunds -->
  <ng-template #refunds let-object="object">
    @if (object.refunds.length > 0) {
      <tui-svg
        src="tuiIconAlertOctagonLarge"
        style="color: var(--tui-warn-bg)"
        [tuiHint]="refundTooltip"
        [tuiHintDirection]="'top'"
        [tuiHintAppearance]="'error'"
      ></tui-svg>
    }
  </ng-template>
  <ng-template #orderNumber let-value>
    @if (value) {
      <!-- copy button -->
      <div
        class="flex-align-center"
        style="gap: 0.75rem; margin-right: 0.5rem; padding-bottom: 0"
      >
        {{ value }}
        <button
          (click)="copyHelperService.copy(value); $event.stopPropagation()"
          [tuiHint]="tooltip"
          appearance="secondary"
          icon="tuiIconCopy"
          size="s"
          tuiHintDirection="right"
          tuiIconButton
        ></button>
        <ng-template #tooltip>
          {{
            copyHelperService.copyState === "ready"
              ? ("general.copy" | translate)
              : copyHelperService.copyState === "success"
                ? ("general.copy-success" | translate)
                : ("general.copy-error" | translate)
          }}
        </ng-template>
      </div>
    } @else {
      -
    }
  </ng-template>

  <!-- timeframeBegin + timeframeEnd -->
  <ng-template #timeFrame let-value>
    {{ value | date: "dd.MM.yyyy / HH:mm" : "UTC" }}
  </ng-template>

  <!-- products -->
  <ng-template #products let-object="object">
    <div class="product-order-table-row">
      @if (object.productOrders?.length === 0) {
        -
      }
      @for (
        productOrder of getUniqueProductNames(object.productOrders);
        track productOrder
      ) {
        <span style="text-wrap: wrap">
          {{ productOrder }}
          @if (!$last) {
            ,
          }
        </span>
      }
    </div>
  </ng-template>

  <!-- total product amount -->
  <ng-template #totalAmount let-object="object">
    {{ getTotalAmountFromProductOrder(object.productOrders) }}
  </ng-template>

  <!-- invoice amount -->
  <ng-template #invoiceAmount let-object="object">
    {{
      getTotalInvoiceAmountFromProductOrder(object.productOrders)
        | currency: "EUR" : "symbol-narrow" : "1.2-2" : "de-DE"
    }}
  </ng-template>

  <!-- deleted from -->
  <ng-template #deleter let-object="object">
    {{ object.deleter?.firstName }}
    {{ object.deleter?.lastName }}
  </ng-template>

  <!--  edit + delete icons  -->
  <ng-template #customEdit let-object="object">
    @if (object.orderState !== ORDER_STATE.CANCELLED) {
      <app-edit-delete-table-icons
        [deleteDisabled]="!itemsDeletable"
        [editDisabled]="!itemsEditable"
        (editEvent)="editEvent.emit(object.id)"
        (openDeleteDialogEvent)="deleteEvent.emit(object.id)"
      />
    }
  </ng-template>
</app-table>

import { Component, EventEmitter, Output } from "@angular/core";
import { debounceTime, Subject } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-table-search-input",
  templateUrl: "./table-search-input.component.html",
  styleUrl: "./table-search-input.component.scss",
})
export class TableSearchInputComponent {
  private searchChanged = new Subject<string>();
  protected searchFormControl: FormControl = new FormControl("");

  @Output() searchChangedDebounced = new EventEmitter<string>();

  ngOnInit(): void {
    this.searchChanged.pipe(debounceTime(400)).subscribe((event) => {
      this.searchChangedDebounced.emit(event);
    });
  }

  onSearchChange($event: string) {
    this.searchChanged.next($event);
  }
}

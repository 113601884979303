import { inject, Injectable, signal } from "@angular/core";
import { PermissionService } from "../permission.service";
import { PermissionType } from "../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ProductOrderPermissionHelperService {
  permissionService = inject(PermissionService);
  readPermissionTooltip = signal<PermissionType[]>([
    {
      name: "Produktbestellungen ansehen",
      hasPermission: this.hasReadProductOrderPermission,
    },
  ]);

  deletePermissionTooltip = signal<PermissionType[]>([
    {
      name: "Tour löschen",
      hasPermission:
        this.permissionService.deleteTourPermission().hasPermission,
    },
    {
      name: "Tourpunkte löschen",
      hasPermission:
        this.permissionService.deleteTourPointPermission().hasPermission,
    },
  ]);

  get hasReadProductOrderPermission(): boolean {
    return this.permissionService.readProductOrderPermission().hasPermission;
  }

  get hasAllDeleteProductOrderPermissions() {
    return (
      this.permissionService.deleteProductOrderPermission().hasPermission &&
      this.permissionService.deleteTourPermission().hasPermission &&
      this.permissionService.deleteTourPointPermission().hasPermission
    );
  }
}

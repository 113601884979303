import { Injectable, signal } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { catchError, map, Observable, of, tap, throwError } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import Customer from "../models/Customer.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

export type CustomerQuery = {
  inactiveSince?: string;
  withLatestOrder?: boolean;
};

type CustomerKpiData = {
  ordersCount: number;
  onTime: number;
  salesVolumeInMonth: number;
  withLatestOrder: boolean;
};

@Injectable({
  providedIn: "root",
})
export class CustomerService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  private _totalCustomers = signal<number>(0);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  private _customers = signal<Customer[]>([]);

  get customers() {
    return this._customers();
  }

  set customers(value: Customer[]) {
    this._customers.set(value);
  }

  get total() {
    return this._totalCustomers();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD Customer
  addCustomer(addCustomerObject: Customer): Observable<Customer> {
    return this.http
      .post<Customer>(this.baseUrl + "customer", addCustomerObject)
      .pipe(
        tap(() => {
          // Call the getAllCustomer method for updating the data + view
          this.getAllCustomers().subscribe();
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.sendErrorMessage("customer", "CREATE", error);
          // Handle error and return it in an Observable
          return of(new Customer({}));
        }),
      );
  }

  // GET ALL Customers
  getAllCustomers(
    customerQuery?: CustomerQuery,
    hasPagination: boolean = true,
  ): Observable<ResponseWithRecordsBody<Customer>> {
    let params: HttpParams = new HttpParams();

    if (customerQuery) {
      for (const [key, value] of Object.entries(customerQuery)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    if (hasPagination) {
      params = super.setPaginationSortingParams(params);
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "customer", {
        params: params,
      })
      .pipe(
        tap((res: ResponseWithRecordsBody) => {
          this._customers.set(
            res.records.map((customer: Customer) => new Customer(customer)),
          );
          this._totalCustomers.set(res.total);
        }),
      );
  }

  getHighestCustomerNumber() {
    const params: HttpParams = new HttpParams();

    params.set("sort", "customerNumber+DESC").set("limit", "1");

    return this.http.get<ResponseWithRecordsBody>(this.baseUrl + "customer", {
      params: params,
    });
  }

  // GET ONE Customer
  getCustomerById(id: string | number): Observable<Customer> {
    return this.http.get<Customer>(this.baseUrl + "customer/" + id);
  }

  // GET KPI Data for Customer
  getCustomerKpiData(id: string): Observable<CustomerKpiData> {
    return this.http.get<CustomerKpiData>(this.baseUrl + "customer/KPI/" + id);
  }

  // CHANGE ONE Customer
  updateCustomerById(
    id: string | number,
    updateCustomerObject: Customer,
  ): Observable<Customer | HttpErrorResponse> {
    return this.http
      .patch<Customer>(this.baseUrl + "customer/" + id, updateCustomerObject)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.sendErrorMessage("customer", "UPDATE", error);
          // Handle error and return it in an Observable
          return throwError(() => error);
        }),
      );
  }

  // ADD Customer File
  addCustomerFileById(customerId: string, formdata: FormData) {
    return this.http
      .post(this.baseUrl + "customer/" + customerId + "/file", formdata)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.sendErrorMessage("customer", "CREATE", error);
          // Handle error and return it in an Observable
          return throwError(() => error);
        }),
      );
  }

  // GET Customer File
  getCustomerFileById(fileId: string) {
    return this.http
      .get(this.baseUrl + "customer/" + "files/" + fileId, {
        responseType: "blob" as "json",
        observe: "response",
      })
      .pipe(
        map((response) => {
          // TODO: fetch filename

          const filename = "fileName";

          // Returning filename and Blob from response
          return { filename, file: response.body };
        }),
      );
  }

  deleteCustomerFileById(fileId: string) {
    return this.http.delete(this.baseUrl + "customer/" + fileId + "/file").pipe(
      tap(() => {
        // Open the snackbar for successful request
        this.handleSuccess("Datei wurde gelöscht");
      }),
      catchError((err) => {
        // Open the snackbar for error
        this.push.sendPush(
          pushTypes.ERROR,
          "",
          "Fehler beim Löschen der Datei",
        );
        return of(err);
      }),
    );
  }

  // DELETE ONE Customer
  // deleteCustomerById(id: number | string): Observable<unknown> {
  //   return this.http.delete<unknown>(this.baseUrl + "customer/" + id).pipe(
  //     tap(() => {
  //       // Open the snackbar for successful request
  //       this.handleSuccess("Kunde wurde gelöscht!");
  //       // Call the getAllCustomers method for updating the data + view
  //       this.getAllCustomers().subscribe();
  //     }),
  //     catchError((err) => {
  //       this.errorHandler.sendErrorMessage("customer", "DELETE", err);
  //       return of(err);
  //     }),
  //   );
  // }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

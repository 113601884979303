import { Component, Input } from "@angular/core";
import {
  tuiIconArrowDownCircleLarge,
  tuiIconArrowUpCircleLarge,
} from "@taiga-ui/icons";
import { TuiSvgModule } from "@taiga-ui/core";
import { NgClass } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-arrow-icon",
  standalone: true,
  imports: [TuiSvgModule, NgClass, TranslateModule],
  templateUrl: "./arrow.component.html",
  styleUrl: "./arrow.component.scss",
})
export class ArrowComponent {
  @Input() down: boolean = true;
  protected readonly tuiIconArrowDownCircleLarge = tuiIconArrowDownCircleLarge;
  protected readonly tuiIconArrowUpCircleLarge = tuiIconArrowUpCircleLarge;
}

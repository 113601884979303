import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import {
  tuiIconArrowUpRight,
  tuiIconDollarSignLarge,
  tuiIconEdit2Large,
  tuiIconPlusLarge,
} from "@taiga-ui/icons";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { CustomerService } from "../../../api/customer.service";
import Customer from "../../../models/Customer.class";
import { LocationService } from "../../../api/location.service";
import LocationC from "../../../models/Location.class";
import { LocationQueryParams } from "../../../interfaces/LocationQueryParams.interface";
import { ResponseWithRecordsBody } from "../../../interfaces/response-with-recors-body";
import { orderQueryParams, OrderService } from "../../../api/order.service";
import { TableSortEvent } from "../../../interfaces/table-sort-event";
import { Location } from "@angular/common";
import { ContactPersonService } from "../../../api/contactPerson.service";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { AddEditCustomerPriceComponent } from "../add-edit-customer-price/add-edit-customer-price.component";
import { PriceOverviewComponent } from "./price-overview/price-overview.component";
import { CustomerFileHelperService } from "./customerFileHelper.service";
import { ContactPersonQueryParams } from "../../../interfaces/contact-person-query-params";
import { AddEditLocationsComponent } from "../add-edit-locations/add-edit-locations.component";
import { AddEditCustomerComponent } from "../add-edit-customer/add-edit-customer.component";
import { TablePageComponent } from "../../../interfaces/table-page-component";
import { TableHelperService } from "../../../services/table-helper.service";
import { PermissionService } from "../../../services/permission.service";
import { Subscription } from "rxjs";
import { HelperService } from "../../../services/helper.service";
import { ProductPricePermissionHelperService } from "../../../services/permission-helper-services/product-price-permission-helper.service";
import {
  BaseMissingPermissionsDialogComponent,
  ContextData,
} from "../../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";
import { LocationPermissionsHelperService } from "../../../services/permission-helper-services/location-permissions-helper.service";

@Component({
  selector: "app-customer-details",
  templateUrl: "./customer-details.component.html",
  styleUrls: ["./customer-details.component.scss"],
})
export class CustomerDetailsComponent
  implements OnInit, OnDestroy, TablePageComponent
{
  ordersCount = signal<number>(0);
  onTime = signal<number>(0);
  salesVolumeInMonth = signal<number>(0);

  helperService = inject(HelperService);
  dialogService = inject(TuiDialogHelperService);
  fileUpdateNotifierService = inject(CustomerFileHelperService);
  fileUpdateNotifierSubscription: Subscription;
  customerId: string;
  customer: Customer;
  locations: LocationC[] = [];

  files: Blob[] = [];
  imageMap = new Map<number, string>(); // Map to hold image data

  tablePage: number = 0;
  tablePageSize: number = 10;

  protected readonly tuiIconEdit2Large = tuiIconEdit2Large;
  protected readonly tuiIconPlusLarge = tuiIconPlusLarge;
  protected readonly tuiIconArrowUpRight = tuiIconArrowUpRight;
  protected readonly tuiIconDollarSignLarge = tuiIconDollarSignLarge;

  @ViewChild(PriceOverviewComponent)
  private priceOverview: PriceOverviewComponent;

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private locationService: LocationService,
    public orderService: OrderService,
    public location: Location,
    private contactPersonService: ContactPersonService,
    public tableHelperService: TableHelperService,
    public permissionService: PermissionService,
    private productPricePermissionHelper: ProductPricePermissionHelperService,
    private locationPermissionHelper: LocationPermissionsHelperService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.orderService);
  }

  ngOnInit(): void {
    this.getIDFromUrl();
    this.getSelectedCustomer();
    this.orderService.setPaginationIndices({ start: 0, limit: 10 });
    this.populateTable();

    // get kpi data
    this.customerService
      .getCustomerKpiData(this.customerId)
      .subscribe((res) => {
        this.ordersCount.set(res.ordersCount ?? 0);
        this.onTime.set(res.onTime ?? 0);
        this.salesVolumeInMonth.set(res.salesVolumeInMonth ?? 0);
      });

    this.fileUpdateNotifierSubscription =
      this.fileUpdateNotifierService.fileUpdated$.subscribe(() => {
        this.getSelectedCustomer();
      });
  }

  ngOnDestroy(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.fileUpdateNotifierSubscription.unsubscribe();
  }

  populateTable() {
    const queryParams: orderQueryParams = {
      customerId: this.customerId,
    };
    this.orderService.getAllOrders(queryParams, true).subscribe();
  }

  customerHasBillingAddress(): boolean {
    return !!(
      this.customer.billStreet &&
      this.customer.billStreet !== "" &&
      this.customer.billStreetNumber &&
      this.customer.billStreetNumber !== "" &&
      this.customer.billCity &&
      this.customer.billCity !== "" &&
      this.customer.billPostalcode &&
      this.customer.billPostalcode !== "" &&
      this.customer.billCityDistrict &&
      this.customer.billCityDistrict !== "" &&
      this.customer.LKZ &&
      this.customer.LKZ !== "" &&
      this.customer.billCity &&
      this.customer.billCity !== ""
    );
  }

  getCustomerBillingAddressString(): string {
    const street = this.getStreetAndNumber();
    const postalAndCity = this.getPostalCodeAndCity();
    const district = this.getCityDistrict();
    const country = this.getCountry();

    return `${street}${postalAndCity}${district}${country}`;
  }

  editCustomer() {
    this.dialogService.openDialog(AddEditCustomerComponent, this.customerId);
  }

  addLocation() {
    if (this.locationPermissionHelper.hasAllCreateLocationPermissions) {
      this.dialogService.openDialog(
        AddEditLocationsComponent,
        { customerId: this.customer.id },
        () => {
          this.getSelectedCustomer();
        },
      );
    } else {
      this.showMissingCreatePermissionsDialog();
    }
  }

  showMissingCreatePermissionsDialog() {
    const permissions: ContextData = {
      mode: "add",
      elementName: "Lieferadressen",
      permissions: [
        {
          name: "Lieferadressen ansehen",
          hasPermission:
            this.permissionService.readLocationPermission().hasPermission,
        },
        {
          name: "Lieferadressen erstellen",
          hasPermission:
            this.permissionService.createLocationPermission().hasPermission,
        },
      ],
    };

    this.dialogService.openDialog(
      BaseMissingPermissionsDialogComponent,
      permissions,
    );
  }

  editLocation(locationId: string) {
    this.dialogService.openDialog(
      AddEditLocationsComponent,
      { locationId: locationId },
      () => {
        this.getSelectedCustomer();
      },
    );
  }

  getSelectedCustomer() {
    if (!this.customerId) return;

    this.customerService
      .getCustomerById(this.customerId)
      .subscribe((customer: Customer) => {
        this.customer = customer;

        if (
          this.customer.note?.startsWith("<p>") &&
          this.customer.note?.endsWith("</p>")
        ) {
          this.customer.note = this.customer.note.slice(3, -4);
        }

        this.getCustomerFiles();
        this.fetchImages(this.files);

        const queryParams: LocationQueryParams = {
          customerId: customer.id,
        };

        this.locationService
          .getAllLocations(queryParams)
          .subscribe((locations: ResponseWithRecordsBody) => {
            this.locations = locations.records;
          });

        const contactPersonQueryParams: ContactPersonQueryParams = {
          customerId: customer.id,
        };

        this.contactPersonService
          .getAllContactPersons(contactPersonQueryParams)
          .subscribe((contactPersons: ResponseWithRecordsBody) => {
            this.customer.contactPersons = contactPersons.records;
          });
      });
  }

  getCustomerFiles() {
    this.files = [];
    this.customer.files.forEach((file) => {
      this.customerService
        .getCustomerFileById(file.id)
        .subscribe((res: any) => {
          const newFile = res.file;
          const reader = new FileReader();
          reader.onloadend = (event) => {
            const newFileDataUri = event.target?.result;

            // Check for each existing file if their dataUri matches with the new file
            const isFileExisting = this.files.some((file) => {
              let readerForExistingFile = new FileReader();
              let existingFileDataUri;
              readerForExistingFile.onload = () => {
                existingFileDataUri = readerForExistingFile.result;
              };
              readerForExistingFile.readAsDataURL(file);

              return existingFileDataUri === newFileDataUri;
            });

            // If file is not existing, add it to the files list and imageMap
            if (!isFileExisting) {
              this.files.push(newFile);

              if (newFile.type.startsWith("image/")) {
                this.imageMap.set(
                  this.files.length - 1,
                  newFileDataUri as string,
                );
              }
            }
          };

          reader.readAsDataURL(newFile);
        });
    });
  }

  getIDFromUrl() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("id")) {
        this.customerId = paramMap.get("id")!;
      }
    });
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    if (tableSort.sortColumn === "timeFrame") {
      tableSort.sortColumn = "timeframeBegin";
    }

    this.tableHelperService.sortTable(tableSort);
  }

  openFile(blob: Blob) {
    // Create an object URL for the blob
    const fileURL = URL.createObjectURL(blob);
    // Opens the object URL in a new tab
    window.open(fileURL);
  }

  fetchImages(files: Blob[]) {
    files.forEach((file, index) => {
      // Check if the file type starts with image/
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Set the base64 string in the Map keyed by the file index
          const base64Url = reader.result as string;
          this.imageMap.set(index, base64Url);
        };
        reader.readAsDataURL(file);
      }
    });
  }

  createCustomerPrice() {
    if (this.productPricePermissionHelper.hasAllCreateProductPricePermissions) {
      const customer = new Customer(this.customer);
      this.dialogService.openDialog(
        AddEditCustomerPriceComponent,
        customer,
        () => {
          //   refresh data
          this.priceOverview.getPrices();
        },
      );
    } else {
      const permissions: ContextData = {
        mode: "add",
        elementName: "Kundenpreise",
        permissions: [
          {
            name: "Produkt ansehen",
            hasPermission:
              this.permissionService.readProductPermission().hasPermission,
          },
          {
            name: "Produktpreise ansehen",
            hasPermission:
              this.permissionService.readProductPricePermission().hasPermission,
          },
          {
            name: "Produktpreise erstellen",
            hasPermission:
              this.permissionService.createProductPricePermission()
                .hasPermission,
          },
        ],
      };

      this.dialogService.openDialog(
        BaseMissingPermissionsDialogComponent,
        permissions,
      );
    }
  }

  private getStreetAndNumber(): string {
    return `${this.customer.billStreet ?? ""} ${this.customer.billStreetNumber ?? ""},<br/>`;
  }

  // downloadFile(blob: Blob, fileNameWithoutExtension: string, mimeType: string) {
  //   const extension = this.fileMimeTypes[mimeType];
  //
  //   // Give a generic name if extension not found in the dictionary
  //   const fileName = extension
  //     ? `${fileNameWithoutExtension}.${extension}`
  //     : `${fileNameWithoutExtension}.bin`;
  //
  //   // Create blob
  //   const fileBlob = new Blob([blob], { type: mimeType });
  //
  //   // Create blob URL
  //   const fileURL = URL.createObjectURL(fileBlob);
  //
  //   // Create temp anchor element
  //   const link = document.createElement("a");
  //
  //   link.href = fileURL;
  //   link.setAttribute("download", fileName);
  //
  //   // Append link to the DOM
  //   document.body.appendChild(link);
  //
  //   // Simulate click
  //   link.click();
  //
  //   // Remove link from the DOM
  //   document.body.removeChild(link);
  // }

  private getPostalCodeAndCity(): string {
    if (
      !this.customer.billStreet &&
      !this.customer.billStreetNumber &&
      !this.customer.billPostalcode &&
      !this.customer.billCity
    ) {
      return "";
    }
    return `${this.customer.billPostalcode ?? ""} ${this.customer.billCity ?? ""}`;
  }

  private getCityDistrict(): string {
    if (!this.customer.billCityDistrict) {
      return "";
    }
    return `(${this.customer.billCityDistrict})`;
  }

  private getCountry(): string {
    if (!this.customer.LKZ) {
      return "";
    }
    return `, ${this.customer.LKZ}`;
  }
}

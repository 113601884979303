<div
  id="no-read-permission-container"
  [style.justify-content]="centerContent ? 'center' : 'start'"
>
  @if (!onlyInfoText) {
    <tui-island style="padding: 5rem; text-align: center; border: none">
      <img
        src="assets/svg/undraw-no-access.svg"
        height="300"
        alt="access-denied-image"
      />
      <h1 style="line-height: 1.1">
        {{ "role.missing-read-permission" | translate }}
      </h1>
      <p style="margin-bottom: 0">
        {{ "role.missing-read-permission-info" | translate }}
        {{ userRoleName() }}:
      </p>
      <br />
      <p style="margin-top: 0.25rem">
        <b>
          @for (permission of missingPermissions; track permission) {
            {{ PermissionTranslations[permission] | translate }}
            @if (
              missingPermissions.indexOf(permission) !==
              missingPermissions.length - 1
            ) {
              @if(differentPermissionTypeAsNextIndex(permission)){
                <span style="font-weight: lighter">
                  {{ "common.and" | translate }}
                </span>
              } @else {
                <span style="font-weight: lighter">
                  {{ "common.or" | translate }}
                </span>
              }
            }
          }
        </b>
      </p>
    </tui-island>
  } @else {
    <span style="text-align: left; color: black">
      <p style="margin-bottom: 0">
        {{ "role.missing-read-permission-summary" | translate }}
      </p>
      <p style="margin-top: 0.25rem">
        <b>
          @for (permission of missingPermissions; track permission) {
            {{ PermissionTranslations[permission] | translate }}
            @if (
              missingPermissions.indexOf(permission) !==
              missingPermissions.length - 1
            ) {
              <span style="font-weight: lighter">
                {{ "common.or" | translate }}
              </span>
            }
          }
        </b>
      </p>
    </span>
  }
</div>

<div class="input-wrapper">
  <tui-textarea
    [expandable]="isExpandable!"
    [ngStyle]="{ 'border-radius': bRadius }"
    class="input"
    formControlName="{{ fieldName }}"
  >
    <span [ngStyle]="{ color: hintColor }" class="hint">
      {{ hint | translate }}
    </span>
    <span *ngIf="required" class="tui-required"></span>
  </tui-textarea>
  <ng-container class="error-wrapper">
    <tui-error
      [error]="[] | tuiFieldError | async"
      formControlName="{{ fieldName }}"
    ></tui-error>
  </ng-container>
</div>

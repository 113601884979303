<div class="container">
  <div class="">
    <h1>{{ "settings.page-title" | translate }}</h1>
  </div>

  <!-- SETTINGS NAVIGATION -->
  @if (hasSettingsPermissions()) {
    <tui-tabs>
      @if (
        permissionService.readRolePermission().hasPermission ||
        permissionService.createRolePermission().hasPermission
      ) {
        <a [routerLink]="NavRoutes.ROLES" routerLinkActive tuiTab>
          {{ "settings.tabs.role" | translate }}
        </a>
      }

      @if (
        permissionService.readProductPermission().hasPermission ||
        permissionService.createProductPermission().hasPermission
      ) {
        <a [routerLink]="NavRoutes.PRODUCTS" routerLinkActive tuiTab>
          {{ "settings.tabs.products" | translate }}
        </a>
      }

      @if (
        permissionService.readWarehousePermission().hasPermission ||
        permissionService.createWarehousePermission().hasPermission
      ) {
        <a [routerLink]="NavRoutes.WAREHOUSE" routerLinkActive tuiTab>
          {{ "settings.tabs.warehouses" | translate }}
        </a>
      }
    </tui-tabs>
  }
  <div>
    @if (hasSettingsPermissions()) {
      <!-- nested route for tabs -->
      <router-outlet></router-outlet>
    }
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import { KpiService, OrderStatusToday } from "../../../../api/kpi.service";
import { TuiRingChartModule } from "@taiga-ui/addon-charts";
import { TuiMoneyModule } from "@taiga-ui/addon-commerce";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-order-today-donut-diagram",
  standalone: true,
  imports: [TuiRingChartModule, TuiMoneyModule, TranslateModule],
  templateUrl: "./order-today-donut-diagram.component.html",
  styleUrl: "./order-today-donut-diagram.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTodayDonutDiagramComponent implements OnInit {
  value = signal<number[]>([]);
  total = signal<number>(0);
  index = NaN;
  donutDataResponse = signal<OrderStatusToday>({} as OrderStatusToday);
  private kpiService = inject(KpiService);

  get sum(): number {
    return Number.isNaN(this.index) ? this.total() : this.value()[this.index];
  }

  get label(): string {
    return Number.isNaN(this.index)
      ? "order.orders-today"
      : "order." + Object.keys(this.donutDataResponse())[this.index];
  }

  ngOnInit(): void {
    this.getDonutData();
  }

  getDonutData() {
    this.kpiService.getDonutData().subscribe((data: OrderStatusToday) => {
      this.donutDataResponse.set(data);

      const values = Object.values(data);
      this.value.set([...this.value(), ...values]);

      const sum = this.value().reduce(
        (total, currentValue) => total + currentValue,
        0,
      );
      this.total.set(sum);
    });
  }
}

<!-- Displays address and a form for the duration -->
<div class="tpl-header-container">
  <!-- The title showing the address -->
  <h4 style="margin: 0; padding: 0; color: white">
    <!-- If-else condition to check whether the location or warehouse is available -->
    @if (tourPoint.location) {
      {{ tourPoint.location.address.street }}
      {{ tourPoint.location.address.houseNo }},
      <div>
        {{ tourPoint.location.address.postalCode }}
        {{ tourPoint.location.address.city }}
      </div>
    } @else if (tourPoint.warehouse) {
      {{ tourPoint.warehouse.address.street }}
      {{ tourPoint.warehouse.address.houseNo }},
      <div>
        {{ tourPoint.warehouse.address.postalCode }}
        {{ tourPoint.warehouse.address.city }}
      </div>
    }
  </h4>

  <!-- Form group for estimated time -->
  <form [formGroup]="estimatedTimeForm">
    <!--  Input number field for stop duration  -->
    <div class="duration">
      <app-input-number
        (keyup.enter)="changeEstimatedTimeDuration()"
        (onBlur)="changeEstimatedTimeDuration()"
        [readOnly]="
          tourPoint.signedByCustomer !== null ||
          tourPoint.signedByWorker !== null ||
          !isTourStateDraft
        "
        [required]="false"
        class="estimatedTime"
        fieldName="estimatedStopDuration"
        hint="Prozessdauer (Min)"
        hintColor="white"
        size="m"
        title="Prozessdauer (Min)"
      />
    </div>
  </form>
</div>

import { Component, Inject } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { UserService } from "../../../api/user.service";
import { phoneValidator } from "../../../other/validators/phone-validator";
import { User, ViewAccess } from "../../../models/User";

import { Role } from "../../../models/Role";
import { RoleService } from "../../../api/role.service";
import { AuthService } from "../../../api/auth.service";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { RadioItem } from "../../../common/radio-list/radio-list.component";
import { Mode } from "../../../other/enums/mode";
import { forkJoin, Observable } from "rxjs";
import {
  WarehouseService,
  WarehouseWorker,
} from "../../../api/warehouse.service";
import Warehouse from "../../../models/Warehouse.class";
import { BaseDialogComponent } from "../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { TuiContextWithImplicit, TuiStringHandler } from "@taiga-ui/cdk";
import { pushTypes } from "../../../other/enums/push-types";
import { PushService } from "../../../services/push.service";
import { WarehousePermissionHelperService } from "../../../services/permission-helper-services/warehouse-permission-helper.service";

@Component({
  selector: "app-add-edit-user",
  templateUrl: "./add-edit-user.component.html",
  styleUrls: ["./add-edit-user.component.scss"],
})
export class AddEditUserComponent extends BaseDialogComponent {
  user: User;
  form: FormGroup;
  toUpdateId: string = "";
  warehouses: Warehouse[];
  mode: string = Mode.ADD;
  // access url to check if the user wants to add or edit a customer
  roles: Role[];
  roleDropdownItems: DropDownItem[] = [];
  radioItems: RadioItem[] = [
    {
      name: "user-profile.radio-active",
      id: true,
    },
    {
      name: "user-profile.radio-inactive",
      id: false,
    },
  ];

  initialEditWarehouses: Warehouse[] | undefined = [];
  protected readonly close = close;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private roleService: RoleService,
    private authService: AuthService,
    private warehouseService: WarehouseService,
    public warehousePermissions: WarehousePermissionHelperService,
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<any>,
    protected override dialogService: TuiDialogHelperService,
    private push: PushService,
  ) {
    super(context, dialogService);
  }

  get isAddMode() {
    return this.mode === Mode.ADD;
  }

  get hasAcceptedInvite() {
    return this.user?.inviteAcceptedAt;
  }

  readonly stringify: TuiStringHandler<
    Warehouse | TuiContextWithImplicit<Warehouse>
  > = (item) => ("name" in item ? item.name : item.$implicit.name);

  async ngOnInit() {
    // access url to check if the user wants to add or edit a customer
    this.getMode();

    // init form
    this.form = this.fb.group({
      firstName: [""],
      lastName: [""],
      email: ["", [Validators.email, Validators.required]],
      phone: ["", [phoneValidator()]],
      roleId: ["", Validators.required],
      active: [""],
      isWarehouseWorker: [false],
      isDriver: [false],
      truckNumber: "",
      workerWarehouses: [[], this.workerWarehousesValidator()],
    });

    // if isWarehouseworker is false, reset workerWarehouses
    this.resetWorkerForm();

    this.form.get("isWarehouseWorker")?.valueChanges.subscribe(() => {
      this.form.get("workerWarehouses")?.updateValueAndValidity();
    });

    // get roles and set form when in edit mode
    this.roleService.getRoles().subscribe((roles: Role[]) => {
      this.roles = roles;
      this.roleDropdownItems = roles.map((role: Role) => {
        return {
          id: role.id,
          label: role.name,
        };
      });

      // load customer data into the form in edit mode
      if (this.isAddMode || !this.toUpdateId) return;
      this.initCaseEdit(this.toUpdateId);
    });
  }

  workerWarehousesValidator(): ValidatorFn {
    return (
      workerWarehousesControl: AbstractControl,
    ): { [key: string]: boolean } | null => {
      if (!this.form) return null; // Check if form is initialized, return if not
      const isWarehouseWorker = this.form.get("isWarehouseWorker")?.value;
      if (
        isWarehouseWorker &&
        (!workerWarehousesControl.value ||
          workerWarehousesControl.value.length === 0)
      ) {
        return { required: true };
      }
      return null;
    };
  }

  resetWorkerForm() {
    this.form
      .get("isWarehouseWorker")
      ?.valueChanges.subscribe((isWarehouseWorkerValue) => {
        if (!isWarehouseWorkerValue) {
          this.form.get("workerWarehouses")?.reset();
        }
      });
  }

  getMode() {
    if (this.context.data) {
      this.toUpdateId = this.context.data;
      this.mode = Mode.EDIT;
    } else {
      this.mode = Mode.ADD;
    }
  }

  // Updated method
  initCaseEdit(id: string) {
    this.userService.getUserById(id).subscribe((res: User) => {
      this.user = res;
      this.updateFormWithUserDetails(res);
    });
  }

  submit() {
    if (this.form.invalid) return;

    const user: User = new User({
      firstName: this.form.get("firstName")?.value,
      lastName: this.form.get("lastName")?.value,
      email: this.form.get("email")?.value,
      phone: this.form.get("phone")?.value,
      password: this.form.get("password")?.value,
      roleId: this.form.get("roleId")?.value,
      truckNumber: this.form.get("truckNumber")?.value,
      viewAccess: [],
    });

    if (this.form.controls["isDriver"].value) {
      user.viewAccess?.push(ViewAccess.DRIVER);
    }
    if (this.form.controls["isWarehouseWorker"].value) {
      user.viewAccess?.push(ViewAccess.WAREHOUSEWORKER);
    }

    if (this.isAddMode) {
      // add user
      this.addUser(user);
    } else if (!this.isAddMode && this.toUpdateId) {
      // update user
      this.updateUser(user, this.toUpdateId);
    }
  }

  addUser(user: User) {
    user.active = true;
    const workerWarehouses = this.form.controls["workerWarehouses"].value;

    let warehouses: Warehouse[] | string[] = [];

    if (workerWarehouses?.every((item: unknown) => typeof item === "string")) {
      // case where workerWarehouses is an array of strings (Ids)
      warehouses = workerWarehouses as string[];
    } else {
      // case where workerWarehouses is an array of Warehouse objects
      warehouses = workerWarehouses?.map(
        (warehouse: Warehouse) => warehouse.id,
      );
    }

    this.userService.createUser(user).subscribe((createdUser: User | null) => {
      if (!createdUser) return;

      if (warehouses && warehouses.length > 0) {
        const observables: Observable<any>[] = (warehouses as any[]).map(
          (warehouseId) => {
            return this.addWarehousesToUser(createdUser.id, warehouseId);
          },
        );

        forkJoin(observables).subscribe(() => {
          this.push.sendPush(
            pushTypes.SUCCESS,
            "",
            "Lager wurde zum Mitarbeiter zugeordnet",
          );
          this.closeDialog();
        });
      } else {
        this.closeDialog();
      }
    });
  }

  addWarehousesToUser(userId: string, warehouseId: string) {
    const data: WarehouseWorker = {
      userId,
      warehouseId,
    };

    return this.warehouseService.addWarehouseWorker(data);
  }

  updateUser(user: User, id: string) {
    user.active = this.form.controls["active"].value;
    // removing attributes if it no longer fits the viewAccess (condition inside methods)
    this.removeTruckNumberFromUser(user);

    this.userService.updateUser(user, id).subscribe((user) => {
      if (!user.id) return;

      if (!user?.viewAccess?.includes(ViewAccess.WAREHOUSEWORKER)) {
        this.removeWorkerWarehousesFromUser(user);
      } else {
        this.updateWorkerWarehouses(id);
      }
    });
  }

  removeTruckNumberFromUser(user: User) {
    if (!user.viewAccess?.includes(ViewAccess.DRIVER)) {
      user.truckNumber = "";
      this.form.controls["truckNumber"].setValue("");
    }
  }

  removeWorkerWarehousesFromUser(user: User) {
    if (!user.viewAccess?.includes(ViewAccess.WAREHOUSEWORKER)) {
      const observables = this.initialEditWarehouses?.map((warehouse) => {
        const param: WarehouseWorker = {
          userId: user.id,
          warehouseId: warehouse.id,
        };

        return this.warehouseService.removeWarehouseWorker(param);
      });

      if (observables && observables.length !== 0) {
        forkJoin(observables).subscribe(() => {
          this.closeDialog();
        });
      } else {
        this.closeDialog();
      }
    }
  }

  updateWorkerWarehouses(userId: string) {
    if (!userId) return;

    const currentWarehouses = this.form.controls["workerWarehouses"].value;
    const initialWarehouses = this.initialEditWarehouses;

    const warehousesToDelete = initialWarehouses?.filter(
      (warehouse) =>
        !currentWarehouses.some((curr: string) => curr === warehouse.id),
    );

    const warehousesToAdd = currentWarehouses?.filter(
      (warehouseId: string) =>
        !initialWarehouses?.some((init) => init.id === warehouseId),
    );

    const deleteObservables =
      warehousesToDelete?.map((warehouse) => {
        const httpData: WarehouseWorker = {
          userId: userId,
          warehouseId: warehouse.id,
        };
        return this.warehouseService.removeWarehouseWorker(httpData);
      }) || [];

    const addObservables = warehousesToAdd.map((warehouseId: string) => {
      const data: WarehouseWorker = {
        userId,
        warehouseId: warehouseId,
      };

      return this.warehouseService.addWarehouseWorker(data);
    });

    // Joining delete and add observables
    const allObservables = [...deleteObservables, ...addObservables];

    if (allObservables.length !== 0) {
      forkJoin(allObservables).subscribe(() => this.closeDialog());
    } else {
      this.closeDialog();
    }
  }

  sendInviteMailAgain(id: string) {
    this.authService.resendInviteMail(id).subscribe();
  }

  // Sets the edit values
  private updateFormWithUserDetails(user: User): void {
    this.initialEditWarehouses = user.warehouses?.map(
      (warehouse) => new Warehouse(warehouse),
    );

    const userAttributes: (keyof User)[] = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "roleId",
      "active",
      "truckNumber",
    ];

    userAttributes.forEach((key) => {
      this.form.controls[key]?.setValue(user[key]);
    });

    // Set role dropdown
    let roleInfo = {
      id: user.roleId,
      label: this.roles.find((role: Role) => role.id === user.roleId)?.name,
    };
    this.form.controls["roleId"].setValue(roleInfo);

    // Set checkboxes
    let isWarehouseWorker =
      user.viewAccess?.includes(ViewAccess.WAREHOUSEWORKER) ?? false;
    this.form.controls["isWarehouseWorker"].setValue(isWarehouseWorker);

    let isDriver = user.viewAccess?.includes(ViewAccess.DRIVER) ?? false;
    this.form.controls["isDriver"].setValue(isDriver);

    // Set list of warehouses
    this.form.controls["workerWarehouses"].setValue(
      user.warehouses?.map((warehouse) => new Warehouse(warehouse).id),
    );
  }
}

import { Component, Inject, inject, OnInit } from "@angular/core";
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";
import { PermissionService } from "../../services/permission.service";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";

export type PermissionType = {
  name: string;
  hasPermission: boolean;
};

export type ContextData = {
  elementName: string;
  permissions: PermissionType[];
  mode: Mode;
};

export type Mode = "add" | "update" | "delete";

@Component({
  selector: "app-base-missing-permissions-dialog",
  standalone: true,
  imports: [NgIf, NgForOf, NgSwitchCase, NgSwitch],
  templateUrl: "./base-missing-permissions-dialog.component.html",
  styleUrl: "./base-missing-permissions-dialog.component.scss",
})
export class BaseMissingPermissionsDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  permissionService = inject(PermissionService);
  mode: Mode = "add";
  permissions: PermissionType[] = [];
  elementName: string;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<ContextData, ContextData>,
    protected override dialogService: TuiDialogHelperService,
  ) {
    super(context, dialogService);
  }

  ngOnInit(): void {
    if (this.context.data) {
      this.mode = this.context.data.mode;
      this.permissions = this.context.data.permissions;
      this.elementName = this.context.data.elementName;
    }
  }
}

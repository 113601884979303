import { Component, OnInit } from "@angular/core";
import { ProductService } from "../../../api/product.service";

import { tuiIconPlus } from "@taiga-ui/icons";
import { ProductHelperService } from "./product-helper.service";
import { PushService } from "../../../services/push.service";
import { pushTypes } from "../../../other/enums/push-types";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { AddEditProductComponent } from "./add-edit-product/add-edit-product.component";
import { Permissions } from "../../../other/enums/permissions";
import { PermissionService } from "../../../services/permission.service";
import Product, { PRODUCT_QUALITY } from "../../../models/Product.class";
import { ProductPriceService } from "../../../api/productPrice.service";
import ProductPrice from "../../../models/ProductPrice.class";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
  //  data to delete a product
  toDeleteId: null | string;
  showDeleteDialog: boolean = false;
  products: Product[];
  productPrices: Map<string, ProductPrice[]> = new Map<
    string,
    ProductPrice[]
  >();
  protected readonly icon = tuiIconPlus;
  protected readonly Permissions = Permissions;

  constructor(
    public productService: ProductService,
    public productHelper: ProductHelperService,
    private pushService: PushService,
    private dialogService: TuiDialogHelperService,
    public permissionService: PermissionService,
    private productPriceService: ProductPriceService,
  ) {}

  onEdit(event: string) {
    this.dialogService.openDialog(AddEditProductComponent, event, () => {
      this.getProducts();
    });
  }

  onDelete(event: string) {
    this.toDeleteId = event!;
    this.showDeleteDialog = true;
  }

  deleteProduct() {
    if (!this.toDeleteId) return;
    this.productService.deleteProductById(this.toDeleteId).subscribe(() => {
      this.toDeleteId = null;
      this.showDeleteDialog = false;
      this.pushService.sendPush(
        pushTypes.SUCCESS,
        "",
        "Produkt wurde gelöscht",
      );
      this.getProducts();
    });
  }

  getProducts() {
    this.productService.getAllProducts().subscribe((res) => {
      this.products = res.records;

      this.products.forEach((product) => {
        this.setSortedQualities(product);
        this.fetchProductPrices(product);
        this.productHelper.fetchProductImages(product);
      });
    });
  }

  fetchProductPrices(product: Product) {
    if (!this.permissionService.readProductPricePermission().hasPermission)
      return;

    this.productPriceService
      .getProductPriceByProductId(product.id)
      .subscribe((res) => {
        // Save prices into the Map
        this.productPrices.set(product.id, res.records);
      });
  }

  setSortedQualities(product: Product) {
    product.possibleQualities = this.sortByEnumOrder(
      product.possibleQualities,
      PRODUCT_QUALITY,
    );
  }

  sortByEnumOrder(
    array: PRODUCT_QUALITY[],
    enumObj: typeof PRODUCT_QUALITY,
  ): PRODUCT_QUALITY[] {
    const enumValues = Object.values(enumObj);
    return array.sort((a, b) => enumValues.indexOf(a) - enumValues.indexOf(b));
  }

  createProduct() {
    this.dialogService.openDialog(AddEditProductComponent, null, () => {
      this.getProducts();
    });
  }

  ngOnInit(): void {
    this.getProducts();
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { TuiSizeL, TuiSizeM, TuiSizeS } from "@taiga-ui/core";

@Component({
  selector: "app-input-number",
  templateUrl: "./input-number.component.html",
  styleUrls: ["./input-number.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InputNumberComponent {
  @Input() fieldName: string; // formControlName
  @Input() hint: string;
  @Input() hintColor: string = "black";
  @Input() max: number = 999999999999;
  @Input() min: number = 0;
  @Input() size: TuiSizeL | TuiSizeS | TuiSizeM = "l";
  @Input() required?: boolean = true;
  @Input() readOnly: boolean = false;

  @Output() onBlur = new EventEmitter();

  blurEvent() {
    this.onBlur.emit();
  }
}

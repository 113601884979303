import { Component, OnInit } from "@angular/core";
import TourPointLoad from "../../models/TourPointLoad.class";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import { DeliveryReceiptDetailsDialogComponent } from "../delivery-receipt-page/delivery-receipt-details-dialog/delivery-receipt-details-dialog.component";
import { AddEditUnannouncedDeliveryComponent } from "./add-edit-unannounced-delivery/add-edit-unannounced-delivery.component";
import { TablePageComponent } from "../../interfaces/table-page-component";
import { TableHelperService } from "../../services/table-helper.service";
import { PushService } from "../../services/push.service";
import { pushTypes } from "../../other/enums/push-types";
import { Permissions } from "../../other/enums/permissions";
import { TourPointService } from "../../api/tourPoint.service";
import { DeliveryReceiptService } from "../../api/deliveryReceipt.service";
import { PermissionService } from "../../services/permission.service";
import { TuiDay } from "@taiga-ui/cdk";
import TourPoint from "../../models/TourPoint.class";

@Component({
  selector: "app-unannounced-delivery-page",
  templateUrl: "./unannounced-delivery-page.component.html",
  styleUrl: "./unannounced-delivery-page.component.scss",
})
export class UnannouncedDeliveryPageComponent
  implements OnInit, TablePageComponent
{
  tablePage: number = 0;
  tablePageSize: number = 10;
  tourPointTableColumnNames: string[] = [
    "Erstellt am",
    "Lager",
    "Lieferant",
    "Kennzeichen",
    "Produkt",
    "Angelegt von Mitarbeiter",
  ];
  tourPointTableShownColumns: string[] = [
    "createdAt",
    "warehouse.name",
    "supplier",
    "truckNumber",
    "product",
    "vcAuthorName",
    "hasDR",
  ];

  // TODO: check warehouse sorting >> bugged right no thats why disabled for the moment
  noSortColumns: string[] = [
    "supplier",
    "truckNumber",
    "product",
    "vcAuthorName",
  ];

  tourPointLoads: TourPointLoad[] = [];
  tourPointTotal: number = 0;
  selectedCreatedAtDate: string | undefined;
  tourPoints: TourPoint[];
  protected readonly Permissions = Permissions;
  protected readonly Math = Math;
  private dateChangeTriggered: boolean = false;

  constructor(
    public tourPointService: TourPointService,
    private dialogService: TuiDialogHelperService,
    private pushService: PushService,
    public tableHelperService: TableHelperService,
    private deliveryReceiptService: DeliveryReceiptService,
    public permissionService: PermissionService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.tourPointService);
  }

  ngOnInit(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.tourPointService.setSorting({
      sortColumn: "createdAt",
      sortDirection: -1,
    });
    this.populateTable();
    this.dateChangeTriggered = true;
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  search(searchInput: string) {
    this.tableHelperService.resetTablePagination();
    this.tourPointService.search = searchInput;
    this.populateTable();
  }

  public onRowClick(id: string): void {
    this.deliveryReceiptService
      .getAllDeliveryReceipts({ tourPointId: id })
      .subscribe((res) => {
        if (res.records.length === 0) {
          this.pushService.sendPush(
            pushTypes.ERROR,
            "",
            "Für diese Lieferung ist kein Lieferschein vorhanden.",
          );
          return;
        }

        this.dialogService.openDialog(
          DeliveryReceiptDetailsDialogComponent,
          res.records[0].id,
        );
      });
  }

  public createNewTourPointLoad() {
    this.dialogService.openDialog(
      AddEditUnannouncedDeliveryComponent,
      null,
      () => {
        this.populateTable();
      },
    );
  }

  populateTable() {
    const queryParams: {
      onlyUnannounced: boolean;
      populateTourPointLoad: boolean;
      populateUnannouncedInfo: boolean;
      createdAt?: string;
    } = {
      onlyUnannounced: true,
      populateTourPointLoad: true,
      populateUnannouncedInfo: true,
    };

    if (this.selectedCreatedAtDate) {
      queryParams.createdAt = this.selectedCreatedAtDate;
    }

    this.tourPointService.getAllTourPoints(queryParams).subscribe((tpRes) => {
      this.tourPoints = tpRes.records;
      this.tourPointTotal = tpRes.total;
      this.getDeliverReceipts();
    });
  }

  getDeliverReceipts() {
    this.deliveryReceiptService.getAllDeliveryReceipts().subscribe((drRes) => {
      this.tourPoints.forEach((tp) => {
        // Find the delivery receipt with the tourPointId same as the tp.id
        let matchingReceipt = drRes.records.find(
          (dr) => dr.tourPointId === tp.id,
        );

        if (matchingReceipt) {
          // Add the delivery receipt to tp
          tp.hasDR = true;
        }
      });
    });
  }

  extractDate(value: TuiDay | null) {
    return value?.toUtcNativeDate()?.toISOString();
  }

  changeDateEvent(createdAtAsIsoDate: string | undefined) {
    if (!this.dateChangeTriggered) {
      this.tableHelperService.resetTablePagination();
      this.selectedCreatedAtDate = createdAtAsIsoDate;
      this.populateTable();
    } else {
      // reset actionTriggered back to false for normal operations
      this.dateChangeTriggered = false;
    }
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-transparent-btn",
  templateUrl: "./transparent-btn.component.html",
  styleUrls: ["./transparent-btn.component.scss"],
})
export class TransparentBtnComponent {
  @Input() iconValue = "";
  @Input({ required: true }) i18StringText = "";

  @Output() btnClickEvent = new EventEmitter();

  btnCLick($event: MouseEvent) {
    this.btnClickEvent.emit($event);
  }
}

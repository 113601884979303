import { BaseModel } from "./BaseModel";
import TourPoint from "./TourPoint.class";
import { FileType } from "../other/enums/file-type";

interface Signature extends BaseModel<Signature> {
  name?: string;
  userId?: string;
  contactPersonId?: string;
}

interface Pdf extends BaseModel<Pdf> {
  fileName: string;
  dbFileId: string;
}

interface File extends BaseModel<File> {
  fileName: string;
  mimetype: string;
  fileType: FileType;
}

export default class DeliveryReceipt extends BaseModel<DeliveryReceipt> {
  tenantId: string;
  tourPoint: Partial<TourPoint>;
  tourPointId: string;
  deliveryReceiptNumber: string;
  note: string;
  signatures: Signature[];
  files: File[];
  pdfs: Pdf[];

  constructor(params: Partial<DeliveryReceipt>) {
    super(params);
    Object.assign(this, params);
  }
}

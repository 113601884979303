<div id="table-component">
  <tui-elastic-container class="visible">
    <ng-container *ngIf="tableData && tableData.length == 0; else showTable">
      <span style="color: black">{{
        "table-header.no-entries" | translate
      }}</span>
    </ng-container>
    <ng-template #showTable>
      <table
        (directionChange)="onDirectionChange($event)"
        [columns]="columns"
        class="tui-table"
        tuiTable
      >
        <thead>
          <tr tuiThGroup>
            <ng-container
              *ngFor="let columnName of tableColumnNames; let i = index"
            >
              <ng-container *ngIf="columns[i] == 'phone'">
                <th *tuiHead="columns[i]" [sorter]="null" tuiTh>
                  {{ columnName }}
                </th>
              </ng-container>

              <ng-container *ngIf="shouldNotSort(columns[i]); else sort">
                <th *tuiHead="columns[i]" [sorter]="null" tuiTh>
                  {{ columnName }}
                </th>
              </ng-container>
              <ng-template #sort>
                <th
                  (click)="onSortChange(columns[i])"
                  *tuiHead="columns[i]"
                  tuiTh
                >
                  {{ columnName }}
                </th>
              </ng-template>
            </ng-container>
          </tr>
        </thead>
        <tbody *tuiLet="tableData" [data]="tableData" tuiTbody>
          <tr
            (click)="rowClickEvent.emit(item.id)"
            *ngFor="let item of tableData"
            [class.hoverable]="isClickable()"
            [style.cursor]="cursor"
            tuiTr
          >
            <ng-container *ngFor="let column of columns">
              <td *tuiCell="column" tuiTd>
                <!-- case: has a matched cellTemplatesMap name -->
                <ng-container *ngIf="cellTemplatesMap[column] as template">
                  <ng-container
                    *ngTemplateOutlet="
                      template;
                      context: {
                        $implicit: extractNestedProperty(item, column),
                        object: item
                      }
                    "
                  >
                  </ng-container>
                </ng-container>

                @if (!cellTemplatesMap[column]) {
                  <!-- if the input is a iso string, format it -->
                  <ng-container
                    *ngIf="
                      isIsoDateString(extractNestedProperty(item, column));
                      else notDate
                    "
                  >
                    <!-- default: can handle nested, like address.street in column array -->
                    {{
                      extractNestedProperty(item, column) | date: "dd.MM.yyyy"
                    }}
                  </ng-container>

                  <!-- default case: if cellTemplatesMap[column] and isIsoDateString are falsy. Display the value in raw -->
                  <ng-template #notDate>
                    <!-- can handle nested, like address.street in column array -->
                    {{
                      (extractNestedProperty(item, column) | translate) || "-"
                    }}
                  </ng-template>
                }
              </td>
            </ng-container>

            <!-- special case createdAt  -->
            <td *tuiCell="'createdAt'" tuiTd>
              {{ item["createdAt"] | date: "dd.MM.yyyy / HH:mm" : "CET" }}
            </td>

            <!-- special case updatedAt  -->
            <td *tuiCell="'updatedAt'" tuiTd>
              {{ item["updatedAt"] | date: "dd.MM.yyyy / HH:mm" : "CET" }}
            </td>

            <!-- special case full name -->
            <td *tuiCell="'fullName'" tuiTd>
              <ng-container *ngIf="item.driver != null; else driverOfTour">
                {{ extractNestedProperty(item, "driver.firstName") }}
                {{ extractNestedProperty(item, "driver.lastName") }}
              </ng-container>
              <ng-template #driverOfTour>
                {{ extractNestedProperty(item, "tour.driver.firstName") }}
                {{ extractNestedProperty(item, "tour.driver.lastName") }}
              </ng-template>
            </td>

            <td *tuiCell="'estimatedArrival'" tuiTd>
              {{ item["estimatedArrival"] | date: "dd.MM.yyyy / HH:mm" }}
            </td>

            <td *tuiCell="'orderState'" tuiTd>
              <tui-badge
                [status]="getBadgeStatus(item['orderState'])"
                [value]="
                  orderStateTranslations.get(item['orderState']) ??
                    ORDER_STATE.UNPLANNED | translate
                "
                size="m"
                style="color: black"
              ></tui-badge>
            </td>

            <td *tuiCell="'tourState'" tuiTd>
              <tui-badge
                [status]="getBadgeStatus(item['tourState'])"
                [value]="
                  tourStateTranslations.get(item['tourState']) ??
                    TOUR_STATE.DRAFT | translate
                "
                size="m"
                style="color: black"
              ></tui-badge>
            </td>

            <!-- Customize specific Columns -->
            <td *tuiCell="'active'" tuiTd>
              <ng-container *ngIf="item.active == true; else inActive">
                <tui-badge
                  [value]="'table.active' | translate"
                  status="success"
                ></tui-badge>
              </ng-container>
              <ng-template #inActive>
                <tui-badge
                  [value]="'table.inactive' | translate"
                  status="error"
                ></tui-badge>
              </ng-template>
            </td>

            <!-- special case role: badge for driver + warehouse worker -->
            <td *tuiCell="'role'" tuiTd>
              <!-- both -->
              @if (
                item.viewAccess.includes(ViewAccess.WAREHOUSEWORKER) &&
                item.viewAccess.includes(ViewAccess.DRIVER)
              ) {
                <tui-badge
                  [value]="'role.warehouse-worker' | translate"
                  status="info"
                  class="worker-badge"
                >
                  <tui-svg src="tuiIconPackage"></tui-svg>
                </tui-badge>

                <tui-badge
                  [value]="'role.driver' | translate"
                  status="neutral"
                  style="margin-left: 0.25rem"
                  class="driver-badge"
                >
                  <tui-svg src="tuiIconTruck"></tui-svg>
                </tui-badge>
                <!-- Driver -->
              } @else if (item.viewAccess.includes(ViewAccess.DRIVER)) {
                <tui-badge
                  [value]="'role.driver' | translate"
                  status="neutral"
                  class="driver-badge"
                >
                  <tui-svg src="tuiIconTruck"></tui-svg>
                </tui-badge>
                <!-- Warehouse worker -->
              } @else if (
                item.viewAccess.includes(ViewAccess.WAREHOUSEWORKER)
              ) {
                <tui-badge
                  [value]="'role.warehouse-worker' | translate"
                  status="info"
                  class="worker-badge"
                >
                  <tui-svg src="tuiIconPackage"></tui-svg>
                </tui-badge>
              }
            </td>

            <!-- invite not accepted -->
            <td *tuiCell="'invite'" tuiTd>
              @if (!item.inviteAcceptedAt) {
                <tui-svg
                  src="tuiIconAlertTriangle"
                  style="color: var(--tui-warn-bg)"
                  [tuiHint]="tooltip"
                  [tuiHintDirection]="'top'"
                  [tuiHintAppearance]="'error'"
                ></tui-svg>

                <ng-template #tooltip>
                  <div>{{ "user.invite-not-accepted" | translate }}!</div>
                </ng-template>
              }
            </td>

            <!-- special case role: badge for driver + warehouse worker -->
            <td *tuiCell="'role'" tuiTd>
              <!-- both -->
              @if (
                item.viewAccess.includes(ViewAccess.WAREHOUSEWORKER) &&
                item.viewAccess.includes(ViewAccess.DRIVER)
              ) {
                <tui-badge
                  [value]="'role.warehouse-worker' | translate"
                  status="info"
                  class="worker-badge"
                >
                  <tui-svg src="tuiIconPackage"></tui-svg>
                </tui-badge>

                <tui-badge
                  [value]="'role.driver' | translate"
                  status="neutral"
                  style="margin-left: 0.25rem"
                  class="driver-badge"
                >
                  <tui-svg src="tuiIconTruck"></tui-svg>
                </tui-badge>
                <!-- Driver -->
              } @else if (item.viewAccess.includes(ViewAccess.DRIVER)) {
                <tui-badge
                  [value]="'role.driver' | translate"
                  status="neutral"
                  class="driver-badge"
                >
                  <tui-svg src="tuiIconTruck"></tui-svg>
                </tui-badge>
                <!-- Warehouse worker -->
              } @else if (
                item.viewAccess.includes(ViewAccess.WAREHOUSEWORKER)
              ) {
                <tui-badge
                  [value]="'role.warehouse-worker' | translate"
                  status="info"
                  class="worker-badge"
                >
                  <tui-svg src="tuiIconPackage"></tui-svg>
                </tui-badge>
              }
            </td>

            <!-- invite not accepted -->

            <td *tuiCell="'invite'" tuiTd>
              @if (!item.inviteAcceptedAt) {
                <tui-svg
                  src="tuiIconAlertTriangle"
                  style="color: var(--tui-warn-bg)"
                  [tuiHint]="tooltip"
                  [tuiHintDirection]="'top'"
                  [tuiHintAppearance]="'error'"
                ></tui-svg>

                <ng-template #tooltip>
                  <div>{{ "user.invite-not-accepted" | translate }}!</div>
                </ng-template>
              }
            </td>

            <!-- show Location or Warehouse name  -->
            <td *tuiCell="'tourPointLocationOrWarehouse'" tuiTd>
              {{
                item.tourPoint?.warehouse?.name ??
                  item.tourPoint?.location?.name ??
                  "-"
              }}
            </td>

            <!-- case inactive customer -->
            <ng-container *ngIf="item.inactiveSince">
              <td *tuiCell="'inactive'" class="delete" tuiTd>
                <tui-tooltip
                  [content]="'table.inactive_customer' | translate"
                  direction="left"
                  style="padding-left: 1rem"
                ></tui-tooltip>
              </td>
            </ng-container>

            <!-- edit icon + edit event -->
            <ng-container
              *ngIf="itemsEditable && item.orderState !== ORDER_STATE.FINISHED"
            >
              <td *tuiCell="'edit'" class="delete" tuiTd>
                <button
                  (click)="edit(item.id, $event)"
                  [icon]="tuiIconEdit2Large"
                  appearance="secondary"
                  size="m"
                  tuiIconButton
                ></button>
              </td>
            </ng-container>

            <!-- delete icon + delete event -->
            <ng-container
              *ngIf="itemsDeletable && item.orderState !== ORDER_STATE.FINISHED"
            >
              <td *tuiCell="'delete'" class="delete" tuiTd>
                <button
                  (click)="delete(item.id, $event)"
                  *ngIf="!item['tenantAdmin'] && !item['superAdmin']"
                  [icon]="tuiIconTrash2Large"
                  appearance="secondary-destructive"
                  size="m"
                  tuiIconButton
                ></button>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </tui-elastic-container>

  <tui-table-pagination
    (paginationChange)="this.paginationEvent.emit($event)"
    *ngIf="tableData && tableData.length > 0 && showPagination"
    [page]="currentPage!"
    [size]="pageSize"
    [total]="totalElements"
    [items]="sizeOptions"
  >
  </tui-table-pagination>
</div>

<div class="delivery-card">
  <div class="delivery-title">
    {{ "delivery-receipt.receipt" | translate }} -
    {{ selectedReceipt().deliveryReceiptNumber }}
  </div>

  <!-- Receipt Details   -->
  <div class="details-grid">
    <!-- location -->
    <div class="receipt-row">
      <div>
        <span class="delivery-headline"
          >{{ "delivery-receipt.location-warehouse" | translate }}:</span
        >
        <div class="delivery-detail-text">
          {{ tourPoint().location?.name ?? tourPoint().warehouse?.name ?? "-" }}
        </div>
      </div>
    </div>

    <!-- createdAt -->
    <div class="receipt-row">
      <div>
        <span class="delivery-headline"
          >{{ "delivery-receipt.delivered-date" | translate }}:</span
        >
        <div class="delivery-detail-text">
          {{ selectedReceipt().createdAt | date: "dd.MM.yyyy" }}
        </div>
      </div>
    </div>

    <!-- driver name -->
    <div *ngIf="tourPoint().tour?.driver" class="receipt-row">
      <div>
        <span class="delivery-headline">{{ "driver.title" | translate }}:</span>
        <div class="delivery-detail-text">
          {{ tourPoint().tour?.driver?.firstName ?? "-" }}
          {{ tourPoint().tour?.driver?.lastName }}
        </div>
      </div>
    </div>

    <!-- truck number -->
    <div
      *ngIf="tourPoint().tour || tourPoint().unannouncedInfos"
      class="receipt-row"
    >
      <div>
        <span class="delivery-headline"
          >{{ "tour.truckNumber" | translate }}:</span
        >
        <div class="delivery-detail-text">{{ truckNumber }}</div>
      </div>
    </div>

    <!--   tpl load + quality   -->
    <div *ngIf="tourPointLoadsUnloaded().length > 0" class="receipt-row">
      <div>
        <span class="delivery-headline"
          >{{ "delivery-receipt.tourpoint-loads-unloaded" | translate }}:</span
        >
        <ul>
          <li
            *ngFor="let tourPointLoadUnloaded of tourPointLoadsUnloaded()"
            class="delivery-detail-text"
          >
            {{ Math.abs(tourPointLoadUnloaded.amount) }}
            {{ tourPointLoadUnloaded.product?.name }}
            <span *ngIf="tourPointLoadUnloaded.quality">{{
              tourPointLoadUnloaded.quality | translate
            }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="tourPointLoadsLoaded().length > 0" class="receipt-row">
      <div>
        <span class="delivery-headline"
          >{{ "delivery-receipt.tourpoint-loads-loaded" | translate }}:</span
        >
        <ul>
          <li
            *ngFor="let tourPointLoadLoaded of tourPointLoadsLoaded()"
            class="delivery-detail-text"
          >
            {{ Math.abs(tourPointLoadLoaded.amount) }}
            {{ tourPointLoadLoaded.product?.name }}
            <span *ngIf="tourPointLoadLoaded.quality">{{
              tourPointLoadLoaded.quality | translate
            }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Signatures   -->
  <ng-container *ngIf="hasSignatures">
    <div class="details-grid">
      <div *ngFor="let signature of signatures" @long-fade class="receipt-row">
        <span class="delivery-headline"
          >{{ "delivery-receipt.signed-by" | translate }}
          {{ signature.name }}:</span
        >
        <div class="tui-space_bottom-2 tui-space_top-2">
          <img
            [src]="signature.signature"
            alt="signature"
            class="signature-img"
          />
        </div>
      </div>
    </div>
  </ng-container>

  <div class="details-grid" style="margin-bottom: 1rem; max-width: 50rem">
    <!-- Files - Images   -->
    @if (hasImageFiles) {
      <div class="receipt-row" [class.full-grid-width]="!hasDocumentFiles">
        <span class="delivery-headline"
          >{{ "delivery-receipt.file-images" | translate }}:</span
        >
        <!--   hasFiles   -->
        <div
          class="image-row"
          [style.max-width]="hasDocumentFiles ? '25rem' : '100%'"
        >
          <img
            (click)="openImageDialogWithFile(i)"
            *ngFor="let file of fileImages; let i = index"
            @long-fade
            [src]="file"
            alt="file"
            class="image-container"
            height="80"
          />
        </div>
      </div>
    }

    <!-- Files - Documents   -->
    @if (hasDocumentFiles) {
      <div class="receipt-row" [class.full-grid-width]="!hasImageFiles">
        <span class="delivery-headline"
          >{{ "delivery-receipt.file-documents" | translate }}:</span
        >
        <div
          class="image-row"
          [style.max-width]="hasImageFiles ? '25rem' : '100%'"
        >
          @for (file of fileDocuments; track file; let i = $index) {
            <img
              (click)="openDocumentDialogWithFile(i)"
              @long-fade
              [src]="file"
              alt="file"
              class="image-container"
              height="80"
            />
          }
        </div>
      </div>
    }
  </div>

  <!-- files which are no images -->
  @if (fileDocumentsNoImages.length > 0) {
    <span class="delivery-headline pb_05">
      {{ "role.permission-file" | translate }}:
    </span>
  }

  <div class="no-images-file-grid">
    @for (document of fileDocumentsNoImages; track document) {
      <button
        size="s"
        tuiButton
        appearance="outline"
        (click)="openFile(document.blob)"
        style="cursor: pointer; display: flex; align-items: center; padding: 0"
      >
        <span class="button-text" [title]="document.fileName">{{
          document.fileName ?? document.blob.type ?? "Unbekannt"
        }}</span>

        <tui-svg [src]="tuiIconArrowUpRight"></tui-svg>
      </button>
    }
  </div>

  <!-- Note   -->
  @if (hasNotes) {
    <div class="receipt-row">
      <span class="delivery-headline"
        >{{ "delivery-receipt.notes" | translate }}:</span
      >
      <div class="delivery-detail-text">{{ selectedReceipt().note }}</div>
    </div>
  }

  <!-- pdfs   -->
  @if (hasPdfs) {
    <div class="receipt-row">
      <span class="delivery-headline"
        >{{ "delivery-receipt.pdf" | translate }}:</span
      >
      <div class="pdf-grid">
        <div
          (click)="getPdfById(this.selectedReceipt().id, pdf.id)"
          *ngFor="let pdf of this.selectedReceipt().pdfs"
          class="file-container"
        >
          <div class="circle">
            <tui-svg src="tuiIconFileTextLarge"></tui-svg>
          </div>
          {{ pdf.fileName }}
        </div>
      </div>
    </div>
  }
</div>

import { Component, inject, Input, OnInit } from "@angular/core";
import TourPoint from "../../../../models/TourPoint.class";
import { MapTpTplService } from "../../map-helper-services/map-tp-tpl.service";
import { TuiDialogHelperService } from "../../../../services/tui-dialog-helper.service";
import { TpTplDeleteDialogComponent } from "../../tp-tpl-delete-dialog/tp-tpl-delete-dialog.component";
import { tuiIconCheckCircleLarge, tuiIconTrash2Large } from "@taiga-ui/icons";
import { TOUR_STATE } from "../../../../models/Tour.class";
import { MapMarkersService } from "../../map-helper-services/map-markers.service";
import { MapPermissionHelperService } from "../../../../services/permission-helper-services/map-permission-helper.service";
import { DateConverterService } from "../../../../services/date-converter.service";

@Component({
  selector: "app-one-tour-point",
  templateUrl: "./one-tour-point.component.html",
  styleUrl: "./one-tour-point.component.scss",
})
export class OneTourPointComponent implements OnInit {
  @Input({ required: true }) tourPoint: TourPoint;
  @Input({ required: false }) title: string | number = "Title";
  mapPermissionHelper = inject(MapPermissionHelperService);
  protected readonly tuiIconTrash2Large = tuiIconTrash2Large;
  protected readonly tuiIconCheckCircleLarge = tuiIconCheckCircleLarge;

  constructor(
    private mapTPService: MapTpTplService,
    private dialogService: TuiDialogHelperService,
    private markerService: MapMarkersService,
    private dateHelperService: DateConverterService,
  ) {}

  get isLocation(): boolean {
    return !!this.tourPoint.locationId;
  }

  get isTourStatusDraft() {
    return this.mapTPService.tour.tourState === TOUR_STATE.DRAFT;
  }

  setOrder() {
    if (this.tourPoint.tourPointLoads.length > 0) {
      let orderId = this.tourPoint.tourPointLoads[0].productOrder?.orderId;

      this.tourPoint.location?.orders?.forEach((order) => {
        if (order.id === orderId) {
          this.tourPoint.order = order;
        }
      });
    }
  }

  isBetweenTime() {
    if (!this.tourPoint.order) {
      this.setOrder();
      if (!this.tourPoint.order) {
        return;
      }
    }

    return this.dateHelperService.isBetweenTimes(
      this.tourPoint.estimatedArrival as string,
      this.tourPoint.order!.timeframeBegin,
      this.tourPoint.order!.timeframeEnd,
    );
  }

  ngOnInit() {
    this.isBetweenTime();
  }

  getAddressText() {
    let fullText = this.isLocation
      ? this.tourPoint.customer?.MATCHC
      : this.tourPoint?.warehouse?.name;

    if (!fullText)
      fullText = this.tourPoint.location?.name ?? "leer / gelöscht";

    const shortenedText =
      fullText.length > 18 ? fullText.slice(0, 18) + "..." : fullText;

    return { fullText, shortenedText };
  }

  deleteTourPoint(tourPoint: TourPoint) {
    this.dialogService.openDialog(
      TpTplDeleteDialogComponent,
      new TourPoint(tourPoint),
      () => {
        this.mapTPService.getTourPointsWithCallback(() => {
          this.markerService.getLocationsAndCreateMarkers();
          this.markerService.getWarehousesAndCreateMarkers();
        });
      },
    );
  }

  isNotSigned(): boolean {
    return !this.tourPoint.signedByWorker || !this.tourPoint.signedByCustomer;
  }
}

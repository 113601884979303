import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-half-transparent-card",
  templateUrl: "./half-transparent-card.component.html",
  styleUrls: ["./half-transparent-card.component.scss"],
})
export class HalfTransparentCardComponent {
  @Input() title: string = "";
  @Input() titlePadding: string = "1rem 0 2rem 0";
  @Input() showIcon: boolean = true;
  @Input() showEllipsis = { ellipse1: false, ellipse2: false };
  @Input() editIcon: boolean = false;
  @Input() backgroundColor: string = "";
  @Input() fontSize: string = "1.813rem";
  @Input() topPadding: string = "2.75rem !important";
  @Input() fontWeight: string = "700";
  @Input() maxWidth: string | undefined = undefined;

  @Input() hasLineSpacer: boolean = false;

  @Output() editIconClick: EventEmitter<any> = new EventEmitter<any>();

  get showBackground(): boolean {
    return this.backgroundColor !== "";
  }

  get background(): string {
    return this.backgroundColor + "!important";
  }

  get hasTitle(): boolean {
    return this.title !== "";
  }
}

import { inject, Injectable } from "@angular/core";
import { orderQueryParams, OrderService } from "../../api/order.service";
import { TableHelperService } from "../../services/table-helper.service";
import Order, { ORDER_STATE } from "../../models/Order.class";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class OrderTableFilterHelperService {
  tableHelperService = inject(TableHelperService);
  orderService = inject(OrderService);

  orders: Order[];
  todayIso: string = DateTime.now()
    .setZone("Europe/Berlin")
    .startOf("day")
    .toISO()!;

  defaultPopulateOrder(params?: Partial<orderQueryParams>) {
    this.orderService.getAllOrders(params, true).subscribe((orders) => {
      this.orders = orders.records;
    });
  }

  getTodayCreatedOrders() {
    const queryParams: Partial<orderQueryParams> = {
      createdAt: this.todayIso,
      withCancelled: true,
      populateDeleter: true,
    };

    this.defaultPopulateOrder(queryParams);
  }

  getTodayFinishedOrders() {
    const queryParams: Partial<orderQueryParams> = {
      updatedAt: this.todayIso,
      orderState: ORDER_STATE.FINISHED,
    };

    this.defaultPopulateOrder(queryParams);
  }

  getAllPlannedOrders() {
    const queryParams: Partial<orderQueryParams> = {
      orderState: ORDER_STATE.PLANNED,
    };

    this.defaultPopulateOrder(queryParams);
  }
}

<svg fill="none" viewBox="0 0 245 225" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.3">
    <path
      d="M254.916 232.75V210.583C254.909 200.761 251.639 191.218 245.621 183.455C239.603 175.692 231.177 170.147 221.666 167.691"
      stroke="url(#paint0_linear_833_60382)"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-opacity="0.5"
      stroke-width="1.5"
    />
    <path
      d="M188.417 232.75V210.583C188.417 198.825 183.746 187.549 175.432 179.235C167.118 170.921 155.842 166.25 144.084 166.25H55.4173C43.6594 166.25 32.383 170.921 24.0689 179.235C15.7548 187.549 11.084 198.825 11.084 210.583V232.75"
      fill="url(#paint1_linear_833_60382)"
      fill-opacity="0.2"
    />
    <path
      d="M188.417 232.75V210.583C188.417 198.825 183.746 187.549 175.432 179.235C167.118 170.921 155.842 166.25 144.084 166.25H55.4173C43.6594 166.25 32.383 170.921 24.0689 179.235C15.7548 187.549 11.084 198.825 11.084 210.583V232.75"
      stroke="url(#paint2_linear_833_60382)"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-opacity="0.5"
      stroke-width="1.5"
    />
    <path
      d="M177.333 34.6909C186.869 37.1326 195.322 42.6787 201.358 50.4549C207.394 58.231 210.67 67.7949 210.67 77.6388C210.67 87.4827 207.394 97.0466 201.358 104.823C195.322 112.599 186.869 118.145 177.333 120.587"
      stroke="url(#paint3_linear_833_60382)"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-opacity="0.5"
      stroke-width="1.5"
    />
    <path
      d="M99.7494 121.917C124.234 121.917 144.083 102.068 144.083 77.5833C144.083 53.0987 124.234 33.25 99.7494 33.25C75.2647 33.25 55.416 53.0987 55.416 77.5833C55.416 102.068 75.2647 121.917 99.7494 121.917Z"
      fill="url(#paint4_linear_833_60382)"
      fill-opacity="0.2"
      stroke="url(#paint5_linear_833_60382)"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-opacity="0.5"
      stroke-width="1.5"
    />
    <rect
      fill="white"
      fill-opacity="0.1"
      height="19.2289"
      rx="9.61446"
      width="20.0185"
      x="244.364"
      y="31.8184"
    />
    <rect
      height="18.7289"
      rx="9.36446"
      stroke="url(#paint6_linear_833_60382)"
      stroke-opacity="0.5"
      stroke-width="0.5"
      width="19.5185"
      x="244.614"
      y="32.0684"
    />
  </g>
  <defs>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint0_linear_833_60382"
      x1="240.209"
      x2="215.018"
      y1="167.691"
      y2="170.376"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint1_linear_833_60382"
      x1="109.981"
      x2="6.19783"
      y1="166.25"
      y2="223.963"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint2_linear_833_60382"
      x1="109.981"
      x2="6.19783"
      y1="166.25"
      y2="223.963"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint3_linear_833_60382"
      x1="195.925"
      x2="170.547"
      y1="34.6909"
      y2="36.7448"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint4_linear_833_60382"
      x1="119.79"
      x2="47.3211"
      y1="52.0917"
      y2="114.303"
    >
      <stop stop-color="#CBF9DD" />
      <stop offset="1" stop-color="#CBF9DD" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint5_linear_833_60382"
      x1="104.865"
      x2="39.7572"
      y1="33.25"
      y2="46.8272"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      gradientUnits="userSpaceOnUse"
      id="paint6_linear_833_60382"
      x1="255.528"
      x2="240.88"
      y1="31.8184"
      y2="34.9985"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" stop-opacity="0" />
    </linearGradient>
  </defs>
</svg>

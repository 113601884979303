import { BaseModel } from "./BaseModel";
import Product, { PRODUCT_QUALITY } from "./Product.class";

export default class ProductPrice extends BaseModel<ProductPrice> {
  tenantId: string;
  productId?: string;
  product!: Product | undefined;
  quality!: PRODUCT_QUALITY;
  price!: number;
  timeframeBegin: string;
  timeframeEnd: string;
  customerId: string;

  constructor(params: Partial<ProductPrice>) {
    super(params);
    Object.assign(this, params);
  }
}

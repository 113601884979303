import { inject, Injectable, signal } from "@angular/core";
import { PermissionService } from "../permission.service";
import { PermissionType } from "../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";

@Injectable({
  providedIn: "root",
})
export class LocationPermissionsHelperService {
  permissionService = inject(PermissionService);

  missingLocationPermissionsTooltip = signal<PermissionType[]>([
    {
      name: "Lieferadresse löschen",
      hasPermission:
        this.permissionService.deleteLocationPermission().hasPermission,
    },

    {
      name: "Kontaktpersonen löschen",
      hasPermission:
        this.permissionService.deleteContactPersonsPermission().hasPermission,
    },
  ]);

  missingContactPersonsPermissionsTooltip = signal<PermissionType[]>([
    {
      name: "Kontaktpersonen ansehen",
      hasPermission:
        this.permissionService.readContactPersonsPermission().hasPermission,
    },

    {
      name: "Kontaktpersonen erstellen",
      hasPermission:
        this.permissionService.createContactPersonsPermission().hasPermission,
    },
    {
      name: "Kontaktpersonen aktualisieren",
      hasPermission:
        this.permissionService.updateContactPersonsPermission().hasPermission,
    },
  ]);

  get hasAllCreateLocationPermissions(): boolean {
    return (
      // Create Location
      this.permissionService.createLocationPermission().hasPermission &&
      // Read Location
      this.permissionService.readLocationPermission().hasPermission
    );
  }

  get hasAllDeleteLocationPermissions(): boolean {
    return (
      // Delete Location
      this.permissionService.deleteLocationPermission().hasPermission &&
      // Read Location
      this.permissionService.readLocationPermission().hasPermission &&
      // Delete Contact Person
      this.permissionService.deleteContactPersonsPermission().hasPermission
      // // Delete Order
      // this.permissionService.deleteOrderPermission().hasPermission &&
      // //   // Delete Product Order
      // this.permissionService.deleteProductOrderPermission().hasPermission &&
      // //   // Delete Tour
      // this.permissionService.deleteTourPermission().hasPermission &&
      // // Delete TourPoint
      // this.permissionService.deleteTourPointPermission().hasPermission
    );
  }

  get hasCreateLocationContactPersonPermissions() {
    return (
      this.hasAllCreateLocationPermissions &&
      this.permissionService.createContactPersonsPermission().hasPermission &&
      this.permissionService.readContactPersonsPermission().hasPermission
    );
  }

  get hasCreateReadAndUpdateContactPersonsPermissions() {
    return (
      // Create Contact Person
      this.permissionService.createContactPersonsPermission().hasPermission &&
      // Read Contact Person
      this.permissionService.readContactPersonsPermission().hasPermission &&
      // Update Contact Person
      this.permissionService.updateContactPersonsPermission().hasPermission
    );
  }
}

<div class="bar-wrapper">
  <nav class="sidenav-wrapper">
    <app-logo-svg style="transform: scale(0.3)"></app-logo-svg>

    <div class="nav-wrapper">
      <div class="nav-items">
        <!-- Different buttons for navigation -->
        @for (item of navItemsArray; track item) {
          @if (hasPermissionForMenuItem(item)) {
            <app-button
              (isClicked)="setSelectedItem($event)"
              [isRound]="true"
              [type]="item"
            ></app-button>
          }
        }
      </div>

      <!-- The bottom, which is always displayed at the bottom -->
      <div class="userBtn">
        <tui-hosted-dropdown
          [(open)]="isOpen"
          [content]="dropdown"
          [sided]="true"
          tuiDropdownAlign="right"
        >
          <button
            [pseudoActive]="isOpen || null"
            appearance=""
            iconRight="tuiIconUserLarge"
            size="m"
            style="color: white !important"
            tuiIconButton
            type="button"
          ></button>
        </tui-hosted-dropdown>
      </div>
    </div>
  </nav>
  <div class="header-wrapper">
    <ng-content></ng-content>
  </div>

  <div class="version">
    <span> WEB: {{ this.webVersion }} </span>

    <span> API: {{ this.apiVersion }} </span>
  </div>
</div>

<!-- Dropup content on the bottom for settings and logout -->
<ng-template #dropdown let-close="close">
  <tui-data-list>
    <tui-opt-group id="user-name">
      {{ userName }}
    </tui-opt-group>
    <tui-opt-group>
      @if (hasSettingsPermissions()) {
        <button [routerLink]="'/' + NavRoutes.SETTINGS" tuiOption>
          {{ "sidenav.bottom-settings" | translate }}
        </button>
      }
      <button (click)="logOut()" tuiOption>
        <tui-svg [src]="tuiIconLogOut"></tui-svg>
        {{ "sidenav.bottom-logout" | translate }}
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>

<div class="permission-wrapper" style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um Aufträge
    {{ isAddmode ? "erstellen" : "aktualisieren" }} zu können:
  </h3>

  <ul>
    <li *ngIf="!permissionService.readCustomerPermission().hasPermission">
      <b>Kunden ansehen</b>
    </li>

    <li *ngIf="!permissionService.readLocationPermission().hasPermission">
      <b>Lieferadressen ansehen</b>
    </li>

    <li
      *ngIf="
        !permissionService.createProductOrderPermission().hasPermission &&
        isAddmode
      "
    >
      <b>Produktbestellungen erstellen</b>
    </li>

    <li *ngIf="!permissionService.readProductPermission().hasPermission">
      <b>Produkte ansehen</b>
    </li>

    <li
      *ngIf="
        !permissionService.updateProductOrderPermission().hasPermission &&
        !isAddmode
      "
    >
      <b>Produktbestellungen aktualisieren</b>
    </li>
  </ul>
</div>

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";

export enum DashboardEntity {
  ORDER = "ORDER",
  DRIVER = "DRIVER",
  TOUR = "TOUR",
  REFUND = "REFUND",
}

export type ChartData = {
  chartData: number[];
  additionalChartData: number[];
};

export type WidgetData = {
  ordersCreatedTodayCount: number;
  orderPlannedCount: number;
  ordersPlanningPhaseCount: number;
  orderFinishedTodayCount: number;
  inactiveCustomers: number;
};

export type OrderStatusToday = {
  unplannedOrdersToday: number;
  partlyPlannedOrdersToday: number;
  plannedOrdersToday: number;
  cancelledOrdersToday: number;
  finishedOrdersToday: number;
};

@Injectable({
  providedIn: "root",
})
export class KpiService {
  baseUrl: string = environment.baseUrl + "kpi/";

  constructor(private http: HttpClient) {}

  // ------------------------------------------------------------------------------------- || Methods ||

  getUnplannedDrivers(): Observable<number> {
    return this.http.get<number>(this.baseUrl + "dashboard/unplannedDrivers");
  }

  getOpenOrders(): Observable<number> {
    return this.http.get<number>(this.baseUrl + "dashboard/openOrders");
  }

  getChartData(dashboardEntity: DashboardEntity): Observable<ChartData> {
    const params = new HttpParams().set("dashboardEntity", dashboardEntity);
    return this.http.get<ChartData>(this.baseUrl + "dashboard/chartData", {
      params,
    });
  }

  getWidgetData() {
    return this.http.get<WidgetData>(this.baseUrl + "dashboard/WidgetData");
  }

  getDonutData() {
    return this.http.get<OrderStatusToday>(
      this.baseUrl + "dashboard/donutData",
    );
  }
}

import TourPointLoad from "./TourPointLoad.class";
import { BaseModel } from "./BaseModel";

export default class Refund extends BaseModel<Refund> {
  tenantId: string;
  tourPointLoadId: string;
  amount: number;
  description: string;
  tourPointLoad: TourPointLoad;
  locationName: string;
  orderId?: string;
  files?: { id: string }[];

  constructor(params: Partial<Refund>) {
    super(params);
    Object.assign(this, params);
  }
}

import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { PermissionGuardHelperService } from "../../services/permission-helper-services/permission-guard-helper.service";

export const hasReadPermissionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): boolean => {
  const permissionService = inject(PermissionGuardHelperService);
  const router: Router = inject(Router);

  const view = route.data["view"];

  if (permissionService.checkPermission(view)) {
    return true;
  } else {
    // If permission check fails, redirect to the next view (with permission) or to a default view
    router.navigate([permissionService.getNextViewWithPermission(view)]).then();
    return false;
  }
};

import { Component, inject } from "@angular/core";
import { PermissionService } from "../../../services/permission.service";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-missing-refunds-permissions-dialog",
  standalone: true,
  imports: [NgIf],
  templateUrl: "./missing-refunds-permissions-dialog.component.html",
  styleUrl: "./missing-refunds-permissions-dialog.component.scss",
})
export class MissingRefundsPermissionsDialogComponent {
  permissionService = inject(PermissionService);
}

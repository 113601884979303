import { Component, Input } from "@angular/core";
import { MapTpTplService } from "../../map-helper-services/map-tp-tpl.service";

@Component({
  selector: "app-driving-duration-distance",
  templateUrl: "./driving-duration-distance.component.html",
  styleUrl: "./driving-duration-distance.component.scss",
})
export class DrivingDurationDistanceComponent {
  @Input() i: number;

  constructor(public mapTPService: MapTpTplService) {}

  formatDuration(duration: number): string {
    if (duration < 60) {
      // duration is less than an hour
      return `${duration} Minuten`;
    } else {
      // duration is more than an hour
      const hours = Math.floor(duration / 60);
      const remainingMinutes = duration % 60;
      return `${hours} ${
        hours <= 1 ? "Stunde" : "Stunden"
      }, ${remainingMinutes} Minuten`;
    }
  }
}

<div class="flex-row" style="justify-content: space-between">
  <h2>
    {{ "dashboard.year-overview" | translate }}
    {{ today() | date: "yyyy" }}
  </h2>
  @if (chartLabels().length !== 0) {
    <div class="scoped">
      <tui-legend-item
        style="margin-right: 0.75rem; cursor: default"
        *ngFor="let label of chartLabels(); let index = index"
        size="s"
        class="item"
        [color]="getColor(index)"
        [text]="label"
      ></tui-legend-item>
    </div>
  }
</div>

<span>
  {{ "common.for" | translate }}:
  <b
    (click)="showSelectionClick()"
    [tuiDropdownManual]="showSelection"
    [tuiDropdown]="dropdown"
  >
    {{ selectedDashboardEntity().label | translate }}
    <tui-svg [src]="arrowIcon()"></tui-svg>
  </b>
</span>
<ng-template #dropdown>
  <tui-data-list>
    <button
      (click)="onSelectionChanged(item)"
      *ngFor="let item of dashBoardEntityDropdownItems"
      tuiOption
    >
      {{ item.label | translate }}
    </button>
  </tui-data-list>
</ng-template>
<tui-axes
  [axisXLabels]="chartLabelX"
  [axisX]="'none'"
  [axisYLabels]="chartLabelY()"
  [axisY]="'none'"
  [horizontalLinesHandler]="horizontalLinesHandler"
  [horizontalLines]="chartLabelY().length - 1"
  class="axes"
  id="dashboard-year-overview"
  style="margin-top: 0.75rem"
>
  <tui-bar-chart
    [collapsed]="!hasTwoDataSets()"
    [max]="maxValue()"
    [size]="hasTwoDataSets() ? 'l' : null"
    [tuiHintAppearance]="'onDark'"
    [tuiHintContent]="hintContent"
    [value]="[chartDataSetFirst(), chartDataSetSecond()]"
  ></tui-bar-chart>
</tui-axes>

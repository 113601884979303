import { BaseModel } from "./BaseModel";
import { User } from "./User";
import TourPoint from "./TourPoint.class";

export enum TOUR_STATE {
  //To CREATE TourState in TourState only requirement is locationId
  DRAFT = "DRAFT",

  //To set an TourState in Unplannedstate, PATCH existing Order in Draftstate, requirements for PATCH are
  READY = "READY",

  //To set an Order to Plannedstate, Patch existing Order in UnplannedState, requirements for Patch are
  //Driver is assigned
  CANCELLED = "CANCELLED",

  //To set an Order Done, Patch existing Order in Plannedstate, requirements for Patch are
  //All ProductOrders are in DoneState
  FINISHED = "FINISHED",
}

export const tourStateTranslations: Map<TOUR_STATE, string> = new Map([
  [TOUR_STATE.DRAFT, "tour.state-draft"],
  [TOUR_STATE.READY, "tour.state-ready"],
  [TOUR_STATE.CANCELLED, "tour.state-cancelled"],
  [TOUR_STATE.FINISHED, "tour.state-finished"],
]);

export default class Tour extends BaseModel<Tour> {
  driverId!: string;
  driver: User;
  driverName: string;
  date: string;
  truckNumber: string;
  tourState: TOUR_STATE;
  tourPoints?: TourPoint[];
  deleter?: User;

  constructor(params: Partial<Tour>) {
    super(params);
    Object.assign(this, params);
  }
}

import { Component, Input, OnInit } from "@angular/core";
import TourPoint from "../../../../models/TourPoint.class";
import {
  tuiIconArrowDownCircleLarge,
  tuiIconArrowUpCircleLarge,
  tuiIconPlus,
} from "@taiga-ui/icons";
import { tabArray } from "../../../../common/tabs/tabs.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DropDownItem } from "../../../../interfaces/drop-down-item";
import { ProductService } from "../../../../api/product.service";
import Product, { PRODUCT_QUALITY } from "../../../../models/Product.class";
import TourPointLoad from "../../../../models/TourPointLoad.class";
import { TourPointLoadService } from "../../../../api/tourPointLoad.service";
import { TOUR_STATE } from "../../../../models/Tour.class";
import { MapTpTplService } from "../../map-helper-services/map-tp-tpl.service";

@Component({
  selector: "app-create-tpl-for-warehouse",
  templateUrl: "./create-tpl-for-warehouse.component.html",
  styleUrl: "./create-tpl-for-warehouse.component.scss",
})
export class CreateTplForWarehouseComponent implements OnInit {
  @Input({ required: true }) tourPoint: TourPoint;
  products: Product[];
  productsDropDown: DropDownItem[];
  qualityVisible: boolean = false;
  showForm: boolean = true;

  tabIndex: number = 0;

  form: FormGroup;

  tabArray: tabArray[] = [
    { name: "Liefern", icon: tuiIconArrowDownCircleLarge },
    { name: "Abholen", icon: tuiIconArrowUpCircleLarge },
  ];

  qualityDropDown: DropDownItem[];
  protected readonly tuiIconPlus = tuiIconPlus;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private tourPointLoadService: TourPointLoadService,
    private mapTPTPLService: MapTpTplService,
  ) {}

  get isTourStateDraft() {
    return this.mapTPTPLService.tour.tourState === TOUR_STATE.DRAFT;
  }

  get isEditable(): boolean {
    return this.isTourStateDraft;
  }

  // Methods

  ngOnInit(): void {
    this.form = this.fb.group({
      amount: [null, [Validators.required, Validators.min(1)]],
      product: [null, Validators.required],
      quality: [null, Validators.required],
    });

    this.getProducts();

    // do not show the tpl form initially if there are already tpl at the tp
    if (this.tourPoint.tourPointLoads.length > 0) {
      this.showForm = false;
    }
  }

  getProducts() {
    this.productService.getAllProducts().subscribe((products) => {
      this.products = products.records;
      this.productsDropDown = this.createDropdownItems(this.products);
    });
  }

  // set the quality dropdown after product selection change
  productSelectionChange(productDropdown: DropDownItem) {
    const foundProduct = this.products.find(
      (product) => product.id === productDropdown.id,
    );

    if (foundProduct) {
      const qualityOrder = [
        PRODUCT_QUALITY.QUALITY_NEU,
        PRODUCT_QUALITY.QUALITY_A,
        PRODUCT_QUALITY.QUALITY_B,
        PRODUCT_QUALITY.QUALITY_C,
        PRODUCT_QUALITY.QUALITY_DEFEKT,
      ];

      const qualities = foundProduct.possibleQualities.map((quality) => ({
        id: quality,
        name: this.getLabelOfQualities(quality),
      }));

      this.qualityVisible = qualities.length > 1;
      this.setQualityFormValue(foundProduct);

      this.qualityDropDown = this.createDropdownItems(qualities).sort(
        //@ts-ignore
        (a, b) => qualityOrder.indexOf(a.id) - qualityOrder.indexOf(b.id),
      );
    }
  }

  setQualityFormValue(product: Product) {
    if (!this.qualityVisible) {
      this.form.get("quality")?.setValue({
        id: product.id,
        label: this.getLabelOfQualities(product.possibleQualities[0]),
      });
    } else {
      this.form.get("quality")?.setValue(null);
    }
  }

  createDropdownItems(
    array: any[],
    keyForId: string = "id",
    keyForLabel: string = "name",
  ): DropDownItem[] {
    return array.map((item: any) => {
      return {
        id: item[keyForId],
        label: item[keyForLabel],
      };
    });
  }

  getLabelOfQualities(quality: PRODUCT_QUALITY) {
    switch (quality) {
      case PRODUCT_QUALITY.QUALITY_NEU:
        return "Neu";
      case PRODUCT_QUALITY.QUALITY_A:
        return "A";
      case PRODUCT_QUALITY.QUALITY_B:
        return "B";
      case PRODUCT_QUALITY.QUALITY_C:
        return "C";
      case PRODUCT_QUALITY.QUALITY_DEFEKT:
        return "Defekt";
    }
  }

  getQualityFromLabel(label: string) {
    switch (label) {
      case "Neu":
        return PRODUCT_QUALITY.QUALITY_NEU;
      case "A":
        return PRODUCT_QUALITY.QUALITY_A;
      case "B":
        return PRODUCT_QUALITY.QUALITY_B;
      case "C":
        return PRODUCT_QUALITY.QUALITY_C;
      case "Defekt":
        return PRODUCT_QUALITY.QUALITY_DEFEKT;
      default:
        throw new Error("Unrecognized label");
    }
  }

  submitOrAddForm() {
    if (this.showForm) {
      this.submit();
    } else {
      this.showForm = true;
    }
  }

  submit() {
    this.createTpl();
  }

  createTpl() {
    const quality = this.getQualityFromLabel(
      this.form.get("quality")?.value.label,
    );

    const tourPointLoad = new TourPointLoad({
      tourPointId: this.tourPoint.id,
      productId: this.form.get("product")?.value.id,
      quality: quality,
      amount: this.form.get("amount")?.value,
    });

    if (this.tabIndex === 0) {
      tourPointLoad.amount = -tourPointLoad.amount;
    }

    let tourPointLoads: TourPointLoad[] = [tourPointLoad];

    this.tourPointLoadService
      .addTourPointLoad(tourPointLoads)
      .subscribe((tpl) => {
        this.hideForm();
        this.tourPoint.tourPointLoads.push(tpl[0]);
      });
  }

  hideForm() {
    this.showForm = false;
    this.resetForm();
  }

  resetForm() {
    this.form.reset(
      {
        amount: null,
        product: null,
        quality: null,
      },
      { emitEvent: false },
    );
  }

  changeIndex(index: number) {
    this.tabIndex = index;
  }
}

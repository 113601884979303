import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import ContactPerson from "../models/ContactPerson.class";
import { ContactPersonQueryParams } from "../interfaces/contact-person-query-params";

@Injectable({
  providedIn: "root",
})
export class ContactPersonService {
  baseUrl: string = environment.baseUrl;
  private _contactPersons = signal<ContactPerson[]>([]);

  constructor(private http: HttpClient) {}

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD ONE ContactPerson
  addContactPerson(
    addContactPersonObject: ContactPerson,
  ): Observable<ContactPerson> {
    return this.http
      .post<ContactPerson>(
        this.baseUrl + "contact-person",
        addContactPersonObject,
      )
      .pipe(tap(() => this.getAllContactPersons().subscribe()));
  }

  // GET ALL ContactPersons
  getAllContactPersons(
    queryParams?: ContactPersonQueryParams,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();
    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "contact-person", { params })
      .pipe(
        tap((response: ResponseWithRecordsBody) =>
          this._contactPersons.set(
            response.records.map(
              (contactPerson: ContactPerson) =>
                new ContactPerson(contactPerson),
            ),
          ),
        ),
      );
  }

  // CHANGE ONE ContactPerson BY ID
  updateContactPersonById(
    id: string | number,
    updateContactPersonObject: ContactPerson,
  ): Observable<ContactPerson> {
    return this.http
      .patch<ContactPerson>(
        this.baseUrl + "contact-person/" + id,
        updateContactPersonObject,
      )
      .pipe(tap(() => this.getAllContactPersons().subscribe()));
  }

  // DELETE ONE ContactPerson BY ID
  deleteContactPersonById(id: number | string): Observable<unknown> {
    return this.http
      .delete<unknown>(this.baseUrl + "contact-person/" + id)
      .pipe(tap(() => this.getAllContactPersons().subscribe()));
  }
}

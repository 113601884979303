import { Component, Input } from "@angular/core";
import TourPoint from "../../../../models/TourPoint.class";

@Component({
  selector: "app-tour-point-load-loop",
  templateUrl: "./tour-point-load-loop.component.html",
  styleUrl: "./tour-point-load-loop.component.scss",
})
export class TourPointLoadLoopComponent {
  @Input() tourPoint: TourPoint;
}

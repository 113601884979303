import { Component, EventEmitter, Input, Output } from "@angular/core";
import Order, { ORDER_STATE } from "../../../models/Order.class";
import { NavRoutes } from "../../../other/enums/nav-routes";
import { Router } from "@angular/router";
import { TuiTablePagination } from "@taiga-ui/addon-table";
import { TableSortEvent } from "../../../interfaces/table-sort-event";
import { OrderService } from "../../../api/order.service";
import { HelperService } from "../../../services/helper.service";
import ProductOrder from "../../../models/ProductOrder.class";
import { TOUR_STATE } from "../../../models/Tour.class";

/**
 * Reusable order table component, used to display a list of orders on the orders page and customer details page
 * */
@Component({
  selector: "app-order-table",
  templateUrl: "./order-table.component.html",
  styleUrl: "./order-table.component.scss",
})
export class OrderTableComponent {
  headerColumns = [
    "Erstellt am",
    "Auftragsnummer",
    "MATCHC",
    "Kundennummer",
    "Standort",
    "Liefern von",
    "Liefern bis",
    "Produkte",
    "Gesamtanzahl",
    "Status",
    "Storniert worden von",
  ];
  dataColumns = [
    "createdAt",
    "orderNumber",
    "location.customer.MATCHC",
    "location.customer.customerNumber",
    "location.name",
    "timeframeBegin",
    "timeframeEnd",
    "products",
    "totalAmount",
    "orderState",
    "deleter",
    "refunds",
    "customEdit",
  ];
  noSortColumns = [
    "orderNumber",
    "products",
    "totalAmount",
    "invoiceAmount",
    "location.customer.MATCHC",
    "location.customer.customerNumber",
    "timeframe",
  ];
  refundTooltip = "Es liegen Reklamationen vor";

  @Input() orders: Order[];
  @Input() itemsDeletable: boolean = true;
  @Input() itemsEditable: boolean = true;
  @Input({ required: true }) tablePage: number = 0;
  @Input({ required: true }) tablePageSize: number = 0;
  @Input() hideHeaderColumns: string[] = [];
  @Input() hideDataColumns: string[] = [];

  @Output() deleteEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() paginationEvent = new EventEmitter<TuiTablePagination>();
  @Output() sortEvent = new EventEmitter<TableSortEvent>();
  protected readonly TOUR_STATE = TOUR_STATE;

  // TODO: commented out because sorting was lost by this
  // isSameDayAndFormatTime(start: Date, end: Date): string {
  //   const startDate = new Date(start);
  //   const endDate = new Date(end);
  //   const fullDay =
  //     this.translateService.instant("general.fullday") ?? "Ganztägig";
  //
  //   const isAllDayEvent =
  //     startDate.getUTCHours() === 0 &&
  //     startDate.getUTCMinutes() === 0 &&
  //     endDate.getUTCHours() === 23 &&
  //     endDate.getUTCMinutes() === 59;
  //
  //   // Prepare date and time options for toLocaleTimeString
  //   const dateOptions: Intl.DateTimeFormatOptions = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     timeZone: "UTC",
  //   };
  //
  //   const timeOptions: Intl.DateTimeFormatOptions = {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     timeZone: "UTC",
  //   };
  //
  //   // Create formatted date and time strings
  //   const startDateStr = startDate
  //     .toLocaleDateString([], dateOptions)
  //     .replace(",", "");
  //   const startTimeStr = startDate.toLocaleTimeString([], timeOptions);
  //   const endDateStr = endDate
  //     .toLocaleDateString([], dateOptions)
  //     .replace(",", "");
  //   const endTimeStr = endDate.toLocaleTimeString([], timeOptions);
  //
  //   if (isAllDayEvent) {
  //     if (startDateStr === endDateStr) {
  //       // Same day all day event
  //       return `${startDateStr} / ${fullDay}`;
  //     } else {
  //       // Multiple days all day event
  //       return `${startDateStr} - ${endDateStr} / ${fullDay}`;
  //     }
  //   } else if (startDateStr === endDateStr) {
  //     // Same day partial day event
  //     return `${startDateStr} / ${startTimeStr} - ${endTimeStr}`;
  //   } else {
  //     // Non-All-Day event spanning multiple days
  //     return "";
  //   }
  // }
  protected readonly ORDER_STATE = ORDER_STATE;

  constructor(
    public orderService: OrderService,
    public copyHelperService: HelperService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.itemsDeletable) {
      this.dataColumns.push("delete");
    }
    if (this.itemsEditable) {
      this.dataColumns.push("edit");
    }

    if (this.hideHeaderColumns.length) {
      this.headerColumns = this.headerColumns.filter(
        (header) => !this.hideHeaderColumns.includes(header),
      );
    }
    if (this.hideDataColumns.length) {
      this.dataColumns = this.dataColumns.filter(
        (data) => !this.hideDataColumns.includes(data),
      );
    }
  }

  getUniqueProductNames(productOrders: ProductOrder[]): string[] {
    return productOrders
      .map((productOrder) => productOrder.product?.name)
      .filter((name): name is string => Boolean(name))
      .filter((v, i, a) => a.indexOf(v) === i);
  }

  routeToOrderDetails($event: string) {
    const url = NavRoutes.ORDER + "/order-detail/" + $event;
    this.router.navigate([url]).then();
  }

  getTotalAmountFromProductOrder(productOrder: ProductOrder[]) {
    let totalAmount = 0;
    productOrder.forEach((product) => {
      totalAmount += Math.abs(product.amount);
    });
    return totalAmount;
  }

  getTotalInvoiceAmountFromProductOrder(productOrder: ProductOrder[]) {
    let invoiceAmount = 0;
    productOrder.forEach((product) => {
      invoiceAmount +=
        Math.abs(product.pricePerProduct) * Math.abs(product.amount);
    });
    return invoiceAmount;
  }
}

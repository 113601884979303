import { Component, Inject, OnInit, signal } from "@angular/core";
import { TuiCarouselModule, TuiPaginationModule } from "@taiga-ui/kit";
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiSvgModule,
} from "@taiga-ui/core";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TranslateModule } from "@ngx-translate/core";
import { NgStyle } from "@angular/common";

@Component({
  selector: "app-receipt-carousel",
  standalone: true,
  imports: [
    TuiCarouselModule,
    TuiButtonModule,
    TuiPaginationModule,
    TuiSvgModule,
    TranslateModule,
    NgStyle,
  ],
  templateUrl: "./receipt-carousel.component.html",
  styleUrl: "./receipt-carousel.component.scss",
})
export class ReceiptCarouselComponent implements OnInit {
  imgUrls = signal<string[]>([]);

  dialogImageIndex: number = 0;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any>,
  ) {}

  get downloadFileName(): string {
    return "Lieferschein-Bild" + this.dialogImageIndex + ".png";
  }

  get selectedImageUrl(): string {
    return this.imgUrls()[this.dialogImageIndex];
  }

  ngOnInit() {
    if (!this.context.data) return;
    this.imgUrls.set(this.context.data["files"]);
    this.dialogImageIndex = this.context.data["index"];
  }

  print() {
    const imageUrl = this.imgUrls()[this.dialogImageIndex];

    const printWindow = window.open("", "_blank");

    // A4 paper dimensions are 210mm x 297mm. Convert dimensions to pixels considering that 1mm = 3.77px. Subtract 2-3 cm for padding.
    const imgElement = `<img src="${imageUrl}" alt="Image to Print" style="width: 760px; height: 1100px; object-fit: contain;" />`;

    printWindow?.document.write(imgElement);
    printWindow?.document.close();
    printWindow!.onload = function () {
      printWindow?.print();
      printWindow!.onafterprint = function () {
        printWindow?.close();
      };
    };
  }
}

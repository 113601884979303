import { Component, Input } from "@angular/core";

@Component({
  selector: "app-tour-point-load-accordion",
  templateUrl: "./tour-point-load-accordion.component.html",
  styleUrl: "./tour-point-load-accordion.component.scss",
})
export class TourPointLoadAccordionComponent {
  @Input() title: string;
  @Input() expanded: boolean = false;

  toggle() {
    this.expanded = !this.expanded;
  }
}

<tui-input-number
  (focusout)="blurEvent()"
  [formControlName]="fieldName"
  [max]="max"
  [min]="min"
  [readOnly]="readOnly"
  [tuiTextfieldSize]="size"
>
  <span [ngStyle]="{ color: hintColor }" class="hint">
    {{ hint | translate }}
  </span>
  <span *ngIf="required" class="tui-required"></span>
</tui-input-number>

import { Injectable } from "@angular/core";
import { OrderService } from "../../../api/order.service";
import Order from "../../../models/Order.class";
import { MapBaseDataService } from "./map-base-data.service";
import ProductOrder from "../../../models/ProductOrder.class";

@Injectable({
  providedIn: "root",
})
export class MapOrdersService {
  allOrders: Order[] = [];
  orders: Order[] = [];
  filters: { [filterType: string]: string } = {};

  constructor(
    private orderService: OrderService,
    private mapBaseService: MapBaseDataService,
  ) {}

  getOrdersWithCallback(callback?: Function) {
    if (!this.mapBaseService.tour().date) return;

    this.orderService
      .getAllOrders(
        { date: this.mapBaseService.tour().date, populateRefund: true },
        true,
      )
      .subscribe((orders) => {
        this.allOrders = orders.records;
        this.applyFilters();

        if (callback) callback();
      });
  }

  // backend filter
  getOrdersByCustomerId(customerId: string) {
    if (!this.mapBaseService.tour().date) return;

    this.orderService
      .getAllOrders(
        {
          customerId,
          populateRefund: true,
          date: this.mapBaseService.tour().date,
        },
        true,
      )
      .subscribe((orders) => {
        this.allOrders = orders.records;
        this.filters["customerId"] = customerId;
        this.applyFilters();
      });
  }

  // backend filter
  getOrdersByLocationId(locationId: string) {
    if (!this.mapBaseService.tour().date) return;

    this.orderService
      .getAllOrders(
        {
          locationId,
          populateRefund: true,
          date: this.mapBaseService.tour().date,
        },
        true,
      )
      .subscribe((orders) => {
        this.allOrders = orders.records;
        this.filters["locationId"] = locationId;
        this.applyFilters();
      });
  }

  // apply and save current filters, to use it after refreshing the order data without showing all again
  applyFilters() {
    let filteredOrders = [...this.allOrders];

    if (this.filters["customerId"]) {
      const customerId = this.filters["customerId"];
      filteredOrders = filteredOrders.filter((order) => {
        return order.location.customerId === customerId;
      });
    }

    if (this.filters["locationId"]) {
      const locationId = this.filters["locationId"];
      filteredOrders = filteredOrders.filter((order) => {
        return order.location.id === locationId;
      });
    }

    this.orders = filteredOrders;

    this.orders.forEach((order) => {
      this.getCalculatedAmount(order);
    });
  }

  // Other
  getCalculatedAmount(order: Order) {
    order.productOrders.forEach((productOrder) => {
      this.calculateProductOrderAmount(productOrder, order);
    });
  }

  private calculateProductOrderAmount(
    productOrder: ProductOrder,
    order: Order,
  ) {
    let initialProductOrderAmount = Math.abs(productOrder.amount);

    const validTPL = productOrder.tourPointLoads.filter(
      (tourPointLoad) =>
        !(tourPointLoad.vcIsLogEntity || tourPointLoad.isDeleted),
    );

    validTPL.forEach((tourPointLoad) => {
      let tourPointLoadAmount = Math.abs(tourPointLoad.amount);

      initialProductOrderAmount -= tourPointLoadAmount;

      const refundAmount =
        order.refunds?.find(
          (refund) => refund.tourPointLoadId === tourPointLoad.id,
        )?.amount ?? 0;

      initialProductOrderAmount += refundAmount;
    });

    productOrder.calculatedAmount = initialProductOrderAmount;
  }
}

import { Component } from "@angular/core";

@Component({
  selector: "app-not-base-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"],
})
/**
 * Layout for the login, reset password, ... pages that are visible to non-authenticated users
 *
 * Not containing the navbar/sidebar like the base layout
 */
export class AuthLayoutComponent {}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DropDownItem } from "../../interfaces/drop-down-item";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../api/auth.service";
import { RoleBody } from "../../interfaces/role-body";
import { PermissionLevel } from "../../other/enums/permission-level";
import { Permissions } from "../../other/enums/permissions";
import { Role } from "../../models/Role";
import { ReplaySubject } from "rxjs";
import { RoleEvent } from "../../interfaces/role-event";

/**
 * Type for valid keys of the FormControls within the roleForm FormGroup.
 */
type ControlKeys =
  | "userView"
  | "userCreate"
  | "userUpdate"
  | "userDelete"
  | "tenantView"
  | "tenantCreate"
  | "tenantUpdate"
  | "tenantDelete"
  | "roleView"
  | "roleCreate"
  | "roleUpdate"
  | "roleDelete";

@Component({
  selector: "app-role-form",
  templateUrl: "./role-form.component.html",
  styleUrls: ["./role-form.component.scss"],
})

/**
 * Component with a form for creating and editing roles-old
 * Using the permissionRow component for displaying the permission dropdowns
 *
 * @Input title: The title of the form
 * @Input selectedRole$: ReplaySubject<Role>: ReplaySubject that emits the selected role
 * @Output roleFormSubmitted: Emits a RoleEvent when the form is submitted
 */
export class RoleFormComponent {
  @Input() title!: string;
  @Input() selectedRole$!: ReplaySubject<Role>;
  @Output() roleFormSubmitted = new EventEmitter<RoleEvent>();
  defaultRoleLevel: DropDownItem = { id: PermissionLevel.NONE, label: "-" };
  allRoleLevel: DropDownItem = { id: PermissionLevel.ALL, label: "Alle" };
  ownRoleLevel: DropDownItem = { id: PermissionLevel.ALL, label: "Eigene" };
  readonly roleForm = new FormGroup({
    roleName: new FormControl("", [Validators.required]),
    roleDescription: new FormControl("", [Validators.required]),

    // checkbox values
    isTenantAdmin: new FormControl(false),
    isSuperAdmin: new FormControl(false),

    // Validators for permission dropdown regarding users permissions
    userDelete: new FormControl(this.defaultRoleLevel, [Validators.required]),
    userCreate: new FormControl(this.defaultRoleLevel, [Validators.required]),
    userUpdate: new FormControl(this.defaultRoleLevel, [Validators.required]),
    userView: new FormControl(this.defaultRoleLevel, [Validators.required]),

    // Validators for permission dropdown regarding tenant permissions
    tenantDelete: new FormControl(this.defaultRoleLevel, [Validators.required]),
    tenantCreate: new FormControl(this.defaultRoleLevel, [Validators.required]),
    tenantUpdate: new FormControl(this.defaultRoleLevel, [Validators.required]),
    tenantView: new FormControl(this.defaultRoleLevel, [Validators.required]),

    // Validators for permission dropdown regarding roles-old permissions
    roleDelete: new FormControl(this.defaultRoleLevel, [Validators.required]),
    roleCreate: new FormControl(this.defaultRoleLevel, [Validators.required]),
    roleUpdate: new FormControl(this.defaultRoleLevel, [Validators.required]),
    roleView: new FormControl(this.defaultRoleLevel, [Validators.required]),
  });
  private selectedRole!: Role;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.onSelectedRoleChanges();
  }

  /**
   * Emits a RoleEvent when the form is submitted consisting of the roleBody and the roleId
   *
   */
  submitRoleForm() {
    const roleBody: RoleBody = {
      tenantId: this.authService.getLoggedInUser()?.tenantId!,
      name: this.roleForm.controls.roleName.value!,
      description: this.roleForm.controls.roleDescription.value!,
      global: false,
      draft: false,
      superAdmin: this.roleForm.controls.isSuperAdmin.value!,
      tenantAdmin: this.roleForm.controls.isTenantAdmin.value!,
      permissions: this.fillPermissionArray([]),
    };

    let selectedRoleId;

    if (this.selectedRole != null) {
      selectedRoleId = this.selectedRole.id;
    } else {
      selectedRoleId = undefined;
    }

    this.roleFormSubmitted.emit({
      roleBody: roleBody,
      roleId: selectedRoleId,
    });
  }

  /**
   * Subscribes to the selectedRole$ subject and sets the default values of the form when the selected role changes
   */
  onSelectedRoleChanges() {
    if (this.selectedRole$ != null) {
      this.selectedRole$.subscribe((role) => {
        this.selectedRole = role;
        this.setDefaultFormValues();
      });
    }
  }

  /**
   * Sets the default values of the form to the values of the selected role
   * Sets the permission dropdowns to the correct permission level
   */
  setDefaultFormValues() {
    this.roleForm.controls["roleName"].setValue(this.selectedRole.name);
    this.roleForm.controls["roleDescription"].setValue(
      this.selectedRole.description,
    );
    this.roleForm.controls["isTenantAdmin"].setValue(
      this.selectedRole.tenantAdmin,
    );
    this.roleForm.controls["isSuperAdmin"].setValue(
      this.selectedRole.superAdmin,
    );

    // disable the checkboxes
    this.roleForm.controls["isSuperAdmin"].disable();
    this.roleForm.controls["isTenantAdmin"].disable();

    this.setPermissions("userView", Permissions.USER_READ);
    this.setPermissions("userCreate", Permissions.USER_CREATE);
    this.setPermissions("userUpdate", Permissions.USER_UPDATE);
    this.setPermissions("userDelete", Permissions.USER_DELETE);

    this.setPermissions("tenantView", Permissions.TENANT_READ);
    this.setPermissions("tenantCreate", Permissions.TENANT_CREATE);
    this.setPermissions("tenantUpdate", Permissions.TENANT_UPDATE);
    this.setPermissions("tenantDelete", Permissions.TENANT_DELETE);

    this.setPermissions("roleView", Permissions.ROLE_READ);
    this.setPermissions("roleCreate", Permissions.ROLE_CREATE);
    this.setPermissions("roleUpdate", Permissions.ROLE_UPDATE);
    this.setPermissions("roleDelete", Permissions.ROLE_DELETE);
  }

  /**
   * Sets the default values of the form to the values of the selected role
   */
  setPermissions(controlName: ControlKeys, permissionType: Permissions) {
    const allPermission =
      this.selectedRole.permissions.includes(permissionType);
    const ownPermission = this.selectedRole.permissions.includes(
      (permissionType + "_OWN") as Permissions,
    );

    if (allPermission) {
      this.roleForm.controls[controlName].setValue(this.allRoleLevel);
    } else if (ownPermission) {
      this.roleForm.controls[controlName].setValue(this.ownRoleLevel);
    } else {
      this.roleForm.controls[controlName].setValue(this.defaultRoleLevel);
    }
  }

  /**
   * Fills the permissions array with the permissions from the form.
   *
   * @param permissions the array to fill
   */
  fillPermissionArray(permissions: string[]) {
    permissions = this.fillPermissionArrayForCategory(permissions, [
      {
        controlName: "userView",
        availablePermissions: [
          Permissions.USER_READ,
          Permissions.USER_READ_OWN,
        ],
      },
      {
        controlName: "userCreate",
        availablePermissions: [Permissions.USER_CREATE],
      },
      {
        controlName: "userUpdate",
        availablePermissions: [
          Permissions.USER_UPDATE,
          Permissions.USER_UPDATE_OWN,
        ],
      },
      {
        controlName: "userDelete",
        availablePermissions: [
          Permissions.USER_DELETE,
          Permissions.USER_DELETE_OWN,
        ],
      },
    ]);

    permissions = this.fillPermissionArrayForCategory(permissions, [
      {
        controlName: "tenantView",
        availablePermissions: [
          Permissions.TENANT_READ,
          Permissions.TENANT_READ_OWN,
        ],
      },
      {
        controlName: "tenantCreate",
        availablePermissions: [Permissions.TENANT_CREATE],
      },
      {
        controlName: "tenantUpdate",
        availablePermissions: [
          Permissions.TENANT_UPDATE,
          Permissions.TENANT_UPDATE_OWN,
        ],
      },
      {
        controlName: "tenantDelete",
        availablePermissions: [
          Permissions.TENANT_DELETE,
          Permissions.TENANT_DELETE_OWN,
        ],
      },
    ]);

    permissions = this.fillPermissionArrayForCategory(permissions, [
      {
        controlName: "roleView",
        availablePermissions: [
          Permissions.ROLE_READ,
          Permissions.ROLE_READ_OWN,
        ],
      },
      {
        controlName: "roleCreate",
        availablePermissions: [Permissions.ROLE_CREATE],
      },
      {
        controlName: "roleUpdate",
        availablePermissions: [Permissions.ROLE_UPDATE],
      },
      {
        controlName: "roleDelete",
        availablePermissions: [Permissions.ROLE_DELETE],
      },
    ]);

    return permissions;
  }

  /**
   * Fills the permissions array with the selected permissions in the form for a specific category (e.g. user, tenant, role):
   * 1. For each FormControl of the category: Get the permissionLevel selected in the form
   * 3. If the permissionLevel is ALL, add all availablePermissions to the permissions array
   * 4. If the permissionLevel is OWN, add all availablePermissions that include '_OWN' to the permissions array
   * 5. return the permissions array
   *
   * @param permissions the array to fill
   * @param actions containing the controlName of the FormControl and the availablePermissions for the category
   */
  fillPermissionArrayForCategory(
    permissions: string[],
    actions: { controlName: ControlKeys; availablePermissions: string[] }[],
  ) {
    for (const action of actions) {
      const permissionLevel =
        this.roleForm.controls[action.controlName].value?.id;
      if (permissionLevel === PermissionLevel.ALL) {
        permissions.push(...action.availablePermissions);
      } else if (permissionLevel === PermissionLevel.OWN) {
        permissions.push(
          ...action.availablePermissions.filter((p) => p.includes("_OWN")),
        );
      }
    }
    return permissions;
  }
}

import { Component, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TUI_VALIDATION_ERRORS } from "@taiga-ui/kit";

/**
 * Component for setting a new password.
 * Used in two different contexts:
 *
 * 1. When a user has forgotten his password and wants to reset it
 * 2. When a user wants to change his password in the settings (TODO: not implemented yet, since the api endpoint is missing)
 *
 * @Output submitNewPassword: Emits the new password when the form is submitted
 */
@Component({
  selector: "app-set-new-password",
  templateUrl: "./set-new-password.component.html",
  styleUrls: ["./set-new-password.component.scss"],
  providers: [
    {
      // To overwrite error messages
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        match: "Die Passwörter stimmen nicht überein", // TODO: translate
      },
    },
  ],
})
export class SetNewPasswordComponent {
  @Output() submitNewPassword = new EventEmitter<string>();
  readonly passwordForm = new FormGroup({
    newPassword: new FormControl(``, [Validators.required]),
    newPasswordConfirmation: new FormControl(``, [Validators.required]),
  });

  constructor() {}

  submitPasswordReset() {
    if (
      this.passwordForm.valid &&
      this.isMatching() &&
      this.isLongEnough() &&
      this.hasOneNumber() &&
      this.hasOneUppercaseLetter() &&
      this.hasOneLowercaseLetter()
    ) {
      this.submitNewPassword.emit(
        this.passwordForm.controls.newPassword.value!,
      );
    }
  }

  // Check if the password has at least one lowercase letter
  hasOneLowercaseLetter(): boolean {
    return !!this.passwordForm.controls.newPassword.value?.match(/[a-z]/g);
  }

  // Check if the password has at least one uppercase letter
  hasOneUppercaseLetter(): boolean {
    return !!this.passwordForm.controls.newPassword.value?.match(/[A-Z]/g);
  }

  // Check if the password has at least one number
  hasOneNumber(): boolean {
    return !!this.passwordForm.controls.newPassword.value?.match(/[0-9]/g);
  }

  // Check if the password is at least 8 characters long
  isLongEnough(): boolean {
    return this.passwordForm.controls.newPassword.value!.length >= 8;
  }

  // Check if the password and the password confirmation are matching
  isMatching(): boolean {
    return (
      this.passwordForm.controls.newPassword.value ===
        this.passwordForm.controls.newPasswordConfirmation.value &&
      this.passwordForm.controls.newPassword.value != "" &&
      this.passwordForm.controls.newPasswordConfirmation.value != ""
    );
  }
}

/**
 * Enum for routes used for navigation in the frontend.
 */
export enum NavRoutes {
  // basic routes

  TENANT = "tenant",
  DASHBOARD = "dashboard",
  PRODUCTS = "products",
  DRIVER = "driver",
  ORDER = "order",
  USER = "user",
  TOURS = "tours",
  WAREHOUSE = "warehouse",
  MAP = "map",
  CUSTOMERS = "customers",
  DELIVERY_RECEIPT = "delivery-receipt",
  UNANNOUNCED_DELIVERIES = "unannounced-deliveries",
  REFUNDS = "refunds",

  // settings routes
  SETTINGS = "settings",
  PROFILE = "profile",
  ROLES = "roles",
  ROLES_OLD = "roles-old",
  PASSWORD = "password",
  GLOBAL_USERS = "global-users",

  // auth routes
  LOGIN = "login",
  RESET_PASSWORD = "reset-password",

  // error routes
  ERROR = "error",
  ERROR_OUT = "error-out",
}

import { Component, effect, OnDestroy, OnInit } from "@angular/core";
import Warehouse from "../../../models/Warehouse.class";
import { WarehouseService } from "../../../api/warehouse.service";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { AddEditWarehouseComponent } from "./add-edit-warehouse/add-edit-warehouse.component";
import { TablePageComponent } from "../../../interfaces/table-page-component";
import { TableHelperService } from "../../../services/table-helper.service";
import { Permissions } from "../../../other/enums/permissions";
import { PermissionService } from "../../../services/permission.service";
import { MapMarkersService } from "../../map-page/map-helper-services/map-markers.service";
import { MissingWarehousePermissionDialogComponent } from "./missing-warehouse-permission-dialog/missing-warehouse-permission-dialog.component";
import { WarehousePermissionHelperService } from "../../../services/permission-helper-services/warehouse-permission-helper.service";

@Component({
  selector: "app-warehouse-page",
  templateUrl: "./warehouse-page.component.html",
  styleUrls: ["./warehouse-page.component.scss"],
})
export class WarehousePageComponent
  implements OnInit, OnDestroy, TablePageComponent
{
  headers = ["Erstellt am", "Name", "Straße", "Postleitzahl", "Stadt"];
  columns = [
    "createdAt",
    "name",
    "address.street",
    "address.postalCode",
    "address.city",
    "edit",
    "delete",
  ];
  noSortColumns: string[] = [
    "address.street",
    "address.city",
    "address.postalCode",
  ];

  warehouses: Warehouse[];
  showDeleteDialog: boolean = false;
  toDeleteId: null;

  // pagination
  tablePage: number = 0;
  tablePageSize: number = 10;
  protected readonly Permissions = Permissions;

  constructor(
    public warehouseService: WarehouseService,
    private dialogService: TuiDialogHelperService,
    public tableHelperService: TableHelperService,
    public permissionService: PermissionService,
    private mapMarkerService: MapMarkersService,
    private warehousePermissions: WarehousePermissionHelperService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.warehouseService);

    effect(() => {
      this.warehouses = this.warehouseService.warehouses;
    });
  }

  ngOnInit(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.populateTable();
  }

  populateTable() {
    this.warehouseService.getAllWarehouses().subscribe();
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  createWarehouse() {
    if (this.warehousePermissions.hasCreateReadWarehousePermission()) {
      this.dialogService.openDialog(AddEditWarehouseComponent, null);
    } else {
      this.dialogService.openDialog(MissingWarehousePermissionDialogComponent);
    }
  }

  openDeleteDialog($event: any) {
    if (!this.permissionService.deleteWarehousePermission().hasPermission) {
      return;
    }

    this.toDeleteId = $event;
    this.showDeleteDialog = true;
  }

  editWarehouse($event: any) {
    this.dialogService.openDialog(AddEditWarehouseComponent, $event);
  }

  deleteWarehouse() {
    if (!this.toDeleteId) return;

    const findWarehouseToDelete = this.warehouses.find(
      (warehouse) => warehouse.id === this.toDeleteId,
    );

    this.warehouseService.deleteWarehouseById(this.toDeleteId).subscribe(() => {
      this.toDeleteId = null;
      this.showDeleteDialog = false;

      if (!findWarehouseToDelete) return;

      // delete marker from the map if the deletion was successful
      const warehouseToDelete = new Warehouse(findWarehouseToDelete);
      this.mapMarkerService.removeMarker(warehouseToDelete);
    });
  }
}

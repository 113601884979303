<div class="bg-white">
  <div class="sticky">
    <app-edit-button-bar
      (cancelClicked)="onCancel()"
      (deleteClicked)="showDeleteDialog = true"
      (saveClicked)="onSubmit()"
      [isInAddMode]="isAddMode"
      [pageTitlePrefix]="'role.role'"
      [pageTitleSuffix]="isAddMode ? 'role.create' : editRoleName()"
      [saveButtonDisabled]="form.invalid"
      [showDeleteButton]="showDeleteButton"
    >
    </app-edit-button-bar>
  </div>

  <form [formGroup]="form">
    <div class="base-info-wrapper">
      <h2>{{ "role.general-information" | translate }}:</h2>
      <app-input-text
        [hint]="'role.name'"
        [required]="true"
        fieldName="name"
      ></app-input-text>
      <app-input-large-text [hint]="'role.description'" fieldName="description">
      </app-input-large-text>
    </div>

    <div
      *ngIf="form.controls['permissions']"
      [formGroup]="permissionsFormGroup"
      class="permissions"
    >
      <span class="permission-title-wrapper">
        <h2>{{ "role.permissions" | translate }}:</h2>
        <div>
          <button
            (click)="onEnableAllPermissions()"
            *ngIf="!hasAllPermissionsEnabled() && showDeleteButton"
            appearance="flat"
            tuiButton
            type="button"
          >
            {{ "common.select-all" | translate }}
          </button>
          <button
            (click)="onDisableAllPermissions()"
            *ngIf="hasAllPermissionsEnabled() && showDeleteButton"
            appearance="icon"
            tuiButton
            type="button"
          >
            {{ "common.select-none" | translate }}
          </button>
        </div>
      </span>
      <div class="permission-grid">
        <div *ngFor="let section of permissionSections" class="permission-card">
          <span class="permission-card-title">
            <h3>{{ section.description | translate }}</h3>
            <div>
              <button
                (click)="onEnableAllPermissionsForSection(section)"
                *ngIf="!hasAllPermissionsEnabled(section) && showDeleteButton"
                appearance="flat"
                tuiButton
                type="button"
              >
                {{ "common.select-all" | translate }}
              </button>
              <button
                (click)="onDisableAllPermissionsForSection(section)"
                *ngIf="hasAllPermissionsEnabled(section) && showDeleteButton"
                appearance="icon"
                tuiButton
                type="button"
              >
                {{ "common.select-none" | translate }}
              </button>
            </div>
          </span>
          @for (row of section.rows; track row.permission) {
            <span class="row">
              <p>
                {{ row.description | translate }}
              </p>
              <tui-checkbox
                [formControlName]="row.permission"
                (tuiValueChanges)="
                  row.children && row.children.length !== 0
                    ? onParentPermissionChange($event, row.children)
                    : null
                "
                size="l"
              ></tui-checkbox>
            </span>
          }
          <br />
        </div>
      </div>
    </div>
  </form>

  <app-delete-dialog
    (cancelEvent)="showDeleteDialog = false"
    (deleteEvent)="onDelete()"
    [isOpen]="showDeleteDialog"
  />
</div>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { OrderService } from "../../../api/order.service";
import Order, { orderStateTranslations } from "../../../models/Order.class";
import ProductOrder from "../../../models/ProductOrder.class";
import { Location } from "@angular/common";
import { TableSortEvent } from "../../../interfaces/table-sort-event";
import TourPoint from "../../../models/TourPoint.class";
import TourPointLoad from "../../../models/TourPointLoad.class";
import getBadgeStatus from "../../../other/helper-functions/get-badge-status";
import { PermissionService } from "../../../services/permission.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Permissions } from "../../../other/enums/permissions";
import { tuiIconEdit2 } from "@taiga-ui/icons";
import { AddEditOrderComponent } from "../add-edit-order/add-edit-order.component";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { ProductOrderPermissionHelperService } from "../../../services/permission-helper-services/product-order-permission-helper.service";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit {
  order: Order;
  productOrders: ProductOrder[];
  tourPoints: TourPoint[];
  headerColumns = ["Fahrer", "Lieferung am", "Status", "Lieferung"];
  dataColumns = ["fullName", "estimatedArrival", "status", "tourPointProducts"];
  noSortColumns = ["fullName", "tourPointProducts", "status"];
  tablePage: number = 0;
  tablePageSize: number = 10;
  overallTotalAmount: number = 0;
  overallCalculatedAmount: number = 0;
  orderId: string | null;
  tourPointDetailsSidebarOpen: boolean = false;
  protected readonly getBadgeStatus = getBadgeStatus;
  protected readonly Math = Math;
  protected readonly tuiIconEdit2 = tuiIconEdit2;
  private latestBlob: Blob | null = null;
  private selectedTourPoint$ = new BehaviorSubject<TourPoint | undefined>(
    undefined,
  );

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    public location: Location,
    public permissionService: PermissionService,
    private dialogService: TuiDialogHelperService,
    public pOrderPermissionsHelper: ProductOrderPermissionHelperService,
  ) {}

  get missingOrderDetailsTablePermissions(): Permissions[] {
    return [
      ...this.permissionService.readProductOrderPermission().missingPermissions,
      ...this.permissionService.readTourPointPermission().missingPermissions,
    ];
  }

  get orderStateString(): string {
    if (this.order == null) return "order.state-draft";
    return (
      orderStateTranslations.get(this.order.orderState) ?? "order.state-draft"
    );
  }

  get overallPercentage(): string {
    return (
      (this.overallCalculatedAmount / this.overallTotalAmount) *
      100
    ).toFixed(1);
  }

  get selectedTourPoint(): Observable<TourPoint | undefined> {
    return this.selectedTourPoint$.asObservable();
  }

  ngOnInit(): void {
    this.getOrderByUrlId();
  }

  getOrderByUrlId() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("id")) {
        const orderId = paramMap.get("id");
        this.orderId = orderId;
        if (orderId) this.getOrderById(orderId);
      }
    });
  }

  getOrderById(orderId: string) {
    this.orderService.getOrderById(orderId).subscribe((order: Order) => {
      this.order = order;
      this.productOrders = order?.productOrders;
      this.attachProductOrderToTourPointLoads(this.productOrders);
      let tourPointLoadsForOrder: TourPointLoad[] = [];

      this.productOrders.forEach((pOrder) => {
        // Concatenate array of tourPointLoads for each productOrder.
        tourPointLoadsForOrder = [
          ...tourPointLoadsForOrder,
          ...pOrder.tourPointLoads,
        ];
      });
      this.tourPoints = this.regroupTourPointLoadsOnTourPoint(
        tourPointLoadsForOrder,
      );
      this.tourPoints = this.sortTourPointsByEstimatedArrival(
        this.tourPoints,
        1,
      );
      this.calculateAmounts();
    });
  }

  // todo: check if still needed or can be removed and replaced by directly using the tourPoint endpoint (if pagination bug is fixed)
  regroupTourPointLoadsOnTourPoint(
    tourPointLoads: TourPointLoad[],
  ): TourPoint[] {
    // Utility function
    function getOrSet<K, V>(map: Map<K, V>, key: K, value: V): V {
      const val = map.get(key);
      if (val !== undefined) {
        return val;
      }
      map.set(key, value);
      return value;
    }

    // Regroup tourPointLoads on tourPoint
    const tpMap = new Map<string, TourPoint>();
    tourPointLoads.forEach((tpl) => {
      const tourPoint: TourPoint = getOrSet(tpMap, tpl.tourPointId, {
        ...tpl.tourPoint,
        tourPointLoads: [],
        product: tpl.product, // Directly add product to tourPoint
      });

      tourPoint.tourPointLoads.push(tpl);
      // @ts-ignore
      delete tpl.tourPoint;
    });
    return Array.from(tpMap.values());
  }

  sortTourPointsByEstimatedArrival(
    tourPoints: TourPoint[],
    direction: number,
  ): TourPoint[] {
    if (direction > 0) {
      return tourPoints.sort((a, b) => {
        if (!a.estimatedArrival || !b.estimatedArrival) return 0;
        return (
          new Date(a.estimatedArrival).getTime() -
          new Date(b.estimatedArrival).getTime()
        );
      });
    } else {
      return tourPoints.sort((a, b) => {
        if (!a.estimatedArrival || !b.estimatedArrival) return 0;
        return (
          new Date(b.estimatedArrival).getTime() -
          new Date(a.estimatedArrival).getTime()
        );
      });
    }
  }

  calculateAmounts() {
    if (!this.order.productOrders) return;

    this.order.productOrders = this.order.productOrders.map((pOrder) => {
      // Return if there are no tourPointLoads
      if (pOrder.tourPointLoads.length <= 0) {
        pOrder.calculatedAmount = 0;
        return pOrder;
      }

      if (pOrder.calculatedAmount === undefined) pOrder.calculatedAmount = 0;

      pOrder.tourPointLoads.forEach((tpl) => {
        // add amounts to calculatedAmount
        pOrder.calculatedAmount += Math.abs(tpl.amount);

        // subtract refunds from calculatedAmount
        const tourPointWithRefunds =
          this.tourPoints.find((tp) => tp.id === tpl.tourPointId)
            ?.tourPointLoads || [];
        if (tourPointWithRefunds && tourPointWithRefunds.length > 0) {
          const refunds =
            tourPointWithRefunds.find(
              (tourPointLoad) => tpl.id === tourPointLoad.id,
            )?.refunds || [];

          if (refunds.length > 0) {
            refunds.forEach((refund) => {
              pOrder.calculatedAmount -= Math.abs(refund.amount);
            });
          }
        }
      });

      return pOrder;
    });

    this.overallTotalAmount = Math.abs(
      this.order.productOrders.reduce(
        (acc, cur) => acc + Math.abs(cur.amount),
        0,
      ),
    );
    this.overallCalculatedAmount = Math.abs(
      this.order.productOrders.reduce(
        (acc, cur) => acc + Math.abs(cur.calculatedAmount),
        0,
      ),
    );
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    if (tableSort.sortColumn === "estimatedArrival") {
      this.tourPoints = this.sortTourPointsByEstimatedArrival(
        this.tourPoints,
        tableSort.sortDirection,
      );
    }
  }

  getPdfByOrderId() {
    if (!this.orderId) return;
    this.orderService.getPdfByOrderId(this.orderId).subscribe((res) => {
      // Check if the response is a blob
      if (res instanceof Blob) {
        this.latestBlob = new Blob([res], { type: "application/pdf" });
        const url = window.URL.createObjectURL(this.latestBlob);
        window.open(url, "_blank");
        window.URL.revokeObjectURL(url);
      }
    });
  }

  attachProductOrderToTourPointLoads(productOrders: ProductOrder[]) {
    productOrders.forEach((pOrder) => {
      if (pOrder.tourPointLoads) {
        pOrder.tourPointLoads.forEach((tpl) => {
          // attach current productOrder to tourPointLoad
          tpl.productOrder = pOrder;
        });
      }
    });
  }

  openTourPointDetailsSidebar(tourPointId: string) {
    this.selectedTourPoint$.next(
      this.tourPoints.find((tp) => tp.id === tourPointId),
    );
    this.tourPointDetailsSidebarOpen = true;
  }

  getTotalInvoiceAmountFromProductOrder(productOrder: ProductOrder[]) {
    let invoiceAmount = 0;
    productOrder.forEach((product) => {
      invoiceAmount +=
        Math.abs(product.pricePerProduct) * Math.abs(product.amount);
    });
    return invoiceAmount;
  }

  editOrder() {
    this.dialogService.openDialog(AddEditOrderComponent, this.orderId, () => {
      if (!this.orderId) return;
      this.getOrderById(this.orderId);
    });
  }
}

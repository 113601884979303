import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../../api/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})

/**
 * This component is used to send a reset password mail to the user.
 * It is used in the login page and is opened as a pop-up modal.
 */
export class ForgotPasswordComponent {
  @Input() isOpen: boolean = true;
  @Output() closingEvent = new EventEmitter<boolean>();
  readonly forgotPasswordForm = new FormGroup({
    emailValue: new FormControl("", [Validators.required, Validators.email]),
  });
  dialogTitle!: string;
  errorEmailUnknown!: string;
  isSent: boolean = false; // true if the reset password mail was sent successfully

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    // Subscribes to the translations for the push message
    this.translate
      .get(["forgot-pw.title", "forgot-pw.validation-failed"])
      .subscribe((translations) => {
        this.errorEmailUnknown = translations["forgot-pw.validation-failed"];
        this.dialogTitle = translations["forgot-pw.title"];
      });
  }

  /**
   * Sends a request to the api to send a reset password mail to the given email.
   * If the mail was sent successfully, the isSent flag is set to true and the DOM changes
   * If the mail was not sent successfully, the email input field is marked as invalid
   */
  submitPasswordReset() {
    if (this.forgotPasswordForm.valid) {
      this.authService
        .sendResetPasswordMail(this.forgotPasswordForm.value.emailValue!)
        .subscribe((success) => {
          if (success) {
            this.isSent = true;
          } else {
            this.forgotPasswordForm.controls.emailValue.setErrors({
              invalid: this.errorEmailUnknown,
            });
          }
        });
    }
  }

  // Closes the dialog by emitting an event to the parent component (login)
  closeDialog() {
    this.closingEvent.emit(true);
  }
}

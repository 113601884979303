<div class="permission-wrapper" style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um Mitarbeiter
    {{ isAddmode ? "erstellen" : "aktualisieren" }} zu können:
  </h3>

  <ul>
    <li *ngIf="!userPermissions.hasCreateUserPermission && isAddmode">
      <b>Mitarbeiter erstellen</b>
    </li>

    <li *ngIf="!userPermissions.hasUpdateUserPermission && !isAddmode">
      <b>Mitarbeiter aktualisieren</b>
    </li>

    <li *ngIf="!userPermissions.hasReadUserPermission">
      <b>Mitarbeiter ansehen</b>
    </li>

    <!-- ROLE -->

    <li *ngIf="!rolePermissions.hasReadRolePermission">
      <b>Rollen ansehen</b>
    </li>
  </ul>
</div>

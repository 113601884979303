<div class="permission-wrapper" style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um Touren
    {{ isAddmode ? "erstellen" : "aktualisieren" }}
    zu können:
  </h3>

  <ul>
    <li *ngIf="!tourPermissions.hasCreateTourPermission && isAddmode">
      <b>Tour erstellen</b>
    </li>

    <li *ngIf="!tourPermissions.hasUpdateTourPermission && !isAddmode">
      <b>Tour aktualisieren</b>
    </li>

    <li *ngIf="!tourPermissions.hasReadTourPermission">
      <b>Tour ansehen</b>
    </li>

    <!-- USER -->

    <li *ngIf="!userPermissions.hasReadUserPermission">
      <b>Mitarbeiter ansehen</b>
    </li>

    <!--  <li *ngIf="!userPermissions.hasUpdateUserPermission">-->
    <!--    <b>User aktualisieren</b>-->
    <!--  </li>-->
  </ul>
</div>

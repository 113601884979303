import { BaseModel } from "./BaseModel";

export default class Address extends BaseModel<Address> {
  tenantId: string;
  street: string;
  houseNo: string;
  postalCode: string;
  city: string;
  longitude: number;
  latitude: number;

  constructor(params: Partial<Address>) {
    super(params);
    Object.assign(this, params);
  }
}

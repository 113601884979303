import { Component, inject, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import Warehouse from "../../../../models/Warehouse.class";
import { WarehouseService } from "../../../../api/warehouse.service";
import Address from "../../../../models/Address.class";
import { Mode } from "../../../../other/enums/mode";
import { BaseDialogComponent } from "../../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../../services/tui-dialog-helper.service";
import { PushService } from "../../../../services/push.service";
import { pushTypes } from "../../../../other/enums/push-types";
import { WarehousePermissionHelperService } from "../../../../services/permission-helper-services/warehouse-permission-helper.service";

@Component({
  selector: "app-add-edit-warehouse",
  templateUrl: "./add-edit-warehouse.component.html",
  styleUrls: ["./add-edit-warehouse.component.scss"],
})
export class AddEditWarehouseComponent
  extends BaseDialogComponent
  implements OnInit
{
  form: FormGroup;
  warehousePermissions = inject(WarehousePermissionHelperService);

  toUpdateId: string;
  mode: string = Mode.ADD;

  constructor(
    private fb: FormBuilder,
    private warehouseService: WarehouseService,
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<any>,
    protected override dialogService: TuiDialogHelperService,
    private pushService: PushService,
  ) {
    super(context, dialogService);
  }

  get isAddMode() {
    return this.mode === Mode.ADD;
  }

  ngOnInit(): void {
    // access url to check if the user wants to add or edit a customer
    this.getMode();

    this.form = this.fb.group({
      name: ["", Validators.required],

      // address values + validation
      street: ["", [Validators.required]],
      houseNo: ["", [Validators.required]],
      postalCode: [
        "",
        [Validators.required, Validators.maxLength(5), Validators.minLength(5)],
      ],
      city: ["", [Validators.required]],
    });

    // load product data into the form in edit mode
    if (this.isAddMode || !this.toUpdateId) return;
    this.initCaseEdit(this.toUpdateId);
  }

  initCaseEdit(id: string) {
    this.warehouseService.getWarehouseById(id).subscribe((res: Warehouse) => {
      this.form.controls["name"].setValue(res.name);
      this.form.controls["street"].setValue(res.address.street);
      this.form.controls["houseNo"].setValue(res.address.houseNo);
      this.form.controls["postalCode"].setValue(res.address.postalCode);
      this.form.controls["city"].setValue(res.address.city);
    });
  }

  getMode() {
    if (this.context.data) {
      this.mode = Mode.EDIT;
      this.toUpdateId = this.context.data;
    } else {
      this.mode = Mode.ADD;
    }
  }

  submit() {
    if (this.form.invalid) return;

    const address: Address = new Address({
      street: this.form.get("street")?.value,
      houseNo: this.form.get("houseNo")?.value,
      postalCode: this.form.get("postalCode")?.value,
      city: this.form.get("city")?.value,
    });

    const warehouse = new Warehouse({
      name: this.form.get("name")?.value,
      address: address,
    });

    const addressGeoString = `${address.street} ${address.houseNo}, ${address.postalCode} ${address.city}`;

    // Geocode address
    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ address: addressGeoString }, (results, status) => {
        if (status === "OK") {
          if (results) {
            const location = results[0].geometry.location;
            address.latitude = location.lat();
            address.longitude = location.lng();

            // adding warehouse (post)
            if (this.isAddMode) {
              this.addWarehouse(warehouse);
              // updating warehouse (patch)
            } else if (!this.isAddMode) {
              this.updateWarehouse(warehouse);
            }
          }
        } else {
          console.error(
            "Geocoding was not successful for the following reason:",
            status,
          );

          this.pushService.sendPush(
            pushTypes.ERROR,
            "",
            "Die Adresse konnte nicht gefunden werden. Bitte prüfe die Richtigkeit der Adresse!",
          );
        }
      })
      .then();
  }

  addWarehouse(warehouse: Warehouse) {
    this.warehouseService
      .addWarehouse(warehouse)
      .subscribe(() => this.cancel());
  }

  updateWarehouse(warehouse: Warehouse) {
    if (!this.toUpdateId) return;

    this.warehouseService
      .updateWarehouseById(this.toUpdateId, warehouse)
      .subscribe(() => this.cancel());
  }

  cancel() {
    this.closeDialog();
  }
}

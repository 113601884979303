import { Component, effect, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Role } from "../../../models/Role";
import { TourService } from "../../../api/tour.service";
import Tour, { TOUR_STATE } from "../../../models/Tour.class";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { TuiDialogContext } from "@taiga-ui/core";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { TuiDay, TuiTime } from "@taiga-ui/cdk";
import { tuiCreateTimePeriods } from "@taiga-ui/kit";
import { UserService } from "../../../api/user.service";
import { User, ViewAccess } from "../../../models/User";
import { TranslateService } from "@ngx-translate/core";
import { TourPermissionHelperService } from "../../../services/permission-helper-services/tour-permission-helper.service";
import { UserPermissionHelperService } from "../../../services/permission-helper-services/user-permission-helper.service";

type extendedDropdown = {
  id: string;
  label: string;
  truckNumber: string | undefined;
  inviteAcceptedAt: string | null;
};

@Component({
  selector: "app-add-edit-tour",
  templateUrl: "./add-edit-tour.component.html",
  styleUrls: ["./add-edit-tour.component.scss"],
})
export class AddEditTourComponent implements OnInit {
  form: FormGroup;
  roles: Role[];
  drivers: extendedDropdown[];
  tourToEdit: Tour;

  statusDropdownEdit: DropDownItem[] = [
    { id: TOUR_STATE.DRAFT, label: "Entwurf" },
    { id: TOUR_STATE.READY, label: "Geplant" },
    { id: TOUR_STATE.CANCELLED, label: "Abgebrochen" },
    { id: TOUR_STATE.FINISHED, label: "Abgeschlossen" },
  ];

  statusDropdownAdd: DropDownItem[] = [
    { id: TOUR_STATE.DRAFT, label: "Entwurf" },
    { id: TOUR_STATE.READY, label: "Geplant" },
  ];

  tuiTimeValues = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);

  constructor(
    private fb: FormBuilder,
    private tourService: TourService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean>,
    private tuiHelper: TuiDialogHelperService,
    private userService: UserService,
    private translate: TranslateService,
    private tourPermission: TourPermissionHelperService,
    private userPermissions: UserPermissionHelperService,
  ) {
    effect(() => {
      this.getDriver();
    });
  }

  // returns true if there is no data provided > ergo the user want to add data, else he wants to edit data
  get isAddMode() {
    return !this.context.data;
  }

  getDriver() {
    this.userService.viewAccess = ViewAccess.DRIVER;
    this.userService.getUsers().subscribe((res) => {
      this.drivers = res.map((user) => {
        return {
          id: user.id,
          label: `${user.firstName} ${user.lastName}`,
          truckNumber: user.truckNumber,
          inviteAcceptedAt: user.inviteAcceptedAt,
        };
      });
    });
  }

  ngOnInit(): void {
    // access url to check if the user wants to add or edit a tour
    this.initForm();

    if (this.context.data) this.tourToEdit = this.context.data;

    // load tour data into the form in edit mode
    if (this.isAddMode) return;
    this.initCaseEdit(this.tourToEdit.id);
  }

  close() {
    this.tuiHelper.close(this.context);
  }

  initForm() {
    const today = new Date();
    const tuiDayToday = TuiDay.fromLocalNativeDate(today);

    this.form = this.fb.group({
      date: [tuiDayToday, Validators.required],
      startingTime: [new TuiTime(7, 0), Validators.required],
      driverId: ["", Validators.required],
      truckNumber: "",
      tourState: [TOUR_STATE.DRAFT, Validators.required],
    });
  }

  initCaseEdit(id: string) {
    this.tourService.getTourById(id).subscribe((tour: Tour) => {
      const driverName = tour.driver.firstName + " " + tour.driver.lastName;

      this.form = this.fb.group({
        date: [this.convertIsoDateToTuiDay(tour.date), Validators.required],
        startingTime: [this.getTuiTime(tour.date), Validators.required],
        truckNumber: tour.truckNumber,
        driverId: [
          { id: tour.driverId, label: driverName },
          Validators.required,
        ],
        tourState: [
          { id: tour.tourState, label: this.translate.instant(tour.tourState) },
          Validators.required,
        ],
      });
    });
  }

  convertIsoDateToTuiDay(isoDateStr: string) {
    const date = new Date(isoDateStr);

    return TuiDay.fromLocalNativeDate(date);
  }

  getTuiTime(inputDateString: string): TuiTime {
    const date = new Date(inputDateString);

    return new TuiTime(date.getHours(), date.getMinutes());
  }

  submit() {
    if (this.form.invalid) return;

    if (this.isAddMode) {
      // add tour
      this.addTour();
    } else if (!this.isAddMode && this.tourToEdit.id) {
      // update tour
      this.updateTour(this.tourToEdit.id);
    }
  }

  createTourFromForm(): Tour {
    const date: TuiDay = this.form.get("date")?.value;
    const datetime = date.toUtcNativeDate();

    // append the picked starting time to the picked date
    const time = this.form.get("startingTime")?.value;

    datetime.setHours(time.hours, time.minutes);

    const formattedDate = datetime.toISOString();

    return new Tour({
      date: formattedDate,
      driverId: this.form.get("driverId")?.value.id,
      truckNumber: this.form.get("truckNumber")?.value,
      tourState: this.form.get("tourState")?.value.id,
    });
  }

  addTour() {
    this.tourService.addTour(this.createTourFromForm()).subscribe((tour) => {
      this.updateDriverTruckNumber(tour);
      this.close();
    });
  }

  updateTour(id: string) {
    this.tourService
      .updateTourById(id, this.createTourFromForm())
      .subscribe((tour) => {
        this.updateDriverTruckNumber(tour);
        this.close();
      });
  }

  updateDriverTruckNumber(tour: Tour) {
    const selectedDriver = this.drivers.find((driver) => {
      return driver.id === tour.driverId;
    });

    const inviteAccepted = selectedDriver?.inviteAcceptedAt !== null;
    const sameTruckNumber = selectedDriver?.truckNumber === tour.truckNumber;

    if (
      this.userPermissions.hasUpdateUserPermission &&
      tour.truckNumber &&
      !sameTruckNumber &&
      inviteAccepted
    ) {
      this.userService
        .updateUser(new User({ truckNumber: tour.truckNumber }), tour.driverId)
        .subscribe();
    }
  }

  changeDriver(driver: User) {
    this.form.controls["truckNumber"].setValue(driver.truckNumber);
  }
}

import { Injectable } from "@angular/core";
import { TableFilterEvent } from "../interfaces/table-filter-event";
import { PaginationEvent } from "../interfaces/pagination-event";
import { TableSortEvent } from "../interfaces/table-sort-event";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
/**
 * abstract service class to be extended by services that need pagination and filtering
 * used mainly to set variables for the pagination and filtering that are used for the api calls
 */
export abstract class PaginationFilterService {
  private _startIndex: number | undefined = undefined;
  private _endIndex: number | undefined = undefined;
  private _totalAmount: number = 0;
  private _filter: TableFilterEvent = { searchTerm: "", tabIndex: 0 };
  private _sort?: string;
  private _search?: string;

  protected constructor() {}

  public setPaginationIndices(paginatorIndex: PaginationEvent) {
    this._startIndex = paginatorIndex.start;
    this._endIndex = paginatorIndex.limit;
  }

  public deletePaginationIndices() {
    this._startIndex = undefined;
    this._endIndex = undefined;
  }

  get startIndex(): string {
    if (this._startIndex !== undefined) {
      return this._startIndex.toString();
    }
    return "";
  }

  get endIndex(): string {
    if (this._endIndex !== undefined) {
      return this._endIndex.toString();
    }
    return "";
  }

  get totalAmount(): number {
    return this._totalAmount;
  }

  set totalAmount(value: number) {
    this._totalAmount = value;
  }

  public set filter(filterEvent: TableFilterEvent) {
    this._filter = filterEvent;
  }

  public get filter(): TableFilterEvent {
    return this._filter;
  }

  public setSorting(sortingEvent: TableSortEvent) {
    if (sortingEvent.sortDirection === 1) {
      this._sort = `${sortingEvent.sortColumn}+ASC`;
    } else {
      this._sort = `${sortingEvent.sortColumn}+DESC`;
    }
  }

  public getAmount(): number {
    return this.totalAmount;
  }

  set search(searchValue: string) {
    this._search = searchValue;
  }

  get search(): string {
    return this._search ?? "";
  }

  setPaginationSortingParams(params: HttpParams): HttpParams {
    return params
      .set(this._sort !== undefined ? "sort" : "", this._sort ?? "")
      .set(this.endIndex !== "" ? "limit" : "", this.endIndex)
      .set(this.startIndex !== "" ? "skip" : "", this.startIndex)
      .set(this.search !== "" ? "search" : "", this.search);
  }
}

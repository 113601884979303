import { Component, inject, Input } from "@angular/core";
import { NgIf } from "@angular/common";
import { WarehousePermissionHelperService } from "../../../../services/permission-helper-services/warehouse-permission-helper.service";

@Component({
  selector: "app-missing-warehouse-permission-dialog",
  standalone: true,
  imports: [NgIf],
  templateUrl: "./missing-warehouse-permission-dialog.component.html",
  styleUrl: "./missing-warehouse-permission-dialog.component.scss",
})
export class MissingWarehousePermissionDialogComponent {
  warehousePermissions = inject(WarehousePermissionHelperService);
  @Input({ required: true }) isAddmode: boolean = true;
}

import { BaseModel } from "./BaseModel";
import Address from "./Address.class";
import Customer from "./Customer.class";
import ProductOrder from "./ProductOrder.class";
import Order from "./Order.class";

// called LocationC because Angular has a Location itself which may cause problems if both have the same name
export default class LocationC extends BaseModel<LocationC> {
  tenantId: string;
  customerId: string;
  customer: Customer;
  address: Address;
  name?: string;
  productOrders: ProductOrder[];
  note: string;
  sideMounting: boolean;
  orders: Order[];

  constructor(params: Partial<LocationC>) {
    super(params);
    Object.assign(this, params);
  }

  get fullAddress() {
    return `${this.address.street} ${this.address.houseNo}, ${this.address.postalCode} ${this.address.city}`;
  }
}

<div *ngIf="order" class="container">
  <div class="detail-page-header">
    <div class="flex-align-center">
      <tui-svg
        (click)="this.location.back()"
        src="tuiIconChevronLeftLarge"
      ></tui-svg>
      <!--    tuiIconChevronLeftLarge -->
      <h1 style="padding-left: 1rem">
        {{ "order.details-title" | translate }} {{ order.orderNumber }}
      </h1>
    </div>

    <div class="flex-row" style="gap: 1.5rem">
      <!-- edit button -->
      @if (permissionService.updateOrderPermission().hasPermission) {
        <app-transparent-btn
          (btnClickEvent)="editOrder()"
          [i18StringText]="'order.edit-title'"
          [iconValue]="tuiIconEdit2"
        />
      }
      <!-- pdf -->
      <div (click)="getPdfByOrderId()" class="flex-align-center pdf pointer">
        <h2 style="margin: 0">
          {{ "order.confirmation" | translate }}
        </h2>
        <div class="file-container">
          <div class="circle">
            <tui-svg src="tuiIconExternalLink"></tui-svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="parent-container">
    <!-- left container (infos) -->
    <div class="left-container">
      <app-half-transparent-card
        [fontSize]="'1.4rem'"
        [title]="order.location.customer.name"
      >
        <ng-container *ngIf="order.location">
          <div class="icon-text-row">
            <tui-svg src="tuiIconMapPinLarge"></tui-svg>
            <span>{{ order.location.name }}</span>
          </div>
          <div class="icon-text-row">
            <tui-svg src="tuiIconHomeLarge"></tui-svg>
            <span>{{
              order.location.address.street +
                " " +
                order.location.address.houseNo +
                ", " +
                order.location.address.postalCode +
                " " +
                order.location.address.city
            }}</span>
          </div>
          <div class="icon-text-row">
            <tui-svg src="tuiIconCalendarLarge"></tui-svg>
            <span
              >{{ order.timeframeBegin | date: "dd.MM.yyyy" : "UTC" }} -
              {{ order.timeframeEnd | date: "dd.MM.yyyy" : "UTC" }}</span
            >
          </div>
          @if (permissionService.readProductOrderPermission().hasPermission) {
            <div class="icon-text-row">
              <img
                alt="euro-currency-icon"
                src="assets/svg/currency-eur.svg"
                width="25"
              />
              <span>{{
                getTotalInvoiceAmountFromProductOrder(order.productOrders)
                  | currency: "EUR" : "symbol-narrow" : "1.2-2" : "de-DE"
              }}</span>
            </div>
          }
        </ng-container>
      </app-half-transparent-card>

      <!--    title and loop of productOrders    -->
      <div class="flex-align-center productOrders">
        <h3 style="margin: 0">
          {{ "order.product-order" | translate }}
        </h3>

        @if (!pOrderPermissionsHelper.hasReadProductOrderPermission) {
          <app-tooltip
            [iconColorWhite]="true"
            [permissions]="pOrderPermissionsHelper.readPermissionTooltip()"
            elementName="Produktbestellungen"
          />
        }
      </div>

      <div
        *ngIf="
          productOrders &&
          productOrders.length > 0 &&
          pOrderPermissionsHelper.hasReadProductOrderPermission
        "
        class="loop-container"
      >
        <app-product-order-card
          *ngFor="let productOrder of productOrders"
          [productOrder]="productOrder"
          [product]="productOrder.product"
        />
      </div>
    </div>

    <!-- right container (table) -->
    <div class="right-container">
      <tui-island class="order-card">
        <div class="top">
          <div
            style="display: flex; align-items: center; gap: 1rem"
            tuiMode="onDark"
          >
            <h1>{{ "order.tracking" | translate }}</h1>
            <tui-badge
              *ngIf="order"
              [status]="getBadgeStatus(order.orderState)"
              [value]="orderStateString | translate"
              size="m"
              style="color: black"
            ></tui-badge>
          </div>
          <label
            *ngIf="this.overallTotalAmount"
            [title]="'des Auftrages ist bereits geplant'"
            tuiProgressLabel
          >
            <span style="color: black">
              <b>{{ overallPercentage }}% </b></span
            >
            <tui-progress-circle
              [max]="overallTotalAmount"
              [value]="overallCalculatedAmount"
              size="l"
            ></tui-progress-circle>
          </label>
        </div>

        @if (
          permissionService.readTourPointPermission().hasPermission &&
          permissionService.readProductOrderPermission().hasPermission
        ) {
          <app-table
            (rowClickEvent)="openTourPointDetailsSidebar($event)"
            (sortEvent)="sortTable($event)"
            [cellTemplatesMap]="{
              tourPointProducts: tourPointProducts,
              status: status
            }"
            [columns]="dataColumns"
            [itemsDeletable]="true"
            [itemsEditable]="true"
            [showPagination]="false"
            [tableColumnNames]="headerColumns"
            [tableData]="tourPoints"
            [noSortColumns]="noSortColumns"
          >
            <ng-template #status let-object="object">
              @if (object.signedByWorker || object.signedByCustomer) {
                <tui-badge
                  status="success"
                  [value]="'tour.state-delivered' | translate"
                  style="color: black"
                ></tui-badge>
              } @else {
                <tui-badge
                  status="neutral"
                  [value]="'tour.state-ready' | translate"
                  style="color: black"
                ></tui-badge>
              }
            </ng-template>
            <ng-template #tourPointProducts let-object="object">
              <!-- special case tourPointProducts -->
              @for (
                tourPointLoad of object.tourPointLoads;
                track tourPointLoad.id
              ) {
                @if (tourPointLoad.amount != null) {
                  <div class="tour-point-products-row">
                    <!-- name and quality  -->
                    <div
                      class="flex-align-center"
                      style="
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding-bottom: 0;
                      "
                    >
                      <span>
                        {{
                          tourPointLoad?.product?.name ??
                            "order.unknown-product" | translate
                        }}
                        {{ " (" + (tourPointLoad?.quality | translate) + ")" }}
                      </span>
                    </div>

                    <!-- amount, icon delivery type -->
                    <div
                      class="flex-align-center"
                      style="flex-direction: column; padding-bottom: 0"
                    >
                      <div class="tour-point-amount-grid">
                        @if (tourPointLoad?.amount !== 0) {
                          <span style="text-align: right">
                            <b> {{ Math.abs(tourPointLoad?.amount) }}</b>
                          </span>
                          <span style="text-align: right">
                            @if (tourPointLoad?.amount < 0) {
                              {{ "order.unload" | translate | lowercase }}
                            } @else {
                              {{ "order.charge" | translate | lowercase }}
                            }
                          </span>
                          <div style="display: flex; justify-content: center">
                            <img
                              [ngSrc]="
                                tourPointLoad?.amount < 0
                                  ? 'assets/svg/arrow-up.svg'
                                  : 'assets/svg/arrow-down.svg'
                              "
                              alt="arrow"
                              class="arrow-svg"
                              height="19"
                              width="19"
                            />
                          </div>
                          <div class="price-column-wrapper border-left">
                            {{
                              Math.abs(tourPointLoad?.amount) *
                                tourPointLoad?.productOrder?.pricePerProduct
                                | currency: "EUR"
                            }}
                          </div>
                        } @else {
                          <span style="text-align: right">
                            <b>0</b>
                          </span>
                        }
                      </div>

                      <!-- new inner row for refunds -->
                      @if (
                        tourPointLoad.refunds &&
                        tourPointLoad.refunds.length > 0
                      ) {
                        @for (
                          refund of tourPointLoad.refunds;
                          track refund.id
                        ) {
                          <div
                            class="tour-point-amount-grid"
                            style="margin-top: 1rem"
                          >
                            <span style="text-align: right"
                              ><b> {{ -Math.abs(refund.amount) }}</b></span
                            >
                            <span style="text-align: right">
                              {{ "order.refund" | translate }}
                            </span>
                            <div style="display: flex; justify-content: center">
                              <tui-svg
                                src="tuiIconAlertOctagon"
                                style="
                                  color: var(--tui-warn-bg);
                                  font-size: 20px;
                                "
                              ></tui-svg>
                            </div>
                            <div class="price-column-wrapper border-left">
                              {{
                                -Math.abs(refund?.amount) *
                                  tourPointLoad.productOrder?.pricePerProduct
                                  | currency: "EUR"
                              }}
                            </div>
                          </div>
                        }
                      }
                    </div>
                  </div>
                }
              }
            </ng-template>
          </app-table>
        } @else {
          <app-no-read-permission
            [missingPermissions]="missingOrderDetailsTablePermissions"
            [centerContent]="true"
          ></app-no-read-permission>
        }
      </tui-island>
    </div>
  </div>
</div>

<!-- Sidebar containing TourPoint details  -->
<app-tour-point-details-sidebar
  (closeEvent)="tourPointDetailsSidebarOpen = false"
  [isOpen]="tourPointDetailsSidebarOpen"
  [selectedTourPoint$]="selectedTourPoint"
>
</app-tour-point-details-sidebar>

/**
 * Class for creating menu items instances.
 * @param tooltip The tooltip text of the menu item.
 * @param icon The icon of the menu item.
 * @param link The link of the menu item.
 */
export class MenuItem {
  tooltip!: string;
  icon!: string;
  link!: string;

  constructor(tooltip: string, icon: string, link: string) {
    this.tooltip = tooltip;
    this.icon = icon;
    this.link = link;
  }
}

/**
 * Enum for all menu items in order to switch between them in the sidenav.
 */
export enum MenuItems {
  DASHBOARD = "DASHBOARD",
  ORDER = "ORDER",
  REFUND = "REFUND",
  TOURS = "TOURS",
  UNANNOUNCED_DELIVERIES = "UNANNOUNCED_DELIVERIES",
  DELIVERY_RECEIPT = "DELIVERY_RECEIPT",
  CUSTOMERS = "CUSTOMERS",
  USER = "USER",
  SETTINGS = "SETTINGS",
}

import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { GoogleMapsScriptLoaderService } from "./google-maps-script-loader.service";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsScriptResolverService implements Resolve<Promise<void>> {
  resolve(): Promise<void> {
    return GoogleMapsScriptLoaderService.load();
  }
}

import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import Refund from "../../models/Refund.class";
import { RefundService } from "../../api/refund.service";
import { NavRoutes } from "../../other/enums/nav-routes";
import { Router } from "@angular/router";
import { TableHelperService } from "../../services/table-helper.service";
import { TablePageComponent } from "../../interfaces/table-page-component";
import { PermissionService } from "../../services/permission.service";
import Order from "../../models/Order.class";
import { pushTypes } from "../../other/enums/push-types";
import { PushService } from "../../services/push.service";
import { TuiDay } from "@taiga-ui/cdk";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import { MissingRefundsPermissionsDialogComponent } from "./missing-refunds-permissions-dialog/missing-refunds-permissions-dialog.component";
import { RefundsPermissionHelperService } from "../../services/permission-helper-services/refunds-permission-helper.service";

@Component({
  selector: "app-refunds-page",
  templateUrl: "./refunds-page.component.html",
  styleUrl: "./refunds-page.component.scss",
})
export class RefundsPageComponent
  implements OnInit, TablePageComponent, OnDestroy
{
  refundPermissionHelper = inject(RefundsPermissionHelperService);
  refunds: Refund[];
  total: number;
  headers = [
    "Erstellt am",
    "Erstellt von",
    "Auftragsnummer",
    "Fahrer",
    "Anzahl",
    "Produkt",
  ];
  columns = [
    "createdAt",
    "createdFrom",
    "tourPointLoad.productOrder.order.orderNumber",
    "driver-fullname",
    "amount",
    "tourPointLoad.product.name",
  ];
  noSortColumns: string[] = [
    "tourPointLoad.productOrder.order.orderNumber",
    "createdFrom",
    "driver-fullname",
    "amount",
    "tourPointLoad.product.name",
  ];
  tableHelperService = inject(TableHelperService);
  tablePage: number = 0;
  tablePageSize: number = 10;
  public permissionService = inject(PermissionService);
  protected readonly Math = Math;
  private dateChangeTriggered: boolean = false;
  private refundService = inject(RefundService);
  private router = inject(Router);
  private selectedCreatedAtDate: string | undefined;

  constructor(
    private push: PushService,
    private dialogService: TuiDialogHelperService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.refundService);
  }

  ngOnInit(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.sorting();
    this.refundService.setPaginationIndices({ start: 0, limit: 10 });
    this.populateTable();
    this.dateChangeTriggered = true;
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  routeToOrder($event: string) {
    const refund = this.refunds.find((r) => r.id === $event);

    if (!refund?.tourPointLoad?.productOrder?.orderId) {
      this.push.sendPush(
        pushTypes.INFO,
        "",
        "Kein Auftrag zur Reklamation gefunden",
      );
      return;
    }

    this.router
      .navigate([`${NavRoutes.ORDER}/order-detail/${refund?.orderId}`])
      .then();
  }

  getDriverNameWithRefundId(refundId: string): string | undefined {
    const refund = this.refunds.find((r) => r.id === refundId);

    if (!refund?.tourPointLoad?.tourPoint?.tour?.driver) {
      return "-";
    }
    return (
      refund?.tourPointLoad?.tourPoint?.tour?.driver.firstName +
      " " +
      refund?.tourPointLoad?.tourPoint?.tour?.driver.lastName
    );
  }

  populateTable(): void {
    const queryParams: {
      createdAt?: string;
    } = {};

    if (this.selectedCreatedAtDate) {
      queryParams.createdAt = this.selectedCreatedAtDate;
    }

    this.refundService.getAllRefunds(queryParams).subscribe((refunds) => {
      this.refunds = refunds.records;
      this.total = refunds.total;
    });
  }

  search(searchInput: string) {
    this.tableHelperService.resetTablePagination();
    this.refundService.search = searchInput;
    this.populateTable();
  }

  getOrderId(object: Refund): string | undefined {
    const refund = new Refund(object);

    if (refund?.tourPointLoad?.productOrder?.order) {
      const order: Order = new Order(refund.tourPointLoad.productOrder.order);
      return order.id;
    }

    return undefined;
  }

  getCreatedFrom(refund: Refund): string {
    // Reference check before accessing properties
    const isWarehouseNameAvailable =
      refund?.tourPointLoad?.tourPoint?.warehouse?.name;
    const isLocationNameAvailable =
      refund?.tourPointLoad?.productOrder?.order?.location?.name;

    if (isWarehouseNameAvailable) {
      return refund.tourPointLoad.tourPoint.warehouse!.name;
    } else if (isLocationNameAvailable) {
      return refund.tourPointLoad.productOrder?.order!.location.name!;
    } else {
      return "-";
    }
  }

  extractDate(value: TuiDay | null) {
    return value?.toUtcNativeDate()?.toISOString();
  }

  changeDateEvent(createdAtAsIsoDate: string | undefined) {
    if (!this.dateChangeTriggered) {
      this.tableHelperService.resetTablePagination();
      this.selectedCreatedAtDate = createdAtAsIsoDate;
      this.populateTable();
    } else {
      // reset actionTriggered back to false for normal operations
      this.dateChangeTriggered = false;
    }
  }

  openMissingPermissionsDialog() {
    this.dialogService.openDialog(MissingRefundsPermissionsDialogComponent);
  }

  private sorting() {
    this.refundService.setSorting({
      sortColumn: "createdAt",
      sortDirection: -1,
    });
  }
}

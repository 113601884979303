import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";

@Injectable({
  providedIn: "root",
})
export class MapPermissionHelperService {
  permissionService = inject(PermissionService);

  get hasReadOrderPermissions() {
    return this.permissionService.readOrderPermission().hasPermission;
  }

  get hasUpdateTourPermissions() {
    return this.permissionService.updateTourPermission().hasPermission;
  }

  get hasReadTourPointPermissions() {
    return this.permissionService.updateTourPermission().hasPermission;
  }

  get hasDeleteTourPointPermissions() {
    return this.permissionService.deleteTourPointPermission().hasPermission;
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { tuiIconPlus } from "@taiga-ui/icons";
import { TuiNamedDay } from "@taiga-ui/kit";
import { TuiDay } from "@taiga-ui/cdk";
import { Permissions } from "../../other/enums/permissions";

export type FilterTab<T = string> = {
  label: string;
  value: T;
  isActive?: boolean;
};

@Component({
  selector: "app-table-layout",
  templateUrl: "./table-layout.component.html",
  styleUrls: ["./table-layout.component.scss"],
})
export class TableLayoutComponent<Tabs> {
  // Input values
  @Input({ required: true }) ngxTitle: string;
  @Input() newButtonTxt: string = "";
  @Input() icon: string = tuiIconPlus;
  @Input() wrappedInCard: boolean = true;
  @Input() showPadding: boolean = true;
  @Input() transparentButton: boolean = true;
  @Input() tabs: FilterTab<Tabs>[] = [];
  @Input() dateLabel: string = "common.inactive-since";
  @Input() showDatePicker: boolean = false;
  @Input() dateItems: TuiNamedDay[] = [];
  @Input() dateValue: TuiDay | null = null;
  @Input() fullWidth: boolean = true;
  @Input() dateHint: string = "common.inactive-since";
  @Input() showAddButton: boolean = true;
  @Input() showTable: boolean = true;
  @Input() missingReadPermissions: Permissions[] = [];

  triggered = false;

  // Output events
  @Output() btnClick = new EventEmitter();
  @Output() tabClick = new EventEmitter<Tabs>();
  @Output() dateSelected = new EventEmitter<TuiDay>();
  protected readonly tuiIconPlus = tuiIconPlus;

  constructor() {}

  get hasTabs() {
    return this.tabs.length > 0;
  }

  clickBtn($event: any) {
    this.btnClick.emit($event);
  }

  clickTab(tab: FilterTab<Tabs>) {
    this.tabClick.emit(tab.value);
  }

  selectedDate(value: TuiDay) {
    this.dateValue = value;
    this.dateSelected.emit(value);

    // if (!this.triggered) {
    //   this.triggered = true;
    // } else {
    // }
  }
}

import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import ProductPrice from "../models/ProductPrice.class";
import { PRODUCT_QUALITY } from "../models/Product.class";
import { PaginationFilterService } from "../services/pagination-filter.service";

export type PriceQueryParams = {
  customerId?: string | null;
  productId?: string;
  quality?: PRODUCT_QUALITY;
};

@Injectable({
  providedIn: "root",
})
export class ProductPriceService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  private _productPrices = signal<ProductPrice[]>([]);

  constructor(private http: HttpClient) {
    super();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  getProductPriceByProductId(productId: string): Observable<any> {
    const params = new HttpParams().set("productId", productId);
    return this.http.get<any>(this.baseUrl + "product-price", {
      params: params,
    });
  }

  getProductPriceById(id: string): Observable<ProductPrice> {
    return this.http.get<any>(this.baseUrl + "product-price/" + id);
  }

  addProductPrice(productPrice: ProductPrice) {
    return this.http.post<any>(this.baseUrl + "product-price", productPrice, {
      observe: "response",
    });
  }

  updateProductPrice(id: string, productPrice: ProductPrice) {
    return this.http.patch<any>(
      this.baseUrl + "product-price/" + id,
      productPrice,
      {
        observe: "response",
      },
    );
  }

  deleteProductPrice(id: string) {
    return this.http.delete<any>(this.baseUrl + "product-price/" + id, {
      observe: "response",
    });
  }

  // GET ALL ProductPrices
  getAllProductPrices(
    priceQueryParams?: PriceQueryParams,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (priceQueryParams) {
      for (const [key, value] of Object.entries(priceQueryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "product-price", { params })
      .pipe(
        tap((res: ResponseWithRecordsBody) =>
          this._productPrices.set(
            res.records.map(
              (productPrice: ProductPrice) => new ProductPrice(productPrice),
            ),
          ),
        ),
      );
  }
}

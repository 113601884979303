<div class="login-wrapper">
  <div class="card-wrapper-login">
    <tui-island class="card">
      <!--   logo   -->
      <app-logo-svg />

      <!--   title   -->
      <h3 class="tui-island__title" style="margin-top: 0.5rem">
        {{ "login.title" | translate }}
      </h3>

      <!--   description   -->
      <p class="tui-island__paragraph">
        {{ "login.description" | translate }}
      </p>

      <!--   form   -->
      <form
        (ngSubmit)="submitLogin()"
        [formGroup]="loginForm"
        class="login-form"
      >
        <!--   email   -->
        <app-input-text
          [fieldName]="'emailValue'"
          [hintColor]="'white'"
          [hint]="'login.input-mail' | translate"
          [showAutocomplete]="true"
          bRadius="40px"
        >
        </app-input-text>

        <!--   password   -->
        <div class="scoped">
          <app-input-password
            [fieldName]="'passwordValue'"
            [hint]="'login.input-password' | translate"
            bRadius="40px"
            hintColor="white"
          ></app-input-password>
        </div>

        <!--    forgot password    -->
        <button
          (click)="isOpen = true"
          class="forgot-pw-button"
          tuiLink
          type="button"
        >
          {{ "login.forgot-password" | translate }}
        </button>

        <!--    login button    -->
        <button
          [disabled]="!loginForm.valid"
          appearance="primary"
          size="m"
          tuiButton
          type="submit"
        >
          {{ "login.button-login" | translate }}
        </button>
      </form>
    </tui-island>
  </div>
</div>

<app-forgot-password
  (closingEvent)="isOpen = false"
  [isOpen]="isOpen"
></app-forgot-password>

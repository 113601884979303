@if (files && files.length > 0) {
  <div style="color: black">
    <h3 style="margin-top: 0">Angeheftete Dateien</h3>

    <div
      style="
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 0.5rem;
      "
    >
      @for (file of files; let i = $index; track file) {
        <div class="chip">
          @if (file.file.type.startsWith("image/")) {
            <!--              <img [src]="createThumbnail(file.file)" alt="Image" width="30px" height="30px">-->
          }
          <button (click)="openFile(file.file)">
            {{ file.file.type }}
          </button>
          @if (isEditable) {
            <button (click)="deleteFile(file.id)">
              <tui-svg
                [src]="tuiIconX"
                style="padding-left: 0.5rem; color: black"
              ></tui-svg>
            </button>
          }
        </div>
      }
    </div>
  </div>
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "app-dialog-wrapper",
  templateUrl: "./dialog-wrapper.component.html",
  styleUrls: ["./dialog-wrapper.component.scss"],
})
export class DialogWrapperComponent {
  @Input({}) isAddMode: boolean = true;
  @Input({}) ngxAddTitle: string = "";
  @Input({}) ngxEditTitle: string = "";

  isOpen: boolean = true;
}

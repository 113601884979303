import { Component, inject } from "@angular/core";
import { NgIf } from "@angular/common";
import { TourPermissionHelperService } from "../../../../services/permission-helper-services/tour-permission-helper.service";

@Component({
  selector: "app-missing-tour-delete-permissions",
  standalone: true,
  imports: [NgIf],
  templateUrl: "./missing-tour-delete-permissions.component.html",
  styleUrl: "./missing-tour-delete-permissions.component.scss",
})
export class MissingTourDeletePermissionsComponent {
  tourPermissions = inject(TourPermissionHelperService);
}

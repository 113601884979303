import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  copyState: "ready" | "success" | "error" = "ready";

  copy(copyString: string) {
    navigator.clipboard.writeText(copyString).then(
      () => {
        this.copyState = "success";
        setTimeout(() => {
          this.copyState = "ready";
        }, 2000);
      },
      () => {
        this.copyState = "error";
        setTimeout(() => {
          this.copyState = "ready";
        }, 2000);
      },
    );
  }

  trimCommasFromStartAndEnd(input: string): string {
    // Use regex to remove commas from start and end of the string
    return input.replace(/(^[,]+)|([,]+$)/g, "");
  }
}

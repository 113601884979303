import { Component, Inject, OnInit } from "@angular/core";
import Customer from "../../../models/Customer.class";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { ProductService } from "../../../api/product.service";
import Product, { PRODUCT_QUALITY } from "../../../models/Product.class";
import ProductPrice from "../../../models/ProductPrice.class";
import { DateConverterService } from "../../../services/date-converter.service";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { ProductPriceService } from "../../../api/productPrice.service";
import { TuiDay } from "@taiga-ui/cdk";
import { tap } from "rxjs";
import { pushTypes } from "../../../other/enums/push-types";
import { PushService } from "../../../services/push.service";

@Component({
  selector: "app-add-edit-customer-price",
  templateUrl: "./add-edit-customer-price.component.html",
  styleUrl: "./add-edit-customer-price.component.scss",
})
export class AddEditCustomerPriceComponent implements OnInit {
  customer: Customer | undefined;
  productPrice: ProductPrice | undefined;
  form: FormGroup;
  products: Product[];
  productDropdown: DropDownItem[];
  qualityDropdown: DropDownItem[];
  qualityVisible: boolean;
  today: TuiDay;
  timeframeBegin: TuiDay;
  mode: "add" | "edit";

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any>,
    private fb: FormBuilder,
    private productPriceService: ProductPriceService,
    private productService: ProductService,
    private dateConverter: DateConverterService,
    private dialogService: TuiDialogHelperService,
    private push: PushService,
  ) {}

  get productPriceFromForm(): ProductPrice {
    const formData = this.form.value;

    const timeframeEnd = this.setEndOfDay(
      this.dateConverter.formatTaigaDateToIsoDate([formData.timeframeEnd]),
    );

    return new ProductPrice({
      productId: formData.productId.id,
      quality: formData.quality.id,
      price: formData.price,
      timeframeBegin: this.dateConverter.formatTaigaDateToIsoDate([
        formData.timeframeBegin,
      ]),
      timeframeEnd: timeframeEnd,
      customerId: formData.customerId,
    });
  }

  getProductOfSelectedPrice(): Product | undefined {
    if (!this.products) return;
    return this.products.find(
      (product) => this.productPrice?.productId === product.id,
    );
  }

  ngOnInit(): void {
    this.getSelectedCustomer();
    this.getSelectedPrice();
    this.getProductDropdown();
    this.initForm();

    this.today = this.dateConverter.formatIsoDateToTaigaDate(
      new Date().toISOString(),
    )[0];
  }

  // update the min date of timeframeEnd, whenever timeframeBegin changes (endDate cant be earlier than startDate)
  timeframeBeginChange($event: any) {
    this.timeframeBegin = $event.timeframeBegin;
  }

  initForm() {
    let startdate = null;
    let enddate = null;
    let product = null;

    if (this.productPrice) {
      startdate = this.dateConverter.formatIsoDateToTaigaDate(
        this.productPrice.timeframeBegin,
      )[0];
      enddate = this.dateConverter.formatIsoDateToTaigaDate(
        this.productPrice.timeframeEnd,
      )[0];
      product = {
        id: this.getProductOfSelectedPrice()?.id,
        label: this.getProductOfSelectedPrice()?.name,
      };

      this.timeframeBegin = startdate;
    }

    this.form = this.fb.group({
      productId: [product, Validators.required],
      quality: [this.productPrice?.quality ?? null, Validators.required],
      price: [this.productPrice?.price ?? null, Validators.required],
      timeframeBegin: [startdate, Validators.required],
      timeframeEnd: [enddate, Validators.required],
      customerId: [this.productPrice?.customerId ?? this.customer?.id],
    });
  }

  getSelectedCustomer() {
    // Check if context.data has properties specific to Customer class
    //@ts-ignore
    if (!this.context.data) return;
    this.customer = new Customer(this.context.data);
    this.mode = "add";
  }

  getSelectedPrice() {
    // Check if context.data has properties specific to ProductPrice class
    //@ts-ignore
    if (!this.context?.data?.price) return;
    this.productPrice = new ProductPrice(this.context.data);
    this.mode = "edit";
  }

  getProductDropdown() {
    this.productService.getAllProducts().subscribe((products) => {
      this.products = products.records;
      this.productDropdown = products.records.map((product: Product) => ({
        id: product.id,
        label: product.name,
      }));

      if (!this.productPrice) return;
      this.productPrice.product = this.getProductOfSelectedPrice();
      this.initForm();
    });
  }

  onProductChange(productDropdown: DropDownItem) {
    const foundProduct = this.products.find(
      (product) => product.id === productDropdown.id,
    );

    if (foundProduct) {
      const qualities = foundProduct.possibleQualities.map((quality) => ({
        id: quality,
        name: this.getLabelOfQualities(quality),
      }));

      this.qualityVisible = qualities.length > 1;
      this.setQualityFormValue(foundProduct);

      this.qualityDropdown = this.createDropdownItems(qualities);
      // .sort(
      //   (a, b) => qualityOrder.indexOf(a.id) - qualityOrder.indexOf(b.id),
      // );
    }
  }

  getLabelOfQualities(quality: PRODUCT_QUALITY) {
    switch (quality) {
      case PRODUCT_QUALITY.QUALITY_NEU:
        return "Neu";
      case PRODUCT_QUALITY.QUALITY_A:
        return "A";
      case PRODUCT_QUALITY.QUALITY_B:
        return "B";
      case PRODUCT_QUALITY.QUALITY_C:
        return "C";
      case PRODUCT_QUALITY.QUALITY_DEFEKT:
        return "Defekt";
    }
  }

  createDropdownItems(
    array: any[],
    keyForId: string = "id",
    keyForLabel: string = "name",
  ): DropDownItem[] {
    return array.map((item: any) => {
      return {
        id: item[keyForId],
        label: item[keyForLabel],
      };
    });
  }

  setQualityFormValue(product: Product) {
    if (!this.qualityVisible) {
      this.form.get("quality")?.setValue({
        id: product.possibleQualities[0],
        label: this.getLabelOfQualities(product.possibleQualities[0]),
      });
    } else {
      this.form.get("quality")?.setValue(null);
    }
  }

  setEndOfDay(date: string): string {
    const dateObject = new Date(date);
    const offsetMinutes = dateObject.getTimezoneOffset();
    dateObject.setHours(23);
    dateObject.setMinutes(59 - offsetMinutes);
    return dateObject.toISOString();
  }

  // create price vis post request
  createPrice() {
    this.productPriceService
      .addProductPrice(this.productPriceFromForm)
      .subscribe(() => {
        this.push.sendPush(
          pushTypes.SUCCESS,
          "",
          "Preis wurde erfolgreich erstellt",
        );
        this.cancel();
      });
  }

  editePrice() {
    if (!this.productPrice?.id) return;
    this.productPriceService
      .updateProductPrice(this.productPrice?.id, this.productPriceFromForm)
      .subscribe(() => {
        tap(() => {
          this.push.sendPush(
            pushTypes.SUCCESS,
            "",
            "Preis wurde erfolgreich aktualisiert",
          );
        });
        this.cancel();
      });
  }

  submit() {
    if (this.mode === "add") {
      // add
      this.createPrice();
    } else if (this.mode === "edit") {
      // update
      this.editePrice();
    }
  }

  cancel() {
    this.dialogService.close(this.context);
  }
}

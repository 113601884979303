<app-table-layout
  (btnClick)="createWarehouse()"
  [fullWidth]="false"
  [missingReadPermissions]="
    permissionService.readWarehousePermission().missingPermissions
  "
  [showAddButton]="permissionService.createWarehousePermission().hasPermission"
  [showPadding]="false"
  [showTable]="permissionService.readWarehousePermission().hasPermission"
  newButtonTxt="warehouse.new-title"
  ngxTitle="warehouse.title"
>
  <app-table
    (deleteEvent)="openDeleteDialog($event)"
    (editEvent)="editWarehouse($event)"
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (sortEvent)="tableHelperService.sortTable($event)"
    [cellTemplatesMap]="{ 'address.street': address }"
    [columns]="columns"
    [currentPage]="tablePage"
    [itemsDeletable]="
      permissionService.deleteWarehousePermission().hasPermission
    "
    [itemsEditable]="
      permissionService.updateWarehousePermission().hasPermission
    "
    [noSortColumns]="noSortColumns"
    [pageSize]="tablePageSize"
    [tableColumnNames]="headers"
    [tableData]="warehouses"
    [totalElements]="warehouseService.total"
  />
  <ng-template #address let-object="object" let-value>
    {{ value }} {{ object.address.houseNo }}
  </ng-template>
</app-table-layout>

<!-- rendering child components -->
<router-outlet></router-outlet>

<!-- delete dialog -->
<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="deleteWarehouse()"
  [isOpen]="showDeleteDialog"
/>

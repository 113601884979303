<tui-tabs
  (activeItemIndexChange)="onChange($event)"
  [(activeItemIndex)]="activeIndex"
  [underline]="underline"
>
  <button
    *ngFor="let tab of tabArray"
    [ngStyle]="{ 'font-size': fontSize }"
    [disabled]="disabled"
    class="tab-button"
    tuiTab
  >
    <span class="inner-tab">
      <tui-svg [src]="tab.icon"></tui-svg>
      <b>{{ tab.name }}</b>
    </span>
  </button>
</tui-tabs>

import { Injectable } from "@angular/core";
import TourPoint from "../../../models/TourPoint.class";
import { TourPointService } from "../../../api/tourPoint.service";
import Tour, { TOUR_STATE } from "../../../models/Tour.class";
import { TourPointLoadService } from "../../../api/tourPointLoad.service";
import {
  DeleteRequestPayload,
  UpdateRequestPayload,
} from "../../../interfaces/request-array.interface";

import LocationC from "../../../models/Location.class";
import Warehouse from "../../../models/Warehouse.class";
import Order from "../../../models/Order.class";
import TourPointLoad from "../../../models/TourPointLoad.class";
import ProductOrder from "../../../models/ProductOrder.class";
import { MapOrdersService } from "./map-orders.service";
import { MapRoutesService } from "./map-routes.service";

@Injectable({
  providedIn: "root",
})
export class MapTpTplService {
  tour: Tour;
  tourPoints: TourPoint[] = [];
  estimatedArrivalDates: any;
  drivingDurations: number[];
  drivingDistances: number[];

  constructor(
    private tourPointService: TourPointService,
    private tplService: TourPointLoadService,
    private mapOrdersService: MapOrdersService,
    private routesService: MapRoutesService,
  ) {}

  getTourPointsWithCallback(callback?: Function) {
    if (this.tour) {
      this.tourPointService
        .getAllTourPoints({
          tourId: this.tour.id,
          populateTourPointLoad: true,
          populateOrder: true,
        })
        .subscribe((tp) => {
          this.tourPoints = tp.records;
          this.setAddress();
          this.setOrders(this.tourPoints);
          this.sortTourPointsBySequenceNumber();
          this.updateSequenceNumbers();

          if (this.tourPoints.length === 1) {
            this.setArrivalTimes();
          }

          if (callback) callback();

          if (this.tourPoints.length > 1) {
            this.setTourPointsAndCreateRoute();
          } else {
            this.routesService.resetDirectionsRenderer();
          }
        });
    } else {
      this.tourPointService
        .getAllTourPoints({ populateTourPointLoad: true })
        .subscribe((tp) => {
          this.tourPoints = tp.records;
          if (callback) callback();
        });
    }
  }

  sortTourPointsBySequenceNumber() {
    this.tourPoints.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  }

  updateSequenceNumbers(callback?: Function) {
    let updateData: UpdateRequestPayload<TourPoint> = { data: [] };

    this.tourPoints.forEach((tp, index) => {
      if (tp.sequenceNumber !== index) {
        tp.sequenceNumber = index;
        updateData.data.push({ id: tp.id, data: tp });
      }
    });

    // Update the tourPoints in the backend
    if (updateData.data.length > 0) {
      this.tourPointService.updateTourPointById(updateData).subscribe(() => {
        if (callback) callback();
      });
    }
  }

  setTourPointsAndCreateRoute(callback?: Function) {
    this.routesService
      .createAndDisplayRoute(this.tourPoints)
      .then(({ durations, distances }) => {
        this.drivingDurations = durations;
        this.drivingDistances = distances;
        this.setArrivalTimes();
        if (callback) callback();
      });
  }

  setArrivalTimes() {
    if (this.tourPoints.length === 1) {
      this.tourPoints[0].estimatedArrival = this.tour.date;
      return;
    }

    let workingDelays: number[] = this.tourPoints.map(
      (tp) => tp.estimatedStopDuration,
    );

    this.estimatedArrivalDates = this.getEstimatedArrivalTimes(
      workingDelays,
      this.drivingDurations,
      new Date(this.tour.date),
    );
  }

  getEstimatedArrivalTimes(
    workingDelays: number[],
    drivingDurations: number[],
    startDate: Date,
  ): string[] {
    let currentArrivalTime = new Date(startDate);
    const estimatedArrivalTimes: string[] = [
      new Date(currentArrivalTime).toISOString(),
    ]; // start time

    for (let i = 0; i < workingDelays.length - 1; i++) {
      currentArrivalTime.setMinutes(
        currentArrivalTime.getMinutes() +
          workingDelays[i] +
          (drivingDurations[i] || 0),
      );
      estimatedArrivalTimes.push(new Date(currentArrivalTime).toISOString());
    }

    this.routesService.updateTourPointsEstimateArrival(
      estimatedArrivalTimes,
      this.tourPoints,
      this.drivingDistances,
    );

    return estimatedArrivalTimes;
  }

  deleteTourPointLoad(tourPointLoadIds: string[]) {
    const deleteIds: DeleteRequestPayload = {
      data: tourPointLoadIds,
    };

    this.tplService.deleteTourPointLoadById(deleteIds).subscribe(() => {
      // On success, update the local data
      deleteIds.data.forEach((deleteId) => {
        // Find the tour point which contains the tour point load to delete
        const tourPoint = this.tourPoints.find((tp) =>
          tp.tourPointLoads.some((tpl) => tpl.id === deleteId),
        );
        if (tourPoint) {
          // Filter out the deleted tour point load
          tourPoint.tourPointLoads = tourPoint.tourPointLoads.filter(
            (tpl) => tpl.id !== deleteId,
          );
        }
      });
      this.mapOrdersService.getOrdersWithCallback();
    });
  }

  setAddress() {
    this.tourPoints.forEach((tp) => {
      if (tp.location) {
        tp.address = tp.location.address;
      } else if (tp.warehouse) {
        tp.address = tp.warehouse.address;
      }
    });
  }

  setOrders(tourPoints: TourPoint[]) {
    tourPoints.forEach((tp) => {
      if (tp.tourPointLoads.length > 0) {
        let orderId = tp.tourPointLoads[0].productOrder.orderId;
        tp.location?.orders?.forEach((order) => {
          if (order.id === orderId) {
            tp.order = order;
          }
        });
      }
    });
  }

  addTourPoint(item: Warehouse | LocationC) {
    // only addable if the tour is of state draft
    if (this.tour.tourState !== TOUR_STATE.DRAFT) return;

    const tourPoint: TourPoint = new TourPoint({
      tourId: this.tour.id,
      sequenceNumber: this.tourPoints.length,
      estimatedArrival: this.tour.date,
      estimatedStopDuration: 15,
    });

    // if there is a locationId or warehouse id, set it to the tourPoint object
    if (item instanceof LocationC) tourPoint.locationId = item.id;
    if (item instanceof Warehouse) tourPoint.warehouseId = item.id;

    return this.tourPointService.addTourPoint(tourPoint);
  }

  addTouPointLoads(order: Order, tourPoint: TourPoint) {
    let tourPointLoads: TourPointLoad[] = [];

    order.productOrders.forEach((pOrder: ProductOrder) => {
      let absolutPOrderAmount = Math.abs(pOrder.amount);

      pOrder.tourPointLoads.forEach((tpl) => {
        absolutPOrderAmount -= Math.abs(tpl.amount);
      });

      // Iterating over the refunds related to the current ProductOrder and reducing the absolutPOrderAmount accordingly.
      order.refunds?.forEach((refund) => {
        if (refund.tourPointLoad.productOrderId === pOrder.id) {
          absolutPOrderAmount += Math.abs(refund.amount);
        }
      });

      if (absolutPOrderAmount > 0) {
        const tourPointLoad: TourPointLoad = new TourPointLoad({
          tourPointId: tourPoint.id,
          productOrderId: pOrder.id,
          productId: pOrder.productId,
          amount: 0,
          quality: pOrder.acceptedQuality,
        });

        tourPointLoads.push(tourPointLoad);
      }
    });

    return this.tplService.addTourPointLoad(tourPointLoads);
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProductHelperService } from "../product-helper.service";
import Product, { PRODUCT_QUALITY } from "../../../../models/Product.class";
import ProductPrice from "../../../../models/ProductPrice.class";
import { ProductService } from "../../../../api/product.service";
import { PermissionService } from "../../../../services/permission.service";
import { TuiDialogHelperService } from "../../../../services/tui-dialog-helper.service";
import { MissingPermissionsDialogComponent } from "../missing-permissions-dialog/missing-permissions-dialog.component";

@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrl: "./product-card.component.scss",
})
export class ProductCardComponent {
  @Input({ required: true }) products: Product[];
  @Input({ required: true }) productPrices: Map<string, ProductPrice[]> =
    new Map<string, ProductPrice[]>();

  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();

  constructor(
    public productService: ProductService,
    public productHelper: ProductHelperService,
    public permissionService: PermissionService,
    private dialogService: TuiDialogHelperService,
  ) {}

  onEdit(id: string) {
    this.editEvent.emit(id);
  }

  getPriceToQuality(productId: string, quality: PRODUCT_QUALITY) {
    // Check if we have prices for the given productId loaded
    if (!this.productPrices.has(productId)) {
      return;
    }

    // Retrieve prices for given productId
    const prices = this.productPrices.get(productId);

    if (!prices) return;
    const priceObj = prices.find((price) => {
      return price.quality === quality;
    });

    return priceObj?.price;
  }

  onDelete(id: string) {
    this.deleteEvent.emit(id);
  }

  openMissingPermissionsDialog() {
    this.dialogService.openDialog(MissingPermissionsDialogComponent);
  }
}

import { Component } from "@angular/core";

@Component({
  selector: "app-base-layout",
  templateUrl: "./base-layout.component.html",
  styleUrls: ["./base-layout.component.scss"],
})
/**
 * Layout for the pages that are visible to authenticated users
 * containing the navbar/sidebar
 */
export class BaseLayoutComponent {}

@if(multiple || !multiple && (fileControl.valueChanges | async) === null ){
  <tui-input-files
    (tuiValueChanges)="onFilesChange($event)"
    [formControl]="fileControl"
    [multiple]="multiple"
    class="control"
    [style.background-color]="customBackgroundColor !== '' ? customBackgroundColor : null"
  >
    <ng-template>
      <div>
        @if (showIcon){
          <tui-marker-icon
            mode="link"
            src="tuiIconUploadCloudLarge"
          ></tui-marker-icon>
        }
        <div [style.color]="customTextColor !== '' ? customTextColor : null">
          {{ customTranslate | translate }}
        </div>
      </div>
    </ng-template>
  </tui-input-files>
}
@if (multiple) {
  <ng-container *ngIf="(fileControl.valueChanges | async) as files">
    @if(files.length !== 0){
      <tui-files class="files">
        @for (file of files; track file.name){
          <tui-file
            (removed)="removeFile(file)"
            [file]="file"
            [showDelete]="fileControl.enabled"
            [leftContent]="content"
          >
          </tui-file>
        }
      </tui-files>
    }
  </ng-container>
} @else {
  <tui-file
    *ngIf="fileControl.valueChanges | async as file"
    [file]="file"
    (removed)="removeFile(file)"
    [showDelete]="fileControl.enabled"
    [leftContent]="content"
  >
  </tui-file>
}

<ng-template #content>
  <tui-svg [src]="tuiIconFile"></tui-svg>
</ng-template>

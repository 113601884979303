<div class="permission-grid">
  <div class="text-wrapper">
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>
  </div>
  <!-- TODO: Component Dropdown (tui-combo-box) seems to be not reusable for this purpose since its heavily nested. Could be refactored? -->
  <div class="head">
    <div class="head-column">
      <tui-combo-box
        [stringify]="stringify"
        formControlName="{{ viewFormControl }}"
        class="dropdown"
      >
        {{ "permission-row.permission-read" | translate }}
        <tui-data-list-wrapper
          *tuiDataList
          [items]="permissionLevels | tuiFilterByInputWith: stringify"
          [itemContent]="stringify | tuiStringifyContent"
        ></tui-data-list-wrapper>
      </tui-combo-box>
    </div>
    <div class="head-column">
      <tui-combo-box
        [stringify]="stringify"
        formControlName="{{ createFormControl }}"
        class="dropdown"
      >
        {{ "permission-row.permission-write" | translate }}
        <tui-data-list-wrapper
          *tuiDataList
          [items]="permissionLevels | tuiFilterByInputWith: stringify"
          [itemContent]="stringify | tuiStringifyContent"
        ></tui-data-list-wrapper>
      </tui-combo-box>
    </div>
    <div class="head-column">
      <tui-combo-box
        [stringify]="stringify"
        formControlName="{{ updateFormControl }}"
        class="dropdown"
      >
        {{ "permission-row.permission-update" | translate }}
        <tui-data-list-wrapper
          *tuiDataList
          [items]="permissionLevels | tuiFilterByInputWith: stringify"
          [itemContent]="stringify | tuiStringifyContent"
        ></tui-data-list-wrapper>
      </tui-combo-box>
    </div>
    <div class="head-column">
      <tui-combo-box
        [stringify]="stringify"
        formControlName="{{ deleteFormControl }}"
        class="dropdown"
      >
        {{ "permission-row.permission-delete" | translate }}
        <tui-data-list-wrapper
          *tuiDataList
          [items]="permissionLevels | tuiFilterByInputWith: stringify"
          [itemContent]="stringify | tuiStringifyContent"
        ></tui-data-list-wrapper>
      </tui-combo-box>
    </div>
  </div>
</div>

<form [formGroup]="passwordForm" class="password-form">
  <div class="inputs-wrapper">
    <app-input-password
      [fieldName]="'newPassword'"
      [hint]="'new-password.input-label-new-password' | translate"
    ></app-input-password>
    <app-input-password
      [fieldName]="'newPasswordConfirmation'"
      [hint]="'new-password.input-label-new-password-repeat' | translate"
    ></app-input-password>
    <button
      tuiButton
      [disabled]="!passwordForm.valid"
      type="submit"
      (click)="submitPasswordReset()"
    >
      {{ "new-password.button-save" | translate }}
    </button>
  </div>

  <div class="">
    <h3 class="">
      {{ "new-password.requirement-title" | translate }}
    </h3>
    <span class="indicator-wrapper">
      <tui-svg
        src="tuiIconCheckCircle"
        class="{{ hasOneLowercaseLetter().toString() }}"
      ></tui-svg>
      <p>
        {{ "new-password.requirement-lowercase" | translate }}
      </p>
    </span>

    <span class="indicator-wrapper">
      <tui-svg
        src="tuiIconCheckCircle"
        class="{{ hasOneUppercaseLetter().toString() }}"
      ></tui-svg>
      <p>
        {{ "new-password.requirement-uppercase" | translate }}
      </p>
    </span>

    <span class="indicator-wrapper">
      <tui-svg
        src="tuiIconCheckCircle"
        class="{{ hasOneNumber().toString() }}"
      ></tui-svg>
      <p>
        {{ "new-password.requirement-number" | translate }}
      </p>
    </span>

    <span class="indicator-wrapper">
      <tui-svg
        src="tuiIconCheckCircle"
        class="{{ isLongEnough().toString() }}"
      ></tui-svg>
      <p>
        {{ "new-password.requirement-length" | translate }}
      </p>
    </span>

    <span class="indicator-wrapper">
      <tui-svg
        src="tuiIconCheckCircle"
        class="{{ isMatching().toString() }}"
      ></tui-svg>
      <p>
        {{ "new-password.requirement-identical" | translate }}
      </p>
    </span>
  </div>
</form>

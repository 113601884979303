<div class="event-wrapper">
  <h3>{{ arg.event.title }}</h3>
  <p>{{ arg.event.timeStamp }}</p>
  <span>
    <p>{{ arg.event.extendedProps["location"] }}</p>
  </span>
  <span>
    <p>
      {{ arg.event.start | date: "HH:mm" }} bis
      {{ arg.event.end | date: "HH:mm" }}
      ({{ arg.event.end - arg.event.start | date: "HH:mm" }}h)
    </p>
  </span>
  <span class="event-pill">
    <p>Peter</p>
  </span>
</div>

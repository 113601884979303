<!-- Cards  -->
<div class="cards-wrapper">
  <!--  Orders created today  -->
  <div
    [class.pointer]="permissionService.readOrderPermission().hasPermission"
    [queryParams]="{ tabVal: 'all', date: getCurrentCETDate() }"
    [routerLink]="
      permissionService.readOrderPermission().hasPermission
        ? '/' + NavRoutes.ORDER
        : null
    "
    class="statistics-card"
    style="background-color: #ebdfff"
  >
    <h2>
      {{ widgetData().ordersCreatedTodayCount }}
      {{ "order.title" | translate }}
    </h2>
    <span>
      {{ "dashboard.title-today" | translate }}
    </span>
  </div>

  <!-- orders fully planned  -->
  <div
    [class.pointer]="permissionService.readOrderPermission().hasPermission"
    [queryParams]="{
      tabVal: 'all',
      selectVal: ORDER_STATE.PLANNED
    }"
    [routerLink]="
      permissionService.readOrderPermission().hasPermission
        ? '/' + NavRoutes.ORDER
        : null
    "
    class="statistics-card"
    style="background-color: #ffdec0"
  >
    <h2>
      {{ widgetData().orderPlannedCount }}
      {{ "common.from" | translate }}
      {{ widgetData().ordersPlanningPhaseCount }}
      {{ "dashboard.title-orders" | translate }}
    </h2>
    <span>
      {{ "dashboard.completely-planned" | translate }}
    </span>
  </div>

  <!--  orders finished today  -->
  <div
    [class.pointer]="permissionService.readOrderPermission().hasPermission"
    [queryParams]="{
      tabVal: 'all',
      selectVal: ORDER_STATE.FINISHED,
      updatedAt: getCurrentCETDate()
    }"
    [routerLink]="
      permissionService.readOrderPermission().hasPermission
        ? '/' + NavRoutes.ORDER
        : null
    "
    class="statistics-card"
    style="background-color: #fee252"
  >
    <h2>
      {{ widgetData().orderFinishedTodayCount }}
      {{ "order.title" | translate }}
    </h2>
    <span>
      {{ "dashboard.completed-today" | translate }}
    </span>
  </div>

  <!--  inactive customers  -->
  <div
    [class.pointer]="permissionService.readCustomerPermission().hasPermission"
    [queryParams]="{ inactiveSinceMonths: 1 }"
    [routerLink]="
      permissionService.readCustomerPermission().hasPermission
        ? '/' + NavRoutes.CUSTOMERS
        : null
    "
    class="statistics-card"
    style="background-color: #000000; color: #ffffff"
  >
    <h2>
      {{ widgetData().inactiveCustomers }}
      {{ "dashboard.title-inactive-customer" | translate }}
    </h2>
    <span>
      {{ "dashboard.since-four-weeks" | translate }}
    </span>
  </div>
</div>

<div style="padding-bottom: 0.5rem">
  <div class="flex-align-center">
    <!-- Heading section -->
    <h4 style="margin: 0">{{ "order.title" | translate }}:</h4>

    <!-- Button for selecting all orders with 'Alle' as label -->
    <button
      (click)="allOrders()"
      appearance="''"
      class="btn"
      size="s"
      tuiButton
    >
      Alle
    </button>

    <app-loading-spinner
      [showSpinnerCentered]="false"
      style="padding-left: 0.5rem"
    />
  </div>

  <!-- Form section-->
  <form [formGroup]="form" class="form">
    <!-- Custom dropdown for selecting customer. Once a customer is selected, the onCustomerChange function is triggered with the selected customer value. -->
    <app-dropdown
      (valueChange)="onCustomerChange($event)"
      [dropDownItems]="customerDropdown"
      [formFieldName]="'customer'"
      [useVirtualScroll]="true"
      hint="order.filterByCustomer"
      size="s"
    />

    <!-- Custom dropdown for selecting location. Once a location is selected, the onLocationChange function is triggered with the selected location value. -->
    <app-dropdown
      (valueChange)="onLocationChange($event)"
      [dropDownItems]="locationDropdown"
      [formFieldName]="'location'"
      [useVirtualScroll]="true"
      hint="map.filter-address"
      size="s"
    />
  </form>
</div>

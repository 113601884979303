@if (tourPointLoad) {
  <div class="all-container">
    <!--  TPL INFO CONTAINER  -->
    <div>
      <div class="amount-maxAmount">
        <!-- TPL AMOUNT FORM -->
        <form [formGroup]="form" class="form">
          <app-input-number
            [required]="false"
            fieldName="amount"
            hintColor="white"
            class="amount"
            size="s"
            (keyup.enter)="updateTpl()"
            (onBlur)="updateTpl()"
            [max]="maxAmount"
            [readOnly]="isSigned || !isTourStateDraft"
          />
        </form>

        @if (productOrder) {
          <!-- MAX POSSIBLE AMOUNT -->
          <span class="maxAmount"> / {{ maxAmount }} </span>
        }
      </div>

      @if (product) {
        <!-- PRODUCT NAME + QUALITY -->
        <div
          class="info-font"
          [title]="product.name + ' ' + (tourPointLoad.quality | translate)"
        >
          {{
            product.name.length > 15
              ? (product.name | slice: 0 : 15) + "..."
              : product.name
          }}
          @if (product.possibleQualities.length > 1) {
            {{ tourPointLoad.quality | translate }}
          }
        </div>
      }
    </div>

    <!-- CHARGE / UNLOAD CONTAINER -->
    <div class="charge-unload">
      @if (product && productOrder) {
        <!-- ARROW IMG -->
        <img
          [ngSrc]="productOrder.amount < 0 ? arrowUp : arrowDown"
          alt="arrow"
          height="25"
          width="25"
        />

        <!-- CHARGE / UNLOAD TXT -->
        <span class="info-font">
          {{
            productOrder.amount < 0
              ? ("order.unload" | translate)
              : ("order.charge" | translate)
          }}
        </span>
        <!--    CASE WAREHOUSE; NO PRODUCT ORDER    -->
      } @else if (tourPointLoad.amount !== 0) {
        <!-- ARROW IMG -->
        <img
          [ngSrc]="tourPointLoad.amount < 0 ? arrowUp : arrowDown"
          alt="arrow"
          height="25"
          width="25"
        />

        <!-- CHARGE / UNLOAD TXT -->
        <span class="info-font">
          {{
            tourPointLoad.amount < 0
              ? ("order.unload" | translate)
              : ("order.charge" | translate)
          }}
        </span>
      }
    </div>

    <!-- DELETE -->
    <div class="trash-icon">
      <tui-svg
        [ngClass]="{
          disabled:
            isSigned ||
            !isTourStateDraft ||
            !mapPermissionHelper.hasDeleteTourPointPermissions
        }"
        (click)="deleteTourPointLoad()"
        src="tuiIconTrash2Large"
      ></tui-svg>
    </div>
  </div>
}

<div class="head-wrapper">
  <h1 class="title">
    {{ pageTitlePrefix | translate }} {{ pageTitleSuffix | translate }}
  </h1>
  <span class="head-button-wrapper">
    <button
      tuiButton
      appearance="secondary"
      size="m"
      (click)="cancelClicked.emit()"
      type="button"
    >
      {{ cancelButtonText | translate }}
    </button>
    <button
      *ngIf="!isInAddMode && showDeleteButton"
      tuiButton
      appearance="secondary-destructive"
      size="m"
      type="button"
      [icon]="tuiIconTrash"
      (click)="deleteClicked.emit()"
    >
      {{ deleteButtonText | translate }}
    </button>
    <button
      tuiButton
      [icon]="tuiIconSave"
      size="m"
      (click)="saveClicked.emit()"
      type="submit"
      appearance="primary"
      class="submit-button"
      [disabled]="saveButtonDisabled"
    >
      {{ saveButtonText | translate }}
    </button>
  </span>
</div>

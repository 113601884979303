<button
  (click)="btnCLick($event)"
  [icon]="iconValue"
  appearance="''"
  class="transparent-btn"
  size="m"
  tuiButton
  style="width: 100%"
>
  {{ i18StringText | translate }}
</button>

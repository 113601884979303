<div style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um Lager
    {{ isAddmode ? "erstellen" : "aktualisieren" }} zu können:
  </h3>

  <ul>
    <li *ngIf="!warehousePermissions.hasCreateWarehousePermission && isAddmode">
      <b>Lager erstellen</b>
    </li>

    <li
      *ngIf="!warehousePermissions.hasUpdateWarehousePermission && !isAddmode"
    >
      <b>Lager aktualisieren</b>
    </li>

    <li *ngIf="!warehousePermissions.hasReadWarehousePermission">
      <b>Lager ansehen</b>
    </li>
  </ul>
</div>

import { Injectable } from "@angular/core";
import { TuiDialogContext, TuiDialogService } from "@taiga-ui/core";
import { PolymorpheusComponent } from "@tinkoff/ng-polymorpheus";

@Injectable({
  providedIn: "root",
})
export class TuiDialogHelperService {
  constructor(private dialogService: TuiDialogService) {}

  openDialog(
    component: any,
    data?: any,
    callback?: Function,
    appearance?: string,
  ) {
    this.dialogService
      .open(new PolymorpheusComponent(component), {
        data: data,
        size: "auto",
        appearance: appearance,
      })
      .subscribe(() => {
        if (callback) callback();
      });
  }

  close(context: TuiDialogContext<boolean, undefined>) {
    context.completeWith(false);
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-wysiwyg-editor",
  templateUrl: "./wysiwyg-editor.component.html",
  styleUrl: "./wysiwyg-editor.component.scss",
})
export class WysiwygEditorComponent {
  @Input() editor = ClassicEditor;
  @Input() data: string;
  @Input() editorConfig = {
    toolbar: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "fontSize",
    ],
  };

  @Output() changeEvent = new EventEmitter();

  handleChange(event: any) {
    this.changeEvent.emit(event);
  }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomerFileHelperService {
  private fileUpdatedSource = new Subject<any>();
  fileUpdated$ = this.fileUpdatedSource.asObservable();

  fileUpdated() {
    this.fileUpdatedSource.next(null);
  }

  // ------------------------------------------------------------------------------------- || Methods ||
}

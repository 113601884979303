<tui-tooltip
  [class.permission-tooltip]="iconColorWhite()"
  [content]="tooltip"
  direction="bottom"
></tui-tooltip>

<ng-template #tooltip>
  Fehlende Berechtigungen für {{ elementName() }} {{ methodType() ?? "" }}:
  <br />
  <ul>
    @for (permission of permissions(); track permission) {
      @if (!permission.hasPermission) {
        <li>
          <b>{{ permission.name }}</b>
        </li>
      }
    }
  </ul>
</ng-template>

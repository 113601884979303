@if (
  i < mapTPService.tourPoints.length - 1 &&
  mapTPService.drivingDurations &&
  mapTPService.drivingDistances
) {
  <span style="display: flex; justify-content: center; margin-left: 1.5rem">
    {{ "map.driving-duration" | translate }}: ca.
    <b>{{ formatDuration(mapTPService.drivingDurations[i]) }}</b>
    <tui-svg src="tuiIconArrowDown"></tui-svg>
    {{ mapTPService.drivingDistances[i] }} KM
  </span>
}

<div class="all-container">
  <!--  title -->
  <h1 style="margin-top: 0">
    {{ "common.delete-title" | translate }}
  </h1>

  <!--  info text -->
  <main>
    {{ "common.delete-hint" | translate }}
  </main>

  <!--  buttons -->
  <div class="actions">
    <button
      (click)="deleteCustomerPrice()"
      appearance="''"
      size="m"
      style="background-color: var(--tui-error-bg); color: var(--tui-error-txt)"
      tuiButton
    >
      {{ "common.button-delete" | translate }}
    </button>

    <button (click)="cancel()" appearance="secondary" size="m" tuiButton>
      {{ "common.button-cancel" | translate }}
    </button>
  </div>
</div>

export enum PRODUCT_QUALITY {
  QUALITY_NEU = "QUALITY_NEU",
  QUALITY_A = "QUALITY_A",
  QUALITY_B = "QUALITY_B",
  QUALITY_C = "QUALITY_C",
  QUALITY_DEFEKT = "QUALITY_DEFEKT",
}

import { BaseModel } from "./BaseModel";

export default class Product extends BaseModel<Product> {
  tenantId: string;
  name: string;
  possibleQualities!: PRODUCT_QUALITY[];
  description?: string;
  dbFileId: string | null;

  constructor(params: Partial<Product>) {
    super(params);
    Object.assign(this, params);
  }
}

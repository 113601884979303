<app-menubar
  [title]="'tenant.new-tenant.title' | translate"
  [description]="'tenant.new-tenant.description' | translate"
  [open]="isOpen"
  (closingEvent)="setCloseEvent()"
>
  <form [formGroup]="newTenantForm" (ngSubmit)="createNewTenant()">
    <app-input-text
      [fieldName]="'name'"
      [hint]="'tenant.new-tenant.input-label-name' | translate"
    ></app-input-text>

    <div class="button-wrapper">
      <button
        tuiButton
        size="m"
        [disabled]="!newTenantForm.valid"
        type="submit"
      >
        {{ "common.button-save" | translate }}
      </button>
      <button
        tuiButton
        size="m"
        appearance="secondary"
        type="button"
        (click)="setCloseEvent()"
      >
        {{ "common.button-cancel" | translate }}
      </button>
    </div>
  </form>
</app-menubar>

import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  WritableSignal,
} from "@angular/core";
import TourPoint from "../../../models/TourPoint.class";
import { pushTypes } from "../../../other/enums/push-types";
import { DeliveryReceiptService } from "../../../api/deliveryReceipt.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { PushService } from "../../../services/push.service";
import { PermissionService } from "../../../services/permission.service";
import { Observable, of } from "rxjs";
import DeliveryReceipt from "../../../models/DeliveryReceipt.class";
import { DeliveryReceiptDetailsDialogComponent } from "../../delivery-receipt-page/delivery-receipt-details-dialog/delivery-receipt-details-dialog.component";
import { RefundService } from "../../../api/refund.service";
import Refund from "../../../models/Refund.class";

interface RefundWithImages extends Refund {
  images?: { blob: Blob; url: string }[];
}

@Component({
  selector: "app-tour-point-details-sidebar",
  templateUrl: "./tour-point-details-sidebar.component.html",
  styleUrl: "./tour-point-details-sidebar.component.scss",
})
export class TourPointDetailsSidebarComponent {
  @Input() isOpen: boolean = true;
  @Input() selectedTourPoint$: Observable<TourPoint | undefined> =
    of(undefined);
  @Output() closeEvent = new EventEmitter<boolean>();

  tourPoint: WritableSignal<TourPoint | undefined> = signal(undefined);
  deliveryReceipts: WritableSignal<DeliveryReceipt[]> = signal([]);
  tourPointRefunds = signal<RefundWithImages[]>([]);

  constructor(
    private deliveryReceiptService: DeliveryReceiptService,
    public location: Location,
    private dialogService: TuiDialogHelperService,
    private router: Router,
    private pushService: PushService,
    public permissionService: PermissionService,
    private refundService: RefundService,
  ) {}

  ngOnInit(): void {
    // reload data on selectedTourPoint$ change
    this.selectedTourPoint$.subscribe((tourPoint) => {
      this.tourPoint.set(tourPoint);
      this.tourPointRefunds.set([]);
      this.deliveryReceipts.set([]);

      if (!tourPoint) return;

      // get all delivery receipts for the selected tour point
      this.deliveryReceiptService
        .getAllDeliveryReceipts({ tourPointId: tourPoint.id })
        .subscribe((deliveryReceipts) => {
          this.deliveryReceipts.set(deliveryReceipts.records);
        });

      this.getRefundsWithImages(tourPoint);
    });
  }

  async routeToTour() {
    const userHasMapPermissions =
      await this.permissionService.checkMapPermissions();

    if (
      !userHasMapPermissions.hasPermission &&
      userHasMapPermissions.missingPermissions
    ) {
      // reduce the missing permission translations to a string
      const missingPermissionString =
        userHasMapPermissions.missingPermissions.reduce(
          (acc, permission, index, arr) => {
            return acc + permission + (index < arr.length - 1 ? ", " : "");
          },
          "",
        );

      this.setCloseEvent();
      this.pushService.sendPush(
        pushTypes.ERROR,
        "",
        "Es fehlen Berechtigungen zum Ansehen der <b>" +
          (missingPermissionString ?? "") +
          "</b> um die Karte zu öffnen",
      );
      return;
    }

    this.router.navigate([`map/${this.tourPoint()?.tourId}`]).then();
  }

  getRefundsWithImages(tourPoint: TourPoint) {
    // reduce all refunds from the tourPointLoads to a single array
    const refunds: Refund[] = tourPoint.tourPointLoads.reduce(
      (acc: Refund[], tpl) => {
        return acc.concat(tpl.refunds);
      },
      [],
    );

    // get detailed refund objects (with tourPointLoad, productOrder, ...) and images for each refund of the tourPoint
    for (const refund of refunds) {
      this.refundService.getDbImagesById(refund.id).subscribe((res) => {
        const detailedRefund: RefundWithImages = res.refund;
        // set tourPointRefunds with the new refund object if there are no images
        if (!res.images) {
          this.tourPointRefunds.set(
            this.tourPointRefunds().concat(detailedRefund),
          );
          return;
        }
        detailedRefund.images = [];
        // convert images and add them to the refund object
        for (const file of res.images) {
          const reader = new FileReader();
          let fileString: string;
          reader.onloadend = () => {
            fileString = reader.result as string;
            detailedRefund.images?.push({ blob: file, url: fileString });
            // if last image, push the refund object to signal that is rendered in the template
            if (res.images!.indexOf(file) === res.images!.length - 1) {
              this.tourPointRefunds.set(
                this.tourPointRefunds().concat(detailedRefund),
              );
            }
          };
          reader.readAsDataURL(file);
        }
      });
    }
  }

  setCloseEvent() {
    this.closeEvent.emit(true);
  }

  openDeliveryReceipt(id: string) {
    this.dialogService.openDialog(DeliveryReceiptDetailsDialogComponent, id);
  }

  openFile(file: Blob) {
    // Create an object URL for the blob
    const fileURL = URL.createObjectURL(file);
    // Opens the object URL in a new tab
    window.open(fileURL);
  }
}

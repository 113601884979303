import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { HttpErrorResponse } from "@angular/common/http";

export type MethodType = "CREATE" | "UPDATE" | "DELETE";

@Injectable({
  providedIn: "root",
})
export class ErrorMessagesService {
  constructor(
    private translate: TranslateService,
    private push: PushService,
  ) {}

  sendErrorMessage(
    endpoint: string,
    method: MethodType,
    error: HttpErrorResponse,
  ) {
    if (error.error.code === 403) {
      return;
    }

    const errorKey = error.error?.key?.toLowerCase() || "";
    const translationKey = `http-error.${endpoint}.${method.toLowerCase()}_${errorKey}`;
    const genericTranslationKey = `http-error.${errorKey}`;

    let translated = this.translate.instant(translationKey);

    if (translated === translationKey) {
      // If not found for the specific endpoint and method, try the generic key
      translated = this.translate.instant(genericTranslationKey);
    }

    let operation: string;
    switch (method) {
      case "CREATE":
        operation = "creating";
        break;
      case "UPDATE":
        operation = "updating";
        break;
      case "DELETE":
        operation = "deleting";
        break;
    }

    const message =
      translated !== genericTranslationKey
        ? translated
        : error.error.message ||
          `Ein Fehler ist aufgetreten während ${operation} a ${endpoint}`;

    this.push.sendPush(pushTypes.ERROR, "", message);
  }
}

<app-table-layout
  (btnClick)="addNewCustomer()"
  (dateSelected)="filterTableByDate(extractDate($event))"
  [dateItems]="dateItems"
  [dateValue]="dateValue"
  [missingReadPermissions]="
    permissionService.readCustomerPermission().missingPermissions
  "
  [showAddButton]="permissionService.createCustomerPermission().hasPermission"
  [showDatePicker]="true"
  [showTable]="permissionService.readCustomerPermission().hasPermission"
  newButtonTxt="customer.new-button-label"
  ngxTitle="customer.page-title"
>
  <!--  search field  -->
  <div class="search-form" header-item style="min-width: 14em !important">
    <app-table-search-input
      (searchChangedDebounced)="search($event)"
    ></app-table-search-input>
  </div>

  <app-table
    (editEvent)="openEditCustomerDialog($event)"
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (rowClickEvent)="customerRowClickEvent($event)"
    (sortEvent)="tableHelperService.sortTable($event)"
    [cellTemplatesMap]="{
      latestOrder: latestOrderTemplate,
      dateLastOrder: dateLatestOrderTemplate,
      name,
      locationAmount
    }"
    [columns]="customerTableShownColumns"
    [currentPage]="tablePage"
    [itemsEditable]="permissionService.updateCustomerPermission().hasPermission"
    [noSortColumns]="noSortColumns"
    [pageSize]="tablePageSize"
    [tableColumnNames]="customerTableColumnNames"
    [tableData]="customers"
    [totalElements]="customerService.total"
  ></app-table>

  <ng-template #name let-value>
    {{ value }}
  </ng-template>

  <ng-template #latestOrderTemplate let-object="object">
    <div class="order-number">
      {{ getLatestOrder(object)?.orderNumber ?? "-" }}

      <button
        *ngIf="getLatestOrder(object) as order"
        [routerLink]="['/order/order-detail/', order.id]"
        [tuiHint]="tooltip"
        appearance="secondary"
        icon="tuiIconExternalLink"
        size="s"
        tuiHintDirection="right"
        tuiIconButton
      ></button>
    </div>
    <!--   tooltip info >> depending on the copyState   -->
    <ng-template #tooltip>
      {{ "order.open" | translate }}
    </ng-template>
  </ng-template>

  <ng-template #dateLatestOrderTemplate let-object="object">
    <div class="last-order-date">
      {{
        (getLatestOrder(object)?.timeframeEnd
          | date: "dd.MM.yyyy / HH:mm" : "UTC") ?? "-"
      }}
    </div>
  </ng-template>

  <ng-template #locationAmount let-object="object">
    {{ object.locations.length }}
  </ng-template>
</app-table-layout>

<div class="dialog-content">
  <h1 style="color: var(--tui-text-dark-02); margin-top: 0">
    {{ "unannounced-deliveries.new" | translate }}
  </h1>
  <form [formGroup]="form">
    <div class="flex-col-g1" style="margin-bottom: 1rem">
      <h3>
        {{ "unannounced-deliveries.general" | translate }}
      </h3>
      <div class="same-row-inputs">
        <app-dropdown
          [dropDownItems]="warehouseDropdownItems"
          [useVirtualScroll]="true"
          formFieldName="warehouseId"
          hint="warehouse.title"
        />

        <app-input-text
          fieldName="unannouncedCompany"
          hint="unannounced-deliveries.delivery-name"
        />
      </div>

      <div class="same-row-inputs">
        <app-input-text fieldName="truckNumber" hint="tour.truckNumber" />

        <app-dropdown
          [dropDownItems]="customerDropdownItems"
          formFieldName="customerId"
          hint="unannounced-deliveries.customer"
        />
      </div>
    </div>

    <div class="flex-col-g1" style="margin-bottom: 1.5rem">
      <h3>{{ "unannounced-deliveries.tour-point-loads" | translate }}</h3>
      <!-- dynamic Form-array for tourPointLoad    -->
      @for (tplDto of tplDtos.controls; track tplDtos.controls) {
        <div
          [formGroup]="
            dynamicFormHelperService.getFormGroupFromAbstractControl(tplDto)
          "
        >
          <div class="delivery-form-card flex-col-g1">
            <app-dropdown
              (valueChange)="productChange($event, $index)"
              [dropDownItems]="productDropdownItems"
              formFieldName="productId"
              hint="product.title"
            />
            <div class="same-row-inputs">
              <app-dropdown
                [dropDownItems]="qualityForProductDropdownItems"
                formFieldName="quality"
                hint="order.quality"
              />
              <app-input-number fieldName="amount" hint="palette.amount" />
            </div>
            <button
              (click)="deleteTplDtoFormRow($index)"
              appearance="icon"
              icon="tuiIconClose"
              id="delete-card-button"
              size="l"
              tuiButton
            ></button>
          </div>
        </div>
      } @empty {
        <tui-error
          [error]="'unannounced-deliveries.no-deliveries' | translate"
        ></tui-error>
      }
      <button
        (click)="addTplDtoFormRow()"
        appearance="secondary"
        icon="tuiIconPlus"
        id="addButton"
        size="m"
        style="width: 100%"
        tuiButton
      >
        {{ "unannounced-deliveries.add-product-delivery" | translate }}
      </button>
    </div>

    <div class="same-row-inputs" style="margin-bottom: 1rem">
      <div class="flex-col-g1">
        <!--  USER SIGN        -->
        <h3>
          {{ "delivery-receipt.sign-user" | translate }}
        </h3>
        <app-dropdown
          [dropDownItems]="userDropdownItems"
          formFieldName="userId"
          hint="user.coworker"
        />
        <app-input-files
          [customTranslate]="'common.sign-upload-hint'"
          [fileControl]="userSignControl"
          [multiple]="false"
          [showIcon]="false"
        />
      </div>

      <div class="flex-col-g1">
        <!--  DRIVER SIGN        -->
        <h3>
          {{ "delivery-receipt.sign-driver" | translate }}
        </h3>
        <app-input-text fieldName="driverName" hint="common.name" />
        <app-input-files
          [customTranslate]="'common.sign-upload-hint'"
          [fileControl]="signControl"
          [multiple]="false"
          [showIcon]="false"
        />
      </div>
    </div>

    <div class="flex-col-g1" style="margin-bottom: 1rem">
      <h3>{{ "unannounced-deliveries.further-information" | translate }}</h3>
      <!-- NOTE    -->
      <app-input-large-text
        fieldName="notes"
        hint="unannounced-deliveries.note"
      />

      <app-input-files
        [customTranslate]="'common.images-upload-hint'"
        [fileControl]="imageControl"
        [showIcon]="false"
      ></app-input-files>
      <app-input-files
        [customTranslate]="'common.document-images-upload-hint'"
        [fileControl]="documentControl"
        [showIcon]="false"
      ></app-input-files>
    </div>
  </form>
</div>

<app-save-cancel-btns
  (onCancel)="closeDialog()"
  (onSubmit)="submit()"
  [customValidatorBoolean]="
    tplDtos.controls.length === 0 ||
    userSignControl.invalid ||
    signControl.invalid
  "
  [form]="form"
/>

import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import ProductOrder from "../models/ProductOrder.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

@Injectable({
  providedIn: "root",
})
export class ProductOrderService {
  baseUrl: string = environment.baseUrl;

  // Define a property to store the calculated amounts and productOrderIds

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {}

  private _productOrders = signal<ProductOrder[]>([]);

  get productOrders() {
    return this._productOrders();
  }

  set productOrders(value: ProductOrder[]) {
    this._productOrders.set(value);
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD ProductOrder
  addProductOrder(
    addProductOrderObject: ProductOrder,
    refetchOrders = true,
  ): Observable<HttpResponse<ProductOrder>> {
    return this.http
      .post<ProductOrder>(
        this.baseUrl + "product-order",
        addProductOrderObject,
        { observe: "response" },
      )
      .pipe(
        tap(() => {
          if (refetchOrders) {
            // Call the getAllProductOrder method for updating the data + view
            this.getAllProductOrders().subscribe();
          }
        }),
      );
  }

  // GET ALL ProductOrders
  getAllProductOrders(queryParams?: any): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "product-order", { params })
      .pipe(
        tap((res: ResponseWithRecordsBody) =>
          this._productOrders.set(
            res.records.map(
              (productOrder: ProductOrder) => new ProductOrder(productOrder),
            ),
          ),
        ),
      );
  }

  // GET ONE ProductOrder
  getProductOrderById(id: string | number): Observable<ProductOrder> {
    return this.http.get<ProductOrder>(this.baseUrl + "product-order/" + id);
  }

  // CHANGE ONE ProductOrder
  updateProductOrderById(
    id: string | number,
    updateProductOrderObject: ProductOrder,
    refetchOrders = true,
  ): Observable<ProductOrder> {
    return this.http
      .patch<ProductOrder>(
        this.baseUrl + "product-order/" + id,
        updateProductOrderObject,
      )
      .pipe(
        tap(() => {
          if (refetchOrders) {
            // Call the getAllProductOrders method for updating the data + view
            this.getAllProductOrders().subscribe();
          }
        }),
      );
  }

  // DELETE ONE ProductOrder
  deleteProductOrderById(
    id: number | string,
    refetchOrders = true,
  ): Observable<unknown> {
    return this.http.delete<unknown>(this.baseUrl + "product-order/" + id).pipe(
      tap(() => {
        // Open the snackbar for successful request
        // this.handleSuccess("Successfully deleted productOrder");
        // Call the getAllProductOrders method for updating the data + view
        if (refetchOrders) {
          this.getAllProductOrders().subscribe();
        }
      }),
      catchError((err) => {
        // Open the snackbar for error
        this.errorHandler.sendErrorMessage("product-order", "DELETE", err);
        return of(err);
      }),
    );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

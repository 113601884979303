import { Component, signal } from "@angular/core";
import { tuiIconPlus } from "@taiga-ui/icons";
import { RoleService } from "../../../../api/role.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Location } from "@angular/common";
import { Permissions } from "../../../../other/enums/permissions";
import { PermissionTranslations } from "../../../../other/enums/permission-translations";
import { Role } from "../../../../models/Role";
import { Mode } from "../../../../other/enums/mode";
import { PushService } from "../../../../services/push.service";
import { TranslateService } from "@ngx-translate/core";
import { pushTypes } from "../../../../other/enums/push-types";
import { forkJoin, map } from "rxjs";

const permissionModes = ["READ", "UPDATE", "CREATE", "DELETE"];

type PermissionSection = {
  permissionType: string;
  description: PermissionTranslations | string;
  rows: PermissionRow[];
  translatedDescription?: string;
};

type PermissionRow = {
  description: PermissionTranslations | string;
  permission: Permissions | string;
  // children are Permissions that are dependent on the parent permission (e.g. CUSTOMER_READ_FROM_LOCATION is child of CUSTOMER_READ)
  children?: Permissions[];
  translatedDescription?: string;
};

@Component({
  selector: "app-add-edit-role",
  templateUrl: "./add-edit-role.component.html",
  styleUrls: ["./add-edit-role.component.scss"],
})
export class AddEditRoleComponent {
  mode: Mode = Mode.ADD;
  editRoleName = signal("");
  roleId: string = "";
  form: FormGroup;
  showDeleteDialog: boolean = false;
  permissionSections: PermissionSection[] = [];
  showDeleteButton: boolean = true;

  protected readonly tuiIconPlus = tuiIconPlus;
  protected readonly FormGroup = FormGroup;

  constructor(
    private roleService: RoleService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private pushService: PushService,
    private translateService: TranslateService,
  ) {}

  get isAddMode() {
    return this.mode === Mode.ADD;
  }

  get permissionsFormGroup(): FormGroup {
    return this.form.get("permissions") as FormGroup;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ["", Validators.required],
      description: [""],
    });

    this.getPermissions();
  }

  getMode(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      const id =
        paramMap.get("id") ?? this.route.parent?.snapshot.paramMap.get("id");
      if (id != null) {
        this.mode = Mode.EDIT;
        this.roleId = id;

        // set role form with selected role
        this.roleService.getRoleById(id).subscribe((role) => {
          this.form.patchValue({
            name: role.name,
            description: role.description,
          });
          // set permissions form controls
          for (const permission of role.permissions) {
            if (this.permissionsFormGroup.controls[permission] == null)
              continue;
            this.permissionsFormGroup.controls[permission].setValue(true);
          }

          // role tenant admin: set all permission-controls to true, disable form except name and description
          if (role.tenantAdmin || role.superAdmin) {
            this.showDeleteButton = false;
            this.onEnableAllPermissions();
            this.form.disable();
            this.form.get("name")?.enable();
            this.form.get("description")?.enable();
          }
        });

        // change page title when editing the roleName in edit-mode
        this.form.get("name")?.valueChanges.subscribe((value) => {
          if (value != null) {
            this.editRoleName.set(value);
          }
        });
      } else {
        this.mode = Mode.ADD;
      }
    });
  }

  getPermissions() {
    this.roleService.getPermissions().subscribe((permissions) => {
      // filter permissions
      const filteredPermissions = this.filterPermissions(permissions);

      // create form controls for each permission
      this.createPermissionFormControls(filteredPermissions);

      // split permissions into sections
      this.createPermissionSections(filteredPermissions);

      // get the mode after the permissions are loaded and set the form values if in edit-mode
      this.getMode();
    });
  }

  createPermissionFormControls(permissions: Permissions[]) {
    // create a form control for each permission and add it to the form
    const formControls: any = {};
    for (const permission of permissions) {
      const enumValue =
        Permissions[permission as keyof typeof Permissions] || permission;
      formControls[enumValue] = new FormControl(false);
    }
    this.form.addControl("permissions", this.fb.group(formControls));
  }

  createPermissionSections(permissions: Permissions[]) {
    this.permissionSections = [];

    for (const permission of permissions) {
      const permissionType = this.getPermissionType(permission);

      const existingSection = this.permissionSections.find(
        (section) => section.permissionType === permissionType,
      );

      const row: PermissionRow = {
        description: PermissionTranslations[permission] ?? permission,
        permission: permission,
        children: this.getAllChildPermissions(
          permission,
          permissionType,
          permissions,
        ),
      };

      if (existingSection) {
        existingSection.rows.push(row);
      } else {
        this.permissionSections.push({
          permissionType: permissionType,
          description:
            (PermissionTranslations as Record<string, string>)[
              permissionType
            ] ?? permissionType,
          rows: [row],
        });
      }
    }

    const translateObservables = this.permissionSections.map((section) =>
      this.translateService.get(section.description).pipe(
        map((translated) => {
          section.translatedDescription = translated;
          return section;
        }),
      ),
    );

    forkJoin(translateObservables).subscribe((sections) => {
      this.permissionSections = sections.sort((a, b) =>
        a.translatedDescription!.localeCompare(b.translatedDescription!),
      );
    });
  }

  // get the permission type from the permission string (e.g. "FILE_READ" -> "FILE")
  getPermissionType(permission: Permissions) {
    // extract the permission type from the permission string
    const parts = permission.split(/_(READ|UPDATE|CREATE|DELETE)/);
    // remove trailing underscore
    return parts[0].replace(/_$/, "");
  }

  // get all child permissions of a parent permission (e.g. Parent: "CUSTOMER_READ", Children: ["CUSTOMER_READ_FROM_LOCATION", "CUSTOMER_READ_FROM_TOUR" ...])
  getAllChildPermissions(
    permission: Permissions,
    type: string,
    permissions: Permissions[],
  ): Permissions[] | undefined {
    // is children if contains OWN or FROM
    if (permission.includes("OWN") || permission.includes("FROM"))
      return undefined;

    // get permission mode (e.g. READ, UPDATE, CREATE, DELETE)
    const permissionMode = this.getPermissionMode(permission);
    if (!permissionMode) return undefined;

    // get all permissions of the same type (e.g. all CUSTOMER permissions)
    const permissionsOfTypeAndMode: Permissions[] | undefined =
      permissions.filter((p) => {
        return p.startsWith(type + "_" + permissionMode);
      });
    if (!permissionsOfTypeAndMode) return undefined;

    let children: Permissions[] = [];
    // get all children of the parent permission
    for (const permissionOfType of permissionsOfTypeAndMode) {
      if (permissionOfType !== permission) {
        children.push(permissionOfType);
      }
    }
    return children;
  }

  // get the permission mode (e.g. READ, UPDATE, CREATE, DELETE)
  getPermissionMode(permission: Permissions) {
    for (const mode of permissionModes) {
      if (permission.includes(mode)) return mode;
    }
    return undefined;
  }

  onParentPermissionChange(event: boolean, children?: Permissions[]) {
    if (!children) return;

    // set all children to the same value as the parent permission
    children.forEach((child) => {
      this.permissionsFormGroup.controls[child].setValue(event);

      if (event) {
        this.permissionsFormGroup.controls[child].disable();
      } else {
        this.permissionsFormGroup.controls[child].enable();
      }
    });
  }

  onEnableAllPermissions() {
    Object.keys(this.permissionsFormGroup.controls).forEach((key) => {
      this.permissionsFormGroup.controls[key].setValue(true);
    });
  }

  onDisableAllPermissions() {
    Object.keys(this.permissionsFormGroup.controls).forEach((key) => {
      this.permissionsFormGroup.controls[key].setValue(false);
    });
  }

  onEnableAllPermissionsForSection(section: PermissionSection) {
    section.rows.forEach((row) => {
      this.permissionsFormGroup.controls[row.permission].setValue(true);
    });
  }

  onDisableAllPermissionsForSection(section: PermissionSection) {
    section.rows.forEach((row) => {
      if (this.permissionsFormGroup.controls[row.permission].enabled) {
        this.permissionsFormGroup.controls[row.permission].setValue(false);
      }
    });
  }

  filterPermissions(permission: Permissions[]) {
    // filter all permissions that contain "TENANT", "FILE" or "ADDRESS"
    permission = permission.filter(
      (permission) =>
        !permission.includes("TENANT") &&
        !permission.includes("ADDRESS") &&
        !permission.includes("FILE"),
    );

    return permission;
  }

  hasAllPermissionsEnabled(section?: PermissionSection) {
    if (section) {
      // check if all permissions in the section are enabled
      return section.rows.every(
        (row) =>
          this.permissionsFormGroup.controls[row.permission].value === true,
      );
    } else {
      // check if all permissions are enabled
      return Object.keys(this.permissionsFormGroup.controls).every(
        (key) => this.permissionsFormGroup.controls[key].value === true,
      );
    }
  }

  onCancel() {
    this.location.back();
  }

  onDelete() {
    const navigateBackAndCloseDialog = () => {
      // navigate back to the previous page and close DeleteDialog, push-notifications will be triggered in the role-service depending on the response
      this.showDeleteDialog = false;
      this.location.back();
    };
    this.roleService.deleteRole(this.roleId).subscribe({
      next: navigateBackAndCloseDialog,
      error: navigateBackAndCloseDialog,
    });
  }

  onSubmit() {
    const role = new Role({
      name: this.form.get("name")?.value,
      description: this.form.get("description")?.value,
      global: true,
      permissions: Object.keys(this.permissionsFormGroup.value).reduce(
        (result: string[], key) => {
          // add all permissions that are enabled to the result array and set it as value for the permissions property in the role object
          if (this.permissionsFormGroup.controls[key].value === true) {
            result.push(key);
          }
          return result;
        },
        [],
      ),
    });

    // check for circular permissions and return if any are found
    if (this.handleCircularPermissions(role)) return;

    const navigateBack = () => {
      // navigate back to the previous page, push-notifications will be triggered in the role-service depending on the response
      this.location.back();
    };

    if (this.isAddMode) {
      this.roleService.createRole(role).subscribe({
        next: navigateBack,
        error: navigateBack,
      });
    } else {
      this.roleService.updateRole(this.roleId, role).subscribe({
        next: navigateBack,
        error: navigateBack,
      });
    }
  }

  handleCircularPermissions(role: Role): boolean {
    // handle circular permissions
    const circularPermissions: Permissions[][] = [
      [
        Permissions.CUSTOMER_READ_FROM_LOCATION,
        Permissions.LOCATION_READ_FROM_CUSTOMER,
      ],
      [
        Permissions.CUSTOMER_UPDATE_FROM_LOCATION,
        Permissions.LOCATION_UPDATE_FROM_CUSTOMER,
      ],
      [
        Permissions.CUSTOMER_DELETE_FROM_LOCATION,
        Permissions.LOCATION_DELETE_FROM_CUSTOMER,
      ],
    ];
    for (const child of circularPermissions) {
      // if both circular permissions are enabled, show error message and return true
      if (
        role.permissions.includes(child[0]) &&
        role.permissions.includes(child[1])
      ) {
        this.translateService
          .get([
            PermissionTranslations[child[0]],
            PermissionTranslations[child[1]],
          ])
          .subscribe((translations) => {
            this.pushService.sendPush(
              pushTypes.ERROR,
              "",
              "Die Berechtigungen <b>" +
                translations[PermissionTranslations[child[0]]] +
                "</b> und <b>" +
                translations[PermissionTranslations[child[1]]] +
                "</b> können nicht gleichzeitig aktiviert sein.",
            );
          });
        return true;
      }
    }
    return false;
  }
}

import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams, HttpStatusCode } from "@angular/common/http";
import { environment } from "../environments/environment";
import { ApiRoutes } from "../other/enums/api-routes";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { catchError, map, Observable, of, tap } from "rxjs";
import { User, ViewAccess } from "../models/User";
import { UserBody } from "../interfaces/user-body";
import { UserQueryParams } from "../interfaces/user-query-params";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { GlobalUserBody } from "../interfaces/global-user-body";
import { pushTypes } from "../other/enums/push-types";
import { PushService } from "../services/push.service";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

@Injectable({
  providedIn: "root",
})
/**
 * Service Class for the getting or manipulating user data
 */
export class UserService extends PaginationFilterService {
  totalUsers = signal<number>(0);
  private viewAccessFilter: ViewAccess | undefined = undefined;
  private readonly baseUrl = environment.baseUrl;
  private readonly _users = signal<User[]>([]);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private userErrors: ErrorMessagesService,
  ) {
    super();
  }

  set viewAccess(viewAccess: ViewAccess | undefined) {
    this.viewAccessFilter = viewAccess;
  }

  get user() {
    return this._users();
  }

  set user(user: User[]) {
    this._users.set(user);
  }

  get total() {
    return this.totalUsers();
  }

  /**
   * get the user data from the api
   * sets query params for the api call if they are passed in
   * sets the totalAmount property of the parent PaginationFilterService if data is returned
   * maps the response to an array of observable user objects with the User.fromJson method
   * @param queryParams optional query params for the api call
   */
  getUsers(queryParams?: UserQueryParams) {
    let params: HttpParams = new HttpParams();

    if (this.viewAccessFilter != undefined) {
      params = params.set("viewAccess", this.viewAccessFilter);
    }

    // set the query params for the api call
    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }
    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.USER, {
        params: params,
      })
      .pipe(
        map((response) => {
          this._users.set(
            response.records.map((user: User) => {
              return new User(user);
            }),
          );
          this.totalUsers.set(response.total);
          return this._users();
        }),
      );
  }

  /**
   * get detailed user data from the api with a user id
   * @param id the id of the user to get
   */
  getUserById(id: string): Observable<User> {
    return this.http.get<any>(this.baseUrl + ApiRoutes.USER + "/" + id).pipe(
      map((user: User) => {
        return new User(user);
      }),
    );
  }

  /**
   * Sends a request to the api to create a new user
   * @param user the user to create as UserBody
   */
  createUser(user: UserBody): Observable<User | null> {
    return this.http.post<User>(this.baseUrl + ApiRoutes.USER, user).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this.getUsers().subscribe();
        this.push.sendPush(
          pushTypes.SUCCESS,
          "",
          "Mitarbeiter erfolgreich erstellt",
        );
      }),
      catchError((err) => {
        this.userErrors.sendErrorMessage("user", "CREATE", err);
        return of(null); // Default return if post fails
      }),
    );
  }

  // todo remove and use createUser instead
  createGlobalUser(globalUser: GlobalUserBody): Observable<boolean> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.USER, globalUser, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  /**
   * Sends a request to the api to patch a user
   * @param user the user to update as UserBody
   * @param id the id of the user to update
   */
  updateUser(user: UserBody, id: string): Observable<User> {
    return this.http
      .patch<User>(this.baseUrl + ApiRoutes.USER + "/" + id, user)
      .pipe(
        map((response) => {
          return response;
        }),
        tap(() => {
          this.getUsers({ withWarehouses: false }).subscribe();
          this.push.sendPush(
            pushTypes.SUCCESS,
            "",
            "Mitarbeiter erfolgreich bearbeitet",
          );
        }),
        catchError((err) => {
          this.userErrors.sendErrorMessage("user", "UPDATE", err);
          return of(new User({}));
        }),
      );
  }

  /**
   * Deletes a user from the API
   * @param id the id of the user to delete
   * @returns true if the user was deleted successfully, false if not
   */
  deleteUser(id: string): Observable<boolean> {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.USER + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        tap(() => {
          this.getUsers().subscribe();
          this.push.sendPush(
            pushTypes.SUCCESS,
            "",
            "Mitarbeiter erfolgreich gelöscht",
          );
        }),
        catchError((err) => {
          this.userErrors.sendErrorMessage("user", "DELETE", err);
          return of(false);
        }),
      );
  }
}

import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import DeliveryReceipt from "../models/DeliveryReceipt.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

export type DeliveryReceiptQueryParams = {
  tourPointId?: string;
  sort: string;
  search: string;
  createdAt: string;
  withDriver: boolean;
  withAuthor: boolean;
  withCustomer: boolean;
};

@Injectable({
  providedIn: "root",
})
export class DeliveryReceiptService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  private _totalDeliveryReceipts = signal<number>(0);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  private _deliveryReceipts = signal<DeliveryReceipt[]>([]);

  get deliveryReceipts() {
    return this._deliveryReceipts();
  }

  get total() {
    return this._totalDeliveryReceipts();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD DeliveryReceipt
  addDeliveryReceipt(
    addDeliveryReceiptObject: DeliveryReceipt,
  ): Observable<DeliveryReceipt> {
    return this.http
      .post<DeliveryReceipt>(
        this.baseUrl + "delivery-receipt",
        addDeliveryReceiptObject,
      )
      .pipe(
        tap(() => {
          // Open the snackbar for successful request
          // this.handleSuccess("Successfully added deliveryReceipt");
          // Call the getAllDeliveryReceipt method for updating the data + view
          // this.getAllDeliveryReceipts().subscribe();
        }),
        catchError((err) => {
          // Open the snackbar for error
          this.errorHandler.sendErrorMessage("delivery-receipt", "CREATE", err);
          return of(err);
        }),
      );
  }

  // GET ALL DeliveryReceipts
  getAllDeliveryReceipts(
    queryParams?: Partial<DeliveryReceiptQueryParams>,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "delivery-receipt", {
        params: params,
      })
      .pipe(
        tap((res: ResponseWithRecordsBody) => {
          this._deliveryReceipts.set(
            res.records.map(
              (deliveryReceipt: DeliveryReceipt) =>
                new DeliveryReceipt(deliveryReceipt),
            ),
          );
          this._totalDeliveryReceipts.set(res.total);
        }),
      );
  }

  // GET ONE DeliveryReceipt
  getDeliveryReceiptById(id: string | number): Observable<DeliveryReceipt> {
    return this.http.get<DeliveryReceipt>(
      this.baseUrl + "delivery-receipt/" + id,
    );
  }

  getSignatures(id: string, signatureId: string): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + "delivery-receipt/" + id + "/sign/" + signatureId,
      { responseType: "blob" as "json" },
    );
  }

  getFiles(id: string, fileId: string): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + "delivery-receipt/" + id + "/file/" + fileId,
      { responseType: "blob" as "json" },
    );
  }

  getPdfByIds(deliveryReceiptId: string, pdfId: string): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + "delivery-receipt/" + deliveryReceiptId + "/pdf/" + pdfId,
      { responseType: "blob" as "json" },
    );
  }

  uploadSignature(id: string, formData: FormData): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "delivery-receipt/" + id + "/sign",
      formData,
      {
        observe: "response",
      },
    );
  }

  uploadFile(id: string, formData: FormData): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + "delivery-receipt/" + id + "/file",
      formData,
      {
        observe: "response",
      },
    );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

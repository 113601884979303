import { Component, Inject } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import LocationC from "../../../models/Location.class";
import { LocationService } from "../../../api/location.service";
import { PushService } from "../../../services/push.service";
import { ResponseWithRecordsBody } from "../../../interfaces/response-with-recors-body";
import { forkJoin, Observable } from "rxjs";
import Address from "../../../models/Address.class";
import { pushTypes } from "../../../other/enums/push-types";
import ContactPerson from "../../../models/ContactPerson.class";
import { ContactPersonService } from "../../../api/contactPerson.service";
import { ContactPersonQueryParams } from "../../../interfaces/contact-person-query-params";
import { phoneValidator } from "../../../other/validators/phone-validator";
import { Mode } from "../../../other/enums/mode";
import { TuiDialogContext } from "@taiga-ui/core";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import Customer from "../../../models/Customer.class";
import { PermissionService } from "../../../services/permission.service";
import { MapMarkersService } from "../../map-page/map-helper-services/map-markers.service";
import { LocationPermissionsHelperService } from "../../../services/permission-helper-services/location-permissions-helper.service";
import {
  BaseMissingPermissionsDialogComponent,
  ContextData,
} from "../../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";

@Component({
  selector: "app-add-edit-locations",
  templateUrl: "./add-edit-locations.component.html",
  styleUrls: ["./add-edit-locations.component.scss"],
})
export class AddEditLocationsComponent {
  form: FormGroup;
  customerId: string = "";
  locationId: string = "";
  isOpen: boolean = true;
  mode: Mode = Mode.ADD;
  showDeleteDialog: boolean = false;
  previousContactPersons: ContactPerson[] = [];
  data: Customer;
  location?: LocationC;

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private pushService: PushService,
    private contactPersonService: ContactPersonService,
    @Inject(POLYMORPHEUS_CONTEXT) private context: TuiDialogContext<any>,
    private dialogService: TuiDialogHelperService,
    public permissionService: PermissionService,
    private mapMarkerService: MapMarkersService,
    public locationPermissionsHelper: LocationPermissionsHelperService,
  ) {}

  get contactPersons() {
    return this.form.get("contactPersons") as FormArray;
  }

  get isAddMode() {
    return this.mode === Mode.ADD;
  }

  get locations() {
    return this.form.get("locations") as FormArray;
  }

  ngOnInit(): void {
    this.getData();
    this.initForm();

    // load customer data into the form in edit mode
    if (this.isAddMode) return;
    this.initCaseEdit(this.locationId);
  }

  getData() {
    if (!this.context.data) return;

    if (this.context.data["customerId"]) {
      this.customerId = this.context.data["customerId"];
      this.mode = Mode.ADD;
    } else if (this.context.data["locationId"]) {
      this.locationId = this.context.data["locationId"];
      this.mode = Mode.EDIT;
    }
  }

  initForm() {
    // init form
    this.form = this.fb.group({
      locationName: "",
      street: ["", Validators.required],
      houseNo: ["", Validators.required],
      postalCode: [
        "",
        [Validators.required, Validators.maxLength(5), Validators.minLength(5)],
      ],
      city: ["", Validators.required],
      note: "",
      sideMounting: false,
      contactPersons: this.fb.array([]),
    });
  }

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup;
  }

  addContactPersonFormRow() {
    this.contactPersons.push(
      this.fb.group({
        contactName: ["", Validators.required],
        contactPhone: ["", [Validators.required, phoneValidator()]],
        contactEmail: ["", [Validators.email, Validators.required]],
        contactDescription: ["", Validators.required],
      }),
    );
  }

  deleteContactPersonFormRow(index: number) {
    this.contactPersons.removeAt(index);
  }

  initCaseEdit(id: string) {
    // get all locations for the customer and fill the dynamic form
    this.locationService.getLocationById(id).subscribe((res) => {
      this.location = res;

      this.form.get("locationName")?.setValue(res.name);
      this.form.get("street")?.setValue(res.address.street);
      this.form.get("houseNo")?.setValue(res.address.houseNo);
      this.form.get("postalCode")?.setValue(res.address.postalCode);
      this.form.get("city")?.setValue(res.address.city);
      this.form.get("note")?.setValue(res.note);
      this.form.get("sideMounting")?.setValue(res.sideMounting);
    });

    const queryParamsContactPerson: ContactPersonQueryParams = {
      locationId: this.locationId,
    };

    this.contactPersonService
      .getAllContactPersons(queryParamsContactPerson)
      .subscribe((res: ResponseWithRecordsBody) => {
        if (res.records.length === 0) return;
        const contactPersons: ContactPerson[] = res.records;
        this.previousContactPersons = [...contactPersons];
        this.contactPersons.removeAt(0);
        contactPersons.forEach((contactPerson: ContactPerson) => {
          this.contactPersons.push(
            this.fb.group({
              id: [contactPerson.id], // to identify contact persons that have to be patched or deleted
              contactName: [contactPerson.name, Validators.required],
              contactPhone: [
                contactPerson.phone,
                [Validators.required, phoneValidator()],
              ],
              contactEmail: [
                contactPerson.email,
                [Validators.email, Validators.required],
              ],
              contactDescription: [
                contactPerson.description,
                Validators.required,
              ],
            }),
          );
        });
      });
  }

  async submit() {
    if (this.form.invalid) return;

    const address: Address = new Address({
      street: this.form.value.street,
      houseNo: this.form.value.houseNo,
      postalCode: this.form.value.postalCode,
      city: this.form.value.city,
    });

    await this.getAddressWithGeoCode(address);

    const location: LocationC = new LocationC({
      customerId: this.customerId,
      name: this.form.value.locationName,
      address: address,
      note: this.form.value.note,
      sideMounting: this.form.value.sideMounting,
    });

    if (this.isAddMode) {
      this.addLocation(location);
    } else {
      this.updateLocation(location);
    }
  }

  addLocation(location: LocationC) {
    // add new location
    this.locationService.addLocation(location).subscribe({
      next: (res) => {
        this.addContactPersons(res);
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  addContactPersons(res: LocationC) {
    const requestObservables: Observable<any>[] = [];

    for (const contactPersonControl of this.contactPersons.controls) {
      const contactPerson: ContactPerson = new ContactPerson({
        name: contactPersonControl.get("contactName")?.value,
        phone: contactPersonControl.get("contactPhone")?.value,
        description: contactPersonControl.get("contactDescription")?.value,
        email: contactPersonControl.get("contactEmail")?.value,
        locationId: res.id,
      });
      requestObservables.push(
        this.contactPersonService.addContactPerson(contactPerson),
      );
    }

    this.handleMultipleRequests(requestObservables);
  }

  updateLocation(location: LocationC) {
    // update existing location
    this.locationService
      .updateLocationById(this.locationId, location)
      .subscribe({
        next: () => {
          this.addOrUpdateContactPersons();
        },
        error: () => {
          this.showErrorMessage();
        },
      });
  }

  addOrUpdateContactPersons() {
    const requestObservables: Observable<any>[] = [];

    for (const element of this.contactPersons.controls) {
      const contactPersonControl: AbstractControl = element;
      const contactPerson: ContactPerson = new ContactPerson({
        name: contactPersonControl.get("contactName")?.value,
        phone: contactPersonControl.get("contactPhone")?.value,
        description: contactPersonControl.get("contactDescription")?.value,
        email: contactPersonControl.get("contactEmail")?.value,
        locationId: this.locationId,
      });

      // check if contact person already exists
      const contactPersonId: string = contactPersonControl.get("id")?.value;
      const previousContactPerson: ContactPerson | undefined =
        this.previousContactPersons.find((cp) => cp.id === contactPersonId);

      if (
        contactPersonId &&
        previousContactPerson &&
        (previousContactPerson.name !== contactPerson.name ||
          previousContactPerson.phone !== contactPerson.phone ||
          previousContactPerson.description !== contactPerson.description ||
          previousContactPerson.email !== contactPerson.email)
      ) {
        // update contact person
        requestObservables.push(
          this.contactPersonService.updateContactPersonById(
            contactPersonId,
            contactPerson,
          ),
        );
      } else if (!contactPersonId) {
        // add new contact person
        requestObservables.push(
          this.contactPersonService.addContactPerson(contactPerson),
        );
      }
    }

    for (const element of this.previousContactPersons) {
      const previousContactPerson = element;
      if (previousContactPerson.id == null) continue;

      const deleteObservable = this.contactPersons.controls.some(
        (contactPersonControl) => {
          return (
            contactPersonControl.get("id")?.value === previousContactPerson.id
          );
        },
      )
        ? null
        : this.contactPersonService.deleteContactPersonById(
            previousContactPerson.id,
          );
      if (deleteObservable != null) {
        requestObservables.push(deleteObservable);
      }
    }

    this.handleMultipleRequests(requestObservables);
  }

  deleteLocation() {
    this.locationService.deleteLocationById(this.locationId).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.closeDialog();
        if (!this.location) return;
        this.mapMarkerService.removeMarker(this.location);
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  async getAddressWithGeoCode(address: Address) {
    const addressGeoString = `${address.street} ${address.houseNo}, ${address.postalCode} ${address.city}`;
    // Geocode address
    const geocoder = new google.maps.Geocoder();
    await geocoder.geocode({ address: addressGeoString }, (results, status) => {
      if (status === "OK") {
        if (results) {
          const location = results[0].geometry.location;
          address.latitude = location.lat();
          address.longitude = location.lng();
        } else {
          console.error(
            "Geocoding was not successful for the following reason:",
            status,
          );

          this.pushService.sendPush(
            pushTypes.ERROR,
            "",
            "Die Adresse konnte nicht gefunden werden. Bitte prüfe die Richtigkeit der Adresse!",
          );
        }
      }
    });
    return address;
  }

  showErrorMessage() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      "",
      "Beim speichern der Standorte ist ein Fehler aufgetreten.",
    );
  }

  showSuccessMessage() {
    this.pushService.sendPush(
      pushTypes.SUCCESS,
      "",
      "Standorte erfolgreich gespeichert.",
    );
  }

  handleMultipleRequests(requestObservables: Observable<any>[]) {
    if (requestObservables.length === 0) {
      // No Contact Person updates or additions, show success message for updated location
      this.showSuccessMessage();
      this.closeDialog();
    } else {
      // combines all observable for all requests, the subscription is only called when all requests are finished
      forkJoin(requestObservables).subscribe({
        next: () => {
          // Success handling
          this.showSuccessMessage();
        },
        error: () => {
          // Error handling
          this.showErrorMessage();
        },
        complete: () => {
          // update data
          this.closeDialog();
        },
      });
    }
  }

  closeDialog() {
    this.dialogService.close(this.context);
  }

  openMissingPermissionsDialog() {
    const permissions: ContextData = {
      mode: "add",
      elementName: "Lieferadressen",
      permissions: [
        {
          name: "Lieferadressen ansehen",
          hasPermission:
            this.permissionService.readLocationPermission().hasPermission,
        },
      ],
    };

    this.dialogService.openDialog(
      BaseMissingPermissionsDialogComponent,
      permissions,
    );
  }
}

import { Component, effect, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "../../api/user.service";
import { User, ViewAccess } from "../../models/User";
import { RoleService } from "../../api/role.service";
import { FilterTab } from "../../layouts/table-layout/table-layout.component";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import { AddEditUserComponent } from "./add-edit-user/add-edit-user.component";
import { TablePageComponent } from "../../interfaces/table-page-component";
import { TableHelperService } from "../../services/table-helper.service";
import { Permissions } from "../../other/enums/permissions";
import { PermissionService } from "../../services/permission.service";
import { UserPermissionHelperService } from "../../services/permission-helper-services/user-permission-helper.service";
import { MissingUserPermissionsDialogComponent } from "./missing-user-permissions-dialog/missing-user-permissions-dialog.component";

enum FilterTabValues {
  ALL = "all",
  DRIVER = "driver",
  WAREHOUSE_WORKER = "warehouse-worker",
}

@Component({
  selector: "app-user-page",
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.scss"],
})
/**
 * Component page for the user page.
 * Serves as a container for the user table and the create user side modal.
 */
export class UserPageComponent
  implements OnInit, OnDestroy, TablePageComponent
{
  userTableData: User[] = [];

  // names for corresponding object properties
  userTableShownColumns: string[] = [
    "role.name",
    "firstName",
    "lastName",
    "phone",
    "email",
    "active",
    "role",
    "invite",
    "edit",
    "delete",
  ];

  noSortingColumns = ["role.name"];
  userFilterTabs: FilterTab<FilterTabValues>[] = [
    {
      label: "common.select-all",
      value: FilterTabValues.ALL,
      isActive: true,
    },
    {
      label: "role.driver",
      value: FilterTabValues.DRIVER,
      isActive: false,
    },
    {
      label: "role.warehouse-worker",
      value: FilterTabValues.WAREHOUSE_WORKER,
      isActive: false,
    },
  ];
  tablePage: number = 0;
  tablePageSize: number = 10;
  isOpen: boolean = false;
  toDeleteId: null | string;
  showDeleteDialog: boolean = false;
  protected readonly Permissions = Permissions;

  constructor(
    public userService: UserService,
    private roleService: RoleService,
    private dialogService: TuiDialogHelperService,
    public tableHelperService: TableHelperService,
    public permissionService: PermissionService,
    private userPermissions: UserPermissionHelperService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.userService);
    effect(() => {
      this.userTableData = this.userService.user;
    });
  }

  ngOnInit(): void {
    this.newInit();
  }

  search(searchInput: string) {
    this.tableHelperService.resetTablePagination();
    this.userService.search = searchInput;
    this.populateTable();
  }

  populateTable() {
    this.userService.getUsers({ withWarehouses: false }).subscribe();
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  newInit() {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.resetTabToAll();
    this.roleService.getRoles().subscribe();
  }

  resetTabToAll() {
    this.onFilterTabClick(FilterTabValues.ALL);
  }

  editUser(id: string): void {
    if (this.userPermissions.hasEditReadUserPermission()) {
      this.dialogService.openDialog(AddEditUserComponent, id);
    } else {
      this.dialogService.openDialog(MissingUserPermissionsDialogComponent, id);
    }
  }

  createUser() {
    if (this.userPermissions.hasCreateReadUserPermission()) {
      this.dialogService.openDialog(AddEditUserComponent, null);
    } else {
      this.dialogService.openDialog(MissingUserPermissionsDialogComponent);
    }
  }

  onDelete(event: string) {
    this.toDeleteId = event!;
    this.showDeleteDialog = true;
  }

  deleteUser() {
    if (!this.toDeleteId) return;

    const closeDialogAndResetId = () => {
      this.toDeleteId = null;
      this.showDeleteDialog = false;
    };

    this.userService.deleteUser(this.toDeleteId).subscribe({
      next: () => closeDialogAndResetId(),
      error: () => closeDialogAndResetId(),
    });
  }

  onFilterTabClick(tab: FilterTabValues) {
    // Update isActive for each tab
    this.userFilterTabs.forEach((oneTab) => {
      oneTab.isActive = oneTab.value === tab;
    });

    // Reset pagination whenever a new tab is clicked
    this.tableHelperService.resetTablePagination();

    switch (tab) {
      case FilterTabValues.ALL:
        // set the view access filter to undefined (all) and get the users
        this.userService.viewAccess = undefined;
        this.populateTable();
        break;
      case FilterTabValues.DRIVER:
        // set the view access filter to driver and get the users
        this.userService.viewAccess = ViewAccess.DRIVER;
        this.populateTable();
        break;
      case FilterTabValues.WAREHOUSE_WORKER:
        // set the view access filter to warehouse worker and get the users
        this.userService.viewAccess = ViewAccess.WAREHOUSEWORKER;
        this.populateTable();
        break;
    }
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { DatePipe, NgOptimizedImage, NgStyle } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { TuiIslandModule } from "@taiga-ui/kit";
import Order from "../../../../models/Order.class";
import LocationC from "../../../../models/Location.class";
import { DateConverterService } from "../../../../services/date-converter.service";
import { Observable, Subscription } from "rxjs";
import { MapMarkersService } from "../../map-helper-services/map-markers.service";
import { TuiSvgModule } from "@taiga-ui/core";
import { ArrowComponent } from "../../../../svg-components/arrow-icon/arrow.component";

@Component({
  selector: "app-one-order",
  standalone: true,
  imports: [
    NgOptimizedImage,
    TranslateModule,
    TuiIslandModule,
    NgStyle,
    TuiSvgModule,
    ArrowComponent,
    DatePipe,
  ],
  templateUrl: "./one-order.component.html",
  styleUrl: "./one-order.component.scss",
})
export class OneOrderComponent implements OnInit, OnDestroy {
  hoverSubscription: Subscription;
  leaveHoverSubscription: Subscription;
  sameLocation: boolean = false;

  @Input({ required: true }) order: Order;
  @Input() selectedOrder: Order | null;
  @Input() location: LocationC | null;

  @Output() isVisible = new EventEmitter<boolean>();

  protected readonly Math = Math;

  constructor(
    public dateHelper: DateConverterService,
    private markerService: MapMarkersService,
  ) {}

  ngOnInit(): void {
    this.addHoverSubscription(
      this.markerService.hoverLocation,
      (id) => this.order.location && this.order.location.id === id,
      true,
      (sub) => (this.hoverSubscription = sub),
    );
    this.addHoverSubscription(
      this.markerService.leaveHoverLocation,
      (id) => this.order.location && this.order.location.id === id,
      false,
      (sub) => (this.leaveHoverSubscription = sub),
    );
  }

  ngOnDestroy(): void {
    this.hoverSubscription.unsubscribe();
    this.leaveHoverSubscription.unsubscribe();
  }

  addHoverSubscription(
    obs: Observable<any>,
    check: (arg: any) => boolean,
    value: boolean,
    assign: (sub: Subscription) => void,
  ): void {
    const sub = obs.subscribe((id) => {
      if (check(id)) {
        this.sameLocation = value;
      }
    });
    assign(sub);
  }
}

import { Component, Input, signal } from "@angular/core";
import { AuthService } from "../../api/auth.service";
import { Permissions } from "../../other/enums/permissions";
import { PermissionTranslations } from "../../other/enums/permission-translations";

@Component({
  selector: "app-no-read-permission",
  templateUrl: "./no-read-permission.component.html",
  styleUrl: "./no-read-permission.component.scss",
})
export class NoReadPermissionComponent {
  @Input({ required: true }) missingPermissions: Permissions[] = [];
  @Input() centerContent: boolean = false;
  @Input() onlyInfoText: boolean = false;

  differentPermissionTypeAsNextIndex(permission: Permissions): boolean {
    const currentIndex = this.missingPermissions.indexOf(permission);

    // if the permission is not in the list, return false
    if (currentIndex == -1) {
      return false;
    }

    // if the permission is the last in the list, return false
    if (currentIndex == this.missingPermissions.length - 1) {
      return false;
    }

    // if the next permission is of a different type, return true
    return (
      this.getPermissionType(permission) !==
      this.getPermissionType(this.missingPermissions[currentIndex + 1])
    );
  }

  userRoleName = signal("");
  protected readonly PermissionTranslations = PermissionTranslations;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.userRoleName.set(this.authService.getLoggedInUser()?.role?.name ?? "");
  }

  // get the permission type from the permission string (e.g. "FILE_READ" -> "FILE")
  getPermissionType(permission: Permissions) {
    // extract the permission type from the permission string
    const parts = permission.split(/_(READ|UPDATE|CREATE|DELETE)/);
    // remove trailing underscore
    return parts[0].replace(/_$/, "");
  }
}

import { BaseModel } from "./BaseModel";
import Address from "./Address.class";
import { User } from "./User";
import ProductOrder from "./ProductOrder.class";

export default class Warehouse extends BaseModel<Warehouse> {
  tenantId: string;
  name: string;
  address: Address;
  worker: User[];
  productOrders: ProductOrder[];

  constructor(params: Partial<Warehouse>) {
    super(params);
    Object.assign(this, params);
  }
}

<div [ngClass]="rowDirection ? 'row' : ''">
  <div *ngFor="let item of items">
    <tui-radio-labeled [item]="item.id" formControlName="{{ fieldName }}">
      <div class="flex-align-center" style="justify-content: space-between">
        <div class="description">{{ item.name | translate }}</div>
        <tui-tooltip
          *ngIf="item.description != null && item.description != ''"
          [content]="item.description"
          direction="top"
        ></tui-tooltip>
      </div>
    </tui-radio-labeled>
  </div>
</div>

import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import Location from "../models/Location.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { LocationQueryParams } from "../interfaces/LocationQueryParams.interface";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private push: PushService,
  ) {}

  private _locations = signal<Location[]>([]);

  get locations() {
    return this._locations();
  }

  set locations(value: Location[]) {
    this._locations.set(value);
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD Location
  addLocation(addLocationObject: Location): Observable<Location> {
    return this.http
      .post<Location>(this.baseUrl + "location", addLocationObject)
      .pipe(
        tap(() => {
          // Call the getAllLocation method for updating the data + view
          this.handleSuccess("Lieferadresse wurde erstellt!");
          this.getAllLocations().subscribe();
        }),
      );
  }

  // GET ALL Locations
  getAllLocations(
    queryParams?: LocationQueryParams,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "location", { params })
      .pipe(
        tap((res: ResponseWithRecordsBody) =>
          this._locations.set(
            res.records.map((location: Location) => new Location(location)),
          ),
        ),
      );
  }

  // GET ONE Location
  getLocationById(id: string | number): Observable<Location> {
    return this.http.get<Location>(this.baseUrl + "location/" + id);
  }

  // CHANGE ONE Location
  updateLocationById(
    id: string | number,
    updateLocationObject: Location,
  ): Observable<Location> {
    return this.http
      .patch<Location>(this.baseUrl + "location/" + id, updateLocationObject)
      .pipe(
        tap(() => {
          // Call the getAllLocations method for updating the data + view
          this.getAllLocations().subscribe();
        }),
      );
  }

  // DELETE ONE Location
  deleteLocationById(id: number | string): Observable<unknown> {
    return this.http.delete<unknown>(this.baseUrl + "location/" + id).pipe(
      tap(() => {
        // Call the getAllLocations method for updating the data + view
        this.getAllLocations().subscribe();
      }),
    );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

<tui-combo-box
  (tuiValueChanges)="onChange($event)"
  [readOnly]="readOnly"
  [stringify]="stringify"
  [tuiTextfieldSize]="size"
  class="dropdown"
  formControlName="{{ formFieldName }}"
>
  <span [ngStyle]="{ color: hintColor }">
    {{ hint | translate }}
  </span>
  <span *ngIf="required" class="tui-required"></span>

  @if (useVirtualScroll) {
    <ng-container *tuiDataList>
      <cdk-virtual-scroll-viewport
        *tuiLet="dropDownItems | tuiFilterByInputWith: stringify as items"
        tuiScrollable
        class="scroll"
        [itemSize]="44"
        [style.height.px]="items.length * 44"
        (indexChange)="list.handleFocusLossIfNecessary()"
      >
        <tui-data-list #list>
          <button *cdkVirtualFor="let item of items" tuiOption [value]="item">
            {{ stringify(item) }}
          </button>
        </tui-data-list>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  } @else {
    <tui-data-list-wrapper
      *tuiDataList
      [itemContent]="stringify | tuiStringifyContent"
      [items]="dropDownItems | tuiFilterByInputWith: stringify"
    ></tui-data-list-wrapper>
  }
</tui-combo-box>

<!-- layout with title + button -->
<app-table-layout
  (btnClick)="createTour()"
  (dateSelected)="changeDateEvent(extractDate($event))"
  (tabClick)="onFilterTabClick($event)"
  [missingReadPermissions]="
    permissionService.readTourPermission().missingPermissions
  "
  [showAddButton]="permissionService.createTourPermission().hasPermission"
  [showDatePicker]="true"
  [showTable]="permissionService.readTourPermission().hasPermission"
  [tabs]="tourTabs"
  dateHint="tour.search-date"
  newButtonTxt="tour.new-title"
  ngxTitle="tour.title"
>
  <!--  search field  -->
  <div class="search-container" header-item id="filter-wrapper">
    <!-- Dropdown Selection for State   -->
    <tui-select
      (ngModelChange)="populateTable($event)"
      [(ngModel)]="filterSelect"
      [stringify]="'label' | tuiStringify"
      style="min-width: 12rem !important"
      tuiTextfieldSize="m"
    >
      {{ "order.status" | translate }}
      <tui-data-list-wrapper
        *tuiDataList
        [itemContent]="'label' | tuiStringify | tuiStringifyContent"
        [items]="
          filterSelectOptions | tuiFilterByInputWith: ('label' | tuiStringify)
        "
      >
      </tui-data-list-wrapper>
    </tui-select>

    <div class="search-form">
      <app-table-search-input
        (searchChangedDebounced)="search($event)"
      ></app-table-search-input>
    </div>
  </div>

  <!-- table -->
  <app-table
    (deleteEvent)="openDeleteDialog($event)"
    (editEvent)="editTour($event)"
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (rowClickEvent)="routeToTour($event)"
    (sortEvent)="tableHelperService.sortTable($event)"
    [cellTemplatesMap]="{
      vcAuthor,
      tourPointsAmount,
      products,
      customers,
      productsAmount,
      customEdit,
      deleter
    }"
    [columns]="columns"
    [currentPage]="tablePage"
    [noSortColumns]="noSortColumns"
    [pageSize]="tablePageSize"
    [tableColumnNames]="headers"
    [tableData]="tours"
    [totalElements]="tourService.totalTours"
  >
    <!-- vcAuthor -->
    <ng-template #vcAuthor let-object="object">
      {{ object.vcAuthor?.firstName + " " + object.vcAuthor?.lastName }}
    </ng-template>

    <!-- tourPointsAmount -->
    <ng-template #tourPointsAmount let-object="object">
      {{ tourPointsMap.get(object.id)?.total }}
    </ng-template>

    <!--  products  -->
    <ng-template #products let-object="object">
      @for (
        productName of tourProductInfoMap.get(object.id)?.productNames;
        track productName
      ) {
        {{ productName }}
        @if (!$last) {
          ,
        }
      }
    </ng-template>

    <!--  products amount  -->
    <ng-template #productsAmount let-object="object">
      {{ tourProductInfoMap.get(object.id)?.totalAmount }}
    </ng-template>

    <!--  customers  -->
    <ng-template #customers let-object="object">
      @if (
        tourPointsMap.get(object.id) && tourPointsMap.get(object.id)?.records
      ) {
        @for (
          name of getUniqueNames(tourPointsMap.get(object.id)!.records);
          track name
        ) {
          {{ name }}
          @if (!$last) {
            ,
          }
        }
      }
    </ng-template>

    <!--  edit + delete icons  -->
    <ng-template #customEdit let-object="object">
      @if (object.tourState && object.tourState !== TOUR_STATE.FINISHED) {
        <app-edit-delete-table-icons
          [deleteDisabled]="
            (object.tourState && object.tourState === TOUR_STATE.CANCELLED) ||
            !permissionService.deleteTourPermission().hasPermission
          "
          [editDisabled]="
            (object.tourState && object.tourState === TOUR_STATE.CANCELLED) ||
            !permissionService.updateTourPermission().hasPermission
          "
          (editEvent)="editTour(object.id)"
          (openDeleteDialogEvent)="openDeleteDialog(object.id)"
        />
      }
    </ng-template>

    <ng-template #deleter let-object="object">
      {{ object.deleter?.firstName }}
      {{ object.deleter?.lastName }}
    </ng-template>
  </app-table>
</app-table-layout>

<!-- sure want to delete dialog -->
<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="deleteTour()"
  [isOpen]="showDeleteDialog"
/>

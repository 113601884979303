/**
 * Api routes enum:
 * contains all the api routes as strings
 */
export enum ApiRoutes {
  USER = "user",
  ROLE = "role",
  TENANT = "tenant",
  AUTH = "auth",
  VERSION = "version",
}

<div class="permission-wrapper" style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um Touren löschen zu können:
  </h3>

  <ul>
    <li *ngIf="!tourPermissions.hasAllDeleteTourPermissions">
      <b>Tourpunkte löschen</b>
    </li>
  </ul>
</div>

import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TUI_VALIDATION_ERRORS } from "@taiga-ui/kit";
import { AuthService } from "../../api/auth.service";
import { PushService } from "../../services/push.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { pushTypes } from "../../other/enums/push-types";

@Component({
  selector: "app-log-in-page",
  templateUrl: "./log-in-page.component.html",
  styleUrls: ["./log-in-page.component.scss"],
  providers: [
    {
      // To overwrite error messages
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: "Diese Feld darf nicht leer sein", // TODO: translate validation
        email: "Gib ein gültige E-Mail-Adresse ein", // TODO: translate validation
      },
    },
  ],
})

/**
 * This component is responsible for the login page.
 * It contains a form for the login credentials.
 * It offers an option to reset the password.
 */
export class LogInPageComponent {
  readonly loginForm = new FormGroup({
    emailValue: new FormControl(``, [Validators.required]),
    passwordValue: new FormControl(``, [Validators.required]),
  });
  isOpen = false; // determines whether the push is open or not
  errorPushTitle!: string;
  errorPushDescription!: string;

  constructor(
    private authService: AuthService,
    private pushService: PushService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    // Subscribes to the translations for the push message
    this.translate
      .get(["login.login-failed-description", "login.login-failed-title"])
      .subscribe((translations) => {
        this.errorPushTitle = translations["login.login-failed-title"];
        this.errorPushDescription =
          translations["login.login-failed-description"];
      });
  }

  /**
   * Sends a post request to the backend to login the user.
   * Includes some client side validation for the login form
   */
  submitLogin() {
    if (this.loginForm.valid) {
      this.authService
        .login({
          username: this.loginForm.controls.emailValue.value!,
          password: this.loginForm.value.passwordValue!,
        })
        .subscribe((loginSuccessful) => {
          if (loginSuccessful) {
            this.router.navigate(["/"]);
          } else {
            this.pushService.sendPush(
              pushTypes.ERROR,
              this.errorPushTitle,
              this.errorPushDescription,
            );
          }
        });
    }
  }
}

<div class="input-wrapper">
  <tui-input-password
    [ngStyle]="{ 'border-radius': bRadius }"
    class="input"
    formControlName="{{ fieldName }}"
  >
    <span [ngStyle]="{ color: hintColor }" class="hint">
      {{ hint }}
    </span>

    <span class="tui-required"></span>
  </tui-input-password>

  <ng-container class="error-wrapper">
    <tui-error
      [error]="[] | tuiFieldError | async"
      formControlName="{{ fieldName }}"
    ></tui-error>
  </ng-container>
</div>

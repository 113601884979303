@if (customer) {
  <div class="container" style="height: 94dvh">
    <div class="header-container">
      <!-- title -->
      <div class="detail-page-header">
        <tui-svg
          (click)="this.location.back()"
          src="tuiIconChevronLeftLarge"
        ></tui-svg>
        <h1 style="padding-left: 1rem">
          {{ "customer-details.page-title" | translate }}
        </h1>
        @if (customer.customerNumber) {
          <h4>
            {{ "customer.customerNumber" | translate }}:
            {{ customer.customerNumber }}
          </h4>
        }
        @if (customer.MATCHC) {
          <h4 style="margin-left: 1rem">
            MATCHC:
            {{ customer.MATCHC }}
          </h4>
        }
      </div>

      <div class="actions">
        <!-- create individual price button -->
        @if (permissionService.createProductPricePermission().hasPermission) {
          <app-transparent-btn
            (btnClickEvent)="createCustomerPrice()"
            [i18StringText]="'customer.create-price'"
            [iconValue]="tuiIconDollarSignLarge"
          />
        }

        <!-- edit button -->
        @if (permissionService.updateCustomerPermission().hasPermission) {
          <app-transparent-btn
            (btnClickEvent)="editCustomer()"
            [i18StringText]="'customer-details.editCustomer'"
            [iconValue]="tuiIconEdit2Large"
          />
        }
      </div>
    </div>

    <div id="detail-page">
      <div class="left">
        @if (customer) {
          <app-half-transparent-card
            [showEllipsis]="{ ellipse1: true, ellipse2: false }"
            [title]="helperService.trimCommasFromStartAndEnd(customer.name)"
            [maxWidth]="'27rem'"
            [titlePadding]="'1rem'"
            [fontSize]="'1.4rem'"
          >
            <div class="max-height-wrapper">
              <!-- CUSTOMER INFOS -->
              @if (customer.email) {
                <div class="row" [title]="customer.email">
                  <tui-svg src="tuiIconMailLarge"></tui-svg>
                  {{ " " + customer.email }}
                </div>
              }

              @if (customer.phone || customer.fax) {
                <div class="two-cols">
                  @if (customer.phone) {
                    <div class="row">
                      <tui-svg src="tuiIconPhoneLarge"></tui-svg>
                      {{ " " + customer.phone }}
                    </div>
                  }
                  @if (customer.fax) {
                    <div class="row">
                      <tui-svg src="tuiIconPrinterLarge"></tui-svg>
                      {{ " " + customer.fax }}
                    </div>
                  }
                </div>
              }

              <!-- CUSTOMER BILL -->
              @if (customerHasBillingAddress() || customer.taxes) {
                @if (customerHasBillingAddress()) {
                  <h4 style="margin: 0">
                    {{ "customer-details.bill-address" | translate }}
                  </h4>
                  <div [innerHTML]="getCustomerBillingAddressString()"></div>
                }
                @if (customer.taxes) {
                  <div class="row" style="margin-top: 0.5rem">
                    <h4 style="margin: 0">
                      {{ "addEditCustomer.bill-tax" | translate }}:
                    </h4>
                    {{ " " + customer.taxes }}
                  </div>
                }
              }

              <!-- CONTACT PERSONS -->
              @if (customer.contactPersons.length > 0) {
                <h4 style="margin: 0">
                  {{ "contactPerson.title" | translate }}
                </h4>
              }
              @for (contact of customer.contactPersons; track contact) {
                <div class="contact">
                  <!-- name -->
                  <div class="flex-align-center" [title]="contact.description">
                    <tui-svg src="tuiIconUser"></tui-svg>
                    {{ contact.name }}
                  </div>

                  <!-- phone -->
                  <div class="flex-align-center" [title]="contact.phone">
                    <tui-svg src="tuiIconPhone"></tui-svg>
                    {{ contact.phone }}
                  </div>
                </div>

                <!-- email -->
                <div class="flex-align-center" [title]="contact.email">
                  <tui-svg src="tuiIconMail"></tui-svg>
                  {{ contact.email }}
                </div>

                @if (!$last) {
                  <div class="spacer"></div>
                }
              }

              <!-- CUSTOMER NOTES -->
              @if (customer.note) {
                <h4 style="margin: 0">
                  {{ "product.note" | translate }}
                </h4>

                <div [innerHTML]="customer.note"></div>
              }
            </div>
          </app-half-transparent-card>
        }

        @if (
          permissionService.createLocationPermission().hasPermission ||
          permissionService.readLocationPermission().hasPermission
        ) {
          <!-- CUSTOMER LOCATIONS -->
        }
        @if (permissionService.createLocationPermission().hasPermission) {
          <app-transparent-btn
            (btnClickEvent)="addLocation()"
            [i18StringText]="'customer-details.addLocation'"
            [iconValue]="tuiIconPlusLarge"
            style="margin-bottom: 0.5rem; width: 100%"
          />
        } @else if (permissionService.readLocationPermission().hasPermission) {
          <h1 style="padding-left: 0.5rem; margin-bottom: 0.5rem">
            {{ "customer-details.locations" | translate }}
          </h1>
        }

        @if (
          permissionService.readLocationPermission().hasPermission &&
          locations &&
          locations.length > 0
        ) {
          <!--  tuiIconEdit2Large    -->
          <div class="scrollable-y location-wrapper">
            <app-location-card-loop
              (editEvent)="editLocation($event)"
              [locations]="locations"
            />
          </div>
        }
      </div>

      <div class="right">
        <app-half-transparent-card
          [backgroundColor]="'#222C28'"
          [showEllipsis]="{ ellipse1: true, ellipse2: true }"
        >
          <div class="row">
            <div>
              <h1>{{ ordersCount() }}</h1>
              <p>{{ "customer-details.order-total" | translate }}</p>
            </div>
            <div>
              <h1>{{ onTime() }} %</h1>
              <p>{{ "customer-details.delivered" | translate }}</p>
            </div>
            <div>
              <h1>
                {{
                  salesVolumeInMonth()
                    | currency: "EUR" : "symbol-narrow" : "1.2-2" : "de-DE"
                }}
              </h1>
              <p>
                {{ "customer-details.sales-volume-since" | translate }}
                <span> 1 {{ "customer-details.month" | translate }}</span>
              </p>
            </div>
          </div>
        </app-half-transparent-card>

        <!-- customer files -->
        @if (files && files.length > 0) {
          <div style="color: black">
            <h1>Angeheftete Dateien</h1>

            <div
              style="
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
                align-items: center;
              "
            >
              @for (file of files; let i = $index; track file) {
                <button
                  size="s"
                  tuiButton
                  appearance="outline"
                  (click)="openFile(file)"
                  style="
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 0;
                  "
                >
                  {{ file.size + " " + file.type }}

                  <ng-container *ngIf="file.type.startsWith('image/')">
                    <img
                      style="padding-left: 0.25rem"
                      [src]="imageMap.get(i)"
                      alt="customer data"
                      width="28px"
                      height="28px"
                    />
                  </ng-container>

                  <tui-svg [src]="tuiIconArrowUpRight"></tui-svg>
                </button>
              }
            </div>
          </div>
        }
        <!--    Orders  -->
        <h1 style="color: black; margin-top: 2rem">
          {{ "customer-details.orders" | translate }}
        </h1>
        @if (permissionService.readOrderPermission().hasPermission) {
          <app-order-table
            [tablePage]="tablePage"
            [tablePageSize]="tablePageSize"
            [itemsDeletable]="false"
            [itemsEditable]="false"
            [hideDataColumns]="[
              'location.customer.MATCHC',
              'location.customer.customerNumber',
              'totalAmount'
            ]"
            [hideHeaderColumns]="['Kundennummer', 'MATCHC', 'Gesamtanzahl']"
            [orders]="orderService.orders"
            (paginationEvent)="tableHelperService.paginationEvent($event)"
            (sortEvent)="sortTable($event)"
          ></app-order-table>
        } @else {
          <app-no-read-permission
            [missingPermissions]="
              permissionService.readOrderPermission().missingPermissions
            "
            [onlyInfoText]="true"
          ></app-no-read-permission>
        }
        <!--    prices  -->
        <app-price-overview [customer]="customer" />
      </div>
    </div>
  </div>
}

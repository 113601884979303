import { ORDER_STATE } from "../../models/Order.class";
import { TOUR_STATE } from "../../models/Tour.class";

// Returns the status of an order or tour as a string that can be used as a badge status
export default function getBadgeStatus(status: string) {
  switch (status) {
    case ORDER_STATE.UNPLANNED:
      return "neutral";
    case ORDER_STATE.PARTLY_PLANNED:
      return "warning";
    case ORDER_STATE.PLANNED:
      return "info";
    case ORDER_STATE.CANCELLED:
      return "error";
    case ORDER_STATE.FINISHED:
      return "success";
    case TOUR_STATE.DRAFT:
      return "neutral";
    case TOUR_STATE.READY:
      return "info";
    case TOUR_STATE.CANCELLED:
      return "error";
    case TOUR_STATE.FINISHED:
      return "success";
    default:
      return "neutral";
  }
}

<div class="all-container">
  <!-- TOUR POINTS -->
  <div class="tourPointsContainer">
    <div class="header">
      <!--   Back Arrow   -->
      <tui-svg (click)="navigateBack()" src="tuiIconChevronLeftLarge"></tui-svg>

      <!--   title   -->
      <h1>{{ "allPlannings.newPlanning" | translate }}</h1>
    </div>

    @if (mapTPService.tour) {
      <!--  tour date  -->
      <div class="tourDate">
        {{ "map.starting-time" | translate }}:
        <b>{{ mapTPService.tour.date | date: "dd.MM.yyyy" }}</b>

        <form style="min-width: 7rem" [formGroup]="timeForm">
          <!--    start time  -->
          <tui-input-time
            [items]="tuiTimeValues"
            [strict]="true"
            [readOnly]="!mapPermissionHelper.hasUpdateTourPermissions"
            formControlName="startingTime"
            tuiTextfieldSize="s"
            (tuiValueChanges)="onStartingTimeChange($event)"
          >
            <span style="color: white">
              {{ "new-appointment.input-start-time" | translate }}
            </span>
            <span [class.tui-required]="true"></span>
          </tui-input-time>
        </form>
      </div>
    }

    <div cdkDropListGroup>
      <div
        (cdkDropListDropped)="drop($event)"
        [cdkDropListData]="mapTPService.tourPoints"
        cdkDropList
        class="tp-list"
      >
        <!--  Tour Points  -->
        @for (
          tourPoint of mapTPService.tourPoints;
          track tourPoint;
          let i = $index
        ) {
          <div class="drag-container" cdkDrag cdkDragLockAxis="y">
            <tui-svg
              [src]="tuiIconDragLarge"
              class="drag-icon"
              cdkDragHandle
            ></tui-svg>
            <app-one-tour-point
              [title]="i + 1"
              [tourPoint]="tourPoint"
              class="drag-child"
            />
          </div>

          <!--  Driving duration + distance  -->
          <div style="padding-block: 0.5rem">
            <app-driving-duration-distance [i]="i" />
          </div>
        }

        <!-- full duration time -->
        @if (
          mapTPService.drivingDurations && mapTPService.tourPoints.length > 1
        ) {
          <div style="text-align: center">
            {{ "map.estimated-time" | translate }}:
            <b>{{ formatDuration(mapTPService.drivingDurations) }}</b>
          </div>
        }

        <!-- release tour (unplanned -> planned) -->
        @if (
          mapTPService.tourPoints.length > 0 &&
          tourStateValidation &&
          mapPermissionHelper.hasUpdateTourPermissions
        ) {
          <div
            class="flex-align-center center-content"
            style="padding-top: 1rem"
          >
            <button
              class="outlined"
              [appearance]="
                tour.tourState === TOUR_STATE.DRAFT ? 'primary' : ''
              "
              size="m"
              tuiButton
              (click)="toggleTourStatus()"
            >
              <div class="innerBtn">
                <div>
                  {{
                    tour.tourState === TOUR_STATE.DRAFT
                      ? ("map.set-planned" | translate)
                      : ("map.set-unplanned" | translate)
                  }}
                </div>

                <tui-svg
                  [src]="
                    tour.tourState === TOUR_STATE.DRAFT
                      ? 'tuiIconCheckCircleLarge'
                      : 'tuiIconEditLarge'
                  "
                />
              </div>
            </button>
          </div>
        }
      </div>
    </div>
  </div>

  <!-- MAP / ORDERS -->
  <div class="map-container">
    <!--  MAP  -->
    <google-map [options]="mapOptions" height="100%" width="100%">
      <!-- see: https://github.com/angular/components/blob/main/src/google-maps/map-traffic-layer/README.md -->
      <map-traffic-layer [autoRefresh]="true"></map-traffic-layer>
    </google-map>

    <!--  ORDER  -->
    @if (showOrders()) {
      <app-order-grid class="order-grid" />
      <tui-svg
        (click)="toggleOrders()"
        [src]="tuiIconMinusLarge"
        class="closeOrder"
      ></tui-svg>
    } @else {
      <div class="small-circle" (click)="toggleOrders()">
        <tui-svg [src]="tuiIconFileTextLarge" style="color: black"></tui-svg>
      </div>
    }
  </div>
</div>

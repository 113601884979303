import { Component, inject, signal } from "@angular/core";
import { NavRoutes } from "../../../other/enums/nav-routes";
import { ORDER_STATE } from "../../../models/Order.class";
import { KpiService, WidgetData } from "../../../api/kpi.service";
import { PermissionService } from "../../../services/permission.service";

@Component({
  selector: "app-dashboard-widgets",
  templateUrl: "./dashboard-widgets.component.html",
  styleUrl: "./dashboard-widgets.component.scss",
})
export class DashboardWidgetsComponent {
  // kpi data for widgets right next to chart
  widgetData = signal<WidgetData>({
    ordersCreatedTodayCount: 0,
    orderPlannedCount: 0,
    ordersPlanningPhaseCount: 0,
    orderFinishedTodayCount: 0,
    inactiveCustomers: 0,
  });
  permissionService = inject(PermissionService);
  protected readonly NavRoutes = NavRoutes;
  protected readonly ORDER_STATE = ORDER_STATE;

  constructor(private kpiService: KpiService) {}

  getCurrentCETDate(): string {
    let date = new Date();
    date.setHours(date.getHours() + 2); // Considering CET is UTC + 2
    return date.toISOString().split("T")[0];
  }

  ngOnInit() {
    this.kpiService.getWidgetData().subscribe((data) => {
      this.widgetData.set(data);
    });
  }
}

import { Component, inject } from "@angular/core";
import { NgIf } from "@angular/common";
import { PermissionService } from "../../../../services/permission.service";

@Component({
  selector: "app-missing-permissions-dialog",
  standalone: true,
  imports: [NgIf],
  templateUrl: "./missing-permissions-dialog.component.html",
  styleUrl: "./missing-permissions-dialog.component.scss",
})
export class MissingPermissionsDialogComponent {
  permissionService = inject(PermissionService);
}

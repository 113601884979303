<div tuiMode="onDark">
  <tui-accordion class="accordion">
    <tui-accordion-item
      (openChange)="toggle()"
      [(open)]="expanded"
      [borders]="null"
      class="accord-border"
      size="s"
    >
      <!--    title      -->
      <div class="scoped accordion-title">
        {{ title | translate }}
      </div>

      <ng-template tuiAccordionItemContent>
        <!--  content shown if expanded    -->
        <ng-content />
      </ng-template>
    </tui-accordion-item>
  </tui-accordion>
</div>

<ng-template #tooltip>
  <div style="color: black">{{ menuItem.tooltip }}</div>
</ng-template>

<button
  #rla="routerLinkActive"
  (click)="onClick()"
  *ngIf="menuItem"
  [class.isRound]="isRound"
  [tuiHint]="tooltip"
  appearance="{{ selected || rla.isActive || isMapActive() ? 'primary' : '' }}"
  class="buttonStyle"
  id="{{ menuItem.tooltip }}"
  routerLink="{{ menuItem.link }}"
  routerLinkActive
  tuiHintAppearance="onDark"
  tuiHintDirection="right"
  tuiIconButton
>
  <tui-svg src="{{ menuItem.icon }}" style="color: white"></tui-svg>
</button>

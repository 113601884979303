<div class="input-wrapper">
  <tui-input
    [ngStyle]="{ 'border-radius': bRadius }"
    [tuiTextfieldIcon]="tuiIconPhone"
    formControlName="{{ fieldName }}"
  >
    <span [ngStyle]="{ color: hintColor }" class="hint">
      {{ hint | translate }}
    </span>
    <span *ngIf="required" class="tui-required"></span>
    <input
      [ngStyle]="{ color: hintColor }"
      autocomplete="off!"
      tuiTextfield
      type="tel"
    />
  </tui-input>
  <ng-container class="error-wrapper">
    <tui-error
      [error]="[] | tuiFieldError | async"
      formControlName="{{ fieldName }}"
    ></tui-error>
  </ng-container>
</div>

<div class="p-container">
  <!-- image container  -->

  <div class="image">
    <div class="image-frame">
      <!--  palette image  -->
      <img
        [ngSrc]="
          productImage != null ? productImage : 'assets/svg/placeholder.svg'
        "
        alt="product"
        height="60"
        width="60"
      />
    </div>
    <!--  arrow image  -->
    <img
      [ngSrc]="
        productOrder.amount < 0
          ? 'assets/svg/arrow-up.svg'
          : 'assets/svg/arrow-down.svg'
      "
      alt="arrow"
      class="arrow-svg"
      height="25"
      width="25"
    />
  </div>

  <!-- info container  -->
  <div id="delivery-progress-wrapper" class="flex-col-g1">
    <!--  product name  -->
    <span
      ><b> {{ productOrder.product?.name ?? "order.unknown-product" | translate}}</b>
      {{"(" + (productOrder.acceptedQuality | translate) + ")"}}
    </span>

    <!-- product order price per product   -->
    <span style="color: #a4a5b1">
      {{"common.for" | translate}}
      {{productOrder.pricePerProduct  | currency: "EUR" : "symbol-narrow" : "1.2-2" : "de-DE"}}
    </span>

    <!--  productOrder amount  -->
    <div>
      <span> {{ calculatedAmount }} / </span>
      <span style="color: #a4a5b1"
        >{{ totalAmount }}
        {{
          productOrder.amount < 0
            ? ("order.unload" | translate)
            : ("order.charge" | translate)
        }}</span
      >
    </div>
    <!--  productOrder unload / charge  -->
    <progress
      style="max-width: 80%; max-height: 1rem; margin: 0.25rem 0;"
      tuiProgressBar
      [max]="totalAmount"
      [value]="calculatedAmount"
    ></progress>
  </div>
</div>

/**
 * Enum for permissions:
 * Contains all permissions as strings.
 */
export enum Permissions {
  FILE_READ = "FILE_READ",
  FILE_READ_OWN = "FILE_READ_OWN",
  FILE_CREATE = "FILE_CREATE",
  FILE_UPDATE = "FILE_UPDATE",
  FILE_UPDATE_OWN = "FILE_UPDATE_OWN",
  FILE_DELETE = "FILE_DELETE",
  FILE_DELETE_OWN = "FILE_DELETE_OWN",

  ROLE_READ = "ROLE_READ",
  ROLE_READ_OWN = "ROLE_READ_OWN",
  ROLE_CREATE = "ROLE_CREATE",
  ROLE_UPDATE = "ROLE_UPDATE",
  ROLE_DELETE = "ROLE_DELETE",

  TENANT_READ = "TENANT_READ",
  TENANT_READ_OWN = "TENANT_READ_OWN",
  TENANT_CREATE = "TENANT_CREATE",
  TENANT_UPDATE = "TENANT_UPDATE",
  TENANT_UPDATE_OWN = "TENANT_UPDATE_OWN",
  TENANT_DELETE = "TENANT_DELETE",
  TENANT_DELETE_OWN = "TENANT_DELETE_OWN",

  USER_READ = "USER_READ",
  USER_READ_OWN = "USER_READ_OWN",
  USER_READ_FROM_TOUR = "USER_READ_FROM_TOUR",
  USER_CREATE = "USER_CREATE",
  USER_UPDATE = "USER_UPDATE",
  USER_UPDATE_OWN = "USER_UPDATE_OWN",
  USER_DELETE = "USER_DELETE",
  USER_DELETE_OWN = "USER_DELETE_OWN",

  KPI_TENANT = "KPI_TENANT",
  KPI_DASHBOARD = "KPI_DASHBOARD",

  ADDRESS_READ = "ADDRESS_READ",
  ADDRESS_UPDATE = "ADDRESS_UPDATE",
  ADDRESS_CREATE = "ADDRESS_CREATE",
  ADDRESS_DELETE = "ADDRESS_DELETE",

  CUSTOMER_READ = "CUSTOMER_READ",
  CUSTOMER_READ_FROM_LOCATION = "CUSTOMER_READ_FROM_LOCATION",
  CUSTOMER_CREATE = "CUSTOMER_CREATE",
  CUSTOMER_UPDATE = "CUSTOMER_UPDATE",
  CUSTOMER_UPDATE_FROM_LOCATION = "CUSTOMER_UPDATE_FROM_LOCATION",
  CUSTOMER_DELETE = "CUSTOMER_DELETE",
  CUSTOMER_DELETE_FROM_LOCATION = "CUSTOMER_DELETE_FROM_LOCATION",

  LOCATION_READ = "LOCATION_READ",
  LOCATION_READ_FROM_CUSTOMER = "LOCATION_READ_FROM_CUSTOMER",
  LOCATION_READ_FROM_TOURPOINT = "LOCATION_READ_FROM_TOURPOINT",
  LOCATION_UPDATE = "LOCATION_UPDATE",
  LOCATION_UPDATE_FROM_CUSTOMER = "LOCATION_UPDATE_FROM_CUSTOMER",
  LOCATION_UPDATE_FROM_TOURPOINT = "LOCATION_UPDATE_FROM_TOURPOINT",
  LOCATION_CREATE = "LOCATION_CREATE",
  LOCATION_DELETE = "LOCATION_DELETE",
  LOCATION_DELETE_FROM_CUSTOMER = "LOCATION_DELETE_FROM_CUSTOMER",
  LOCATION_DELETE_FROM_TOURPOINT = "LOCATION_DELETE_FROM_TOURPOINT",

  ORDER_READ = "ORDER_READ",
  ORDER_READ_FROM_LOCATION = "ORDER_READ_FROM_LOCATION",
  ORDER_READ_FROM_CUSTOMER = "ORDER_READ_FROM_CUSTOMER",
  ORDER_UPDATE = "ORDER_UPDATE",
  ORDER_UPDATE_FROM_LOCATION = "ORDER_UPDATE_FROM_LOCATION",
  ORDER_UPDATE_FROM_CUSTOMER = "ORDER_UPDATE_FROM_CUSTOMER",
  ORDER_CREATE = "ORDER_CREATE",
  ORDER_DELETE = "ORDER_DELETE",
  ORDER_DELETE_FROM_LOCATION = "ORDER_DELETE_FROM_LOCATION",
  ORDER_DELETE_FROM_CUSTOMER = "ORDER_DELETE_FROM_CUSTOMER",

  PRODUCT_READ = "PRODUCT_READ",
  PRODUCT_READ_FROM_PRODUCT_ORDER = "PRODUCT_READ_FROM_PRODUCT_ORDER",
  PRODUCT_READ_FROM_TOUR_POINT_LOAD = "PRODUCT_READ_FROM_TOUR_POINT_LOAD",
  PRODUCT_UPDATE = "PRODUCT_UPDATE",
  PRODUCT_UPDATE_FROM_PRODUCT_ORDER = "PRODUCT_UPDATE_FROM_PRODUCT_ORDER",
  PRODUCT_UPDATE_FROM_TOUR_POINT_LOAD = "PRODUCT_UPDATE_FROM_TOUR_POINT_LOAD",
  PRODUCT_CREATE = "PRODUCT_CREATE",
  PRODUCT_DELETE = "PRODUCT_DELETE",
  PRODUCT_DELETE_FROM_PRODUCT_ORDER = "PRODUCT_DELETE_FROM_PRODUCT_ORDER",
  PRODUCT_DELETE_FROM_TOUR_POINT_LOAD = "PRODUCT_DELETE_FROM_TOUR_POINT_LOAD",

  PRODUCT_ORDER_READ = "PRODUCT_ORDER_READ",
  PRODUCT_ORDER_READ_FROM_ORDER = "PRODUCT_ORDER_READ_FROM_ORDER",
  PRODUCT_ORDER_READ_FROM_TOUR_POINT_LOAD = "PRODUCT_ORDER_READ_FROM_TOUR_POINT_LOAD",
  PRODUCT_ORDER_UPDATE = "PRODUCT_ORDER_UPDATE",
  PRODUCT_ORDER_UPDATE_FROM_ORDER = "PRODUCT_ORDER_UPDATE_FROM_ORDER",
  PRODUCT_ORDER_UPDATE_FROM_TOUR_POINT_LOAD = "PRODUCT_ORDER_UPDATE_FROM_TOUR_POINT_LOAD",
  PRODUCT_ORDER_CREATE = "PRODUCT_ORDER_CREATE",
  PRODUCT_ORDER_DELETE = "PRODUCT_ORDER_DELETE",
  PRODUCT_ORDER_DELETE_FROM_ORDER = "PRODUCT_ORDER_DELETE_FROM_ORDER",
  PRODUCT_ORDER_DELETE_FROM_TOUR_POINT_LOAD = "PRODUCT_ORDER_DELETE_FROM_TOUR_POINT_LOAD",

  PRODUCT_PRICE_READ = "PRODUCT_PRICE_READ",
  PRODUCT_PRICE_READ_FROM_PRODUCT = "PRODUCT_PRICE_READ_FROM_PRODUCT",
  PRODUCT_PRICE_UPDATE = "PRODUCT_PRICE_UPDATE",
  PRODUCT_PRICE_UPDATE_FROM_PRODUCT = "PRODUCT_PRICE_UPDATE_FROM_PRODUCT",
  PRODUCT_PRICE_CREATE = "PRODUCT_PRICE_CREATE",
  PRODUCT_PRICE_DELETE = "PRODUCT_PRICE_DELETE",
  PRODUCT_PRICE_DELETE_FROM_PRODUCT = "PRODUCT_PRICE_DELETE_FROM_PRODUCT",

  TOUR_READ = "TOUR_READ",
  TOUR_READ_OWN = "TOUR_READ_OWN",
  TOUR_READ_FROM_TOUR_POINT = "TOUR_READ_FROM_TOUR_POINT",
  TOUR_UPDATE = "TOUR_UPDATE",
  TOUR_UPDATE_OWN = "TOUR_UPDATE_OWN",
  TOUR_CREATE = "TOUR_CREATE",
  TOUR_DELETE = "TOUR_DELETE",
  TOUR_DELETE_OWN = "TOUR_DELETE_OWN",

  TOUR_POINT_READ = "TOUR_POINT_READ",
  TOUR_POINT_READ_OWN = "TOUR_POINT_READ_OWN",
  TOUR_POINT_READ_FROM_TOUR = "TOUR_POINT_READ_FROM_TOUR",
  TOUR_POINT_READ_FROM_WAREHOUSE = "TOUR_POINT_READ_FROM_WAREHOUSE",
  TOUR_POINT_UPDATE = "TOUR_POINT_UPDATE",
  TOUR_POINT_UPDATE_OWN = "TOUR_POINT_UPDATE_OWN",
  TOUR_POINT_UPDATE_FROM_TOUR = "TOUR_POINT_UPDATE_FROM_TOUR",
  TOUR_POINT_CREATE = "TOUR_POINT_CREATE",
  TOUR_POINT_DELETE = "TOUR_POINT_DELETE",
  TOUR_POINT_DELETE_OWN = "TOUR_POINT_DELETE_OWN",
  TOUR_POINT_DELETE_FROM_TOUR = "TOUR_POINT_DELETE_FROM_TOUR",

  DELIVERY_RECEIPT_READ = "DELIVERY_RECEIPT_READ",
  DELIVERY_RECEIPT_READ_OWN = "DELIVERY_RECEIPT_READ_OWN",
  DELIVERY_RECEIPT_READ_FROM_TOUR_POINT = "DELIVERY_RECEIPT_READ_FROM_TOUR_POINT",
  DELIVERY_RECEIPT_UPDATE = "DELIVERY_RECEIPT_UPDATE",
  DELIVERY_RECEIPT_UPDATE_OWN = "DELIVERY_RECEIPT_UPDATE_OWN",
  DELIVERY_RECEIPT_UPDATE_FROM_TOUR_POINT = "DELIVERY_RECEIPT_UPDATE_FROM_TOUR_POINT",
  DELIVERY_RECEIPT_CREATE = "DELIVERY_RECEIPT_CREATE",
  DELIVERY_RECEIPT_DELETE = "DELIVERY_RECEIPT_DELETE",
  DELIVERY_RECEIPT_DELETE_OWN = "DELIVERY_RECEIPT_DELETE_OWN",
  DELIVERY_RECEIPT_DELETE_FROM_TOUR_POINT = "DELIVERY_RECEIPT_DELETE_FROM_TOUR_POINT",

  WAREHOUSE_READ = "WAREHOUSE_READ",
  WAREHOUSE_READ_FROM_TOUR_POINT = "WAREHOUSE_READ_FROM_TOUR_POINT",
  WAREHOUSE_READ_OWN = "WAREHOUSE_READ_OWN",
  WAREHOUSE_UPDATE = "WAREHOUSE_UPDATE",
  WAREHOUSE_UPDATE_FROM_TOUR_POINT = "WAREHOUSE_UPDATE_FROM_TOUR_POINT",
  WAREHOUSE_UPDATE_OWN = "WAREHOUSE_UPDATE_OWN",
  WAREHOUSE_CREATE = "WAREHOUSE_CREATE",
  WAREHOUSE_DELETE = "WAREHOUSE_DELETE",
  WAREHOUSE_DELETE_OWN = "WAREHOUSE_DELETE_OWN",
  WAREHOUSE_DELETE_FROM_TOUR_POINT = "WAREHOUSE_DELETE_FROM_TOUR_POINT",

  CONTACT_PERSON_READ = "CONTACT_PERSON_READ",
  CONTACT_PERSON_READ_FROM_CUSTOMER = "CONTACT_PERSON_READ_FROM_CUSTOMER",
  CONTACT_PERSON_READ_FROM_LOCATION = "CONTACT_PERSON_READ_FROM_LOCATION",
  CONTACT_PERSON_UPDATE = "CONTACT_PERSON_UPDATE",
  CONTACT_PERSON_UPDATE_FROM_CUSTOMER = "CONTACT_PERSON_UPDATE_FROM_CUSTOMER",
  CONTACT_PERSON_UPDATE_FROM_LOCATION = "CONTACT_PERSON_UPDATE_FROM_LOCATION",
  CONTACT_PERSON_CREATE = "CONTACT_PERSON_CREATE",
  CONTACT_PERSON_DELETE = "CONTACT_PERSON_DELETE",
  CONTACT_PERSON_DELETE_OWN = "CONTACT_PERSON_DELETE_OWN",
  CONTACT_PERSON_DELETE_FROM_CUSTOMER = "CONTACT_PERSON_DELETE_FROM_CUSTOMER",
  CONTACT_PERSON_DELETE_FROM_LOCATION = "CONTACT_PERSON_DELETE_FROM_LOCATION",

  REFUND_READ = "REFUND_READ",
  REFUND_READ_FROM_TOURPOINTLOAD = "REFUND_READ_FROM_TOURPOINTLOAD",
  REFUND_UPDATE = "REFUND_UPDATE",
  REFUND_UPDATE_FROM_TOURPOINTLOAD = "REFUND_UPDATE_FROM_TOURPOINTLOAD",
  REFUND_CREATE = "REFUND_CREATE",
  REFUND_DELETE = "REFUND_DELETE",
  REFUND_DELETE_FROM_TOURPOINTLOAD = "REFUND_DELETE_FROM_TOURPOINTLOAD",
}

import { Component, OnInit } from "@angular/core";
import { CustomerQuery, CustomerService } from "../../api/customer.service";
import { ActivatedRoute, Router } from "@angular/router";
import Customer from "../../models/Customer.class";
import { NavRoutes } from "../../other/enums/nav-routes";
import { TuiNamedDay } from "@taiga-ui/kit";
import { TuiDay } from "@taiga-ui/cdk";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import { AddEditCustomerComponent } from "./add-edit-customer/add-edit-customer.component";
import { TablePageComponent } from "../../interfaces/table-page-component";
import { TableHelperService } from "../../services/table-helper.service";
import { Permissions } from "../../other/enums/permissions";
import { PermissionService } from "../../services/permission.service";
import Order from "../../models/Order.class";

@Component({
  selector: "app-customer-page",
  templateUrl: "./customer-page.component.html",
  styleUrls: ["./customer-page.component.scss"],
})

/**
 * Component page for customer
 * Currently not implemented
 */
export class CustomerPageComponent implements OnInit, TablePageComponent {
  tablePage: number = 0;
  tablePageSize: number = 10;
  customerTableColumnNames: string[] = [
    "Erstellt am",
    "Zuletzt verändert",
    "Name",
    "MatchC",
    "LKZ",
    "Kundennummer",
    "Anzahl Standorte ",
    "Telefon",
    "Datum des letzten Auftrages",
    "Letzter Auftrag",
  ];
  customerTableShownColumns: string[] = [
    "createdAt",
    "updatedAt",
    "name",
    "MATCHC",
    "LKZ",
    "customerNumber",
    "locationAmount",
    "phone",
    "dateLastOrder",
    "latestOrder",
    "edit",
  ];
  noSortColumns: string[] = [
    "phone",
    "latestOrder",
    "dateLastOrder",
    "locationAmount",
  ];
  customers: Customer[];
  dateValue: TuiDay | null = null;
  public dateItems: TuiNamedDay[] = [
    new TuiNamedDay(
      TuiDay.currentLocal().append({
        month: -1,
      }),
      "1 Monat",
    ),
  ];

  protected readonly Permissions = Permissions;
  private dateChangeTriggered: boolean = false;

  constructor(
    public customerService: CustomerService,
    private router: Router,
    private dialogService: TuiDialogHelperService,
    public tableHelperService: TableHelperService,
    private route: ActivatedRoute,
    public permissionService: PermissionService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.customerService);
  }

  ngOnInit(): void {
    this.defaultTableConfig();

    // condition is within the method
    this.setInactiveSinceAndPopulate();
  }

  defaultTableConfig() {
    this.customerService.setSorting({ sortColumn: "name", sortDirection: 1 });
    this.customerService.setPaginationIndices({ start: 0, limit: 10 });
    this.tableHelperService.resetTablePaginationAndSearch();
  }

  setInactiveSinceAndPopulate() {
    // set filters in the UI depending on the queryParams
    this.route.queryParams.subscribe((params) => {
      if (params["inactiveSinceMonths"]) {
        this.dateValue = TuiDay.currentLocal().append({
          month: -1,
        });
      } else {
        // fetch Table data (customers)
        this.populateTable();
        this.dateChangeTriggered = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  search(searchInput: string) {
    this.tableHelperService.resetTablePagination();
    this.customerService.search = searchInput;
    this.populateTable();
  }

  populateTable(params: Partial<CustomerQuery> = {}): void {
    const defaultParams = { withLatestOrder: true };

    this.customerService
      .getAllCustomers({ ...defaultParams, ...params })
      .subscribe((customers) => {
        this.customers = customers.records.map((c) => new Customer(c));
      });
  }

  getLatestOrder(customer: Customer): Order | undefined {
    if (!customer.locations) return undefined;

    for (let location of customer.locations) {
      if (!location.orders || location.orders.length === 0) continue;
      // (if there is any latest order, it will be the first in the array)
      return location.orders[0];
    }
    return undefined;
  }

  public customerRowClickEvent(id: string): void {
    this.router.navigate([NavRoutes.CUSTOMERS + `/${id}`]).then();
  }

  addNewCustomer() {
    this.dialogService.openDialog(AddEditCustomerComponent, null, () => {
      this.populateTable();
    });
  }

  openEditCustomerDialog($event: any) {
    this.dialogService.openDialog(AddEditCustomerComponent, $event, () => {
      this.populateTable();
    });
  }

  extractDate(value: TuiDay | null) {
    return value?.toUtcNativeDate()?.toISOString();
  }

  // INACTIVE SINCE
  filterTableByDate(inactiveSince?: string): void {
    if (!this.dateChangeTriggered) {
      this.tableHelperService.resetTablePagination();
      this.populateTable({ inactiveSince: inactiveSince });
    } else {
      // reset actionTriggered back to false for normal operations
      this.dateChangeTriggered = false;
    }
  }
}

<div class="carouselWrapper" tuiCarouselButtons>
  <!-- button previous -->
  @if (imgUrls().length > 1) {
    <button
      (click)="carousel.prev()"
      icon="tuiIconChevronLeftLarge"
      title="Previous"
      tuiIconButton
      type="button"
    ></button>
  }

  <!--  carousel -->
  <tui-carousel #carousel [(index)]="dialogImageIndex" style="max-width: 50rem">
    @for (img of imgUrls(); track img) {
      <div *tuiItem style="margin: auto">
        <div class="downloadActions">
          <a [download]="downloadFileName" [href]="selectedImageUrl">
            <button
              appearance="''"
              icon="tuiIconDownload"
              size="s"
              tuiButton
              type="button"
            >
              {{ "common.download" | translate }}
            </button>
          </a>

          <button
            appearance="''"
            size="s"
            tuiButton
            class="action-button"
            (click)="print()"
          >
            <tui-svg src="tuiIconPrinter"></tui-svg>
            {{ "common.print" | translate }}
          </button>
        </div>
        <img class="receipt-img" [src]="img" alt="Image" />
      </div>
    }
  </tui-carousel>

  <!-- button next -->
  @if (imgUrls().length > 1) {
    <button
      (click)="carousel.next()"
      icon="tuiIconChevronRightLarge"
      title="Next"
      tuiIconButton
      type="button"
    ></button>
  }
</div>

@if (imgUrls().length > 1) {
  <!-- pagination -->
  <tui-pagination
    [(index)]="dialogImageIndex"
    [length]="imgUrls().length"
    class="tui-space_top-4"
    size="s"
  ></tui-pagination>
}

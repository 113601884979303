import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { tuiIconFile } from "@taiga-ui/icons";

@Component({
  selector: "app-input-files",
  templateUrl: "./input-files.component.html",
  styleUrl: "./input-files.component.scss",
})
export class InputFilesComponent {
  @Input() fileControl: FormControl = new FormControl();
  @Input() multiple: boolean = true;
  @Input() showIcon: boolean = true;
  @Input() customTranslate: string = "common.file-upload-hint";
  @Input() customTextColor: string = "";
  @Input() customBackgroundColor: string = "";
  @Output() emitFiles = new EventEmitter();
  protected readonly tuiIconFile = tuiIconFile;

  ngAfterViewInit() {
    // workaround to rerender the tui-files with the content of the form control
    if (this.fileControl.value) {
      const currentVal = this.fileControl.value;
      this.fileControl.setValue(null);
      this.fileControl.setValue(currentVal);
    }
  }

  removeFile({ name }: File) {
    if (this.multiple) {
      this.fileControl.setValue(
        this.fileControl.value!.filter((file: File) => file.name !== name) ??
          [],
      );
    } else {
      this.fileControl.setValue(null);
    }
  }

  onFilesChange($event: File) {
    this.emitFiles.emit($event);
  }
}

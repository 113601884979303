import { Component } from "@angular/core";
import { TUI_ARROW } from "@taiga-ui/kit";
import { FormControl, FormGroup } from "@angular/forms";
import { NavRoutes } from "../../other/enums/nav-routes";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * The bar at the top of the page which supports the sidenav
 * @deprecated not used nor maintained in the current version
 */
export class TopbarComponent {
  readonly arrow = TUI_ARROW;

  searchFieldForm = new FormGroup({
    searchField: new FormControl(``),
  });
  protected readonly NavRoutes = NavRoutes;
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "app-event-item",
  templateUrl: "./event-item.component.html",
  styleUrls: ["./event-item.component.scss"],
})
/**
 * A component that displays an event in the calendar
 * @Input arg - The event to display (contains the title, start and end dates, a timestamp, and extendedProps which contains a location)
 * todo: create interface instead of using any
 */
export class EventItemComponent {
  @Input() arg: any = {};
}

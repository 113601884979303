<app-menubar
  (closingEvent)="setCloseEvent()"
  [open]="isOpen"
  [title]="'delivery-receipt.title' | translate"
>
  <div class="delivery-receipt-wrapper">
      <!-- tour button   -->
      <section>
        <button
          style="width: 100%"
          tuiButton
          appearance="secondary"
          type="button"
          size="m"
          icon="tuiIconMapPin"
          (click)="routeToTour()"
        >
          {{ "delivery-receipt.route-to-map" | translate }}
        </button>
      </section>

    <!-- delivery receipts -->
    <section>
      <h3 style="margin-top: 1.5rem">
        {{ "delivery-receipt.receipts" | translate }}
      </h3>
      @if(deliveryReceipts().length > 0){
        @for (receipt of deliveryReceipts(); track receipt.id) {
          <a
            (click)="openDeliveryReceipt(receipt.id)"
          >
          <span class="delivery-receipt-link">
            <tui-svg src="tuiIconFileTextLarge"></tui-svg>
            <h2>
              {{ "delivery-receipt.receipt" | translate }}
              {{ receipt.createdAt | date: "dd.MM.yyyy" }}
            </h2>
          </span>
          </a>
        }
      } @else {
        <p>
          {{ "delivery-receipt.empty" | translate }}
        </p>
      }
    </section>

    <!-- refund detail cards  -->
    <section>
      <h3 style="margin-top: 1.5rem">
        {{ "order.refunds" | translate }}
      </h3>
      @if (tourPointRefunds().length > 0) {
        @for (refund of tourPointRefunds(); track refund.id) {
          <div class="refund-card">
            <h4 class="refund-headline">
              <tui-svg
                src="tuiIconAlertOctagon"
                style="color: var(--tui-warn-bg); font-size: 17px"
              ></tui-svg>
              <div>
                <span>{{refund.amount }} </span>
                <span>{{refund.tourPointLoad.productOrder?.product?.name ?? "order.unknown-product" | translate}}</span>
                <span> ({{refund.tourPointLoad.quality | translate}})</span>
              </div>
            </h4>
            <div class="refund-content">
              <span style="opacity: 0.7"> {{refund.createdAt | date: "dd.MM.yyyy HH:mm"}} </span>
              <p style="margin: 0.4rem 0 0.2rem;">{{refund.description}}</p>
              <div>
                @for (image of refund.images; track image.url) {
                  <img
                    [src]="image.url"
                    alt="refund-image"
                    style="width: 50px; cursor: pointer"
                    (click)="openFile(image.blob)"
                  />
                }
              </div>
            </div>
          </div>
        }
      } @else {
        <p>
          {{ "order.refunds-empty" | translate }}
        </p>
      }
    </section>
    </div>
</app-menubar>

<app-table-layout
  (btnClick)="createNewOrder()"
  (dateSelected)="onChildDateChanged($event)"
  (tabClick)="onFilterTabClick($event)"
  [dateValue]="dateValue"
  [missingReadPermissions]="
    permissionService.readOrderPermission().missingPermissions
  "
  [newButtonTxt]="'order.add-title'"
  [ngxTitle]="'order.title'"
  [showAddButton]="permissionService.createOrderPermission().hasPermission"
  [showDatePicker]="true"
  [showTable]="permissionService.readOrderPermission().hasPermission"
  [tabs]="orderTabs"
  dateHint="general.createdAt"
>
  <!--  search field  -->
  <div header-item id="filter-wrapper">
    <!-- Dropdown Selection for State   -->
    <tui-select
      (ngModelChange)="populateTable($event)"
      [(ngModel)]="filterSelectStatus"
      [stringify]="'label' | tuiStringify"
      style="min-width: 10rem !important"
      tuiTextfieldSize="m"
    >
      {{ "order.status" | translate }}
      <tui-data-list-wrapper
        *tuiDataList
        [itemContent]="'label' | tuiStringify | tuiStringifyContent"
        [items]="
          filterSelectStatusOptions
            | tuiFilterByInputWith: ('label' | tuiStringify)
        "
      >
      </tui-data-list-wrapper>
    </tui-select>

    <!-- Search   -->
    <div class="search-form">
      <app-table-search-input
        (searchChangedDebounced)="search($event)"
      ></app-table-search-input>
    </div>
  </div>

  <app-order-table
    (deleteEvent)="deleteEvent($event)"
    (editEvent)="editOrder($event)"
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (sortEvent)="sortTable($event)"
    [itemsDeletable]="permissionService.deleteOrderPermission().hasPermission"
    [itemsEditable]="permissionService.updateOrderPermission().hasPermission"
    [orders]="orderFilterHelper.orders"
    [tablePageSize]="tablePageSize"
    [tablePage]="tablePage"
  ></app-order-table>
</app-table-layout>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="deleteOrder()"
  [isOpen]="showDeleteDialog"
/>

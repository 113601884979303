@if (tourPoint) {
  <app-tour-point-load-accordion [title]="'tourPointLoad.title'">
    <!-- HEADER -->
    <app-tour-point-load-header [tourPoint]="tourPoint" />

    <!--  SPACER  -->
    @if (tourPoint.tourPointLoads.length > 0) {
      <div class="line-spacer"></div>
    }
    
    <!-- TOUR POINT LOAD LOOP -->
    @for (tourPointLoad of tourPoint.tourPointLoads; track tourPointLoad) {
      <app-one-tour-point-load
        [tourPointLoad]="tourPointLoad"
        [isSigned]="
          tourPoint.signedByWorker !== null ||
          tourPoint.signedByCustomer !== null
        "
      />

      @if (!$last) {
        <!--  SPACER  -->
        <div class="line-spacer"></div>
      }
    }

    <!-- create tpl from warehouse -->
    @if (tourPoint.warehouseId) {
      <!--   spacer if there are tpl already   -->
      @if (tourPoint.tourPointLoads.length > 0) {
        <div style="padding-block: 0.5rem">
          <div class="line-spacer"></div>
        </div>
      }
      <app-create-tpl-for-warehouse [tourPoint]="tourPoint" />
    }
  </app-tour-point-load-accordion>
} @else {
  loading...
}

import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";

@Injectable({
  providedIn: "root",
})
export class RolePermissionHelperService {
  private permissionService = inject(PermissionService);

  get hasCreateRolePermission() {
    return this.permissionService.createRolePermission().hasPermission;
  }

  get hasReadRolePermission() {
    return this.permissionService.readRolePermission().hasPermission;
  }

  get hasUpdateRolePermission() {
    return this.permissionService.updateRolePermission().hasPermission;
  }

  hasCreateReadRolePermission(boolVal: boolean = true) {
    return (
      this.hasCreateRolePermission && this.hasReadRolePermission && boolVal
    );
  }

  hasEditReadRolePermission(boolVal: boolean = true) {
    return (
      this.hasUpdateRolePermission && this.hasReadRolePermission && boolVal
    );
  }
}

import { BaseModel } from "./BaseModel";
import Product, { PRODUCT_QUALITY } from "./Product.class";
import TourPoint from "./TourPoint.class";
import ProductOrder from "./ProductOrder.class";
import Order from "./Order.class";
import Refund from "./Refund.class";

export default class TourPointLoad extends BaseModel<TourPointLoad> {
  // tenantId: string;
  tourPointId: string;
  productOrderId: string;
  productOrder: ProductOrder;
  productId: string;
  quality: PRODUCT_QUALITY;
  amount: number;

  // get all additions
  product?: Product;
  tourPoint: TourPoint;
  order: Order;

  deliveryReceiptNumber: string;
  refunds: Refund[];

  constructor(params: Partial<TourPointLoad>) {
    super(params);
    Object.assign(this, params);
  }
}

import { inject, Injectable, signal } from "@angular/core";
import { PermissionService } from "../permission.service";
import { PermissionType } from "../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";

@Injectable({
  providedIn: "root",
})
export class CustomerPermissionHelperService {
  permissionService = inject(PermissionService);

  missingContactPersonsPermissionsTooltip = signal<PermissionType[]>([
    {
      name: "Kontaktpersonen ansehen",
      hasPermission:
        this.permissionService.readContactPersonsPermission().hasPermission,
    },

    {
      name: "Kontaktpersonen erstellen",
      hasPermission:
        this.permissionService.createContactPersonsPermission().hasPermission,
    },
    {
      name: "Kontaktpersonen aktualisieren",
      hasPermission:
        this.permissionService.updateContactPersonsPermission().hasPermission,
    },

    {
      name: "Kontaktpersonen löschen",
      hasPermission:
        this.permissionService.deleteContactPersonsPermission().hasPermission,
    },
  ]);

  get hasCreateCustomerContactPersonPermissions() {
    return (
      this.permissionService.readContactPersonsPermission().hasPermission &&
      this.permissionService.createContactPersonsPermission().hasPermission
    );
  }

  get hasCRUDContactPersonPermissions() {
    return (
      // Create Contact Person
      this.permissionService.createContactPersonsPermission().hasPermission &&
      // Read Contact Person
      this.permissionService.readContactPersonsPermission().hasPermission &&
      // Update Contact Person
      this.permissionService.updateContactPersonsPermission().hasPermission &&
      // Delete Contact Person
      this.permissionService.deleteContactPersonsPermission().hasPermission
    );
  }
}

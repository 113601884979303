import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";
import { RolePermissionHelperService } from "./role-permission-helper.service";

@Injectable({
  providedIn: "root",
})
export class UserPermissionHelperService {
  private permissionService = inject(PermissionService);
  private rolePermissions = inject(RolePermissionHelperService);

  get hasCreateUserPermission() {
    return this.permissionService.createUserPermission().hasPermission;
  }

  get hasReadUserPermission() {
    return this.permissionService.readUserPermission().hasPermission;
  }

  get hasUpdateUserPermission() {
    return this.permissionService.updateUserPermission().hasPermission;
  }

  hasCreateReadUserPermission(boolVal: boolean = true) {
    return (
      this.hasCreateUserPermission &&
      this.hasReadUserPermission &&
      this.rolePermissions.hasReadRolePermission &&
      boolVal
    );
  }

  hasEditReadUserPermission(boolVal: boolean = true) {
    return (
      this.hasUpdateUserPermission &&
      this.hasReadUserPermission &&
      this.rolePermissions.hasReadRolePermission &&
      boolVal
    );
  }
}

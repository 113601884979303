import { inject, Injectable } from "@angular/core";
import { NavRoutes } from "../../other/enums/nav-routes";
import { PermissionService } from "../permission.service";

@Injectable({
  providedIn: "root",
})
export class SettingsGuardHelperService {
  permissionService = inject(PermissionService);
  // An ordered list view names (paths)
  private viewsOrder = ["roles", "products", "warehouse"];

  checkPermission(view: string): boolean {
    return Boolean(this.permissions()[view]);
  }

  getNextViewWithPermission(currentViewPath: string): string {
    let currentIndex = this.viewsOrder.indexOf(currentViewPath);

    // Looking for the next view starting from the next index
    for (let i = currentIndex + 1; i < this.viewsOrder.length; i++) {
      if (this.checkPermission(this.viewsOrder[i])) {
        console.log();
        return "settings/" + this.viewsOrder[i]; // Return the view path if permission is granted
      }
    }

    return NavRoutes.ERROR;
  }

  private permissions(): { [key: string]: boolean } {
    return {
      roles:
        this.permissionService.readRolePermission().hasPermission ||
        this.permissionService.createRolePermission().hasPermission,
      products:
        this.permissionService.readProductPermission().hasPermission ||
        this.permissionService.createProductPermission().hasPermission,
      warehouse:
        this.permissionService.readWarehousePermission().hasPermission ||
        this.permissionService.createWarehousePermission().hasPermission,
    };
  }
}

<app-table-layout
  (btnClick)="createNewTourPointLoad()"
  (dateSelected)="changeDateEvent(extractDate($event))"
  [missingReadPermissions]="
    permissionService.readTourPointPermission().missingPermissions
  "
  [showAddButton]="
    permissionService.createUnannouncedDeliveryPermission().hasPermission
  "
  [showDatePicker]="true"
  [showTable]="permissionService.readTourPointPermission().hasPermission"
  dateHint="general.createdAt"
  newButtonTxt="unannounced-deliveries.new"
  ngxTitle="unannounced-deliveries.unannounced-deliveries"
>
  <!--  search field  -->
  <div class="search-form" header-item>
    <app-table-search-input
      (searchChangedDebounced)="search($event)"
    ></app-table-search-input>
  </div>

  <div class="table">
    <app-table
      (paginationEvent)="tableHelperService.paginationEvent($event)"
      (rowClickEvent)="onRowClick($event)"
      (sortEvent)="tableHelperService.sortTable($event)"
      [cellTemplatesMap]="{
        product: product,
        truckNumber: truckNumber,
        supplier: supplier,
        vcAuthorName: fullName,
        hasDR
      }"
      [columns]="tourPointTableShownColumns"
      [currentPage]="tablePage"
      [noSortColumns]="noSortColumns"
      [pageSize]="tablePageSize"
      [tableColumnNames]="tourPointTableColumnNames"
      [tableData]="tourPoints"
      [totalElements]="tourPointTotal"
    >
      <ng-template #supplier let-object="object">
        @if (object.unannouncedInfos) {
          {{ object.unannouncedInfos[0]?.carrier }}
        }
      </ng-template>
      <ng-template #truckNumber let-object="object">
        @if (object.unannouncedInfos) {
          {{ object.unannouncedInfos[0]?.truckNumber }}
        }
      </ng-template>
      <ng-template #product let-object="object">
        <div class="flex-col-g1" style="gap: 0.75rem; padding: 1rem 0">
          @for (
            tourPointLoad of object.tourPointLoads;
            track tourPointLoad.id
          ) {
            <span>
              {{ Math.abs(tourPointLoad?.amount) }}
              {{ tourPointLoad?.product?.name }}
              ({{ tourPointLoad.quality | translate }})
            </span>
          }
        </div>
      </ng-template>
      <ng-template #fullName let-object="object">
        {{ object.vcAuthor?.firstName }} {{ object.vcAuthor?.lastName }}
      </ng-template>

      <ng-template #hasDR let-value>
        @if (!value) {
          <tui-svg
            src="tuiIconAlertTriangle"
            style="color: var(--tui-warn-bg)"
            [tuiHint]="tooltip"
            [tuiHintDirection]="'top'"
            [tuiHintAppearance]="'error'"
          ></tui-svg>

          <ng-template #tooltip>
            <div>{{ "receipt.not_found" | translate }}!</div>
          </ng-template>
        }
      </ng-template>
    </app-table>
  </div>
</app-table-layout>

<router-outlet></router-outlet>

import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { orderQueryParams, OrderService } from "../../api/order.service";
import Order, { ORDER_STATE } from "../../models/Order.class";
import { ActivatedRoute, Params } from "@angular/router";
import { TableSortEvent } from "../../interfaces/table-sort-event";
import { FilterTab } from "../../layouts/table-layout/table-layout.component";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import { AddEditOrderComponent } from "./add-edit-order/add-edit-order.component";
import { TablePageComponent } from "../../interfaces/table-page-component";
import { TableHelperService } from "../../services/table-helper.service";
import {
  TuiContextWithImplicit,
  TuiDay,
  tuiPure,
  TuiStringHandler,
} from "@taiga-ui/cdk";
import { PermissionService } from "../../services/permission.service";
import { OrderPermissionsHelperService } from "../../services/permission-helper-services/order-permissions-helper.service";
import { MissingOrderPermissionsDialogComponent } from "./missing-order-permissions-dialog/missing-order-permissions-dialog.component";
import { BaseMissingPermissionsDialogComponent } from "../../common/base-missing-permissions-dialog/base-missing-permissions-dialog.component";
import { OrderTableFilterHelperService } from "./order-table-filter-helper.service";
import { DateTime } from "luxon";
import { DateConverterService } from "../../services/date-converter.service";

type FilterSelect = {
  value: ORDER_STATE | "ALL";
  label: string;
};

export enum QueryParams {
  TAB_VAL = "tabVal",
  SELECT_VAL = "selectVal",
  DATE = "date",
}

@Component({
  selector: "app-orders-page",
  templateUrl: "./orders-page.component.html",
  styleUrls: ["./orders-page.component.scss"],
})
export class OrdersPageComponent
  implements OnInit, OnDestroy, TablePageComponent
{
  orders: Order[];

  //  data to delete a product
  toDeleteId: null | string;
  showDeleteDialog: boolean = false;

  tablePage: number = 0;
  tablePageSize: number = 10;
  orderTabs: FilterTab<any>[] = [
    {
      label: "table-header.filter-all",
      value: "all",
      isActive: false,
    },
    {
      label: "order.current",
      value: "current",
      isActive: true,
    },
    {
      label: "order.late",
      value: "former",
      isActive: false,
    },
    {
      label: "order.refundTab",
      value: "refunds",
      isActive: false,
    },
  ];
  filterSelectStatusOptions: FilterSelect[] = [
    {
      value: "ALL",
      label: "Alle",
    },
    {
      value: ORDER_STATE.UNPLANNED,
      label: "Ungeplant",
    },
    {
      value: ORDER_STATE.PARTLY_PLANNED,
      label: "Teilweise geplant",
    },
    {
      value: ORDER_STATE.PLANNED,
      label: "Vollständig geplant",
    },
    {
      value: ORDER_STATE.FINISHED,
      label: "Erledigt",
    },
    {
      value: ORDER_STATE.CANCELLED,
      label: "Storniert",
    },
  ] as const;
  tabVal: string = "current";
  filterSelectStatus: FilterSelect = this.filterSelectStatusOptions[0];
  selectedCreatedAtDate?: string;
  selectedUpdatedAtDate?: string;
  dateValue: TuiDay | null;
  dateChangeTriggered = false;
  private oldDateValue: string | undefined;
  private dateConverter = inject(DateConverterService);

  constructor(
    public orderService: OrderService,
    private route: ActivatedRoute,
    private dialogService: TuiDialogHelperService,
    public tableHelperService: TableHelperService,
    public permissionService: PermissionService,
    private orderPermissionsHelperService: OrderPermissionsHelperService,
    public orderFilterHelper: OrderTableFilterHelperService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.orderService);
  }

  get currentDayAsISO(): string {
    return DateTime.now().setZone("Europe/Berlin").startOf("day").toISO()!;
  }

  search(searchInput: string) {
    this.tableHelperService.resetTablePagination();
    this.orderService.search = searchInput;
    const queryParams = this.setQueryParamsBasedOnTabAndSelectVal();
    this.orderService.getAllOrders(queryParams, true).subscribe((res) => {
      this.orders = res.records;
    });
  }

  ngOnInit(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    // sort by createdAt
    this.orderService.setSorting({
      sortColumn: "createdAt",
      sortDirection: -1,
    });
    this.routeQueryParamsHandler();
  }

  routeQueryParamsHandler() {
    // Depending on the given url query params perfrom the correct initial populateOrder method
    this.route.queryParams.subscribe((params: Params) => {
      const action = this.getInitialPopulateOrders(params);
      if (action) this.performAction(action);
    });
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  populateTable(event?: FilterSelect) {
    // manually set the filterSelect if the event is not undefined (todo: check why this is not working with the ngModel anymore)
    if (event) {
      this.tableHelperService.resetTablePagination();
      this.filterSelectStatus = this.filterSelectStatusOptions.find(
        (option) => option.value === event.value,
      )!;
    }

    const queryParams = this.setQueryParamsBasedOnTabAndSelectVal();

    this.orderService.getAllOrders(queryParams, true).subscribe((res) => {
      this.orderFilterHelper.orders = res.records;
    });
  }

  createNewOrder() {
    if (this.orderPermissionsHelperService.hasAllCreateOrderPermissions) {
      this.dialogService.openDialog(AddEditOrderComponent, null, () => {
        this.populateTable();
      });
    } else {
      this.dialogService.openDialog(MissingOrderPermissionsDialogComponent);
    }
  }

  deleteEvent(event: string) {
    if (this.orderPermissionsHelperService.hasAllDeleteOrderPermissions) {
      this.toDeleteId = event!;
      this.showDeleteDialog = true;
    } else {
      this.showMissingDeletePermissions();
    }
  }

  showMissingDeletePermissions() {
    this.dialogService.openDialog(
      BaseMissingPermissionsDialogComponent,
      this.orderPermissionsHelperService.missingDeletePermissions,
    );
  }

  editOrder(event: string) {
    if (this.orderPermissionsHelperService.hasAllEditOrderPermissions) {
      this.dialogService.openDialog(AddEditOrderComponent, event, () => {
        this.populateTable();
      });
    } else {
      this.dialogService.openDialog(
        MissingOrderPermissionsDialogComponent,
        event,
      );
    }
  }

  deleteOrder() {
    if (!this.toDeleteId) return;

    this.orderService.deleteOrderById(this.toDeleteId).subscribe(() => {
      // change status in local array to prevent a http getOrder request
      this.populateTable();
      this.toDeleteId = null;
      this.showDeleteDialog = false;
    });
  }

  /**
   * when the sort event is triggered:
   * sets the table sorting to the selected column and direction and updates the table data
   */
  public sortTable(tableSort: TableSortEvent) {
    this.tableHelperService.sortTable(tableSort);
  }

  onFilterTabClick(value: string) {
    this.tabVal = value;

    // Update isActive for each tab
    this.orderTabs.forEach((tab) => {
      tab.isActive = tab.value === value;
    });

    // Reset pagination whenever a new tab is clicked
    this.tableHelperService.resetTablePagination();

    // Load new orders based on current tab and pagination
    this.populateTable();
  }

  @tuiPure
  stringify(
    items: readonly FilterSelect[],
  ): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map = new Map(
      items.map(({ value, label }) => [value, label] as [string, string]),
    );

    return ({ $implicit }: TuiContextWithImplicit<string>) =>
      map.get($implicit) ?? this.filterSelectStatusOptions[0].label;
  }

  extractDate(value: TuiDay | null) {
    return value?.toUtcNativeDate()?.toISOString();
  }

  onChildDateChanged(date: TuiDay): void {
    const newDateValue = this.extractDate(date);

    if (this.oldDateValue !== newDateValue) {
      this.oldDateValue = newDateValue;
      this.changeDateEvent(newDateValue);
    }
  }

  changeDateEvent(createdAtAsIsoDate: string | undefined) {
    if (!this.dateChangeTriggered) {
      this.tableHelperService.resetTablePagination();
      this.selectedCreatedAtDate = createdAtAsIsoDate;
      this.populateTable();
    } else {
      // reset actionTriggered back to false for normal operations
      this.dateChangeTriggered = false;
    }
  }

  setQueryParamsBasedOnTabAndSelectVal(): Partial<orderQueryParams> {
    //Default queryParams
    let queryParams: Partial<orderQueryParams> = {
      withCancelled: false,
      onlyCancelled: false,
      populateDeleter: true,
    };

    // Apply TabVal
    queryParams = { ...queryParams, ...this.handleTabVal(this.tabVal) };

    // Apply SelectVal
    queryParams = {
      ...queryParams,
      ...this.handleSelectStatus(this.filterSelectStatus.value),
    };

    //Apply createdAt
    if (this.selectedCreatedAtDate) {
      queryParams.createdAt = this.selectedCreatedAtDate;
    }

    //Apply updatedAt
    if (this.selectedUpdatedAtDate) {
      queryParams.updatedAt = this.selectedUpdatedAtDate;
    }

    if (this.tabVal === "all") {
      queryParams.withCancelled = true;
    }
    return queryParams;
  }

  private getInitialPopulateOrders(params: Params) {
    // depending on the route url params run a different fetch order method
    const handlers = [
      {
        name: "getTodayCreatedOrders",
        condition: params[QueryParams.TAB_VAL] && params[QueryParams.DATE],
        action: () => {
          this.dateValue = this.dateConverter.formatIsoDateToTaigaDateNoUTC(
            this.currentDayAsISO,
          )[0];
          this.orderFilterHelper.getTodayCreatedOrders();
          this.dateChangeTriggered = true;
        },
      },
      {
        name: "getAllPlannedOrders",
        condition:
          params[QueryParams.TAB_VAL] &&
          params[QueryParams.SELECT_VAL] === ORDER_STATE.PLANNED,
        action: () => {
          // sets the status dropdown to PLANNED
          this.filterSelectStatus = this.filterSelectStatusOptions[3];
          // set the active tab to all
          this.orderTabs[0].isActive = true;
          this.orderTabs[1].isActive = false;
          this.orderFilterHelper.getAllPlannedOrders();
        },
      },
      {
        name: "getTodayFinishedOrders",
        condition:
          params[QueryParams.TAB_VAL] &&
          params[QueryParams.SELECT_VAL] === ORDER_STATE.FINISHED,
        action: () => {
          // sets the status dropdown to FINISHED
          this.filterSelectStatus = this.filterSelectStatusOptions[4];
          this.orderFilterHelper.getTodayFinishedOrders();
        },
      },
      {
        name: "defaultPopulateOrder",
        condition: true,
        action: () =>
          this.orderFilterHelper.defaultPopulateOrder({
            dateFrom: this.currentDayAsISO,
          }),
      },
    ];

    const handler = handlers.find((handler) => handler.condition);
    return handler!.action;
  }

  private performAction(action: () => void) {
    action();
  }

  // New helper methods to handle queryParams selection
  private handleTabVal(tab: string): {
    dateFrom?: string;
    dateTo?: string;
    excludeOrderState?: ORDER_STATE;
    onlyRefund?: boolean;
  } {
    switch (tab) {
      case "all":
        return {};
      case "current":
        return {
          dateFrom: this.currentDayAsISO,
        };
      case "former":
        return {
          dateTo: this.currentDayAsISO,
          excludeOrderState: ORDER_STATE.FINISHED,
        };
      case "refunds":
        return { onlyRefund: true };
      default:
        return {};
    }
  }

  private handleSelectStatus(selectVal: ORDER_STATE | "ALL"): {
    orderState?: ORDER_STATE;
    onlyCancelled: boolean;
    withCancelled: boolean;
  } {
    switch (selectVal) {
      case "ALL":
        return { onlyCancelled: false, withCancelled: false };
      case ORDER_STATE.CANCELLED:
        return {
          orderState: ORDER_STATE.CANCELLED,
          onlyCancelled: false,
          withCancelled: true,
        };
      default:
        return {
          orderState: selectVal,
          onlyCancelled: false,
          withCancelled: false,
        };
    }
  }
}

import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class DynamicFormHelperService {
  constructor() {}

  getFormControlFromFromGroup(
    formGroup: FormGroup,
    formControlName: string,
  ): FormControl {
    return formGroup.controls[formControlName] as FormControl;
  }

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup;
  }
}

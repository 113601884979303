<div class="table-header" id="table-header">
  <div id="left-table-header">
    <h1>{{ tableTitle }}</h1>
    <app-tenant-selector-dropdown
      *ngIf="isSuperAdmin() && tenantSelector"
    ></app-tenant-selector-dropdown>
  </div>
  <div id="right-table-header">
    <button
      (click)="searchExpanded = !searchExpanded"
      appearance="icon"
      icon="tuiIconSearch"
      tuiIconButton
      type="button"
    ></button>

    <div *ngIf="searchExpanded" @fade @slide-short id="search-container">
      <tui-input
        (keyup)="filterTable()"
        [formControl]="this.searchTerm"
        tuiTextfieldSize="s"
      >
        {{ "table-header.search" | translate }}
      </tui-input>
    </div>

    <tui-tabs (click)="filterTable()" [(activeItemIndex)]="activeItemIndex">
      <button tuiTab>{{ "table-header.filter-all" | translate }}</button>
      <button tuiTab>{{ "table-header.filter-active" | translate }}</button>
      <button tuiTab>{{ "table-header.filter-inactive" | translate }}</button>
    </tui-tabs>

    <button
      (click)="createNewClick()"
      appearance="primary"
      id="new-company"
      size="m"
      tuiButton
      type="button"
    >
      {{ buttonText }}
    </button>
  </div>
</div>

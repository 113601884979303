import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, forkJoin, map, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import TourPointLoad from "../models/TourPointLoad.class";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import {
  DeleteRequestPayload,
  UpdateRequestPayload,
} from "../interfaces/request-array.interface";
import { PaginationFilterService } from "../services/pagination-filter.service";
import TourPoint from "../models/TourPoint.class";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

export type TourPointLoadQueryParams = {
  tourPointId: string;
  orderId: string;
  onlyUnannounced: boolean;
  withDeliveryReceipts: boolean;
  withRefunds: boolean;
};

@Injectable({
  providedIn: "root",
})
export class TourPointLoadService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  _totalTourPointLoads = signal<number>(0);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  _tourPointLoads = signal<TourPointLoad[]>([]);

  get tourPointLoads() {
    return this._tourPointLoads();
  }

  set tourPointLoads(value: TourPointLoad[]) {
    this._tourPointLoads.set(value);
  }

  get total() {
    return this._totalTourPointLoads();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD TourPointLoad
  addTourPointLoad(
    addTourPointLoadObjects: TourPointLoad[],
  ): Observable<TourPointLoad[]> {
    let requests: Observable<TourPointLoad>[] = addTourPointLoadObjects.map(
      (object) =>
        this.http.post<TourPointLoad>(this.baseUrl + "tour-point-load", object),
    );

    return forkJoin(requests).pipe(
      tap(() => {
        // Open the snackbar for successful request
        this.handleSuccess("Tour Stop Beladung wurde erstellt");

        // Call the getAllTourPointLoad method for updating the data + view
        this.getAllTourPointLoads().subscribe();
      }),
      catchError((err) => {
        // Open the snackbar for error
        this.errorHandler.sendErrorMessage("tour-point-load", "CREATE", err);
        return of(err);
      }),
    );
  }

  addUnannouncedDelivery(
    addTourPointLoad: TourPoint,
  ): Observable<TourPointLoad> {
    return this.http
      .post<any>(
        this.baseUrl + "tour-point-load/warehouseworker",
        addTourPointLoad,
      )
      .pipe(
        map((response) => {
          return response.tourPointLoads[0];
        }),
      );
  }

  // GET ALL TourPointLoads
  getAllTourPointLoads(
    queryParams?: Partial<TourPointLoadQueryParams>,
  ): Observable<ResponseWithRecordsBody> {
    let params: HttpParams = new HttpParams();

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "tour-point-load", {
        params,
      })
      .pipe(
        tap((res: ResponseWithRecordsBody) => {
          this._tourPointLoads.set(
            res.records.map(
              (tourPointLoad: TourPointLoad) =>
                new TourPointLoad(tourPointLoad),
            ),
          );
          this._totalTourPointLoads.set(res.total);
          return this._tourPointLoads();
        }),
      );
  }

  // CHANGE ONE TourPointLoad
  updateTourPointLoadById(
    updatePayload: UpdateRequestPayload<TourPointLoad>,
  ): Observable<TourPointLoad[]> {
    // now returns an Observable of array

    // Map to an array of observables
    const updateObservables = updatePayload.data.map(({ id, data }) =>
      this.http.patch<TourPointLoad>(
        `${this.baseUrl}tour-point-load/${id}`,
        data,
      ),
    );

    // Use forkJoin to wait for all observables
    return forkJoin(updateObservables).pipe(
      tap(() => {
        this.handleSuccess("Tour Stop Beladung wurde aktualisiert");
        this.getAllTourPointLoads().subscribe();
      }),
      catchError((err) => {
        // Handle the error: this will catch the error as soon as one of the observables fails
        this.errorHandler.sendErrorMessage("tour-point-load", "UPDATE", err);
        // Return an observable with error
        return of(err);
      }),
    );
  }

  // DELETE ONE TourPointLoad
  deleteTourPointLoadById(
    deletePayload: DeleteRequestPayload,
  ): Observable<unknown[]> {
    const deleteObservables = deletePayload.data.map((id) =>
      this.http.delete<unknown>(`${this.baseUrl}tour-point-load/${id}`),
    );

    return forkJoin(deleteObservables).pipe(
      tap(() => {
        this.handleSuccess(
          "Es wurden " +
            deletePayload.data.length +
            " Tour Stop Beladungen gelöscht",
        );
        this.getAllTourPointLoads().subscribe();
      }),
      catchError((err) => {
        this.errorHandler.sendErrorMessage("tour-point-load", "DELETE", err);
        return of(err);
      }),
    );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

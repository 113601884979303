import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";
import { UserPermissionHelperService } from "./user-permission-helper.service";

@Injectable({
  providedIn: "root",
})
export class TourPermissionHelperService {
  private permissionService = inject(PermissionService);
  private userPermissions = inject(UserPermissionHelperService);

  get hasCreateTourPermission() {
    return this.permissionService.createTourPermission().hasPermission;
  }

  get hasReadTourPermission() {
    return this.permissionService.readTourPermission().hasPermission;
  }

  get hasUpdateTourPermission() {
    return this.permissionService.updateTourPermission().hasPermission;
  }

  get hasAllDeleteTourPermissions() {
    return (
      this.permissionService.deleteTourPermission().hasPermission &&
      this.permissionService.deleteTourPointPermission().hasPermission
    );
  }

  hasCreateReadTourPermission(boolVal: boolean = true) {
    return (
      this.hasCreateTourPermission &&
      this.hasReadTourPermission &&
      this.userPermissions.hasReadUserPermission &&
      boolVal
    );
  }

  hasEditReadTourPermission(boolVal: boolean = true) {
    return (
      this.hasUpdateTourPermission &&
      this.hasReadTourPermission &&
      this.userPermissions.hasReadUserPermission &&
      boolVal
    );
  }
}

<h1 class="dialog-title">
  {{
    isAddMode
      ? ("product.add-title" | translate)
      : ("product.edit-title" | translate)
  }}
</h1>

@if (hasCreateProductPermission || hasEditProductPermission || !isAddMode) {
  <form [formGroup]="form">
    <div class="flex-col-g1" style="padding-bottom: 1rem">
      <!-- product name -->
      <app-input-text fieldName="name" hint="product.name" />
      <!-- note -->
      <!--    <app-input-large-text fieldName="note" hint="product.note" />-->
    </div>
    @if (hasEditProductPermission || hasCreateProductPermission) {
      <div style="padding: 0.25rem"></div>
      <!--   possible qualities   -->
      <div class="quality-price-wrapper">
        <app-multi-select-buttons
          (selectEvent)="onQualitySelectionChange($event)"
          [enumOrArray]="qualities"
          [selectedOptionsArray]="selectedQualities"
          [disabled]="
            !isAddMode &&
            !permissionsService.updateProductPricePermission().hasPermission
          "
          [disabledArray]="
            !permissionsService.deleteProductPricePermission().hasPermission
              ? previousQualities
              : []
          "
          ngxTitle="product.qualities"
          style="width: 16rem"
        />
        <div>
          <h3 style="padding: 0; margin: 0 0 1rem">
            {{ "product.price" | translate }}
          </h3>
          <div class="price-wrapper">
            <app-input-payment-amount
              *ngFor="let quality of qualityArray"
              [fieldName]="quality"
              style="width: 16rem"
            >
            </app-input-payment-amount>
          </div>
        </div>
      </div>
      <div style="padding: 0.25rem"></div>
    } @else {
      <div
        style="
          background-color: var(--tui-warn-bg);
          padding: 1.5rem;
          border-radius: 1rem;
        "
      >
        <div style="color: var(--tui-warn-txt)">
          Es fehlt folgende Berechtigung, um die Produktpreise zu bearbeiten:
          <div>
            @if (!hasProductPriceEditPermission) {
              <li><b>Produktpreis bearbeiten</b></li>
            }

            @if (
              !permissionsService.readProductPricePermission().hasPermission
            ) {
              <li><b>Produktpreis ansehen</b></li>
            }
          </div>
        </div>
      </div>
    }

    <h3>
      {{ "product.images" | translate }}
    </h3>

    <!--  product image  -->
    <div *ngIf="!isAddMode && productHasImage" id="image">
      <img [src]="productImage.image" alt="product" class="productImage" />

      <svg
        (click)="resetImage()"
        class="delete-icon"
        fill="#1f1f1f"
        height="28"
        style="background-color: white"
        viewBox="0 0 256 256"
        width="28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM165.66,154.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32L139.31,128Z"
        ></path>
      </svg>
      <br />
      <div class="filename">{{ productImage.fileName }}</div>
    </div>

    <!--  file input -->
    <tui-input-files
      *ngIf="!fileControl.value && !productHasImage"
      [formControl]="fileControl"
      accept="image/*"
    >
      <ng-template>
        <div class="content">
          <tui-marker-icon
            mode="link"
            src="tuiIconUploadCloudLarge"
          ></tui-marker-icon>
          <div>
            {{ "common.file-upload-hint" | translate }}
          </div>
        </div>
      </ng-template>
    </tui-input-files>

    <tui-files>
      <tui-file
        (removed)="removeFile()"
        *ngIf="fileControl.valueChanges | async as file"
        [file]="file"
        [leftContent]="content"
        [showDelete]="fileControl.enabled"
      >
      </tui-file>
    </tui-files>

    <ng-template #content>
      <tui-svg [src]="tuiIconFile"></tui-svg>
    </ng-template>

    <!--  custom spacer  -->
    <div style="padding: 0.5rem"></div>

    <!--   save button   -->
    <app-save-cancel-btns
      (onCancel)="cancel()"
      (onSubmit)="submit()"
      [form]="form"
    />
  </form>
} @else {
  <div
    style="
      background-color: var(--tui-warn-bg);
      padding: 1.5rem;
      border-radius: 1rem;
    "
  >
    <div style="color: var(--tui-warn-txt)">
      Es fehlt folgende Berechtigung, um ein Produkt zu erstellen:

      <div>
        @if (!hasProductPriceCreatePermission) {
          <li><b>Produktpreis erstellen</b></li>
        }

        @if (!hasProductPriceReadPermission) {
          <li><b>Produktpreis ansehen</b></li>
        }

        @if (!permissionsService.readProductPermission().hasPermission) {
          <li>
            <b> Produkte ansehen</b>
          </li>
        }
      </div>
    </div>
  </div>
}

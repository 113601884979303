<app-table-layout
  (dateSelected)="changeDateEvent(extractDate($event))"
  [missingReadPermissions]="
    permissionService.readRefundPermission().missingPermissions
  "
  [showDatePicker]="true"
  [showTable]="permissionService.readRefundPermission().hasPermission"
  dateHint="general.createdAt"
  ngxTitle="order.refunds"
>
  <!--  search field  -->
  <div class="search-form" header-item>
    <app-table-search-input
      (searchChangedDebounced)="search($event)"
    ></app-table-search-input>
  </div>

  <!-- info if permissions are missing -->
  @if (!refundPermissionHelper.allPermissionsForReadAllRefundInfos()) {
    <div header-item-left>
      <tui-svg
        class="pointer"
        (click)="openMissingPermissionsDialog()"
        src="tuiIconInfoLarge"
      ></tui-svg>
    </div>
  }

  <app-table
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (rowClickEvent)="routeToOrder($event)"
    (sortEvent)="tableHelperService.sortTable($event)"
    [cellTemplatesMap]="{
      amount: amount,
      'driver-fullname': driver,
      'tourPointLoad.productOrder.order.orderNumber': orderNumber,
      createdFrom
    }"
    [columns]="columns"
    [currentPage]="tablePage"
    [noSortColumns]="noSortColumns"
    [pageSize]="tablePageSize"
    [tableColumnNames]="headers"
    [tableData]="refunds"
    [totalElements]="total"
  >
    <ng-template #createdFrom let-refund="object">
      {{ getCreatedFrom(refund) }}
    </ng-template>

    <ng-template #amount let-value>
      {{ Math.abs(value) }}
    </ng-template>

    <ng-template #driver let-object="object">
      {{ getDriverNameWithRefundId(object.id) }}
    </ng-template>

    <ng-template #orderNumber let-object="object" let-value>
      <div class="order-number">
        {{ value ?? "-" }}

        <button
          *ngIf="value"
          [routerLink]="['/order/order-detail/', getOrderId(object)]"
          [tuiHint]="tooltip"
          appearance="secondary"
          icon="tuiIconExternalLink"
          size="s"
          tuiHintDirection="right"
          tuiIconButton
        ></button>
      </div>

      <!--   tooltip info >> depending on the copyState   -->
      <ng-template #tooltip>
        {{ "order.open" | translate }}
      </ng-template>
    </ng-template>
  </app-table>
</app-table-layout>

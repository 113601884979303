import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-add-edit-address",
  templateUrl: "./add-edit-address.component.html",
  styleUrls: ["./add-edit-address.component.scss"],
})
export class AddEditAddressComponent {
  @Input() form: FormGroup;
  @Input() required: boolean = true;
}

import { Component, Input } from "@angular/core";
import { tuiIconCalendar } from "@taiga-ui/icons";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { TuiDay } from "@taiga-ui/cdk";

@Component({
  selector: "app-input-date",
  templateUrl: "./input-date.component.html",
  styleUrls: ["./input-date.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})

/**
 * A component that displays an input field for entering an date like birthday
 * @Input hint - The hint to display on top of the input field
 * @Input fieldName - The name of the field in the FormGroup
 */
export class InputDateComponent {
  @Input() hint: string = "Geburtsdatum";
  @Input() fieldName?: string = "birthday";
  @Input() hintColor: string = "black";
  @Input() bRadius?: string = "";
  @Input() required: boolean = true;

  @Input() min: TuiDay = TuiDay.currentLocal();
  @Input() readOnly: boolean = false;
  protected readonly tuiIconCalendar = tuiIconCalendar;
}

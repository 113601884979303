import { Component } from "@angular/core";
import { RoleService } from "../../../api/role.service";
import { NavRoutes } from "../../../other/enums/nav-routes";
import { Router } from "@angular/router";
import { tuiIconPlus } from "@taiga-ui/icons";
import { TablePageComponent } from "../../../interfaces/table-page-component";
import { TableHelperService } from "../../../services/table-helper.service";
import { Permissions } from "../../../other/enums/permissions";
import { PermissionService } from "../../../services/permission.service";
import { RolePermissionHelperService } from "../../../services/permission-helper-services/role-permission-helper.service";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { MissingRolePermissionDialogComponent } from "./missing-role-permission-dialog/missing-role-permission-dialog.component";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements TablePageComponent {
  tablePage: number = 0;
  tablePageSize: number = 10;
  roleTableColumnNames: string[] = ["Rolle"];
  roleTableShownColumns: string[] = ["name", "edit", "delete"];
  showDeleteDialog: boolean = false;
  roleToDeleteId: null;

  protected readonly tuiIconPlus = tuiIconPlus;
  protected readonly Permissions = Permissions;

  constructor(
    public roleService: RoleService,
    private router: Router,
    public tableHelperService: TableHelperService,
    public permissionService: PermissionService,
    private rolePermissions: RolePermissionHelperService,
    private dialogService: TuiDialogHelperService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.roleService);
  }

  ngOnInit(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.populateTable();
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  populateTable(): void {
    this.roleService.getRoles().subscribe();
  }

  public editRole(roleId: string): void {
    this.router
      .navigate([
        NavRoutes.SETTINGS +
          "/" +
          NavRoutes.ROLES +
          "/edit-role" +
          `/${roleId}`,
      ])
      .then();
  }

  public createNewRole(): void {
    if (this.rolePermissions.hasCreateReadRolePermission()) {
      this.router
        .navigate([NavRoutes.SETTINGS + "/" + NavRoutes.ROLES + "/add-role"])
        .then();
    } else {
      this.dialogService.openDialog(MissingRolePermissionDialogComponent);
    }
  }

  public deleteRoleDialog(event: any) {
    this.roleToDeleteId = event;
    this.showDeleteDialog = true;
  }

  public deleteRole(): void {
    if (!this.roleToDeleteId) return;
    this.roleService.deleteRole(this.roleToDeleteId).subscribe(() => {
      this.roleService.getRoles().subscribe();
      this.showDeleteDialog = false;
    });
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneNumber = control.value as string;

    if (phoneNumber === null || phoneNumber === undefined) return null;

    if (phoneNumber.length === 0) {
      return null;
    }

    // Allowed starting characters are '+', '(' and '0'
    const startsWithValidCharacter = /^[+(0]/.test(phoneNumber);
    // Only one '+' is allowed at the start. followed by the common values
    const containsOnlyValidCharacters = /^\+?[0-9() \/\-]*$/.test(phoneNumber);
    // Additional check: no two '/'s can follow each other.
    const noDoubleSlashes = !/\/\//.test(phoneNumber);

    if (!startsWithValidCharacter) {
      return { startsWithValidCharacter: true };
    }

    if (!containsOnlyValidCharacters) {
      return { containsOnlyValidCharacters: true };
    }

    if (!noDoubleSlashes) {
      return { noDoubleSlashes: true };
    }

    return null;
  };
}

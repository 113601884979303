<tui-island
  [ngClass]="{ 'edit-icon-layout': editIcon }"
  [style.background-color]="showBackground ? background : ''"
  [style.max-width]="maxWidth"
  class="transparent-card"
>
  @if (hasTitle) {
    <div>
      <div
        [ngStyle]="{
          'font-size': fontSize,
          padding: titlePadding,
          'font-weight': fontWeight
        }"
        class="title"
      >
        {{ title }}
      </div>

      @if (hasLineSpacer) {
        <div class="line-spacer"></div>
      }
    </div>
  }

  <div
    [style.padding-top]="!hasTitle ? topPadding : '0'"
    class="bottom-container"
  >
    <ng-content></ng-content>
  </div>

  <div (click)="editIconClick.emit()" *ngIf="editIcon" id="editButton">
    <tui-svg src="tuiIconEdit2Large" style="color: var(--tui-primary)">
    </tui-svg>
  </div>

  <app-two-people-svg *ngIf="showIcon" class="svg" />

  <div *ngIf="showEllipsis.ellipse1" class="ellipse"></div>
  <div *ngIf="showEllipsis.ellipse2" class="ellipse2"></div>
</tui-island>

import { BaseModel } from "./BaseModel";
import { User } from "./User";

/**
 * Model Class for creating Tenant instances
 * extends the BaseModel
 */
export class Tenant extends BaseModel<Tenant> {
  name!: string;
  user?: User[];

  constructor(params: Partial<Tenant>) {
    super(params);
    this.createdAt = new Date(this.createdAt);
    this.updatedAt = new Date(this.updatedAt);
  }

  toJSON(): any {
    const object = {
      ...this,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    };

    delete object.user;

    return object;
  }
}

import { Injectable } from "@angular/core";
import Product from "../../../models/Product.class";
import { ProductService } from "../../../api/product.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductHelperService {
  productImages: Map<string, string> = new Map();
  private _products = new BehaviorSubject<Product[]>([]);
  public readonly products$ = this._products.asObservable();

  constructor(private productService: ProductService) {}

  fetchProductsWithImages(callback?: Function) {
    this.productService.getAllProducts().subscribe((res) => {
      this._products.next(res.records);
      this._products.getValue().forEach((product) => {
        this.fetchProductImages(product);
      });

      if (callback) callback();
    });
  }

  fetchProductImages(product: Product) {
    // Fetch the product image
    if (product.dbFileId) {
      this.productService.getProductImage(product.dbFileId).subscribe((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.productImages.set(product.id, reader.result as string);
        };
        reader.readAsDataURL(res);
      });
    } else {
      // Set default image if dbFileId is undefined
      this.productImages.set(product.id, "assets/svg/placeholder.svg");
    }
  }
}

import { BaseModel } from "./BaseModel";
import LocationC from "./Location.class";
import ProductOrder from "./ProductOrder.class";
import Refund from "./Refund.class";
import { User } from "./User";

export enum ORDER_STATE {
  //To CREATE Order in Draftstate only requirement is locationId
  UNPLANNED = "UNPLANNED",

  //To set an Order in Unplannedstate, PATCH existing Order in Draftstate, requirements for PATCH are
  //TimeFrameBegin, TimeFrameEnd, at least one ProductOrder
  PARTLY_PLANNED = "PARTLY_PLANNED",

  PLANNED = "PLANNED",

  //To set an Order to Plannedstate, Patch existing Order in UnplannedState, requirements for Patch are
  //Driver is assigned
  CANCELLED = "CANCELLED",

  //To set an Order Done, Patch existing Order in Plannedstate, requirements for Patch are
  //All ProductOrders are in DoneState
  FINISHED = "FINISHED",
}

export const orderStateTranslations: Map<ORDER_STATE, string> = new Map([
  [ORDER_STATE.UNPLANNED, "order.state-draft"],
  [ORDER_STATE.PARTLY_PLANNED, "order.state-partly-planned"],
  [ORDER_STATE.PLANNED, "order.state-ready"],
  [ORDER_STATE.CANCELLED, "order.state-cancelled"],
  [ORDER_STATE.FINISHED, "order.state-finished"],
]);

export default class Order extends BaseModel<Order> {
  tenantId: string;
  locationId: string;
  location: LocationC;
  timeframeBegin!: string;
  timeframeEnd!: string;
  productOrders: ProductOrder[];
  orderState: ORDER_STATE;
  statusReadySetAt: Date;
  orderNumber: string;
  refunds?: Refund[];
  deleter?: User;

  constructor(params: Partial<Order>) {
    super(params);
    Object.assign(this, params);
  }
}

<div class="forgot-pw-wrapper">
  <tui-island>
    <div class="island-wrapper">
      <div class="headline-wrapper">
        <div *ngIf="type == RESET; else default">
          <h1 class="title">{{ "reset-password.title-reset" | translate }}</h1>
          <p>
            {{ "reset-password.description-reset" | translate }}
          </p>
        </div>

        <!-- if type is not reset (if its by an invite) -->
        <ng-template #default>
          <h1 class="title">{{ "reset-password.title-new" | translate }}</h1>
          <p>
            {{ "reset-password.description-new" | translate }}
          </p>
        </ng-template>
      </div>
      <app-set-new-password
        (submitNewPassword)="submit($event)"
      ></app-set-new-password>
    </div>
  </tui-island>
</div>

<!-- success dialog -->
<ng-template
  [(tuiDialog)]="isOpen"
  [tuiDialogOptions]="{ label: title, size: 's' }"
>
  <tui-elastic-container>
    <ng-container>
      <p class="instruction-text">
        {{ "reset-password.pop-up-description" | translate }}
      </p>
      <div class="button-wrapper">
        <button
          [routerLink]="'/' + NavRoutes.LOGIN"
          size="m"
          tuiButton
          type="submit"
        >
          {{ "reset-password.pop-up-button" | translate }}
        </button>
      </div>
    </ng-container>
  </tui-elastic-container>
</ng-template>

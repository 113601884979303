import { Component, Inject } from "@angular/core";
import { TourPointLoadService } from "../../../api/tourPointLoad.service";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Mode } from "../../../other/enums/mode";
import TourPoint from "../../../models/TourPoint.class";
import { WarehouseService } from "../../../api/warehouse.service";
import { DynamicFormHelperService } from "../../../services/dynamic-form-helper.service";
import { DropDownItem } from "../../../interfaces/drop-down-item";
import { ProductService } from "../../../api/product.service";
import Product, { PRODUCT_QUALITY } from "../../../models/Product.class";
import { DeliveryReceiptService } from "../../../api/deliveryReceipt.service";
import DeliveryReceipt from "../../../models/DeliveryReceipt.class";
import { forkJoin, lastValueFrom, Observable } from "rxjs";
import { pushTypes } from "../../../other/enums/push-types";
import { PushService } from "../../../services/push.service";
import { CustomerService } from "../../../api/customer.service";
import { UserService } from "../../../api/user.service";
import { BaseDialogComponent } from "../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { TourPointService } from "../../../api/tourPoint.service";
import { FileType } from "../../../other/enums/file-type";

@Component({
  selector: "app-add-edit-unannounced-delivery",
  templateUrl: "./add-edit-unannounced-delivery.component.html",
  styleUrl: "./add-edit-unannounced-delivery.component.scss",
})
export class AddEditUnannouncedDeliveryComponent extends BaseDialogComponent {
  form: FormGroup;
  userSignControl = new FormControl(null, Validators.required);
  signControl = new FormControl(null, Validators.required);
  imageControl = new FormControl();
  documentControl = new FormControl();
  mode: string = Mode.ADD; // no Edit mode for this form yet (can be added if needed)
  navigatedBack: boolean = false; // to determine if already navigated back to products page (because the several requests are async and can lead to multiple navigations)

  warehouseDropdownItems: DropDownItem[] = [];
  productDropdownItems: DropDownItem[] = [];
  products: Product[] = [];
  qualityForProductDropdownItems: DropDownItem[] = [];
  customerDropdownItems: DropDownItem[] = [];
  userDropdownItems: DropDownItem[] = [];

  constructor(
    private tourPointService: TourPointService,
    private tourPointLoadService: TourPointLoadService,
    private fb: FormBuilder,
    private warehouseService: WarehouseService,
    private productService: ProductService,
    public dynamicFormHelperService: DynamicFormHelperService,
    private deliveryReceiptService: DeliveryReceiptService,
    private pushService: PushService,
    private customerService: CustomerService,
    private userService: UserService,
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<any>,
    dialogService: TuiDialogHelperService,
  ) {
    super(context, dialogService);
  }

  get tplDtos() {
    return this.form.get("tplDtos") as FormArray;
  }

  ngOnInit(): void {
    this.navigatedBack = false;

    // todo: split form into multiple pages / forms
    // init form
    this.form = this.fb.group({
      warehouseId: ["", Validators.required],
      truckNumber: ["", Validators.required],
      unannouncedCompany: ["", Validators.required],
      customerId: ["", Validators.required],
      driverName: ["", Validators.required],
      userId: ["", Validators.required],
      tplDtos: this.fb.array([
        this.fb.group({
          amount: [1, [Validators.required]],
          quality: ["", [Validators.required]],
          productId: ["", [Validators.required]],
        }),
      ]),
      notes: [""],
    });

    // get all warehouses and create dropdown items
    this.warehouseService.getAllWarehouses().subscribe((warehouses) => {
      this.warehouseDropdownItems = warehouses.records.map((warehouse) => {
        return { id: warehouse.id, label: warehouse.name };
      });
    });

    // get all products and create dropdown items
    this.productService.getAllProducts().subscribe((products) => {
      this.productDropdownItems = products.records.map((product) => {
        return { id: product.id, label: product.name };
      });
      this.products = products.records;
    });

    // get all customers and create dropdown items
    this.customerService.getAllCustomers().subscribe((customers) => {
      this.customerDropdownItems = customers.records.map((customer) => {
        let cleanName = customer.name.replace(/,/g, "");
        return { id: customer.id, label: cleanName };
      });
    });

    // get all users and create dropdown items
    this.userService.getUsers().subscribe((users) => {
      this.userDropdownItems = users.map((user) => {
        return { id: user.id, label: user.name };
      });
    });
  }

  productChange($event: any, index: number) {
    const foundProduct = this.products.find(
      (product) => product.id === $event.id,
    );
    if (!foundProduct) return;
    // set possible qualities for product as dropdown items for quality dropdown
    this.qualityForProductDropdownItems = foundProduct.possibleQualities.map(
      (quality) => {
        return { id: quality, label: this.getLabelOfQualities(quality) };
      },
    );

    const PRODUCT_QUALITY_SORT_ORDER = [
      PRODUCT_QUALITY.QUALITY_NEU,
      PRODUCT_QUALITY.QUALITY_A,
      PRODUCT_QUALITY.QUALITY_B,
      PRODUCT_QUALITY.QUALITY_C,
      PRODUCT_QUALITY.QUALITY_DEFEKT,
    ];

    // Sort the items based on the defined sortOrder
    this.qualityForProductDropdownItems.sort((a, b) => {
      return (
        PRODUCT_QUALITY_SORT_ORDER.indexOf(<PRODUCT_QUALITY>a.id) -
        PRODUCT_QUALITY_SORT_ORDER.indexOf(<PRODUCT_QUALITY>b.id)
      );
    });

    //  if there is only one quality, set it as default otherwise reset the form
    (this.tplDtos.at(index) as FormGroup).controls["quality"].enable();
    if (this.qualityForProductDropdownItems.length === 1) {
      (this.tplDtos.at(index) as FormGroup).controls["quality"].setValue(
        this.qualityForProductDropdownItems[0],
      );
      (this.tplDtos.at(index) as FormGroup).controls["quality"].disable();
    } else {
      (this.tplDtos.at(index) as FormGroup).controls["quality"].setValue(null);
    }
  }

  getLabelOfQualities(quality: PRODUCT_QUALITY) {
    switch (quality) {
      case PRODUCT_QUALITY.QUALITY_NEU:
        return "Neu";
      case PRODUCT_QUALITY.QUALITY_A:
        return "A";
      case PRODUCT_QUALITY.QUALITY_B:
        return "B";
      case PRODUCT_QUALITY.QUALITY_C:
        return "C";
      case PRODUCT_QUALITY.QUALITY_DEFEKT:
        return "Defekt";
    }
  }

  addTplDtoFormRow() {
    this.tplDtos.push(
      this.fb.group({
        amount: [1, [Validators.required]],
        quality: ["", [Validators.required]],
        productId: ["", [Validators.required]],
      }),
    );
  }

  deleteTplDtoFormRow(index: number) {
    this.tplDtos.removeAt(index);
  }

  async submit() {
    // create TourPoint object from form values
    const tourPoint = new TourPoint({
      warehouseId: this.form.value.warehouseId.id,
      truckNumber: this.form.value.truckNumber,
      unannouncedCompany: this.form.value.unannouncedCompany,
      customerId: this.form.value.customerId.id,
      tplDtos: [],
    });

    // push tplDtos (tourPointLoads) to tourPoint
    this.tplDtos.controls.forEach((tplDto: AbstractControl) => {
      tourPoint.tplDtos.push({
        amount: tplDto.value.amount,
        quality: tplDto.getRawValue().quality.id,
        productId: tplDto.value.productId.id,
      });
    });

    try {
      // add tourPoint
      const tourPointLoad = await lastValueFrom(
        this.tourPointLoadService.addUnannouncedDelivery(tourPoint),
      );

      // add deliveryReceipt
      const deliveryReceipt = new DeliveryReceipt({
        tourPointId: tourPointLoad.tourPointId,
        note: this.form.value.notes,
      });
      const createdReceipt = await lastValueFrom(
        this.deliveryReceiptService.addDeliveryReceipt(deliveryReceipt),
      );

      // add signature and image requests to array and handle them with forkJoin
      const requestObservables$: Observable<any>[] = [];

      // add user-signature request
      if (this.userSignControl.value) {
        const signFormData = new FormData();
        signFormData.append("userId", this.form.value.userId.id);
        signFormData.append("file", this.userSignControl.value);
        requestObservables$.push(
          this.deliveryReceiptService.uploadSignature(
            createdReceipt.id,
            signFormData,
          ),
        );
      }

      // add driver signature request
      if (this.signControl.value) {
        const signFormData = new FormData();
        signFormData.append("file", this.signControl.value);
        signFormData.append("name", this.form.value.driverName);
        requestObservables$.push(
          this.deliveryReceiptService.uploadSignature(
            createdReceipt.id,
            signFormData,
          ),
        );
      }

      // add image requests
      if (this.imageControl.value) {
        for (const image of this.imageControl.value) {
          const fileFormData = new FormData();
          fileFormData.append("file", image);
          fileFormData.append("mimeType", image.type);
          fileFormData.append("fileName", image.name);
          fileFormData.append("fileType", FileType.PHOTO);
          requestObservables$.push(
            this.deliveryReceiptService.uploadFile(
              createdReceipt.id,
              fileFormData,
            ),
          );
        }
      }

      // add document requests
      if (this.documentControl.value) {
        for (const document of this.documentControl.value) {
          const fileFormData = new FormData();
          fileFormData.append("file", document);
          fileFormData.append("mimeType", document.type);
          fileFormData.append("fileName", document.name);
          fileFormData.append("fileType", FileType.DOCUMENT);
          requestObservables$.push(
            this.deliveryReceiptService.uploadFile(
              createdReceipt.id,
              fileFormData,
            ),
          );
        }
      }

      // handle requests
      this.handleMultipleRequests(requestObservables$);
    } catch (err) {
      console.error("Error while adding unannounced delivery", err);
      this.showErrorMessage();
    }
  }

  handleMultipleRequests(requestObservables: Observable<any>[]) {
    if (requestObservables.length === 0) {
      this.showSuccessMessage();
      return;
    }

    // combines all observable for all requests, the subscription is only called when all requests are finished
    forkJoin(requestObservables).subscribe({
      next: () => {
        // Success handling: reload data and show success message
        this.tourPointService
          .getAllTourPoints({
            onlyUnannounced: true,
            populateTourPointLoad: true,
            populateUnannouncedInfo: true,
          })
          .subscribe();
        this.showSuccessMessage();
      },
      error: () => {
        // Error handling
        this.showErrorMessage();
      },
    });
  }

  showErrorMessage() {
    this.navigateBack();
    this.pushService.sendPush(
      pushTypes.ERROR,
      "",
      "Beim speichern der Lieferung ist ein Fehler aufgetreten.",
    );
  }

  showSuccessMessage() {
    this.navigateBack();
    this.pushService.sendPush(
      pushTypes.SUCCESS,
      "",
      "Die Lieferung wurde erfolgreich gespeichert.",
    );
  }

  navigateBack() {
    if (this.navigatedBack) return;
    this.tourPointLoadService
      .getAllTourPointLoads({
        onlyUnannounced: true,
        // withDeliveryReceipts: true, // leads to errors with pagination
      })
      .subscribe();
    this.closeDialog();
    this.navigatedBack = true;
  }
}

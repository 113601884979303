import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsScriptLoaderService {
  private static promise: Promise<void>;

  public static load(): Promise<void> {
    if (typeof this.promise !== "undefined") {
      return this.promise;
    }

    this.promise = new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      script.onload = () => resolve();
      script.onerror = reject;
    });

    return this.promise;
  }
}

<div style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um alle Informationen der Reklamationen
    ansehen zu können:
  </h3>

  <ul>
    <li *ngIf="!permissionService.readOrderPermission().hasPermission">
      <b>Aufträge ansehen</b>
    </li>

    <li *ngIf="!permissionService.readLocationPermission().hasPermission">
      <b>Lieferadressen ansehen</b>
    </li>

    <li *ngIf="!permissionService.readUserPermission().hasPermission">
      <b>Mitarbeiter ansehen</b>
    </li>

    <li *ngIf="!permissionService.readProductOrderPermission().hasPermission">
      <b>Produktbestellungen ansehen</b>
    </li>

    <li *ngIf="!permissionService.readProductPermission().hasPermission">
      <b>Produkte ansehen</b>
    </li>

    <li *ngIf="!permissionService.readRefundPermission().hasPermission">
      <b>Reklamationen ansehen</b>
    </li>

    <li *ngIf="!permissionService.readTourPermission().hasPermission">
      <b>Touren ansehen</b>
    </li>

    <li *ngIf="!permissionService.readTourPointPermission().hasPermission">
      <b>Tourpunkte ansehen</b>
    </li>
  </ul>
</div>

import { Inject, Injectable } from "@angular/core";
import { take } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { TuiAlertService } from "@taiga-ui/core";
import { DateTime } from "luxon";
import { pushTypes } from "../other/enums/push-types";

@Injectable({
  providedIn: "root",
})
/**
 * Service Class for displaying push-notifications
 */
export class PushService {
  private alertTranslationKeys = {
    [pushTypes.ERROR]: "alert.error",
    [pushTypes.INFO]: "alert.info",
    [pushTypes.SUCCESS]: "alert.success",
    [pushTypes.WARNING]: "alert.warning",
  };

  constructor(
    @Inject(TuiAlertService) protected readonly alert: TuiAlertService,
    private translateService: TranslateService,
  ) {}

  sendPush(type: pushTypes, content: string = "", pushText?: string) {
    const translationKeys = Object.values(this.alertTranslationKeys);
    this.loadTranslations(translationKeys).subscribe((translations) => {
      const translatedAlert = translations[this.alertTranslationKeys[type]];
      const topLine = this.getFormattedMessage(type, translatedAlert);

      this.alert
        .open(pushText ?? "", {
          status: type,
          label: topLine,
          autoClose: true,
          hasCloseButton: true,
          hasIcon: true,
        })
        .pipe(take(1))
        .subscribe();
    });
  }

  private getCurrentDate(): string {
    return DateTime.now().setZone("Europe/Berlin").toFormat("dd.MM.yyyy HH:mm");
  }

  private getFormattedMessage(type: string, translatedAlert: string): string {
    return type === pushTypes.ERROR || type === pushTypes.WARNING
      ? `${translatedAlert}: ${this.getCurrentDate()}`
      : `${translatedAlert}`;
  }

  private loadTranslations(keys: string[]) {
    return this.translateService.get(keys);
  }
}

<div class="flex-col-g1">
  <div class="same-row-inputs">
    <!--   street   -->
    <app-input-text
      [hint]="'address.street'"
      [required]="required"
      fieldName="street"
    />

    <!--   house no   -->
    <app-input-text
      [hint]="'address.houseNo'"
      [required]="required"
      fieldName="houseNo"
    />
  </div>

  <div class="same-row-inputs">
    <!--   street   -->
    <app-input-text
      [hint]="'address.postal'"
      [required]="required"
      fieldName="postalCode"
    />

    <!--   house no   -->
    <app-input-text
      [hint]="'address.city'"
      [required]="required"
      fieldName="city"
    />
  </div>
</div>

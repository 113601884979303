<h1 class="txt-color">
  {{ "common.delete-title" | translate }}
</h1>

<div class="hint-container">
  <tui-svg
    [src]="tuiIconAlertOctagonLarge"
    style="color: red !important"
  ></tui-svg>
  <div>
    <div class="txt-color">{{ "tp-delete-start" | translate }}</div>
    <div class="txt-color">{{ "tp-delete-end" | translate }}</div>
  </div>
</div>

<hr />

<div class="button-wrapper" style="padding-top: 1rem">
  <button
    (click)="deleteEvent()"
    appearance="''"
    class="deleteBtn"
    size="m"
    tuiButton
    type="button"
  >
    {{ "common.button-delete" | translate }}
  </button>

  <button
    (click)="cancelEvent()"
    appearance="secondary"
    size="m"
    style="flex-grow: 1"
    tuiButton
    type="button"
  >
    {{ "common.button-cancel" | translate }}
  </button>
</div>

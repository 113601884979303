import { BaseModel } from "./BaseModel";
import { Tenant } from "./Tenant";
import { Role } from "./Role";
import Warehouse from "./Warehouse.class";

export enum ViewAccess {
  DRIVER = "DRIVER",
  WAREHOUSEWORKER = "WAREHOUSEWORKER",
}

/**
 * Model Class for creating User instances
 * extends the BaseModel
 */
export class User extends BaseModel<User> {
  tenantId!: string;
  password!: string;
  email!: string;
  firstName!: string;
  lastName!: string;
  phone!: string;
  roleId!: string;
  active!: boolean;
  inviteAcceptedAt!: string;
  tenant?: Tenant;
  role?: Role;
  viewAccess?: ViewAccess[] | null;
  warehouses?: Warehouse[];
  truckNumber?: string;

  constructor(params: Partial<User>) {
    super(params);
    Object.assign(this, params);
  }

  get name() {
    return this.firstName + " " + this.lastName;
  }
}

import { Component, Input } from "@angular/core";
import ProductOrder from "../../../../models/ProductOrder.class";
import { ProductService } from "../../../../api/product.service";
import Product from "../../../../models/Product.class";

@Component({
  selector: "app-product-order-card",
  templateUrl: "./product-order-card.component.html",
  styleUrls: ["./product-order-card.component.scss"],
})
export class ProductOrderCardComponent {
  @Input() productOrder: ProductOrder;
  @Input() product: Product | null = null;
  productImage: any;
  protected readonly Math = Math;

  constructor(private productService: ProductService) {}

  get totalAmount(): number {
    if (this.productOrder.amount == null || this.productOrder.amount == 0)
      return 0;
    return Math.abs(this.productOrder.amount);
  }

  get calculatedAmount(): number {
    if (
      this.productOrder.calculatedAmount == null ||
      this.productOrder.calculatedAmount == 0
    )
      return 0;
    return Math.abs(this.productOrder.calculatedAmount);
  }

  ngOnInit(): void {
    if (this.product == null) {
      this.productService
        .getProductById(this.productOrder.productId)
        .subscribe((product) => {
          if (product != null) {
            this.productOrder.product = product;
          }
          if (product.dbFileId == null) return;
          this.getImageForProduct(product.dbFileId);
        });
    } else {
      this.productOrder.product = this.product;
      if (this.product.dbFileId == null) return;
      this.getImageForProduct(this.product.dbFileId);
    }
  }

  getImageForProduct(productDbFileId: string) {
    this.productService.getProductImage(productDbFileId).subscribe((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.productImage = reader.result as string;
      };
      reader.readAsDataURL(res);
    });
  }
}

<div class="dialog-content">
  <h1 style="color: var(--tui-text-dark-02); margin: 0; font-size: 1.5rem">
    {{ "location.edit-title" | translate }}
  </h1>

  <div class="flex-align-center">
    <!--   title   -->
    <h3>{{ "address.add-title" | translate }}</h3>

    @if (
      !permissionService.deleteLocationPermission().hasPermission ||
      !permissionService.deleteContactPersonsPermission().hasPermission
    ) {
      <app-tooltip
        elementName="Lieferadresse"
        [permissions]="
          locationPermissionsHelper.missingLocationPermissionsTooltip()
        "
        methodType="löschen"
      />
    }
  </div>

  <!--   form   -->
  <form [formGroup]="form" class="flex-col-g1">
    <!--     location name   -->
    <app-input-text
      [hint]="'location.name'"
      [required]="false"
      fieldName="locationName"
    />

    <div class="same-row-inputs">
      <!--   street   -->
      <app-input-text [hint]="'address.street'" fieldName="street" />

      <!--   house no   -->
      <app-input-text [hint]="'address.houseNo'" fieldName="houseNo" />
    </div>

    <div class="same-row-inputs">
      <!--   street   -->
      <app-input-text [hint]="'address.postal'" fieldName="postalCode" />

      <!--   house no   -->
      <app-input-text [hint]="'address.city'" fieldName="city" />
    </div>

    <!--  note  -->
    <app-input-large-text fieldName="note" hint="general.note" />

    <!--  loading  from side -->
    <div class="sideLoading">
      {{ "location.sideMounting" | translate }}:

      <tui-toggle
        [class.toggle]="!form.controls['sideMounting'].value"
        formControlName="sideMounting"
      ></tui-toggle>

      {{
        form.controls["sideMounting"].value
          ? ("general.yes" | translate)
          : ("general.no" | translate)
      }}
    </div>

    <!--   CONTACT PERSON -->

    <div class="flex-align-center">
      <!--   contact person title   -->
      <h3 style="margin: 0">{{ "contactPerson.title" | translate }}</h3>

      @if (
        !locationPermissionsHelper.hasCreateReadAndUpdateContactPersonsPermissions
      ) {
        <app-tooltip
          elementName="Kontaktpersonen"
          [permissions]="
            locationPermissionsHelper.missingContactPersonsPermissionsTooltip()
          "
        />
      }
    </div>

    @if (permissionService.readContactPersonsPermission().hasPermission) {
      <div
        *ngFor="let contactPerson of contactPersons.controls; let i = index"
        class="contact-persons-wrapper"
      >
        <div
          [formGroup]="getFormGroupFromAbstractControl(contactPerson)"
          class="contact-person-form"
        >
          <div>
            <app-input-text
              [hint]="'contactPerson.name'"
              fieldName="contactName"
            />
            <app-input-text
              [hint]="'contactPerson.description'"
              fieldName="contactDescription"
            />
          </div>
          <div>
            <app-input-phone fieldName="contactPhone" />
            <app-input-email fieldName="contactEmail" />
          </div>
        </div>
        <button
          (click)="deleteContactPersonFormRow(i)"
          appearance="icon"
          class="delete-button"
          icon="tuiIconTrash2Large"
          id="delete-button"
          size="l"
          tuiButton
          type="button"
        ></button>
      </div>
    }

    @if (locationPermissionsHelper.hasCreateLocationContactPersonPermissions) {
      <button
        (click)="addContactPersonFormRow()"
        appearance="secondary"
        icon="tuiIconPlus"
        id="addButton"
        size="m"
        style="margin-top: 0.25rem"
        tuiButton
        type="button"
      >
        {{ "contactPerson.add" | translate }}
      </button>
    }

    <!-- custom spacer -->
    <div style="margin-top: 0.5rem"></div>

    <!-- cancel, save buttons -->
    <app-save-cancel-btns
      (onCancel)="closeDialog()"
      (onDelete)="this.showDeleteDialog = true"
      (onSubmit)="submit()"
      [form]="form"
      [showDeleteBtn]="
        !isAddMode && locationPermissionsHelper.hasAllDeleteLocationPermissions
      "
    />
  </form>
</div>

<app-delete-dialog
  (cancelEvent)="showDeleteDialog = false"
  (deleteEvent)="deleteLocation()"
  [isOpen]="showDeleteDialog"
/>

import { Component } from "@angular/core";
import { NavRoutes } from "../../other/enums/nav-routes";
import { Router } from "@angular/router";
import { RefundService } from "../../api/refund.service";
import Refund from "../../models/Refund.class";
import { TableHelperService } from "../../services/table-helper.service";
import { Permissions } from "../../other/enums/permissions";
import { PermissionService } from "../../services/permission.service";
import { PushService } from "../../services/push.service";
import { pushTypes } from "../../other/enums/push-types";
import {
  MissingRefundsPermissionsDialogComponent,
} from "../refunds-table-page/missing-refunds-permissions-dialog/missing-refunds-permissions-dialog.component";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import {
  RefundsPermissionHelperService,
} from "../../services/permission-helper-services/refunds-permission-helper.service";

/**
 * The dashboard page component shows statistics about the company.
 */
@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent {
  // table data
  headers = [
    "Erstellt am",
    "Kunde",
    "Auftragsnummer",
    "Fahrer",
    "Anzahl",
    "Produkt",
  ];
  columns = [
    "createdAt",
    "createdFrom",
    "tourPointLoad.productOrder.order.orderNumber",
    "driver-fullname",
    "amount",
    "tourPointLoad.product.name",
  ];
  noSortColumns: string[] = [
    "tourPointLoad.productOrder.order.orderNumber",
    "createdFrom",
    "driver-fullname",
    "amount",
    "tourPointLoad.product.name",
  ];
  refunds: Refund[];
  readonly maxTours = 5;
  readonly date = new Date().toLocaleDateString();
  tablePage: number;
  tablePageSize: number;
  // needed readonly variables for the html template
  protected readonly Math = Math;
  protected readonly Date = Date;
  protected readonly NavRoutes = NavRoutes;
  protected readonly Permissions = Permissions;

  constructor(
    private refundService: RefundService,
    private router: Router,
    public tableHelperService: TableHelperService,
    public permissionService: PermissionService,
    private push: PushService,
    private dialogService: TuiDialogHelperService,
    public refundPermissionHelper: RefundsPermissionHelperService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.refundService);
  }

  ngOnInit(): void {
    this.getLastCreatedRefunds();
  }

  getLastCreatedRefunds() {
    this.refundService.setSorting({
      sortDirection: -1,
      sortColumn: "createdAt",
    });
    this.refundService.setPaginationIndices({ start: 0, limit: this.maxTours });

    this.populateTable();
  }

  ngOnDestroy(): void {
    this.refundService.deletePaginationIndices();
  }

  routeToOrderRefunds($event: string) {
    const refund = this.refunds.find((r) => r.id === $event);

    if (!refund?.tourPointLoad?.productOrder?.orderId) {
      this.push.sendPush(
        pushTypes.INFO,
        "",
        "Kein Auftrag zur Reklamation gefunden",
      );
      return;
    }
    this.router
      .navigate([
        NavRoutes.ORDER +
          "/" +
          "order-detail/" +
          refund?.tourPointLoad?.productOrder?.orderId,
      ])
      .then();
  }

  populateTable() {
    this.refundService.getAllRefunds().subscribe((refunds) => {
      if (refunds.records.length > this.maxTours) {
        return;
      }
      this.refunds = refunds.records;
    });
  }

  getDriverNameWithRefundId(refundId: string): string | undefined {
    const refund = this.refunds.find((r) => r.id === refundId);

    if (!refund?.tourPointLoad?.tourPoint?.tour?.driver) {
      return "-";
    }
    return (
      refund?.tourPointLoad?.tourPoint?.tour?.driver.firstName +
      " " +
      refund?.tourPointLoad?.tourPoint?.tour?.driver.lastName
    );
  }

  getCreatedFromForRefunds(refund: Refund): string {
    // Reference check before accessing properties
    const isWarehouseNameAvailable =
      refund?.tourPointLoad?.tourPoint?.warehouse?.name;
    const isLocationNameAvailable =
      refund?.tourPointLoad?.productOrder?.order?.location?.name;

    if (isWarehouseNameAvailable) {
      return refund.tourPointLoad.tourPoint.warehouse!.name;
    } else if (isLocationNameAvailable) {
      return refund.tourPointLoad.productOrder?.order!.location.name!;
    } else {
      return "-";
    }
  }

  openMissingPermissionsDialog() {
    this.dialogService.openDialog(MissingRefundsPermissionsDialogComponent);
  }
}

<div class="dialog-content">
  <h1 style="color: var(--tui-text-dark-02); margin-top: 0; font-size: 1.5rem">
    {{ isAddMode ? ("addCustomer" | translate) : ("editCustomer" | translate) }}
  </h1>

  <!--   form   -->
  <form [formGroup]="form" class="customer-grid">
    <div class="flex-col-g1">
      <h3 style="margin: 0">{{ "customerInfo" | translate }}</h3>
      <div class="same-row-inputs">
        <!--   customer number   -->
        <app-input-text
          [required]="true"
          fieldName="customerNumber"
          hint="Nummer"
        />

        <!--   customer name   -->
        <app-input-text [hint]="'addEditCustomer.title'" fieldName="name" />

        <!--   customer MATCHC   -->
        <app-input-text fieldName="MATCHC" hint="MATCHC" />

        <!--   phone number   -->
        <app-input-phone [required]="false" fieldName="phone" />

        <!-- email -->
        <app-input-email [required]="false" fieldName="email" />

        <!--  fax number -->
        <app-input-phone
          [required]="false"
          fieldName="fax"
          hint="addEditCustomer.fax"
        />
      </div>
    </div>

    <div class="flex-col-g1">
      <h3 style="margin: 0">
        {{ "addEditCustomer.bill-details" | translate }}
      </h3>
      <div class="same-row-inputs">
        <!--   bill-street   -->
        <app-input-text
          [required]="false"
          fieldName="billStreet"
          hint="addEditCustomer.bill-street"
        />

        <!--   bill-house-number  -->
        <app-input-text
          [required]="false"
          fieldName="billStreetNumber"
          hint="addEditCustomer.bill-house-number"
        />

        <!--  bill-postal-code    -->
        <app-input-text
          [required]="false"
          fieldName="billPostalcode"
          hint="addEditCustomer.bill-postal-code"
        />

        <!--   bill-city  -->
        <app-input-text
          [required]="false"
          fieldName="billCity"
          hint="addEditCustomer.bill-city"
        />

        <!--  bill-city-district    -->
        <app-input-text
          [required]="false"
          fieldName="billCityDistrict"
          hint="addEditCustomer.bill-city-district"
        />

        <!--  bill-country    -->
        <app-input-text
          [required]="false"
          fieldName="LKZ"
          hint="addEditCustomer.bill-lkz"
        />

        <!--  bill-tax    -->
        <app-input-number
          [required]="false"
          fieldName="taxes"
          hint="addEditCustomer.bill-tax"
        />
      </div>
    </div>

    <div class="flex-col-g1">
      <h3 style="margin: 0">
        {{ "addEditCustomer.additional-information" | translate }}
      </h3>
      <tui-accordion>
        <tui-accordion-item>
          <p style="margin: 0; color: black">
            {{ "addEditCustomer.note-files" | translate }}
          </p>
          <ng-template tuiAccordionItemContent>
            <div class="flex-col-g1">
              <!--   wysiwyg editor   -->
              <app-wysiwyg-editor
                (changeEvent)="editorChangeData($event)"
                [data]="editorTextValue"
                style="max-height: 30rem"
              />
              <!--   customer file input   -->
              <app-input-files
                [fileControl]="fileControl"
                [showIcon]="false"
                customBackgroundColor="white"
                customTextColor="black"
              />

              <!--   customer file gallery   -->
              <app-file-gallery
                (onDelete)="deleteFiles($event)"
                [files]="customerFiles"
                style="margin-top: 1rem"
              ></app-file-gallery>
            </div>
          </ng-template>
        </tui-accordion-item>
      </tui-accordion>

      <div class="flex-align-center">
        <!--   contact person title   -->
        <h3 style="margin: 0">{{ "contactPerson.title" | translate }}</h3>

        @if (!customerPermissionHelper.hasCRUDContactPersonPermissions) {
          <app-tooltip
            [permissions]="
              customerPermissionHelper.missingContactPersonsPermissionsTooltip()
            "
            elementName="Kontaktpersonen"
          />
        }
      </div>

      @if (
        contactPersons &&
        permissionService.readContactPersonsPermission().hasPermission
      ) {
        <div
          *ngFor="let contactPerson of contactPersons.controls; let i = index"
          class="contact-persons-wrapper"
        >
          <div
            [formGroup]="getFormGroupFromAbstractControl(contactPerson)"
            class="contact-person-form"
          >
            <div>
              <app-input-text
                [hint]="'contactPerson.name'"
                fieldName="contactName"
              />
              <app-input-text
                [hint]="'contactPerson.description'"
                fieldName="contactDescription"
              />
            </div>
            <div>
              <app-input-phone fieldName="contactPhone" />
              <app-input-email fieldName="contactEmail" />
            </div>
          </div>
          @if (
            isAddMode ||
            !isExistingContactPerson(i) ||
            permissionService.deleteContactPersonsPermission().hasPermission
          ) {
            <button
              (click)="deleteContactPersonFormRow(i)"
              appearance="icon"
              class="delete-button"
              icon="tuiIconTrash2Large"
              id="delete-button"
              size="l"
              tuiButton
              type="button"
            ></button>
          }
        </div>
      }
      @if (customerPermissionHelper.hasCreateCustomerContactPersonPermissions) {
        <button
          (click)="addContactPersonFormRow()"
          appearance="secondary"
          icon="tuiIconPlus"
          id="addButton"
          size="m"
          style="margin-top: 0.25rem; width: 100%"
          tuiButton
          type="button"
        >
          {{ "contactPerson.add" | translate }}
        </button>
      }
    </div>

    <div class="full-customer-grid-span" style="margin-top: 0.5rem">
      <!-- cancel, save buttons -->
      <app-save-cancel-btns
        (onCancel)="closeDialog()"
        (onSubmit)="submit()"
        [form]="form"
      />
    </div>
  </form>
</div>

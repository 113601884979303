@if (tourPoint) {
  <tui-island class="transparent-island">
    <div class="header">
      <div class="contentContainer">
        <ng-content />

        <span class="title" [title]="'Stop: ' + title">{{ title }}</span>
      </div>

      <span>
        <div class="flex-align-center" style="--gap: 0.5rem">
          @if (
            tourPoint.order?.timeframeBegin &&
            tourPoint.order?.timeframeEnd &&
            !isBetweenTime()
          ) {
            <tui-svg
              src="tuiIconAlertTriangleLarge"
              class="pointer"
              style="color: var(--tui-error-bg)"
              [tuiHint]="tooltip"
            ></tui-svg>

            <ng-template #tooltip>
              Außerhalb der Lieferzeit:

              {{ tourPoint.order?.timeframeBegin | date: "HH:mm" : "UTC" }} -
              {{ tourPoint.order?.timeframeEnd | date: "HH:mm" : "UTC" }}
            </ng-template>
          }

          {{ tourPoint.estimatedArrival | date: "HH:mm" }}
        </div>
      </span>
    </div>

    <tui-island class="tp-card">
      <div class="address">
        <!--  marker image  -->
        <img
          [ngSrc]="
            isLocation ? 'assets/svg/red-marker.svg' : 'assets/svg/logo.svg'
          "
          alt="marker"
          height="20"
          width="20"
        />

        <!-- title including name / address -->

        <span [title]="getAddressText().fullText">
          {{ getAddressText().shortenedText }}
        </span>
      </div>

      <!--    delete tp button      -->
      <div
        class="scoped"
        [ngClass]="{
          unsigned: !isNotSigned(),
          signed: isNotSigned(),
          disabled:
            !isTourStatusDraft ||
            !mapPermissionHelper.hasDeleteTourPointPermissions
        }"
      >
        <button
          (click)="deleteTourPoint(tourPoint)"
          appearance="icon"
          [icon]="isNotSigned() ? tuiIconTrash2Large : tuiIconCheckCircleLarge"
          [disabled]="
            !isNotSigned() ||
            !isTourStatusDraft ||
            !mapPermissionHelper.hasDeleteTourPointPermissions
          "
          tuiIconButton
          type="button"
        ></button>
      </div>
    </tui-island>

    <!-- TPL  -->
    <app-tour-point-load-loop [tourPoint]="tourPoint" />
  </tui-island>
}

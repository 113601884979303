// const enumValue = PermissionTranslations[keyOfEnum as keyof typeof PermissionTranslations];

export enum PermissionTranslations {
  FILE = "role.permission-file",
  FILE_READ = "role.permission-file-read",
  FILE_READ_OWN = "role.permission-file-read-own",
  FILE_CREATE = "role.permission-file-create",
  FILE_UPDATE = "role.permission-file-update",
  FILE_UPDATE_OWN = "role.permission-file-update-own",
  FILE_DELETE = "role.permission-file-delete",
  FILE_DELETE_OWN = "role.permission-file-delete-own",

  ROLE = "role.permission-role",
  ROLE_READ = "role.permission-role-read",
  ROLE_READ_OWN = "role.permission-role-read-own",
  ROLE_CREATE = "role.permission-role-create",
  ROLE_UPDATE = "role.permission-role-update",
  ROLE_DELETE = "role.permission-role-delete",

  TENANT = "role.permission-tenant",
  TENANT_READ = "role.permission-tenant-read",
  TENANT_READ_OWN = "role.permission-tenant-read-own",
  TENANT_CREATE = "role.permission-tenant-create",
  TENANT_UPDATE = "role.permission-tenant-update",
  TENANT_UPDATE_OWN = "role.permission-tenant-update-own",
  TENANT_DELETE = "role.permission-tenant-delete",
  TENANT_DELETE_OWN = "role.permission-tenant-delete-own",

  USER = "role.permission-user",
  USER_READ = "role.permission-user-read",
  USER_READ_OWN = "role.permission-user-read-own",
  USER_READ_FROM_TOUR = "role.permission-user-read-from-tour",
  USER_CREATE = "role.permission-user-create",
  USER_UPDATE = "role.permission-user-update",
  USER_UPDATE_OWN = "role.permission-user-update-own",
  USER_DELETE = "role.permission-user-delete",
  USER_DELETE_OWN = "role.permission-user-delete-own",

  KPI = "role.permission-kpi",
  KPI_TENANT = "role.permission-kpi-tenant",

  KPI_DASHBOARD = "role.permission-kpi-dashboard",

  ADDRESS = "role.permission-address",
  ADDRESS_READ = "role.permission-address-read",
  ADDRESS_UPDATE = "role.permission-address-update",
  ADDRESS_CREATE = "role.permission-address-create",
  ADDRESS_DELETE = "role.permission-address-delete",

  CUSTOMER = "role.permission-customer",
  CUSTOMER_READ = "role.permission-customer-read",
  CUSTOMER_READ_FROM_LOCATION = "role.permission-customer-read-from-location",
  CUSTOMER_CREATE = "role.permission-customer-create",
  CUSTOMER_UPDATE = "role.permission-customer-update",
  CUSTOMER_UPDATE_FROM_LOCATION = "role.permission-customer-update-from-location",
  CUSTOMER_DELETE = "role.permission-customer-delete",
  CUSTOMER_DELETE_FROM_LOCATION = "role.permission-customer-delete-from-location",

  LOCATION = "role.permission-location",
  LOCATION_READ = "role.permission-location-read",
  LOCATION_READ_FROM_CUSTOMER = "role.permission-location-read-from-customer",
  LOCATION_READ_FROM_TOURPOINT = "role.permission-location-read-from-tourpoint",
  LOCATION_UPDATE = "role.permission-location-update",
  LOCATION_UPDATE_FROM_CUSTOMER = "role.permission-location-update-from-customer",
  LOCATION_UPDATE_FROM_TOURPOINT = "role.permission-location-update-from-tourpoint",
  LOCATION_CREATE = "role.permission-location-create",
  LOCATION_DELETE = "role.permission-location-delete",
  LOCATION_DELETE_FROM_CUSTOMER = "role.permission-location-delete-from-customer",
  LOCATION_DELETE_FROM_TOURPOINT = "role.permission-location-delete-from-tourpoint",

  ORDER = "role.permission-order",
  ORDER_READ = "role.permission-order-read",
  ORDER_READ_FROM_LOCATION = "role.permission-order-read-from-location",
  ORDER_READ_FROM_CUSTOMER = "role.permission-order-read-from-customer",
  ORDER_UPDATE = "role.permission-order-update",
  ORDER_UPDATE_FROM_LOCATION = "role.permission-order-update-from-location",
  ORDER_UPDATE_FROM_CUSTOMER = "role.permission-order-update-from-customer",
  ORDER_CREATE = "role.permission-order-create",
  ORDER_DELETE = "role.permission-order-delete",
  ORDER_DELETE_FROM_LOCATION = "role.permission-order-delete-from-location",
  ORDER_DELETE_FROM_CUSTOMER = "role.permission-order-delete-from-customer",

  PRODUCT = "role.permission-product",
  PRODUCT_READ = "role.permission-product-read",
  PRODUCT_READ_FROM_PRODUCT_ORDER = "role.permission-product-read-from-product-order",
  PRODUCT_READ_FROM_TOUR_POINT_LOAD = "role.permission-product-read-from-tour-point-load",
  PRODUCT_UPDATE = "role.permission-product-update",
  PRODUCT_UPDATE_FROM_PRODUCT_ORDER = "role.permission-product-update-from-product-order",
  PRODUCT_UPDATE_FROM_TOUR_POINT_LOAD = "role.permission-product-update-from-tour-point-load",
  PRODUCT_CREATE = "role.permission-product-create",
  PRODUCT_DELETE = "role.permission-product-delete",
  PRODUCT_DELETE_FROM_PRODUCT_ORDER = "role.permission-product-delete-from-product-order",
  PRODUCT_DELETE_FROM_TOUR_POINT_LOAD = "role.permission-product-delete-from-tour-point-load",

  PRODUCT_ORDER = "role.permission-product-order",
  PRODUCT_ORDER_READ = "role.permission-product-order-read",
  PRODUCT_ORDER_READ_FROM_ORDER = "role.permission-product-order-read-from-order",
  PRODUCT_ORDER_READ_FROM_TOUR_POINT_LOAD = "role.permission-product-order-read-from-tour-point-load",
  PRODUCT_ORDER_UPDATE = "role.permission-product-order-update",
  PRODUCT_ORDER_UPDATE_FROM_ORDER = "role.permission-product-order-update-from-order",
  PRODUCT_ORDER_UPDATE_FROM_TOUR_POINT_LOAD = "role.permission-product-order-update-from-tour-point-load",
  PRODUCT_ORDER_CREATE = "role.permission-product-order-create",
  PRODUCT_ORDER_DELETE = "role.permission-product-order-delete",
  PRODUCT_ORDER_DELETE_FROM_ORDER = "role.permission-product-order-delete-from-order",
  PRODUCT_ORDER_DELETE_FROM_TOUR_POINT_LOAD = "role.permission-product-order-delete-from-tour-point-load",

  PRODUCT_PRICE = "role.permission-product-price",
  PRODUCT_PRICE_READ = "role.permission-product-price-read",
  PRODUCT_PRICE_READ_FROM_PRODUCT = "role.permission-product-price-read-from-product",
  PRODUCT_PRICE_UPDATE = "role.permission-product-price-update",
  PRODUCT_PRICE_UPDATE_FROM_PRODUCT = "role.permission-product-price-update-from-product",
  PRODUCT_PRICE_CREATE = "role.permission-product-price-create",
  PRODUCT_PRICE_DELETE = "role.permission-product-price-delete",
  PRODUCT_PRICE_DELETE_FROM_PRODUCT = "role.permission-product-price-delete-from-product",

  TOUR = "role.permission-tour",
  TOUR_READ = "role.permission-tour-read",
  TOUR_READ_OWN = "role.permission-tour-read-own",
  TOUR_READ_FROM_TOUR_POINT = "role.permission-tour-read-from-tour-point",
  TOUR_UPDATE = "role.permission-tour-update",
  TOUR_UPDATE_OWN = "role.permission-tour-update-own",
  TOUR_CREATE = "role.permission-tour-create",
  TOUR_DELETE = "role.permission-tour-delete",
  TOUR_DELETE_OWN = "role.permission-tour-delete-own",

  TOUR_POINT = "role.permission-tour-point",
  TOUR_POINT_READ = "role.permission-tour-point-read",
  TOUR_POINT_READ_OWN = "role.permission-tour-point-read-own",
  TOUR_POINT_READ_FROM_TOUR = "role.permission-tour-point-read-from-tour",
  TOUR_POINT_READ_FROM_WAREHOUSE = "role.permission-tour-point-read-from-warehouse",
  TOUR_POINT_UPDATE = "role.permission-tour-point-update",
  TOUR_POINT_UPDATE_OWN = "role.permission-tour-point-update-own",
  TOUR_POINT_UPDATE_FROM_TOUR = "role.permission-tour-point-update-from-tour",
  TOUR_POINT_CREATE = "role.permission-tour-point-create",
  TOUR_POINT_DELETE = "role.permission-tour-point-delete",
  TOUR_POINT_DELETE_OWN = "role.permission-tour-point-delete-own",
  TOUR_POINT_DELETE_FROM_TOUR = "role.permission-tour-point-delete-from-tour",

  DELIVERY_RECEIPT = "role.permission-delivery-receipt",
  DELIVERY_RECEIPT_READ = "role.permission-delivery-receipt-read",
  DELIVERY_RECEIPT_READ_OWN = "role.permission-delivery-receipt-read-own",
  DELIVERY_RECEIPT_READ_FROM_TOUR_POINT = "role.permission-delivery-receipt-read-from-tour-point",
  DELIVERY_RECEIPT_UPDATE = "role.permission-delivery-receipt-update",
  DELIVERY_RECEIPT_UPDATE_OWN = "role.permission-delivery-receipt-update-own",
  DELIVERY_RECEIPT_UPDATE_FROM_TOUR_POINT = "role.permission-delivery-receipt-update-from-tour-point",
  DELIVERY_RECEIPT_CREATE = "role.permission-delivery-receipt-create",
  DELIVERY_RECEIPT_DELETE = "role.permission-delivery-receipt-delete",
  DELIVERY_RECEIPT_DELETE_OWN = "role.permission-delivery-receipt-delete-own",
  DELIVERY_RECEIPT_DELETE_FROM_TOUR_POINT = "role.permission-delivery-receipt-delete-from-tour-point",

  WAREHOUSE = "role.permission-warehouse",
  WAREHOUSE_READ = "role.permission-warehouse-read",
  WAREHOUSE_READ_FROM_TOUR_POINT = "role.permission-warehouse-read-from-tour-point",
  WAREHOUSE_READ_OWN = "role.permission-warehouse-read-own",
  WAREHOUSE_UPDATE = "role.permission-warehouse-update",
  WAREHOUSE_UPDATE_FROM_TOUR_POINT = "role.permission-warehouse-update-from-tour-point",
  WAREHOUSE_UPDATE_OWN = "role.permission-warehouse-update-own",
  WAREHOUSE_CREATE = "role.permission-warehouse-create",
  WAREHOUSE_DELETE = "role.permission-warehouse-delete",
  WAREHOUSE_DELETE_OWN = "role.permission-warehouse-delete-own",
  WAREHOUSE_DELETE_FROM_TOUR_POINT = "role.permission-warehouse-delete-from-tour-point",

  CONTACT_PERSON = "role.permission-contact-person",
  CONTACT_PERSON_READ = "role.permission-contact-person-read",
  CONTACT_PERSON_READ_FROM_CUSTOMER = "role.permission-contact-person-read-from-customer",
  CONTACT_PERSON_READ_FROM_LOCATION = "role.permission-contact-person-read-from-location",
  CONTACT_PERSON_UPDATE = "role.permission-contact-person-update",
  CONTACT_PERSON_UPDATE_FROM_CUSTOMER = "role.permission-contact-person-update-from-customer",
  CONTACT_PERSON_UPDATE_FROM_LOCATION = "role.permission-contact-person-update-from-location",
  CONTACT_PERSON_CREATE = "role.permission-contact-person-create",
  CONTACT_PERSON_DELETE = "role.permission-contact-person-delete",
  CONTACT_PERSON_DELETE_OWN = "role.permission-contact-person-delete-own",
  CONTACT_PERSON_DELETE_FROM_CUSTOMER = "role.permission-contact-person-delete-from-customer",
  CONTACT_PERSON_DELETE_FROM_LOCATION = "role.permission-contact-person-delete-from-location",

  REFUND = "role.permission-refund",
  REFUND_READ = "role.permission-refund-read",
  REFUND_READ_FROM_TOURPOINTLOAD = "role.permission-refund-read-from-tour-point-load",
  REFUND_UPDATE = "role.permission-refund-update",
  REFUND_UPDATE_FROM_TOURPOINTLOAD = "role.permission-refund-update-from-tour-point-load",
  REFUND_CREATE = "role.permission-refund-create",
  REFUND_DELETE = "role.permission-refund-delete",
  REFUND_DELETE_FROM_TOURPOINTLOAD = "role.permission-refund-delete-from-tour-point-load",
}

<div class="wrapper">
  <app-table-header
    [tableTitle]="'tenant.page-title' | translate"
    [buttonText]="'tenant.new-button-label' | translate"
    (filterEvent)="filterTable($event)"
    (createNewClickEvent)="isOpen = true"
  ></app-table-header>
  <app-table
    [tableColumnNames]="['tenant.table-name' | translate]"
    [columns]="tenantTableShownColumns"
    [tableData]="tenantTableData"
    [totalElements]="totalTenants"
    [currentPage]="tablePage"
    [pageSize]="tablePageSize"
    (paginationEvent)="paginationEvent($event)"
    (rowClickEvent)="tenantRowClickEvent($event)"
    (sortEvent)="sortTable($event)"
  ></app-table>
</div>

<!-- Slidethingy on the right -->
<app-new-tenant
  [isOpen]="isOpen"
  (isCreatedEvent)="createdTenantEvent()"
  (closeEvent)="isOpen = false"
></app-new-tenant>

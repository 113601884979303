import { BaseModel } from "./BaseModel";

export default class ContactPerson extends BaseModel<ContactPerson> {
  name: string;
  email: string;
  phone: string;
  description: string;
  customerId: string;
  locationId: string;

  constructor(params: Partial<ContactPerson>) {
    super(params);
    Object.assign(this, params);
  }
}

/**
 * Enum for months:
 * Contains all month abbreviations as strings in german.
 * Used in the dashboard
 */
export enum Months {
  JANUARY = "Jan.",
  FEBRUARY = "Feb.",
  MARCH = "März",
  APRIL = "Apr.",
  MAY = "Mai",
  JUNE = "Jun.",
  JULY = "Jul.",
  AUGUST = "Aug.",
  SEPTEMBER = "Sept.",
  OCTOBER = "Okt.",
  NOVEMBER = "Nov.",
  DECEMBER = "Dez.",
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { tuiIconEdit2Large, tuiIconTrash2Large } from "@taiga-ui/icons";
import { TuiButtonModule } from "@taiga-ui/core";

@Component({
  selector: "app-edit-delete-table-icons",
  standalone: true,
  imports: [TuiButtonModule],
  templateUrl: "./edit-delete-table-icons.component.html",
  styleUrl: "./edit-delete-table-icons.component.scss",
})
export class EditDeleteTableIconsComponent {
  @Output() editEvent = new EventEmitter();
  @Output() openDeleteDialogEvent = new EventEmitter();
  @Input() editDisabled: boolean = false;
  @Input() deleteDisabled: boolean = false;
  protected readonly tuiIconEdit2Large = tuiIconEdit2Large;
  protected readonly tuiIconTrash2Large = tuiIconTrash2Large;

  edit() {
    this.editEvent.emit();
  }

  openDeleteDialog() {
    this.openDeleteDialogEvent.emit();
  }
}

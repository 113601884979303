import { Component, inject, Input, OnInit } from "@angular/core";
import TourPointLoad from "../../../../models/TourPointLoad.class";
import ProductOrder from "../../../../models/ProductOrder.class";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Product from "../../../../models/Product.class";
import { TourPointLoadService } from "../../../../api/tourPointLoad.service";
import { UpdateRequestPayload } from "../../../../interfaces/request-array.interface";
import { MapOrdersService } from "../../map-helper-services/map-orders.service";
import { TpTplDeleteDialogComponent } from "../../tp-tpl-delete-dialog/tp-tpl-delete-dialog.component";
import { TuiDialogHelperService } from "../../../../services/tui-dialog-helper.service";
import { MapTpTplService } from "../../map-helper-services/map-tp-tpl.service";
import { TOUR_STATE } from "../../../../models/Tour.class";
import { PushService } from "../../../../services/push.service";
import { pushTypes } from "../../../../other/enums/push-types";
import { MapPermissionHelperService } from "../../../../services/permission-helper-services/map-permission-helper.service";

@Component({
  selector: "app-one-tour-point-load",
  templateUrl: "./one-tour-point-load.component.html",
  styleUrl: "./one-tour-point-load.component.scss",
})
export class OneTourPointLoadComponent implements OnInit {
  @Input({ required: true }) tourPointLoad: TourPointLoad;
  @Input() isSigned: boolean = true;
  productOrder: ProductOrder;
  product?: Product;
  form: FormGroup;
  maxAmount: number;
  mapPermissionHelper = inject(MapPermissionHelperService);

  arrowUp = "assets/svg/arrow-up.svg";
  arrowDown = "assets/svg/arrow-down.svg";

  constructor(
    private tplService: TourPointLoadService,
    private mapOrderService: MapOrdersService,
    private dialogService: TuiDialogHelperService,
    private fb: FormBuilder,
    private mapTPTPLService: MapTpTplService,
    private pushService: PushService,
  ) {}

  get isTourStateDraft() {
    return this.mapTPTPLService.tour.tourState === TOUR_STATE.DRAFT;
  }

  ngOnInit(): void {
    if (this.tourPointLoad.productOrderId) {
      this.getProductOrder();
    }

    this.product = this.tourPointLoad.product;
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      amount: [Math.abs(this.tourPointLoad.amount), Validators.required],
    });
  }

  getProductOrder() {
    this.productOrder = this.tourPointLoad.productOrder;
    this.calculateMaxAmount();
  }

  // calculate the max amount selectable in the current tpl
  calculateMaxAmount(): void {
    if (!this.productOrder?.amount) {
      this.maxAmount = 5000;
      return;
    }
    let maxAmount = Math.abs(this.productOrder.amount);

    const validTourPointLoads = this.productOrder.tourPointLoads.filter(
      (tpl) => !tpl.isDeleted && !tpl.vcIsLogEntity,
    );

    validTourPointLoads.forEach((tpl) => {
      const tplAmount = Math.abs(tpl.amount);
      maxAmount -= tplAmount;

      // directly get refunds from each tourPointLoad
      const refunds = tpl.refunds ?? []; // assuming refunds is an array in each tourPointLoad
      const refundAmount = refunds.reduce(
        (sum, refund) => sum + Math.abs(refund.amount),
        0,
      );
      maxAmount += refundAmount;
    });

    this.maxAmount = maxAmount + Math.abs(this.tourPointLoad.amount); // add tourPointLoad amount
  }

  updateTpl() {
    let tplAmount = this.form.get("amount")?.value;

    if (!tplAmount) {
      this.pushService.sendPush(
        pushTypes.ERROR,
        "",
        "Tour Stop Beladung kann nicht 0 oder nichts sein!",
      );
      return;
    }

    if (this.productOrder.amount < 0) {
      tplAmount = -tplAmount;
    }

    const tpl = new TourPointLoad({
      amount: tplAmount,
    });

    const payload: UpdateRequestPayload<TourPointLoad> = {
      data: [{ id: this.tourPointLoad.id, data: tpl }],
    };

    if (this.tourPointLoad.amount !== tpl.amount) {
      this.tplService.updateTourPointLoadById(payload).subscribe((tpl) => {
        this.tourPointLoad = new TourPointLoad(tpl[0]);
        this.mapOrderService.getOrdersWithCallback();
      });
    }
  }

  deleteTourPointLoad() {
    if (
      this.isSigned ||
      !this.isTourStateDraft ||
      !this.mapPermissionHelper.hasDeleteTourPointPermissions
    )
      return;

    this.dialogService.openDialog(
      TpTplDeleteDialogComponent,
      new TourPointLoad(this.tourPointLoad),
      () => {},
    );
  }
}

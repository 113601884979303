<!-- TODO: read permissions  -->

<!--all drivers card-->
<tui-island class="rightCard01">
  <!-- title + subtitle -->
  <div class="title_subtitle">
    <div class="date-container">
      <app-base-badge [value]="date" status="neutral"></app-base-badge>
    </div>

    <div class="title-row">
      <h3 class="tui-island__title">
        {{ unplannedDrivers() }} {{ "driver.title" | translate }}
      </h3>
    </div>

    <span class="subtitle"
      >{{
        (unplannedDrivers() == 1 ? "driver.waiting-singular" : "driver.waiting")
          | translate
      }}
    </span>
  </div>
  <!-- truck svg -->
  <app-truck-svg class="truckSvg"></app-truck-svg>
</tui-island>

<!--all orders card-->
<tui-island class="rightCard02">
  <!-- title + subtitle -->
  <div class="title_subtitle">
    <div class="title-row">
      <h3 class="tui-island__title txt">
        {{ openOrders() }}
        {{
          (openOrders() == 1 ? "order.title-singular" : "order.title")
            | translate
        }}
      </h3>
      <app-base-badge [value]="date" status="neutral"></app-base-badge>
    </div>
    <span class="subtitle">{{
      (openOrders() == 1 ? "order.is-open" : "order.are-open") | translate
    }}</span>

    <!--   new planing button    -->
    <button
      [disabled]="
        !permissionService.readTourPermission().hasPermission ||
        !permissionService.createTourPermission().hasPermission
      "
      [routerLink]="'/tours'"
      appearance="primary"
      class="button"
      size="m"
      tuiButton
    >
      <span style="color: white !important">
        {{ "allPlannings.newPlanning" | translate }}
      </span>
    </button>
  </div>

  <!--   palette svg    -->
  <app-palette-svg class="paletteSvg" />
</tui-island>

<tui-island class="rightCard03">
  <div class="title-row">
    <h3 class="tui-island__title">{{ "order.title" | translate }}</h3>
    <app-base-badge [value]="date" status="neutral"></app-base-badge>
  </div>

  <!-- DONUT DIAGRAM -->
  <div class="content">
    <app-order-today-donut-diagram />
  </div>
</tui-island>

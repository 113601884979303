import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import localeDe from "@angular/common/locales/de";
import {
  TUI_SANITIZER,
  TuiAlertModule,
  TuiButtonModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiDropdownModule,
  TuiErrorModule,
  TuiExpandModule,
  TuiFormatDatePipeModule,
  TuiGroupModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiModeModule,
  TuiRootModule,
  TuiScrollbarModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiTooltipModule,
} from "@taiga-ui/core";
import { TuiLanguageName } from "@taiga-ui/i18n/interfaces";
import { tuiLanguageSwitcher } from "@taiga-ui/i18n/switch";
import {
  TuiAxesModule,
  TuiBarChartModule,
  TuiBarModule,
  TuiBarSetModule,
  TuiLegendItemModule,
  TuiLineChartModule,
  TuiLineDaysChartModule,
  TuiRingChartModule,
} from "@taiga-ui/addon-charts";
import {
  TuiAccordionModule,
  TuiAvatarModule,
  TuiBadgeModule,
  TuiBreadcrumbsModule,
  TuiCalendarRangeModule,
  TuiCarouselModule,
  TuiCheckboxLabeledModule,
  TuiCheckboxModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiElasticContainerModule,
  TuiFieldErrorPipeModule,
  TuiFilterByInputPipeModule,
  TuiInputCountModule,
  TuiInputDateModule,
  TuiInputDateRangeModule,
  TuiInputDateTimeModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPasswordModule,
  TuiInputPhoneModule,
  TuiInputTimeModule,
  TuiIslandModule,
  TuiMarkerIconModule,
  TuiMultiSelectModule,
  TuiPaginationModule,
  TuiProgressModule,
  TuiPushModule,
  TuiRadioBlockModule,
  TuiRadioLabeledModule,
  TuiRadioListModule,
  TuiRadioModule,
  TuiSelectModule,
  TuiStepperModule,
  TuiStringifyContentPipeModule,
  TuiStringifyPipeModule,
  TuiTabsModule,
  TuiTextareaModule,
  TuiToggleModule,
} from "@taiga-ui/kit";
import {
  TuiTableModule,
  TuiTablePaginationModule,
} from "@taiga-ui/addon-table";
import {
  TuiActiveZoneModule,
  TuiAutoFocusModule,
  TuiLetModule,
  TuiObscuredModule,
  TuiPortalModule,
  TuiValueChangesModule,
} from "@taiga-ui/cdk";
import { TuiMobileTabsModule, TuiSidebarModule } from "@taiga-ui/addon-mobile";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TenantPageComponent } from "./views/tenant-page/tenant-page.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TableComponent } from "./common/table/table.component";
import { SidenavComponent } from "./common/sidenav/sidenav.component";
import { TopbarComponent } from "./common/topbar/topbar.component";
import { ButtonComponent } from "./common/sidenav/button/button.component";
import { SettingsPageComponent } from "./views/settings-page/settings-page.component";
import { InfoBitComponent } from "./common/info-bit/info-bit.component";
import { MapPageComponent } from "./views/map-page/map-page.component";
import { UserPageComponent } from "./views/user-page/user-page.component";
import { CustomerPageComponent } from "./views/customer-page/customer-page.component";
import { LogInPageComponent } from "./views/log-in-page/log-in-page.component";
import { DashboardPageComponent } from "./views/dashboard-page/dashboard-page.component";
import { TableHeaderComponent } from "./common/table-header/table-header.component";
import { BaseLayoutComponent } from "./layouts/base-layout/base-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { MenubarComponent } from "./common/menubar/menubar.component";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { TokenInterceptor } from "./other/interceptors/token.interceptor";
import { EventItemComponent } from "./common/event-item/event-item.component";
import { InputPasswordComponent } from "./common/input-password/input-password.component";
import { InputTextComponent } from "./common/input-text/input-text.component";
import { ForgotPasswordComponent } from "./views/log-in-page/forgot-password/forgot-password.component";
import { ResetPasswordPageComponent } from "./views/reset-password-page/reset-password-page.component";
import { SetNewPasswordComponent } from "./common/set-new-password/set-new-password.component";
import { InputPhoneComponent } from "./common/input-phone/input-phone.component";
import { InputEmailComponent } from "./common/input-email/input-email.component";
import { RadioListComponent } from "./common/radio-list/radio-list.component";
import { DropdownComponent } from "./common/dropdown/dropdown.component";
import { ErrorPageComponent } from "./views/error-page/error-page.component";
import { InputLargeTextComponent } from "./common/input-large-text/input-large-text.component";
import { PermissionRowComponent } from "./common/permission-row/permission-row.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NewTenantComponent } from "./views/tenant-page/new-tenant/new-tenant.component";
import { RoleFormComponent } from "./common/role-form/role-form.component";
import { LoadingInterceptor } from "./other/interceptors/loading.interceptor";
import { TenantSelectorDropdownComponent } from "./common/tenant-selector-dropdown/tenant-selector-dropdown.component";
import { EditButtonBarComponent } from "./common/edit-button-bar/edit-button-bar.component";
import { InputDateComponent } from "./common/input-date/input-date.component";
import { DeleteDialogComponent } from "./common/delete-dialog/delete-dialog.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { BreadcrumbsComponent } from "./common/breadcrumbs/breadcrumbs.component";
import { OrdersPageComponent } from "./views/orders-page/orders-page.component";
import { LogoSvgComponent } from "./svg-components/logo-svg/logo-svg.component";
import { TruckSvgComponent } from "./svg-components/truck-svg/truck-svg.component";
import { PaletteSvgComponent } from "./svg-components/palette-svg/palette-svg.component";
import { TransparentBtnComponent } from "./common/transparent-btn/transparent-btn.component";
import { AddEditCustomerComponent } from "./views/customer-page/add-edit-customer/add-edit-customer.component";
import { ProductsComponent } from "./views/settings-page/products/products.component";
import { AddEditProductComponent } from "./views/settings-page/products/add-edit-product/add-edit-product.component";
import { MultiSelectButtonsComponent } from "./common/multi-select-buttons/multi-select-buttons.component";
import { TableLayoutComponent } from "./layouts/table-layout/table-layout.component";
import { SaveCancelBtnsComponent } from "./common/save-cancel-btns/save-cancel-btns.component";
import { AddEditAddressComponent } from "./common/add-edit-address/add-edit-address.component";
import { TuiMoneyModule } from "@taiga-ui/addon-commerce";
import { AddEditOrderComponent } from "./views/orders-page/add-edit-order/add-edit-order.component";
import { TabsComponent } from "./common/tabs/tabs.component";
import { InputNumberComponent } from "./common/input-number/input-number.component";
import { InputPaymentAmountComponent } from "./common/input-payment-amount/input-payment-amount.component";
import { WarehousePageComponent } from "./views/settings-page/warehouse-page/warehouse-page.component";
import { AddEditWarehouseComponent } from "./views/settings-page/warehouse-page/add-edit-warehouse/add-edit-warehouse.component";
import { DialogWrapperComponent } from "./layouts/dialog-wrapper/dialog-wrapper.component";
import { AddEditUserComponent } from "./views/user-page/add-edit-user/add-edit-user.component";
import { TwoPeopleSvgComponent } from "./svg-components/two-people-svg/two-people-svg.component";
import { HalfTransparentCardComponent } from "./common/half-transparent-card/half-transparent-card.component";
import { CustomerDetailsComponent } from "./views/customer-page/customer-details/customer-details.component";
import { LocationSvgComponent } from "./svg-components/location-svg/location-svg.component";
import { OrderDetailsComponent } from "./views/orders-page/order-details/order-details.component";
import { ProductOrderCardComponent } from "./views/orders-page/order-details/product-order-card/product-order-card.component";
import { NgOptimizedImage, registerLocaleData } from "@angular/common";
import { AddEditLocationsComponent } from "./views/customer-page/add-edit-locations/add-edit-locations.component";
import { AddEditTourComponent } from "./views/tour-page/add-edit-tour/add-edit-tour.component";
import { TourPageComponent } from "./views/tour-page/tour-page.component";
import { RolesComponent } from "./views/settings-page/roles/roles.component";
import { AddEditRoleComponent } from "./views/settings-page/roles/add-edit-role/add-edit-role.component";
import { DeliveryReceiptPageComponent } from "./views/delivery-receipt-page/delivery-receipt-page.component";
import {
  CdkDrag,
  CdkDragHandle,
  CdkDropList,
  CdkDropListGroup,
} from "@angular/cdk/drag-drop";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { UnannouncedDeliveryPageComponent } from "./views/unannounced-delivery-page/unannounced-delivery-page.component";
import { ProductCardComponent } from "./views/settings-page/products/product-card/product-card.component";
import { WysiwygEditorComponent } from "./common/wysiwyg-editor/wysiwyg-editor.component";

import { AddEditUnannouncedDeliveryComponent } from "./views/unannounced-delivery-page/add-edit-unannounced-delivery/add-edit-unannounced-delivery.component";
import { InputFilesComponent } from "./common/input-files/input-files.component";
import { TourPointLoadHeaderComponent } from "./views/map-page/tour-point-loads/one-tour-point-load/tour-point-load-header/tour-point-load-header.component";
import { OneTourPointLoadComponent } from "./views/map-page/tour-point-loads/one-tour-point-load/one-tour-point-load.component";
import { TourPointLoadLoopComponent } from "./views/map-page/tour-point-loads/tour-point-load-loop/tour-point-load-loop.component";
import { OrderFilterComponent } from "./views/map-page/orders/order-filter/order-filter.component";
import { OrderGridComponent } from "./views/map-page/orders/order-grid/order-grid.component";
import { TourPointLoadAccordionComponent } from "./views/map-page/tour-point-loads/tour-point-load-loop/tour-point-load-accordion/tour-point-load-accordion.component";
import { OneTourPointComponent } from "./views/map-page/tour-points/one-tour-point/one-tour-point.component";
import { DrivingDurationDistanceComponent } from "./views/map-page/tour-points/driving-duration-distance/driving-duration-distance.component";
import { CreateTplForWarehouseComponent } from "./views/map-page/tour-point-loads/create-tpl-for-warehouse/create-tpl-for-warehouse.component";
import { OneOrderComponent } from "./views/map-page/orders/one-order/one-order.component";
import { FileGalleryComponent } from "./common/file-gallery/file-gallery.component";
import { AddEditCustomerPriceComponent } from "./views/customer-page/add-edit-customer-price/add-edit-customer-price.component";
import { PriceOverviewComponent } from "./views/customer-page/customer-details/price-overview/price-overview.component";
import { ReceiptCarouselComponent } from "./views/delivery-receipt-page/delivery-receipt-details-dialog/receipt-carousel/receipt-carousel.component";
import { DeliveryReceiptDetailsDialogComponent } from "./views/delivery-receipt-page/delivery-receipt-details-dialog/delivery-receipt-details-dialog.component";
import { TableSearchInputComponent } from "./common/table-search-input/table-search-input.component";
import { OrderTableComponent } from "./views/orders-page/order-table/order-table.component";
import { NoReadPermissionComponent } from "./common/no-read-permission/no-read-permission.component";
import { ForbiddenInterceptor } from "./other/interceptors/forbidden.interceptor";
import { LoadingSpinnerComponent } from "./common/loading-spinner/loading-spinner.component";
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from "@angular/cdk/scrolling";
import { IndexChangeDirective } from "./other/directives/index-change.directive";
import { RefundsPageComponent } from "./views/refunds-table-page/refunds-page.component";
import { DashboardWidgetsComponent } from "./views/dashboard-page/dashboard-widgets/dashboard-widgets.component";
import { DashboardChartComponent } from "./views/dashboard-page/dashboard-chart/dashboard-chart.component";
import { DashboardWidgetsOutsideComponent } from "./views/dashboard-page/dashboard-widgets-outside/dashboard-widgets-outside.component";
import { LocationCardLoopComponent } from "./views/customer-page/customer-details/location-card-loop/location-card-loop.component";
import { TourPointDetailsSidebarComponent } from "./views/orders-page/tour-point-details-sidebar/tour-point-details-sidebar.component";
import { WarehouseworkerComponent } from "./views/user-page/warehouseworker/warehouseworker.component";
import { ArrowComponent } from "./svg-components/arrow-icon/arrow.component";
import { EditDeleteTableIconsComponent } from "./common/edit-delete-table-icons/edit-delete-table-icons.component";
import { BaseBadgeComponent } from "./common/base-badge/base-badge.component";
import { MissingWarehousePermissionDialogComponent } from "./views/settings-page/warehouse-page/missing-warehouse-permission-dialog/missing-warehouse-permission-dialog.component";
import { MissingUserPermissionsDialogComponent } from "./views/user-page/missing-user-permissions-dialog/missing-user-permissions-dialog.component";
import { TooltipComponent } from "./common/tooltip/tooltip.component";
import { OrderTodayDonutDiagramComponent } from "./views/dashboard-page/dashboard-widgets-outside/order-today-donut-diagram/order-today-donut-diagram.component";

// needed for ngx-translate (i18n)
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    TenantPageComponent,
    TableHeaderComponent,
    TableComponent,
    SidenavComponent,
    TopbarComponent,
    ButtonComponent,
    SettingsPageComponent,
    InfoBitComponent,
    MapPageComponent,
    UserPageComponent,
    CustomerPageComponent,
    LogInPageComponent,
    InfoBitComponent,
    DashboardPageComponent,
    BaseLayoutComponent,
    AuthLayoutComponent,
    MenubarComponent,
    EventItemComponent,
    InputPasswordComponent,
    InputTextComponent,
    InputPaymentAmountComponent,
    ForgotPasswordComponent,
    ResetPasswordPageComponent,
    SetNewPasswordComponent,
    InputPhoneComponent,
    InputEmailComponent,
    RadioListComponent,
    RefundsPageComponent,
    DropdownComponent,
    ErrorPageComponent,
    InputLargeTextComponent,
    PermissionRowComponent,
    NewTenantComponent,
    RoleFormComponent,
    TenantSelectorDropdownComponent,
    EditButtonBarComponent,
    InputDateComponent,
    DeleteDialogComponent,
    BreadcrumbsComponent,
    LogoSvgComponent,
    TruckSvgComponent,
    PaletteSvgComponent,
    TransparentBtnComponent,
    AddEditCustomerComponent,
    ProductsComponent,
    AddEditProductComponent,
    MultiSelectButtonsComponent,
    TableLayoutComponent,
    SaveCancelBtnsComponent,
    OrdersPageComponent,
    AddEditAddressComponent,
    AddEditOrderComponent,
    TabsComponent,
    InputNumberComponent,
    WarehousePageComponent,
    AddEditWarehouseComponent,
    DeliveryReceiptDetailsDialogComponent,
    DialogWrapperComponent,
    AddEditUserComponent,
    TwoPeopleSvgComponent,
    HalfTransparentCardComponent,
    CustomerDetailsComponent,
    LocationSvgComponent,
    OrderDetailsComponent,
    ProductOrderCardComponent,
    AddEditLocationsComponent,
    AddEditTourComponent,
    TourPageComponent,
    RolesComponent,
    AddEditRoleComponent,
    DeliveryReceiptPageComponent,
    ProductCardComponent,
    UnannouncedDeliveryPageComponent,
    AddEditUnannouncedDeliveryComponent,
    WysiwygEditorComponent,
    InputFilesComponent,
    TourPointLoadHeaderComponent,
    OneTourPointLoadComponent,
    TourPointLoadLoopComponent,
    OrderFilterComponent,
    OrderGridComponent,
    TourPointLoadAccordionComponent,
    OneTourPointComponent,
    DrivingDurationDistanceComponent,
    CreateTplForWarehouseComponent,
    AddEditCustomerPriceComponent,
    PriceOverviewComponent,
    TableSearchInputComponent,
    OrderTableComponent,
    NoReadPermissionComponent,
    IndexChangeDirective,
    DashboardWidgetsComponent,
    DashboardChartComponent,
    DashboardWidgetsOutsideComponent,
    TourPointDetailsSidebarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiExpandModule,
    TuiGroupModule,
    TuiRadioBlockModule,
    TuiTabsModule,
    FormsModule,
    ReactiveFormsModule,
    TuiTableModule,
    TuiTablePaginationModule,
    TuiSvgModule,
    TuiTooltipModule,
    TuiHintModule,
    TuiInputModule,
    TuiHostedDropdownModule,
    ReactiveFormsModule,
    TuiDataListModule,
    TuiDropdownModule,
    TuiGroupModule,
    TuiObscuredModule,
    TuiActiveZoneModule,
    TuiTabsModule,
    TuiAccordionModule,
    TuiSidebarModule,
    TuiIslandModule,
    TuiLinkModule,
    TuiInputPasswordModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiTabsModule,
    TuiIslandModule,
    TuiAxesModule,
    TuiLineDaysChartModule,
    TuiInputDateRangeModule,
    TuiLineChartModule,
    TuiStepperModule,
    TuiModeModule,
    TuiPortalModule,
    TuiCalendarRangeModule,
    TuiInputDateModule,
    TuiInputTimeModule,
    TuiAutoFocusModule,
    TuiSelectModule,
    TuiLineChartModule,
    TuiBadgeModule,
    TuiElasticContainerModule,
    HttpClientModule,
    TuiFormatDatePipeModule,
    TuiLoaderModule,
    TuiInputPhoneModule,
    TuiPushModule,
    TuiComboBoxModule,
    TuiDataListWrapperModule,
    TuiRadioListModule,
    TuiRadioLabeledModule,
    TuiFilterByInputPipeModule,
    TuiStringifyContentPipeModule,
    TuiCheckboxLabeledModule,
    TuiLetModule,
    TuiTextareaModule,
    HttpClientModule,
    GoogleMapsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TuiToggleModule,
    TuiRadioModule,
    TuiBreadcrumbsModule,
    TuiRingChartModule,
    TuiMoneyModule,
    TuiRootModule,
    TuiDialogModule,
    TuiInputFilesModule,
    TuiMarkerIconModule,
    TuiInputNumberModule,
    TuiMobileTabsModule,
    TuiInputCountModule,
    TuiInputNumberModule,
    TuiValueChangesModule,
    TuiCheckboxModule,
    NgOptimizedImage,
    TuiInputDateTimeModule,
    TuiScrollbarModule,
    TuiProgressModule,
    TuiBarChartModule,
    TuiCarouselModule,
    TuiPaginationModule,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CKEditorModule,
    OneOrderComponent,
    FileGalleryComponent,
    TuiMultiSelectModule,
    TuiAvatarModule,
    ReceiptCarouselComponent,
    TuiStringifyPipeModule,
    LoadingSpinnerComponent,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    LocationCardLoopComponent,
    TuiBarSetModule,
    TuiBarModule,
    TuiLegendItemModule,
    WarehouseworkerComponent,
    ArrowComponent,
    EditDeleteTableIconsComponent,
    BaseBadgeComponent,
    MissingWarehousePermissionDialogComponent,
    MissingUserPermissionsDialogComponent,
    TooltipComponent,
    OrderTodayDonutDiagramComponent,
  ],

  providers: [
    { provide: LOCALE_ID, useValue: "de-DE" },
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    tuiLanguageSwitcher(async (language: TuiLanguageName): Promise<unknown> => {
      switch (language) {
        case `german`:
          return import(`@taiga-ui/i18n/languages/german`);
        case `english`:
          return import(`@taiga-ui/i18n/languages/english`);
        default:
          return import(`@taiga-ui/i18n/languages/german`);
      }
    }),
    // INTERCEPTORS:
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [
    PaletteSvgComponent,
    InputNumberComponent,
    TourPointLoadHeaderComponent,
    DropdownComponent,
    OrderFilterComponent,
    TableComponent,
    TableLayoutComponent,
  ],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, "de-DE");
  }
}

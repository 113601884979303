import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TuiButtonModule, TuiSvgModule } from "@taiga-ui/core";
import { tuiIconX } from "@taiga-ui/icons";
import { CustomerFile } from "../../interfaces/customer-file";

@Component({
  selector: "app-file-gallery",
  standalone: true,
  imports: [TuiSvgModule, TuiButtonModule],
  templateUrl: "./file-gallery.component.html",
  styleUrl: "./file-gallery.component.scss",
})
export class FileGalleryComponent implements OnInit {
  imageMap = new Map<number, string>();
  pendingDeleteIds: string[] = [];

  @Input() title: string = "common.button-save";
  @Input() files: CustomerFile[] = [];
  @Input() isEditable: boolean = true;

  @Output() onClick = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  protected readonly tuiIconX = tuiIconX;

  ngOnInit(): void {
    // reset delete ids on init
    this.pendingDeleteIds = [];
  }

  openFile(blob: Blob) {
    // Create an object URL for the blob
    const fileURL = URL.createObjectURL(blob);
    // Opens the object URL in a new tab
    window.open(fileURL);
  }

  createThumbnail(blob: Blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      return reader.result;
    };
  }

  deleteFile(id: string) {
    this.pendingDeleteIds.push(id);
    this.files = this.files.filter((file: CustomerFile) => file.id !== id);
    this.imageMap.delete(Number(id));

    this.onDelete.emit(this.pendingDeleteIds);
  }
}

<div style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um Produkte löschen zu können:
  </h3>

  <ul>
    <li *ngIf="!permissionService.deleteProductPermission().hasPermission">
      <b>Produkt löschen</b>
    </li>

    <li *ngIf="!permissionService.deleteProductPricePermission().hasPermission">
      <b>Produktpreis löschen</b>
    </li>
  </ul>
</div>

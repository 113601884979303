<div class="input-wrapper">
  <tui-input
    [ngStyle]="{ 'border-radius': bRadius }"
    [tuiTextfieldIcon]="tuiIconMail"
    class="input"
    formControlName="{{ fieldName }}"
  >
    <span [ngStyle]="{ color: hintColor }" class="hint">
      {{ hint | translate }}
    </span>

    <span [class.tui-required]="required"></span>
    <input
      [ngStyle]="{ color: hintColor }"
      autocomplete="off!"
      tuiTextfield
      type="email"
    />
  </tui-input>
  <ng-container class="error-wrapper">
    <tui-error
      [error]="[] | tuiFieldError | async"
      formControlName="{{ fieldName }}"
    ></tui-error>
  </ng-container>
</div>

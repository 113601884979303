<div style="color: var(--tui-warn-txt)">
  <h3 style="margin-top: 0">
    Es fehlen folgende Berechtigungen, um Rollen
    {{ isAddmode ? "erstellen" : "aktualisieren" }} zu können:
  </h3>

  <ul>
    <li *ngIf="!rolePermissions.hasCreateRolePermission && isAddmode">
      <b>Rolle erstellen</b>
    </li>

    <li *ngIf="!rolePermissions.hasUpdateRolePermission && !isAddmode">
      <b>Rolle aktualisieren</b>
    </li>

    <li *ngIf="!rolePermissions.hasReadRolePermission">
      <b>Rolle ansehen</b>
    </li>
  </ul>
</div>

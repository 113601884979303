import { Injectable } from "@angular/core";
import { Permissions } from "../other/enums/permissions";
import { lastValueFrom } from "rxjs";
import { PermissionTranslations } from "../other/enums/permission-translations";
import { AuthService } from "../api/auth.service";
import { TranslateService } from "@ngx-translate/core";

export type UserPermission = {
  hasPermission: boolean;
  missingPermissions: Permissions[];
};

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  // DASHBOARD PERMISSIONS ---------------------
  readonly readDashboardPermission = () =>
    this.checkUserPermissions([Permissions.KPI_DASHBOARD]);

  // REFUND PERMISSIONS ---------------------
  readonly readRefundPermission = () =>
    this.checkUserPermissions(
      [Permissions.REFUND_READ, Permissions.REFUND_READ_FROM_TOURPOINTLOAD],
      true,
    );

  // TOUR PERMISSIONS ---------------------
  readonly readTourPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.TOUR_READ,
        Permissions.TOUR_READ_OWN,
        Permissions.TOUR_READ,
        Permissions.TOUR_READ_FROM_TOUR_POINT,
      ],
      true,
    );

  readonly deleteTourPermission = () =>
    this.checkUserPermissions(
      [Permissions.TOUR_DELETE, Permissions.TOUR_DELETE_OWN],
      true,
    );

  readonly updateTourPermission = () =>
    this.checkUserPermissions(
      [Permissions.TOUR_UPDATE, Permissions.TOUR_UPDATE_OWN],
      true,
    );

  readonly createTourPermission = () =>
    this.checkUserPermissions([Permissions.TOUR_CREATE]);

  // UNANNOUNCED DELIVERY PERMISSIONS ---------------------
  readonly createUnannouncedDeliveryPermission = () =>
    this.checkUserPermissions([
      Permissions.TOUR_POINT_CREATE,
      Permissions.DELIVERY_RECEIPT_CREATE,
    ]);

  // DELIVERY RECEIPT PERMISSIONS ---------------------
  readonly readDeliveryReceiptPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.DELIVERY_RECEIPT_READ,
        Permissions.DELIVERY_RECEIPT_READ_OWN,
        Permissions.DELIVERY_RECEIPT_READ_FROM_TOUR_POINT,
      ],
      true,
    );

  // CUSTOMER PERMISSIONS ---------------------
  readonly readCustomerPermission = () =>
    this.checkUserPermissions(
      [Permissions.CUSTOMER_READ, Permissions.CUSTOMER_READ_FROM_LOCATION],
      true,
    );

  readonly deleteCustomerPermission = () =>
    this.checkUserPermissions(
      [Permissions.CUSTOMER_DELETE, Permissions.CUSTOMER_DELETE_FROM_LOCATION],
      true,
    );

  readonly createCustomerPermission = () =>
    this.checkUserPermissions([Permissions.CUSTOMER_CREATE]);

  readonly updateCustomerPermission = () =>
    this.checkUserPermissions(
      [Permissions.CUSTOMER_UPDATE, Permissions.CUSTOMER_UPDATE_FROM_LOCATION],
      true,
    );

  // LOCATION PERMISSIONS ---------------------
  readonly readLocationPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.LOCATION_READ,
        Permissions.LOCATION_READ_FROM_CUSTOMER,
        Permissions.LOCATION_READ_FROM_TOURPOINT,
      ],
      true,
    );

  readonly createLocationPermission = () =>
    this.checkUserPermissions([Permissions.LOCATION_CREATE]);

  readonly updateLocationPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.LOCATION_UPDATE,
        Permissions.LOCATION_UPDATE_FROM_CUSTOMER,
        Permissions.LOCATION_UPDATE_FROM_TOURPOINT,
      ],
      true,
    );

  readonly deleteLocationPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.LOCATION_DELETE,
        Permissions.LOCATION_DELETE_FROM_CUSTOMER,
        Permissions.LOCATION_DELETE_FROM_TOURPOINT,
      ],
      true,
    );

  // PRODUCT PRICE PERMISSIONS ---------------------
  readonly readProductPricePermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_PRICE_READ,
        Permissions.PRODUCT_PRICE_READ_FROM_PRODUCT,
      ],
      true,
    );

  readonly createProductPricePermission = () =>
    this.checkUserPermissions([Permissions.PRODUCT_PRICE_CREATE]);

  readonly updateProductPricePermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_PRICE_UPDATE,
        Permissions.PRODUCT_PRICE_UPDATE_FROM_PRODUCT,
      ],
      true,
    );

  readonly deleteProductPricePermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_PRICE_DELETE,
        Permissions.PRODUCT_PRICE_DELETE_FROM_PRODUCT,
      ],
      true,
    );

  // ORDER PERMISSIONS ---------------------
  readonly readOrderPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.ORDER_READ,
        Permissions.ORDER_READ_FROM_LOCATION,
        Permissions.ORDER_READ_FROM_CUSTOMER,
      ],
      true,
    );

  readonly deleteOrderPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.ORDER_DELETE,
        Permissions.ORDER_DELETE_FROM_CUSTOMER,
        Permissions.ORDER_DELETE_FROM_LOCATION,
      ],
      true,
    );

  readonly updateOrderPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.ORDER_UPDATE,
        Permissions.ORDER_UPDATE_FROM_CUSTOMER,
        Permissions.ORDER_UPDATE_FROM_LOCATION,
      ],
      true,
    );

  readonly createOrderPermission = () =>
    this.checkUserPermissions([Permissions.ORDER_CREATE]);

  // TOUR_POINT PERMISSIONS ---------------------
  readonly readTourPointPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.TOUR_POINT_READ,
        Permissions.TOUR_POINT_READ_OWN,
        Permissions.TOUR_POINT_READ_FROM_TOUR,
        Permissions.TOUR_POINT_READ_FROM_WAREHOUSE,
      ],
      true,
    );

  readonly updateTourPointPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.TOUR_POINT_UPDATE,
        Permissions.TOUR_POINT_UPDATE_OWN,
        Permissions.TOUR_POINT_UPDATE_FROM_TOUR,
      ],
      true,
    );

  readonly deleteTourPointPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.TOUR_POINT_DELETE,
        Permissions.TOUR_POINT_DELETE_OWN,
        Permissions.TOUR_POINT_DELETE_FROM_TOUR,
      ],
      true,
    );

  // USER PERMISSIONS ---------------------
  readonly readUserPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.USER_READ,
        Permissions.USER_READ_OWN,
        Permissions.USER_READ_FROM_TOUR,
      ],
      true,
    );

  readonly deleteUserPermission = () =>
    this.checkUserPermissions(
      [Permissions.USER_DELETE, Permissions.USER_DELETE_OWN],
      true,
    );

  readonly updateUserPermission = () =>
    this.checkUserPermissions(
      [Permissions.USER_UPDATE, Permissions.USER_UPDATE_OWN],
      true,
    );

  readonly createUserPermission = () =>
    this.checkUserPermissions([Permissions.USER_CREATE]);

  // PRODUCT PERMISSIONS ---------------------
  readonly readProductPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_READ,
        Permissions.PRODUCT_READ_FROM_PRODUCT_ORDER,
        Permissions.PRODUCT_READ_FROM_TOUR_POINT_LOAD,
      ],
      true,
    );

  readonly createProductPermission = () =>
    this.checkUserPermissions([Permissions.PRODUCT_CREATE]);

  readonly updateProductPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_UPDATE,
        Permissions.PRODUCT_UPDATE_FROM_PRODUCT_ORDER,
        Permissions.PRODUCT_UPDATE_FROM_TOUR_POINT_LOAD,
      ],
      true,
    );

  readonly deleteProductPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_DELETE,
        Permissions.PRODUCT_DELETE_FROM_PRODUCT_ORDER,
        Permissions.PRODUCT_DELETE_FROM_TOUR_POINT_LOAD,
      ],
      true,
    );

  // ROLE PERMISSIONS ---------------------
  readonly readRolePermission = () =>
    this.checkUserPermissions(
      [Permissions.ROLE_READ, Permissions.ROLE_READ_OWN],
      true,
    );

  readonly createRolePermission = () =>
    this.checkUserPermissions([Permissions.ROLE_CREATE]);

  readonly updateRolePermission = () =>
    this.checkUserPermissions([Permissions.ROLE_UPDATE]);

  readonly deleteRolePermission = () =>
    this.checkUserPermissions([Permissions.ROLE_DELETE]);

  // WAREHOUSE PERMISSIONS ---------------------
  readonly readWarehousePermission = () =>
    this.checkUserPermissions(
      [
        Permissions.WAREHOUSE_READ,
        Permissions.WAREHOUSE_READ_OWN,
        Permissions.WAREHOUSE_READ_FROM_TOUR_POINT,
      ],
      true,
    );

  readonly createWarehousePermission = () =>
    this.checkUserPermissions([Permissions.WAREHOUSE_CREATE]);

  readonly updateWarehousePermission = () =>
    this.checkUserPermissions(
      [
        Permissions.WAREHOUSE_UPDATE,
        Permissions.WAREHOUSE_UPDATE_OWN,
        Permissions.WAREHOUSE_UPDATE_FROM_TOUR_POINT,
      ],
      true,
    );

  // CONTACT PERSON PERMISSIONS ---------------------

  readonly deleteWarehousePermission = () =>
    this.checkUserPermissions(
      [
        Permissions.WAREHOUSE_DELETE,
        Permissions.WAREHOUSE_DELETE_OWN,
        Permissions.WAREHOUSE_DELETE_FROM_TOUR_POINT,
      ],
      true,
    );

  // CONTACT PERSON PERMISSIONS ---------------------
  readonly readContactPersonsPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.CONTACT_PERSON_READ,
        Permissions.CONTACT_PERSON_READ_FROM_CUSTOMER,
        Permissions.CONTACT_PERSON_READ_FROM_LOCATION,
      ],
      true,
    );

  readonly updateContactPersonsPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.CONTACT_PERSON_UPDATE,
        Permissions.CONTACT_PERSON_UPDATE_FROM_CUSTOMER,
        Permissions.CONTACT_PERSON_UPDATE_FROM_LOCATION,
      ],
      true,
    );

  readonly createContactPersonsPermission = () =>
    this.checkUserPermissions([Permissions.CONTACT_PERSON_CREATE]);

  readonly deleteContactPersonsPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.CONTACT_PERSON_DELETE,
        Permissions.CONTACT_PERSON_DELETE_FROM_CUSTOMER,
        Permissions.CONTACT_PERSON_DELETE_FROM_LOCATION,
        Permissions.CONTACT_PERSON_DELETE_OWN,
      ],
      true,
    );

  // PRODUCT ORDER PERMISSIONS ---------------------
  readonly readProductOrderPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_ORDER_READ,
        Permissions.PRODUCT_ORDER_READ_FROM_ORDER,
        Permissions.PRODUCT_ORDER_READ_FROM_TOUR_POINT_LOAD,
      ],
      true,
    );

  readonly createProductOrderPermission = () =>
    this.checkUserPermissions([Permissions.PRODUCT_ORDER_CREATE]);

  readonly updateProductOrderPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_ORDER_UPDATE,
        Permissions.PRODUCT_ORDER_UPDATE_FROM_ORDER,
        Permissions.PRODUCT_ORDER_UPDATE_FROM_TOUR_POINT_LOAD,
      ],
      true,
    );

  readonly deleteProductOrderPermission = () =>
    this.checkUserPermissions(
      [
        Permissions.PRODUCT_ORDER_DELETE,
        Permissions.PRODUCT_ORDER_DELETE_FROM_ORDER,
        Permissions.PRODUCT_ORDER_DELETE_FROM_TOUR_POINT_LOAD,
      ],
      true,
    );

  // FILE PERMISSIONS ---------------------
  readonly readFilePermission = () =>
    this.checkUserPermissions(
      [Permissions.FILE_READ, Permissions.FILE_READ_OWN],
      true,
    );

  readonly createFilePermission = () =>
    this.checkUserPermissions([Permissions.FILE_CREATE]);

  readonly updateFilePermission = () =>
    this.checkUserPermissions(
      [Permissions.FILE_UPDATE, Permissions.FILE_UPDATE_OWN],
      true,
    );

  readonly deleteFilePermission = () =>
    this.checkUserPermissions(
      [Permissions.FILE_DELETE, Permissions.FILE_DELETE_OWN],
      true,
    );

  // SPECIAL CASE PERMISSION CHECKS ---------------------
  /**
   * Checks if the logged-in user has the given permissions
   * If the user is a super admin or tenant admin, the method returns true
   * If the user has the given permissions, the method returns true
   * Otherwise, the method returns false and the missing permissions as an array
   * @param permission the permission to check for
   * @param needAtLeastOnePermission if true, the method returns true if the user has at least one of the given permissions
   * @returns true if the logged-in user has the given permission and false+ missing permissions if not
   */
  checkUserPermissions(
    permission: Permissions[],
    needAtLeastOnePermission?: boolean,
  ): UserPermission {
    const user = this.authService.getLoggedInUser();
    if (!user) return { hasPermission: false, missingPermissions: [] };

    if (user.role?.superAdmin || user.role?.tenantAdmin)
      return { hasPermission: true, missingPermissions: [] };

    if (user.role?.permissions) {
      const missingPermissions = permission.filter(
        (p) => !user.role?.permissions?.includes(p),
      );

      if (needAtLeastOnePermission) {
        return {
          hasPermission: missingPermissions.length < permission.length,
          missingPermissions: missingPermissions,
        };
      }

      return {
        hasPermission: missingPermissions.length === 0,
        missingPermissions: missingPermissions,
      };
    }
    return { hasPermission: false, missingPermissions: [] };
  }

  /**
   * reusable function to check if the user has the required permission to navigate to a map-page
   * If the user is a super admin or tenant admin, the method returns true
   * If the user has the given permissions, the method returns true
   * Otherwise, the method returns false and the missing permissions as an array
   * @returns true if the logged-in user has the given permission a
   */
  //
  async checkMapPermissions() {
    const user = this.authService.getLoggedInUser();
    if (!user || !user.role?.permissions)
      return { hasPermission: false, missingPermissions: [] };
    if (user.role?.superAdmin || user.role?.tenantAdmin)
      return { hasPermission: true };

    // static MAP with permissions:  at least one permission of each permission-array is needed
    const mapReadPermissions: Map<string, Permissions[]> = new Map([
      [
        "LOCATION",
        [
          Permissions.LOCATION_READ,
          Permissions.LOCATION_READ_FROM_CUSTOMER,
          Permissions.LOCATION_READ_FROM_TOURPOINT,
        ],
      ],
      [
        "WAREHOUSE",
        [
          Permissions.WAREHOUSE_READ,
          Permissions.WAREHOUSE_READ_FROM_TOUR_POINT,
          Permissions.WAREHOUSE_READ_OWN,
        ],
      ],
      [
        "TOUR",
        [
          Permissions.TOUR_READ,
          Permissions.TOUR_READ_OWN,
          Permissions.TOUR_READ_FROM_TOUR_POINT,
        ],
      ],
      // [
      //   "ORDER",
      //   [
      //     Permissions.ORDER_READ,
      //     Permissions.ORDER_READ_FROM_LOCATION,
      //     Permissions.ORDER_READ_FROM_CUSTOMER,
      //   ],
      // ],
      [
        "TOUR_POINT",
        [
          Permissions.TOUR_POINT_READ,
          Permissions.TOUR_POINT_READ_OWN,
          Permissions.TOUR_POINT_READ_FROM_TOUR,
          Permissions.TOUR_POINT_READ_FROM_WAREHOUSE,
        ],
      ],
      [
        "PRODUCT",
        [
          Permissions.PRODUCT_READ,
          Permissions.PRODUCT_READ_FROM_PRODUCT_ORDER,
          Permissions.PRODUCT_READ_FROM_TOUR_POINT_LOAD,
        ],
      ],
      [
        "PRODUCT_ORDER",
        [
          Permissions.PRODUCT_ORDER_READ,
          Permissions.PRODUCT_ORDER_READ_FROM_ORDER,
          Permissions.PRODUCT_ORDER_READ_FROM_TOUR_POINT_LOAD,
        ],
      ],
      [
        "CUSTOMER",
        [Permissions.CUSTOMER_READ, Permissions.CUSTOMER_READ_FROM_LOCATION],
      ],
    ]);

    let missingPermissionForMap: string[] = [];
    // if all permissions of one key are missing, translate key and add translation to missingPermissionForMap
    for (const [key, value] of mapReadPermissions.entries()) {
      const missingPermissionForKey = value.filter(
        (p) => !user.role?.permissions?.includes(p),
      );
      if (missingPermissionForKey.length === value.length) {
        // translate the keys with the translationService
        const permissionTranslation = await lastValueFrom(
          this.translateService.get(
            PermissionTranslations[key as keyof typeof PermissionTranslations],
          ),
        );
        missingPermissionForMap.push(permissionTranslation);
      }
    }

    return {
      hasPermission: missingPermissionForMap.length === 0,
      missingPermissions: missingPermissionForMap,
    };
  }
}

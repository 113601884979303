<tui-input
  (tuiValueChanges)="onSearchChange($event)"
  [required]="false"
  [formControl]="searchFormControl"
  tuiTextfieldSize="m"
  [tuiTextfieldLabelOutside]="true"
  [tuiTextfieldCleaner]="true"
  tuiTextfieldIconLeft="tuiIconSearch"
  tuiMode="onDark"
>
  <span [ngStyle]="{ color: 'white' }" class="hint">
    {{ "table-header.search" | translate }}
  </span>
  <input [ngStyle]="{ color: 'white' }" tuiTextfield type="text" />
</tui-input>

@if (tourPoint && isEditable) {
  @if (showForm) {
    <!-- FORM  -->
    <form [formGroup]="form" class="form">
      <!-- TABS  -->
      <div class="tabs">
        <app-tabs
          (clickEvent)="changeIndex($event)"
          [tabArray]="tabArray"
          [underline]="false"
          fontSize="0.69rem"
        />

        <button
          (click)="hideForm()"
          appearance="icon"
          class="delete-button"
          icon="tuiIconXCircleLarge"
          size="l"
          tuiButton
        ></button>
      </div>

      <!--   product   -->
      <div class="scoped">
        <app-dropdown
          (valueChange)="productSelectionChange($event)"
          [dropDownItems]="productsDropDown"
          formFieldName="product"
          hint="order.palette-type"
          hintColor="white"
          size="m"
        />
      </div>

      @if (qualityVisible) {
        <!-- quality -->
        <app-dropdown
          [dropDownItems]="qualityDropDown"
          class="fx-grow1"
          formFieldName="quality"
          hint="order.quality"
          hintColor="white"
          size="m"
        />
      }

      <!-- amount -->
      <app-input-number
        [max]="5000"
        fieldName="amount"
        hint="order.palette-amount"
        hintColor="white"
        size="m"
      />
    </form>
  }

  <!-- Button for submit / add form -->
  <button
    tuiButton
    size="m"
    style="width: 100%; margin-top: 0.5rem"
    (click)="submitOrAddForm()"
    [disabled]="showForm && form.invalid"
    class="submitAddButton"
    [icon]="!showForm ? tuiIconPlus : ''"
  >
    {{
      !showForm
        ? ("tourPointLoad.title" | translate)
        : ("common.button-save" | translate)
    }}
  </button>
}

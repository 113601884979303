import { Component, OnInit } from "@angular/core";
import Tour, { TOUR_STATE } from "../../models/Tour.class";
import { TourService } from "../../api/tour.service";
import { AddEditTourComponent } from "./add-edit-tour/add-edit-tour.component";
import { TuiDialogHelperService } from "../../services/tui-dialog-helper.service";
import { Router } from "@angular/router";
import { FilterTab } from "../../layouts/table-layout/table-layout.component";
import { TableHelperService } from "../../services/table-helper.service";
import { TablePageComponent } from "../../interfaces/table-page-component";
import { Permissions } from "../../other/enums/permissions";
import { PushService } from "../../services/push.service";
import { ResponseWithRecordsBody } from "../../interfaces/response-with-recors-body";
import TourPoint from "../../models/TourPoint.class";
import { TuiDay } from "@taiga-ui/cdk";
import { PermissionService } from "../../services/permission.service";
import { DateTime } from "luxon";
import { tuiIconTrash2Large } from "@taiga-ui/icons";
import { pushTypes } from "../../other/enums/push-types";
import { TourPermissionHelperService } from "../../services/permission-helper-services/tour-permission-helper.service";
import { MissingTourPermissionsDialogComponent } from "./missing-tour-permissions-dialog/missing-tour-permissions-dialog.component";
import { MissingTourDeletePermissionsComponent } from "./missing-tour-permissions-dialog/missing-tour-delete-permissions/missing-tour-delete-permissions.component";

export type FilterSelect = {
  value: TOUR_STATE | "ALL";
  label: string;
};

@Component({
  selector: "app-tour-page",
  templateUrl: "./tour-page.component.html",
  styleUrls: ["./tour-page.component.scss"],
})
export class TourPageComponent implements OnInit, TablePageComponent {
  headers = [
    "Erstellt am",
    "Erstell von",
    "Fahrer",
    "Fahrzeugnummer",
    "Liefertag",
    "Stops",
    "Produkte",
    "Produktmenge",
    "Kunden",
    "Status",
    "Abgebrochen worden von",
  ];
  columns = [
    "createdAt",
    "vcAuthor",
    "fullName",
    "truckNumber",
    "date",
    "tourPointsAmount",
    "products",
    "productsAmount",
    "customers",
    "tourState",
    "deleter",
    "customEdit",
  ];
  noSortColumns: string[] = [
    "fullName",
    "tourPointsAmount",
    "products",
    "productsAmount",
    "customers",
  ];
  tabVal: string = "current";
  tourPointsMap: Map<string, ResponseWithRecordsBody<TourPoint>> = new Map();

  tours: Tour[];

  filterSelectOptions: FilterSelect[] = [
    {
      value: "ALL",
      label: "Alle",
    },
    {
      value: TOUR_STATE.DRAFT,
      label: "Entwurf",
    },
    {
      value: TOUR_STATE.READY,
      label: "Geplant",
    },
    {
      value: TOUR_STATE.FINISHED,
      label: "Erledigt",
    },
    {
      value: TOUR_STATE.CANCELLED,
      label: "Abgebrochen",
    },
  ];

  filterSelect: FilterSelect = this.filterSelectOptions[0];

  tourTabs: FilterTab<any>[] = [
    {
      label: "table-header.filter-all",
      value: "all",
      isActive: false,
    },
    {
      label: "order.current",
      value: "current",
      isActive: true,
    },
    {
      label: "tour.former",
      value: "former",
      isActive: false,
    },
  ];

  showDeleteDialog: boolean;
  tourToDeleteId: string | null;
  tablePageSize: number = 10;
  tablePage: number = 0;
  currentTourState: TOUR_STATE | undefined;
  tourProductInfoMap = new Map<
    string,
    { productNames: string[]; totalAmount: number }
  >();
  currentSelectedDate: string | undefined;
  protected readonly Permissions = Permissions;
  protected readonly tuiIconTrash2Large = tuiIconTrash2Large;
  protected readonly TOUR_STATE = TOUR_STATE;
  private dateChangeTriggered: boolean = false;

  constructor(
    public tourService: TourService,
    private dialog: TuiDialogHelperService,
    private router: Router,
    public tableHelperService: TableHelperService,
    public permissionService: PermissionService,
    private tourPermissions: TourPermissionHelperService,
    private pushService: PushService,
  ) {
    this.tableHelperService.setComponentAndService(this, this.tourService);
  }

  get currentDayAsISO(): string {
    const currentDate = DateTime.now()
      .setZone("Europe/Berlin")
      .startOf("day")
      .toISO();
    if (currentDate === null) {
      throw new Error("Date is invalid");
    }
    return currentDate;
  }

  // Set initial pagination settings and populate the table.
  ngOnInit(): void {
    this.tableHelperService.resetTablePaginationAndSearch();
    this.populateTable();
    this.dateChangeTriggered = true;
  }

  populateTable(event?: FilterSelect) {
    const queryParams = this.generateQueryParams(event);
    this.tourService.getAllTours(queryParams).subscribe((res) => {
      this.tours = res.records;
      this.filterOutFinishedFromCurrent();
      this.getTourProducts();
    });
  }

  filterOutFinishedFromCurrent() {
    const tourStateFilterValue =
      this.tabVal === "current" && this.currentTourState !== TOUR_STATE.FINISHED
        ? TOUR_STATE.FINISHED
        : undefined;

    if (tourStateFilterValue) {
      // Filter out the tours where the tour state is not equal to the filter value
      this.tours = this.tours.filter(
        (tour) => tour.tourState !== tourStateFilterValue,
      );
    }
  }

  search(searchInput: string) {
    this.tableHelperService.resetTablePagination();
    this.tourService.search = searchInput;
    this.populateTable();
  }

  ngOnDestroy(): void {
    this.tableHelperService.deletePaginationIndicesAndSearch();
  }

  editTour(tourId: string) {
    if (
      this.tourPermissions.hasEditReadTourPermission() &&
      this.permissionService.readUserPermission().hasPermission
    ) {
      this.tourService.getTourById(tourId).subscribe((tour: Tour) => {
        this.dialog.openDialog(AddEditTourComponent, new Tour(tour), () => {
          // refresh data after dialog closes
          this.populateTable();
        });
      });
    } else {
      this.dialog.openDialog(MissingTourPermissionsDialogComponent, tourId);
    }
  }

  // Opens a dialog to create a new tour.
  createTour() {
    if (
      this.tourPermissions.hasCreateTourPermission &&
      this.permissionService.readUserPermission().hasPermission
    ) {
      this.dialog.openDialog(AddEditTourComponent, null, () => {
        // refresh data after dialog closes
        this.populateTable();
      });
    } else {
      this.dialog.openDialog(MissingTourPermissionsDialogComponent);
    }
  }

  // Navigates to a map page for a specific tour.
  async routeToTour(id: string) {
    const tour = this.tours.find((tour) => tour.id === id);

    if (tour?.tourState === TOUR_STATE.CANCELLED) {
      this.pushService.sendPush(
        pushTypes.INFO,
        "",
        "Eine abgebrochene Tour kann nicht in der Map betrachtet werden",
      );

      return;
    }

    const userHasMapPermissions =
      await this.permissionService.checkMapPermissions();

    // Check if updateTourPoint permission is missing, include it to missingPermissions
    const userCanUpdateTourPoint =
      this.permissionService.updateTourPointPermission().hasPermission;
    if (!userCanUpdateTourPoint) {
      userHasMapPermissions.hasPermission = false;
      userHasMapPermissions.missingPermissions?.push(
        "Tourpunkte aktualisieren",
      );
    }

    if (
      !userHasMapPermissions.hasPermission &&
      userHasMapPermissions.missingPermissions
    ) {
      // reduce the missing permission translations to a string
      const missingPermissionString = userHasMapPermissions.missingPermissions
        .sort()
        .reduce((acc, permission, index, arr) => {
          return acc + permission + (index < arr.length - 1 ? ", " : "");
        }, "");
      this.pushService.sendPush(
        pushTypes.ERROR,
        "",
        "Es fehlen Berechtigungen zum Ansehen der <b>" +
          (missingPermissionString ?? "") +
          "</b> um die Karte zu öffnen",
      );
      return;
    }

    this.router.navigate([`map/${id}`]).then();
  }

  // Opens a deletion dialog for a tour.
  openDeleteDialog(event: string) {
    if (this.permissionService.deleteTourPointPermission().hasPermission) {
      this.tourToDeleteId = event;
      this.showDeleteDialog = true;
    } else {
      this.dialog.openDialog(MissingTourDeletePermissionsComponent);
    }
  }

  // Deletes a tour by its id and updates the UI accordingly.
  deleteTour() {
    if (!this.tourToDeleteId) return;
    this.tourService.deleteTourById(this.tourToDeleteId).subscribe(() => {
      this.populateTable();
      this.tourToDeleteId = null;
      this.showDeleteDialog = false;
    });
  }

  onFilterTabClick(value: string) {
    this.tabVal = value;
    // Update isActive for each tab
    this.tourTabs.forEach((tab) => {
      tab.isActive = tab.value === value;
    });
    // Reset pagination whenever a new tab is clicked
    this.tableHelperService.resetTablePagination();
    this.populateTable();
  }

  getUniqueNames(tourPoints: TourPoint[]) {
    const pointNames = new Set();

    tourPoints.forEach((point) => {
      const name = point.location ? point.location.name : point.warehouse?.name;
      if (name) pointNames.add(name);
    });
    return Array.from(pointNames);
  }

  getTourProducts() {
    this.tours.forEach((tour) => {
      let productSet = new Set<string>();
      let totalAmount = 0;
      tour.tourPoints?.forEach((point) => {
        point.tourPointLoads.forEach((load) => {
          let productName = load.product?.name;
          if (productName != null) {
            productSet.add(productName);
          }
          totalAmount += Math.abs(load.amount);
        });
      });
      this.tourProductInfoMap.set(tour.id, {
        productNames: Array.from(productSet),
        totalAmount,
      });
    });
  }

  extractDate(value: TuiDay | null) {
    return value?.toUtcNativeDate()?.toISOString();
  }

  changeDateEvent(createdAtAsIsoDate: string | undefined) {
    if (!this.dateChangeTriggered) {
      this.tableHelperService.resetTablePagination();
      this.currentSelectedDate = createdAtAsIsoDate;
      this.populateTable();
    } else {
      // reset actionTriggered back to false for normal operations
      this.dateChangeTriggered = false;
    }
  }

  private generateQueryParams(event?: FilterSelect): any {
    const queryParams: {
      dateFrom?: string;
      dateTo?: string;
      tourState?: TOUR_STATE;
      withTourPointLoads?: boolean;
      withAuthor?: boolean;
      withProduct?: boolean;
      searchDate?: string;
      populateDeleter?: boolean;
      sort?: string;
    } = {
      withTourPointLoads: true,
      withAuthor: true,
      withProduct: true,
      populateDeleter: true,
      sort: "date+ASC",
    };

    switch (this.tabVal) {
      case "all":
        break;
      case "current":
        queryParams["dateFrom"] = this.currentDayAsISO;
        break;
      case "former":
        queryParams["dateTo"] = this.currentDayAsISO;
        break;
    }

    if (event) {
      this.tableHelperService.resetTablePagination();
      this.currentTourState = event.value === "ALL" ? undefined : event.value;
    }

    if (this.currentTourState) {
      queryParams["tourState"] = this.currentTourState;
    }

    if (this.currentSelectedDate) {
      queryParams["searchDate"] = this.currentSelectedDate;
    }

    return queryParams;
  }
}

<div class="add-edit-order-container">
  <h1 class="dialog-title">
    {{
      isAddMode
        ? ("order.add-title" | translate)
        : ("order.edit-title" | translate)
    }}
  </h1>

  <h3>
    {{ "addEditCustomer.name" | translate }}
  </h3>

  <form [formGroup]="form">
    <!--   customer   -->
    <app-dropdown
      (valueChange)="onCustomerChange($event)"
      [dropDownItems]="customerDropdown"
      [useVirtualScroll]="true"
      formFieldName="customerId"
      hint="order.customer"
    />
    <!--   location   -->
    <div style="margin-top: 1rem"></div>
    <app-dropdown
      (valueChange)="onLocationChange($event)"
      [dropDownItems]="locationDropdown"
      [useVirtualScroll]="true"
      formFieldName="locationId"
      hint="order.location"
    />

    <!--   time title  -->
    <h3>
      {{ "order.add-order-time" | translate }}
    </h3>

    <!-- start -->
    <div class="same-row-inputs">
      <tui-input-date-time
        (tuiValueChanges)="timeframeBeginChange($event)"
        [min]="today[0]"
        formControlName="timeframeBegin"
      >
        {{ "order.begin" | translate }}

        <input tuiTextfield />
      </tui-input-date-time>

      <!-- end -->
      <tui-input-date-time
        [min]="timeframeBegin"
        formControlName="timeframeEnd"
      >
        {{ "order.end" | translate }}

        <input tuiTextfield />
      </tui-input-date-time>
    </div>

    @if (hasProductOrderPermission) {
      <!--   product order title  -->
      <h3>
        {{ "order.information" | translate }}
      </h3>

      <div
        *ngFor="let order of orders.controls; let i = index"
        class="product-order-wrapper"
      >
        <div
          [formGroup]="
            dynamicFormHelperService.getFormGroupFromAbstractControl(order)
          "
          class="product-order-form"
        >
          <!--  product orders tabs   -->
          <app-tabs
            [control]="
              dynamicFormHelperService.getFormControlFromFromGroup(
                dynamicFormHelperService.getFormGroupFromAbstractControl(order),
                'tabIndex'
              )
            "
            [tabArray]="tabArray"
            [underline]="false"
            [disabled]="
              !permissionService.updateProductOrderPermission().hasPermission &&
              !permissionService.createProductOrderPermission().hasPermission
            "
          />

          <div class="four-inputs">
            <app-input-number fieldName="amount" hint="order.palette-amount" />
            <!--   product / product qualities    -->
            <app-dropdown
              (valueChange)="productChange($event, i)"
              [dropDownItems]="productsDropDown"
              formFieldName="productId"
              hint="order.palette-type"
            />

            <app-dropdown
              (valueChange)="qualityChange()"
              [dropDownItems]="qualityDropDown"
              formFieldName="quality"
              hint="order.quality"
            />

            <app-input-payment-amount
              fieldName="pricePerProduct"
              hint="order.price"
              size="l"
            />
          </div>
        </div>
        @if (
          this.isAddMode ||
          !isExistingProductOrder(i) ||
          productOrderPermissionHelper.hasAllDeleteProductOrderPermissions
        ) {
          <button
            (click)="deleteOrderFormRow(i)"
            appearance="icon"
            class="delete-button"
            icon="tuiIconTrash2Large"
            id="delete-button"
            size="l"
            style="margin-top: 4rem"
            tuiButton
          ></button>
        } @else if (
          permissionService.deleteProductOrderPermission().hasPermission &&
          (!permissionService.deleteTourPermission().hasPermission ||
            !permissionService.deleteTourPointPermission().hasPermission)
        ) {
          <app-tooltip
            elementName="Produktbestellungen"
            methodType="löschen"
            [permissions]="
              productOrderPermissionHelper.deletePermissionTooltip()
            "
          />
        }
      </div>
      @if (permissionService.createProductOrderPermission().hasPermission) {
        <button
          (click)="addOrderFormRow()"
          [disabled]="
            !permissionService.createProductOrderPermission().hasPermission
          "
          appearance="primary"
          icon="tuiIconPlus"
          id="addButton"
          size="m"
          style="width: 100%; margin-top: 1rem"
          tuiButton
        >
          {{ "order.add-product-order" | translate }}
        </button>
      } @else {
        <div
          class="permission-wrapper"
          style="
            color: var(--tui-warn-txt);
            background-color: var(--tui-warn-bg);
            padding: 1rem;
            border-radius: 1rem;
          "
        >
          <h3 style="margin-top: 0">
            Es fehlen folgende Berechtigungen, <br />
            um Produktbestellungen hinzufügen zu können:
          </h3>

          <ul>
            <li>
              <b>Produktbestellungen erstellen</b>
            </li>
          </ul>
        </div>
      }
    }

    <!-- custom spacer -->
    <div style="margin-top: 2rem"></div>

    <!--   save button   -->
    <app-save-cancel-btns
      (onCancel)="cancel()"
      (onSubmit)="submit()"
      [form]="form"
    />
  </form>
</div>

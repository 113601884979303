import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, Observable, of, tap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import Warehouse from "../models/Warehouse.class";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

export type WarehouseWorker = {
  warehouseId: string;
  userId: string;
};

@Injectable({
  providedIn: "root",
})
export class WarehouseService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;
  totalWarehouses = signal<number>(0);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  _warehouses = signal<Warehouse[]>([]);

  get warehouses() {
    return this._warehouses();
  }

  set warehouses(value: Warehouse[]) {
    this._warehouses.set(value);
  }

  get total() {
    return this.totalWarehouses();
  }

  // ------------------------------------------------------------------------------------- || Methods ||

  // ADD Warehouse
  addWarehouse(addWarehouseObject: Warehouse): Observable<Warehouse> {
    return this.http
      .post<Warehouse>(this.baseUrl + "warehouse", addWarehouseObject)
      .pipe(
        tap(() => {
          // Open the snackbar for successful request
          this.handleSuccess("Lager wurde hinzugefügt");

          // Call the getAllWarehouse method for updating the data + view
          this.getAllWarehouses().subscribe();
        }),
        catchError((err) => {
          // Open the snackbar for error
          this.errorHandler.sendErrorMessage("warehouse", "CREATE", err);
          return of(err);
        }),
      );
  }

  // GET ALL Warehouses
  getAllWarehouses(): Observable<ResponseWithRecordsBody> {
    let params = new HttpParams();

    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + "warehouse", {
        params: params,
      })
      .pipe(
        tap((res: ResponseWithRecordsBody) => {
          this._warehouses.set(
            res.records.map((warehouse: Warehouse) => new Warehouse(warehouse)),
          );
          this.totalWarehouses.set(res.total);
        }),
      );
  }

  // GET ONE Warehouse
  getWarehouseById(id: string | number): Observable<Warehouse> {
    return this.http.get<Warehouse>(this.baseUrl + "warehouse/" + id);
  }

  // CHANGE ONE Warehouse
  updateWarehouseById(
    id: string | number,
    updateWarehouseObject: Warehouse,
  ): Observable<Warehouse> {
    return this.http
      .patch<Warehouse>(this.baseUrl + "warehouse/" + id, updateWarehouseObject)
      .pipe(
        tap(() => {
          // Open the snackbar for successful request
          this.handleSuccess("Lager wurde aktualisiert!");
          // Call the getAllWarehouses method for updating the data + view
          this.getAllWarehouses().subscribe();
        }),
        catchError((err) => {
          // Open the snackbar for error
          this.errorHandler.sendErrorMessage("warehouse", "UPDATE", err);
          return of(err);
        }),
      );
  }

  // DELETE ONE Warehouse
  deleteWarehouseById(id: number | string): Observable<unknown> {
    return this.http.delete<unknown>(this.baseUrl + "warehouse/" + id).pipe(
      tap(() => {
        // Open the snackbar for successful request
        this.handleSuccess("Lager wurde gelöscht");
        // Call the getAllWarehouses method for updating the data + view
        this.getAllWarehouses().subscribe();
      }),
      catchError((err) => {
        // Open the snackbar for error
        this.errorHandler.sendErrorMessage("warehouse", "DELETE", err);
        return of(err);
      }),
    );
  }

  // ADD ONE warehouse worker
  addWarehouseWorker(addWarehouseWorkerObject: WarehouseWorker) {
    return this.http
      .post(this.baseUrl + "warehouse/worker", addWarehouseWorkerObject)
      .pipe(
        tap(() => {
          // Call the getAllWarehouse method for updating the data + view
          this.getAllWarehouses().subscribe();
        }),
        catchError((err) => {
          // Open the snackbar for error
          this.push.sendPush(
            pushTypes.ERROR,
            "",
            "Fehler bei der Zuordnung vom Lager zum Mitarbeiter",
          );
          return of(err);
        }),
      );
  }

  // REMOVE ONE warehouse worker  // TODO: change backend
  removeWarehouseWorker(removeWarehouseWorkerObject: WarehouseWorker) {
    if (!removeWarehouseWorkerObject.userId) return;

    return this.http
      .delete(
        this.baseUrl +
          "warehouse/remove/worker/" +
          removeWarehouseWorkerObject.userId +
          "/" +
          removeWarehouseWorkerObject.warehouseId,
      )
      .pipe(
        tap(() => {
          // Call the getAllWarehouse method for updating the data + view
          this.getAllWarehouses().subscribe();
        }),
        catchError((err) => {
          // Open the snackbar for error
          this.push.sendPush(
            pushTypes.ERROR,
            "",
            "Fehler beim Entfernen einer Lagerzuordnung vom Mitarbeiter",
          );
          return of(err);
        }),
      );
  }

  private handleSuccess(message: string) {
    this.push.sendPush(pushTypes.SUCCESS, "", message);
  }
}

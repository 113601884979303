<app-table-layout
  (dateSelected)="changeDateEvent(extractDate($event))"
  [missingReadPermissions]="
    permissionService.readDeliveryReceiptPermission().missingPermissions
  "
  [showDatePicker]="true"
  [showTable]="permissionService.readDeliveryReceiptPermission().hasPermission"
  dateHint="general.createdAt"
  ngxTitle="delivery-receipt.receipts"
>
  <!--  search field  -->
  <div class="search-form" header-item>
    <app-table-search-input
      (searchChangedDebounced)="search($event)"
    ></app-table-search-input>
  </div>

  <!-- default ng-content table -->
  <app-table
    (paginationEvent)="tableHelperService.paginationEvent($event)"
    (rowClickEvent)="deliveryReceiptRowClickEvent($event)"
    (sortEvent)="tableHelperService.sortTable($event)"
    [cellTemplatesMap]="{
      deliveryReceiptNumber: deliveryReceiptNumber,
      authorName: authorFullName,
      driverName: driverFullName
    }"
    [columns]="deliveryReceiptsTableShownColumns"
    [currentPage]="tablePage"
    [noSortColumns]="noSortColumns"
    [pageSize]="tablePageSize"
    [tableColumnNames]="deliveryReceiptTableColumnNames"
    [tableData]="deliveryReceipts"
    [totalElements]="deliveryReceiptService.total"
  >
    <!--  custom cell with copy to clipboard  -->
    <ng-template #deliveryReceiptNumber let-value>
      <div class="delivery-number">
        {{ value }}
        <button
          (click)="copyHelperService.copy(value); $event.stopPropagation()"
          [tuiHint]="tooltip"
          appearance="secondary"
          icon="tuiIconCopy"
          size="s"
          tuiHintDirection="right"
          tuiIconButton
        ></button>
      </div>

      <!--   tooltip info >> depending on the copyState   -->
      <ng-template #tooltip>
        {{
          copyHelperService.copyState === "ready"
            ? ("general.copy" | translate)
            : copyHelperService.copyState === "success"
              ? ("general.copy-success" | translate)
              : ("general.copy-error" | translate)
        }}
      </ng-template>
    </ng-template>
    <ng-template #authorFullName let-object="object">
      {{ object.vcAuthor?.firstName }} {{ object.vcAuthor?.lastName }}
    </ng-template>

    <ng-template #driverFullName let-object="object">
      @if (object.tourPoint?.tour?.driver) {
        {{ object.tourPoint?.tour?.driver?.firstName }}
        {{ object.tourPoint?.tour?.driver?.lastName }}
      } @else {
        {{ "common.unknown" | translate }}
      }
    </ng-template>
  </app-table>
</app-table-layout>

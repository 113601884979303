import { Component, Inject, OnInit } from "@angular/core";
import { BaseDialogComponent } from "../../../common/base-dialog/base-dialog.component";
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiSvgModule,
} from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { tuiIconAlertOctagonLarge } from "@taiga-ui/icons";
import { TranslateModule } from "@ngx-translate/core";
import TourPoint from "../../../models/TourPoint.class";
import { TourPointService } from "../../../api/tourPoint.service";
import TourPointLoad from "../../../models/TourPointLoad.class";
import { MapTpTplService } from "../map-helper-services/map-tp-tpl.service";

@Component({
  selector: "app-tp-tpl-delete-dialog",
  standalone: true,
  imports: [TranslateModule, TuiButtonModule, TuiSvgModule],
  templateUrl: "./tp-tpl-delete-dialog.component.html",
  styleUrl: "./tp-tpl-delete-dialog.component.scss",
})
export class TpTplDeleteDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  data: unknown;
  protected readonly tuiIconAlertOctagonLarge = tuiIconAlertOctagonLarge;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) override context: TuiDialogContext<any>,
    dialogService: TuiDialogHelperService,
    private tourPointService: TourPointService,
    private mapTourService: MapTpTplService,
  ) {
    super(context, dialogService);
  }

  ngOnInit(): void {
    this.data = this.context.data;
  }

  cancelEvent() {
    this.closeDialog();
  }

  deleteEvent() {
    if (this.data instanceof TourPoint) {
      this.tourPointService
        .deleteTourPointById(this.data.id)
        .subscribe(() => this.closeDialog());
    }
    if (this.data instanceof TourPointLoad) {
      this.mapTourService.deleteTourPointLoad([this.data.id]);
      this.closeDialog();
    }
  }
}

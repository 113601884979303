import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class VersionService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  // ------------------------------------------------------------------------------------- || Methods ||

  // GET Version
  getAllVersions() {
    return this.http.get(this.baseUrl + "version");
  }
}

<div class="input-wrapper">
  <tui-input-number
    [formControlName]="fieldName"
    [min]="minValue"
    [ngStyle]="{ color: hintColor }"
    [tuiTextfieldCleaner]="false"
    [tuiTextfieldIconLeft]="MyCustomSvg"
    [tuiTextfieldLabelOutside]="hint == ''"
    [tuiTextfieldSize]="size"
    style="color: var(--tui-text-01) !important"
  >
    <span *ngIf="hint != ''" style="color: black">{{ hint | translate }}</span>
  </tui-input-number>

  <!--  using  eur icon as prefix -->
  <ng-template #MyCustomSvg>
    <svg
      [ngStyle]="{ fill: hintColor }"
      fill="#c4c4c4"
      height="18"
      viewBox="0 0 256 256"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M190,192.33a8,8,0,0,1-.63,11.3A80,80,0,0,1,56.4,152H40a8,8,0,0,1,0-16H56V120H40a8,8,0,0,1,0-16H56.4A80,80,0,0,1,189.34,52.37,8,8,0,0,1,178.66,64.3,64,64,0,0,0,72.52,104H136a8,8,0,0,1,0,16H72v16h48a8,8,0,0,1,0,16H72.52a64,64,0,0,0,106.14,39.71A8,8,0,0,1,190,192.33Z"
      ></path>
    </svg>
  </ng-template>
</div>

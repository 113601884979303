import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { tuiIconMail } from "@taiga-ui/icons";

@Component({
  selector: "app-input-email",
  templateUrl: "./input-email.component.html",
  styleUrls: ["./input-email.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
/**
 * A component that displays an input field for entering an email address
 * @Input hint - The hint to display on top of the input field
 * @Input fieldName - The name of the field in the FormGroup
 */
export class InputEmailComponent {
  @Input() hint: string = "Email";
  @Input() fieldName?: string = "email";
  @Input() hintColor: string = "black";
  @Input() bRadius?: string = "";
  @Input() required?: boolean = true;
  protected readonly tuiIconMail = tuiIconMail;
}

import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import LocationC from "../../../../models/Location.class";
import { TranslateModule } from "@ngx-translate/core";
import { TuiIslandModule } from "@taiga-ui/kit";
import { TuiSvgModule } from "@taiga-ui/core";
import { HelperService } from "../../../../services/helper.service";
import { NgClass } from "@angular/common";
import { PermissionService } from "../../../../services/permission.service";

@Component({
  selector: "app-location-card-loop",
  standalone: true,
  imports: [TranslateModule, TuiIslandModule, TuiSvgModule, NgClass],
  templateUrl: "./location-card-loop.component.html",
  styleUrl: "./location-card-loop.component.scss",
})
export class LocationCardLoopComponent {
  @Input() locations: LocationC[];

  @Output() editEvent = new EventEmitter();

  permissionService = inject(PermissionService);
  helperService = inject(HelperService);

  onEdit($event: string) {
    this.editEvent.emit($event);
  }
}

<div *ngIf="enumOrArray" class="flex">
  <!-- title -->
  <h3 style="margin: 0; padding: 0; color: var(--tui-text-dark-01)">
    {{ ngxTitle | translate }}
  </h3>

  <div class="btnContainer" [class.vertical]="vertical">
    <button
      (click)="addOrRemove(item)"
      *ngFor="let item of enumOrArr"
      [class.highlighted]="includes(selectedOptionsArray, item)"
      [disabled]="disabled || includes(disabledArray, item)"
      appearance="''"
      class="notHighlighted"
      size="m"
      tuiButton
    >
      {{ item | translate }}
    </button>
  </div>
</div>

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { forkJoin, map, Observable, of, switchMap } from "rxjs";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { environment } from "../environments/environment";
import Refund from "../models/Refund.class";
import { PaginationFilterService } from "../services/pagination-filter.service";

type QueryParams = {
  createdAt?: string;
  tourPointLoadId?: string;
};

@Injectable({
  providedIn: "root",
})
export class RefundService extends PaginationFilterService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  // ------------------------------------------------------------------------------------- || Methods ||
  // GET ALL Refunds
  getAllRefunds(
    queryParams?: QueryParams,
  ): Observable<ResponseWithRecordsBody<Refund>> {
    let params: HttpParams = new HttpParams();

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    params = super.setPaginationSortingParams(params);

    return this.http.get<ResponseWithRecordsBody<Refund>>(
      this.baseUrl + "refund",
      {
        params,
      },
    );
  }

  // GET ONE Refund BY ID
  getRefundById(id: string | number): Observable<Refund> {
    return this.http.get<Refund>(this.baseUrl + "refund/" + id);
  }

  //  Get Refund by id and all image requests for that refund
  getDbImagesById(id: string): Observable<{ refund: Refund; images?: Blob[] }> {
    return this.getRefundById(id).pipe(
      switchMap((refund) => {
        if (refund.files && refund.files.length > 0) {
          const imageRequests = refund.files.map((file) =>
            this.http.get(this.baseUrl + "refund/" + id + "/file/" + file.id, {
              responseType: "blob" as "json",
            }),
          );
          // get all images and return them with the refund
          return forkJoin(imageRequests).pipe(
            map((images) => {
              return { refund, images };
            }),
          );
        } else {
          // return the refund without images
          return of({ refund });
        }
      }),
    );
  }
}

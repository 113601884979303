import { Component, EventEmitter, Input, Output } from "@angular/core";

import { DropDownItem } from "../../interfaces/drop-down-item";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { TuiSizeL, TuiSizeM, TuiSizeS } from "@taiga-ui/core";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
/**
 * A component that displays a dropdown menu with custom items
 * @Input dropDownItems - The items to display in the dropdown menu
 * @Input formFieldName - The name of the field in the FormGroup
 * @Input hint - The hint to display on top of the dropdown menu
 */
export class DropdownComponent {
  @Input({ required: true }) dropDownItems!: DropDownItem[];
  @Input({ required: true }) formFieldName!: string;
  @Input() hint: string = "";
  @Input() hintColor: string = "black";
  @Input() size: TuiSizeL | TuiSizeS | TuiSizeM = "l";
  @Input() readOnly: boolean = false;
  @Input() required?: boolean = true;
  @Input() useVirtualScroll: boolean = false;

  @Output() valueChange = new EventEmitter();

  onChange(event: any) {
    this.valueChange.emit(event);
  }

  /*
   * Returns the label of the item to display in the dropdown menu
   */
  readonly stringify = (item: DropDownItem): string => {
    if (item.label != undefined) {
      return `${item.label}`;
    } else {
      return "";
    }
  };
}

@if (
  (productPrices && productPrices.length > 0) ||
  !permissionService.readProductPricePermission().hasPermission
) {
  <h1 style="color: black">Kundenpreise</h1>

  @if (permissionService.readProductPricePermission().hasPermission) {
    <app-table
      [totalElements]="total"
      (paginationEvent)="paginationEvent($event)"
      [columns]="columns"
      [tableColumnNames]="headers"
      [tableData]="productPrices"
      [itemsEditable]="
        permissionService.updateProductPricePermission().hasPermission
      "
      [itemsDeletable]="
        permissionService.deleteProductPricePermission().hasPermission
      "
      (deleteEvent)="deleteCustomerPrice($event)"
      [cellTemplatesMap]="{
        updatedAt: updatedAt,
        price: price,
        timeframeBegin: timeframeBegin,
        quality
      }"
      (editEvent)="editPrice($event)"
      [currentPage]="tablePage"
      [pageSize]="tablePageSize"
    >
      <!-- updatedAt -->
      <ng-template #updatedAt let-value>
        {{ value | date: "dd.MM.yyyy / HH:mm" }}
      </ng-template>

      <!-- Timeframe -->
      <ng-template #timeframeBegin let-value let-object="object">
        {{ value | date: "dd.MM.yyyy" }} -
        {{ object.timeframeEnd | date: "dd.MM.yyyy" }}
      </ng-template>

      <!-- Price -->
      <ng-template #price let-value> {{ value }} €</ng-template>

      <!-- Quality -->
      <ng-template #quality let-value> {{ value | translate }}</ng-template>
    </app-table>
  } @else {
    <app-no-read-permission
      [missingPermissions]="
        permissionService.readProductPricePermission().missingPermissions
      "
      [onlyInfoText]="true"
    ></app-no-read-permission>
  }
}

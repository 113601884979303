import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-multi-select-buttons",
  templateUrl: "./multi-select-buttons.component.html",
  styleUrls: ["./multi-select-buttons.component.scss"],
})
export class MultiSelectButtonsComponent {
  @Input() ngxTitle: string;
  @Input({ required: true }) enumOrArray?: any;
  @Input({ required: true }) selectedOptionsArray: any[];
  @Input() singleChoice: boolean = false;
  @Input() vertical: boolean = true;
  @Input() disabled: boolean = false;
  @Input() disabledArray: any[] = []; // array to disable specific buttons

  @Output() selectEvent = new EventEmitter();

  // convert an enum into a string array to be able to work with it
  get enumOrArr(): string[] {
    return Object.values(this.enumOrArray);
  }

  emit() {
    this.selectEvent.emit(this.selectedOptionsArray);
  }

  // check if the clicked item is in the selected array or not
  includes(array: string | any[], value: any): boolean {
    if (!array) return false;
    return array.includes(value);
  }

  // adds or removes the item on click from the selected array
  addOrRemove(value: string) {
    if (!this.selectedOptionsArray) return;

    const index = this.selectedOptionsArray.indexOf(value);

    if (index === -1) {
      if (this.singleChoice) {
        this.selectedOptionsArray.splice(0, this.selectedOptionsArray.length);
      }
      this.selectedOptionsArray.push(value);
      this.emit();
    } else {
      this.selectedOptionsArray.splice(index, 1);
      this.emit();
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MenuItem, MenuItems } from "../../../other/enums/menu-items";
import { TranslateService } from "@ngx-translate/core";
import { NavRoutes } from "../../../other/enums/nav-routes";
import { Router } from "@angular/router";
import { AuthService } from "../../../api/auth.service";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})

/**
 * This component represents a button in the sidenav to switch between the different pages.
 * Each button represents a page and has a name, an icon and a tooltip text.
 *
 * @Input type: string - Which Button is clicked (see: menu-items enum).
 * @Input selected: boolean - Checks if the button is selected.
 * @Input name: string - Name of the button.
 * @Output isClicked: EventEmitter<MenuItem> - Emits the clicked button to the parent component (sidenav).
 */
export class ButtonComponent implements OnInit {
  @Input() type: MenuItems | string = MenuItems.DASHBOARD; // Which Button is clicked.
  @Input() selected: boolean = false; // Checks if the button is selected.
  @Input() name: string = ""; // Name of the button.
  @Input() isRound: boolean = false; // makes the button to a circle
  @Output() isClicked: EventEmitter<MenuItem> = new EventEmitter<MenuItem>(); // Emits the clicked button to the parent component.
  // For the translations
  overview: string;
  driver: string;
  refunds: string;
  customers: string;
  tours: string;
  calendar: string;
  map: string;
  settings: string;
  order: string;
  warehouse: string;
  user: string;
  deliveryReceipt: string;
  unannouncedDeliveries: string;
  protected menuItem: MenuItem;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    // Subscribes to the translations for the menu items
    this.getAllTranslations();

    // Listen to language changes, because the translations on the sidenav have to be updated directly after the language changed in the dropdown (are not reloaded automatically).
    this.translate.onLangChange.subscribe(() => {
      this.getAllTranslations();
    });
  }

  /**
   * Get all translations for the menu items
   */
  getAllTranslations() {
    this.translate
      .get([
        "sidenav.overview",
        "order.refunds",
        "warehouse.title",
        "sidenav.driver",
        "order.title",
        "user.coworker",
        "sidenav.customer",
        "sidenav.allPlannings",
        "sidenav.calendar",
        "sidenav.map",
        "sidenav.settings",
        "sidenav.unannounced-deliveries",
        "sidenav.delivery-receipt",
      ])
      .subscribe((translations) => {
        this.overview = translations["sidenav.overview"];
        this.refunds = translations["order.refunds"];
        this.warehouse = translations["warehouse.title"];
        this.driver = translations["sidenav.driver"];
        this.order = translations["order.title"];
        this.customers = translations["sidenav.customer"];
        this.user = translations["user.coworker"];
        this.tours = translations["sidenav.allPlannings"];
        this.calendar = translations["sidenav.calendar"];
        this.map = translations["sidenav.map"];
        this.deliveryReceipt = translations["sidenav.delivery-receipt"];
        this.settings = translations["sidenav.settings"];
        this.unannouncedDeliveries =
          translations["sidenav.unannounced-deliveries"];
        this.getType();
      });
  }

  isMapActive() {
    const url = this.router.url;
    return this.menuItem.link === "tours" && url.includes("map");
  }

  /**
   * Determines the type of the button and sets the corresponding values,
   * including the tooltip text, the icon and the link by creating a new MenuItem object.
   */
  getType(): void {
    switch (this.type) {
      case MenuItems.DASHBOARD:
        this.menuItem = new MenuItem(
          this.overview,
          "tuiIconGridLarge",
          NavRoutes.DASHBOARD,
        );

        break;
      case MenuItems.REFUND:
        this.menuItem = new MenuItem(
          this.refunds,
          "tuiIconAlertOctagonLarge",
          NavRoutes.REFUNDS,
        );
        break;
      case MenuItems.TOURS:
        this.menuItem = new MenuItem(
          this.tours,
          "tuiIconMapLarge",
          NavRoutes.TOURS,
        );
        break;
      // case MenuItems.WAREHOUSE:
      //   this.menuItem = new MenuItem(
      //     this.warehouse,
      //     "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" fill=\"#ffffff\" viewBox=\"0 0 256 256\"><path d=\"M240,184h-8V57.9l9.67-2.08a8,8,0,1,0-3.35-15.64l-224,48A8,8,0,0,0,16,104a8.16,8.16,0,0,0,1.69-.18L24,102.47V184H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM40,99,216,61.33V184H192V128a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8v56H40Zm136,53H80V136h96ZM80,168h96v16H80Z\"></path></svg>",
      //     NavRoutes.WAREHOUSE
      //   );
      //   break;
      case MenuItems.CUSTOMERS:
        this.menuItem = new MenuItem(
          this.customers,
          '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"></path></svg>',
          NavRoutes.CUSTOMERS,
        );

        break;
      // case MenuItems.DRIVERS:
      //   this.menuItem = new MenuItem(
      //     this.driver,
      //     "tuiIconTruckLarge",
      //     NavRoutes.DRIVER
      //   );
      //   break;
      case MenuItems.ORDER:
        this.menuItem = new MenuItem(
          this.order,
          "tuiIconPackageLarge",
          NavRoutes.ORDER,
        );

        break;
      // case MenuItems.ALL_PLANNINGS:
      //   this.menuItem = new MenuItem(
      //     this.allPlannings,
      //     "tuiIconClipboardLarge",
      //     NavRoutes.ALL_PLANNINGS
      //   );
      //   break;
      case MenuItems.USER:
        this.menuItem = new MenuItem(
          this.user,
          "tuiIconUsersLarge",
          NavRoutes.USER,
        );

        break;
      case MenuItems.DELIVERY_RECEIPT:
        this.menuItem = new MenuItem(
          this.deliveryReceipt,
          "tuiIconFileTextLarge",
          NavRoutes.DELIVERY_RECEIPT,
        );
        break;

      case MenuItems.UNANNOUNCED_DELIVERIES:
        this.menuItem = new MenuItem(
          this.unannouncedDeliveries,
          "tuiIconInboxLarge",
          NavRoutes.UNANNOUNCED_DELIVERIES,
        );
        break;

      case MenuItems.SETTINGS:
        this.menuItem = new MenuItem(
          this.settings,
          "tuiIconSettingsLarge",
          NavRoutes.SETTINGS,
        );
        break;
    }
  }

  /**
   * Emits true to the parent component if clicked.
   */
  onClick(): void {
    this.isClicked.emit(this.menuItem);
  }
}

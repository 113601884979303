<div *ngIf="title" class="headline-wrapper">
  <h2>{{ title }}</h2>
</div>

<form [formGroup]="roleForm" (ngSubmit)="submitRoleForm()">
  <div class="form-wrapper">
    <div class="base-info-wrapper">
      <app-input-text
        [hint]="'settings.role-tab.input-role-name' | translate"
        [fieldName]="'roleName'"
      ></app-input-text>
      <app-input-large-text
        [hint]="'settings.role-tab.input-role-description' | translate"
        [fieldName]="'roleDescription'"
        [isExpandable]="true"
      ></app-input-large-text>
      <div class="checkbox-container">
        <tui-checkbox-labeled formControlName="isTenantAdmin" size="l">
          {{ "settings.role-tab.checkbox-is-tenant-admin" | translate }}
        </tui-checkbox-labeled>
        <tui-checkbox-labeled formControlName="isSuperAdmin" size="l">
          {{ "settings.role-tab.checkbox-is-super-admin" | translate }}
        </tui-checkbox-labeled>
      </div>

      <button
        tuiButton
        type="submit"
        size="m"
        [disabled]="!roleForm.valid"
        appearance="primary"
        class="submit-button"
      >
        {{ "common.button-save" | translate }}
      </button>
    </div>

    <div class="permission-wrapper">
      <app-permission-row
        [title]="'settings.role-tab.title-permissions-user' | translate"
        [description]="
          'settings.role-tab.description-permissions-user' | translate
        "
        [fieldNamePrefix]="'user'"
      ></app-permission-row>
      <hr />
      <app-permission-row
        [title]="'settings.role-tab.title-permissions-tenant' | translate"
        [description]="
          'settings.role-tab.description-permissions-tenant' | translate
        "
        [fieldNamePrefix]="'tenant'"
      ></app-permission-row>
      <hr />
      <app-permission-row
        [title]="'settings.role-tab.title-permissions-role' | translate"
        [description]="
          'settings.role-tab.description-permissions-role' | translate
        "
        [fieldNamePrefix]="'role'"
      ></app-permission-row>
    </div>
  </div>
</form>

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from "@angular/core";
import { TuiDataListWrapperModule, TuiMultiSelectModule } from "@taiga-ui/kit";
import {
  TUI_DEFAULT_MATCHER,
  TuiContextWithImplicit,
  TuiHandler,
  TuiLetModule,
} from "@taiga-ui/cdk";
import { TuiModeModule, TuiTextfieldControllerModule } from "@taiga-ui/core";
import {
  from,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from "rxjs";
import {
  ControlContainer,
  FormGroupDirective,
  ReactiveFormsModule,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AsyncPipe } from "@angular/common";
import { WarehouseService } from "../../../api/warehouse.service";
import Warehouse from "../../../models/Warehouse.class";

@Component({
  selector: "app-warehouseworker",
  standalone: true,
  imports: [
    TuiMultiSelectModule,
    TuiLetModule,
    TuiTextfieldControllerModule,
    TuiDataListWrapperModule,
    ReactiveFormsModule,
    TranslateModule,
    AsyncPipe,
    TuiModeModule,
  ],
  templateUrl: "./warehouseworker.component.html",
  styleUrl: "./warehouseworker.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class WarehouseworkerComponent {
  warehouseService = inject(WarehouseService);

  @Input() fControlName: string;

  private readonly server$ = from(
    this.warehouseService.getAllWarehouses(),
  ).pipe(
    map((response) => {
      return response.records;
    }),
    map((items: Warehouse[]) => {
      return items.map((item) => ({ id: item.id, name: item.name }));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  // Stringify mapper that turns IDs to names
  readonly stringify$: Observable<
    TuiHandler<TuiContextWithImplicit<string> | string, string>
  > = this.server$.pipe(
    map(
      (items) =>
        new Map(items.map<[string, string]>(({ id, name }) => [id, name])),
    ),
    startWith(new Map()),
    map(
      (map) => (id: TuiContextWithImplicit<string> | string) =>
        (typeof id === "string" ? map.get(id) : map.get(id.$implicit)) ||
        "Loading...",
    ),
  );
  private readonly search$ = new Subject<string>();
  // Items only hold IDs
  readonly items$ = this.search$.pipe(
    startWith(""),
    switchMap((search) =>
      this.server$.pipe(
        map((items) =>
          items
            .filter(({ name }) => TUI_DEFAULT_MATCHER(name, search))
            .map(({ id }) => id),
        ),
      ),
    ),
    startWith(null), // <-- loading
  );

  onSearch(search: string | null): void {
    this.search$.next(search ?? "");
  }
}

import { Injectable } from "@angular/core";
import TourPoint from "../../../models/TourPoint.class";
import { UpdateRequestPayload } from "../../../interfaces/request-array.interface";
import { TourPointService } from "../../../api/tourPoint.service";
import { MapBaseDataService } from "./map-base-data.service";

@Injectable({
  providedIn: "root",
})
export class MapRoutesService {
  polylineOptions = {
    strokeColor: "#4285F4",
    strokeOpacity: 1.0,
    strokeWeight: 6,
  };

  private directionsRenderer: google.maps.DirectionsRenderer | null;

  constructor(
    private tourPointService: TourPointService,
    private mapBaseService: MapBaseDataService,
  ) {}

  resetDirectionsRenderer(): void {
    if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
      this.directionsRenderer = null;
    }
  }

  initializeDirectionsRenderer(): void {
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    this.directionsRenderer.setOptions({
      polylineOptions: this.polylineOptions,
    });
    this.directionsRenderer.setMap(this.mapBaseService.map.googleMap!);
  }

  createWaypoints(tourPoints: TourPoint[]): any[] {
    return tourPoints.slice(1, tourPoints.length - 1).map((point) => ({
      location: new google.maps.LatLng(
        point.address.latitude,
        point.address.longitude,
      ),
      stopover: true,
    }));
  }

  getOriginAndDestination(tourPoints: TourPoint[]): {
    origin: any;
    destination: any;
  } | null {
    let origin = null;
    let destination = null;

    // Find the first tourPoint with a non-null address
    const originPoint = tourPoints.find((tp) => tp.address);
    if (originPoint) {
      origin = new google.maps.LatLng(
        originPoint.address.latitude,
        originPoint.address.longitude,
      );
    }

    // Find the last tourPoint with a non-null address
    const destinationPoint = [...tourPoints].reverse().find((tp) => tp.address);
    if (destinationPoint) {
      destination = new google.maps.LatLng(
        destinationPoint.address.latitude,
        destinationPoint.address.longitude,
      );
    }

    if (origin && destination) {
      return { origin, destination };
    }

    // If no valid origin and destination were found, return null
    return null;
  }

  createAndDisplayRoute(
    tourPoints: TourPoint[],
  ): Promise<{ durations: number[]; distances: number[] }> {
    return new Promise((resolve, reject) => {
      this.resetDirectionsRenderer();
      if (tourPoints.length < 2) {
        reject(new Error("Need at least two tour points to create a route"));
        return;
      }
      const directionsService = new google.maps.DirectionsService();
      this.initializeDirectionsRenderer();
      const waypoints = this.createWaypoints(tourPoints);

      const originAndDestination = this.getOriginAndDestination(tourPoints);
      if (originAndDestination === null) {
        reject(
          new Error(
            "Origin or destination could not be determined due to missing address.",
          ),
        );
        return;
      }
      const { origin, destination } = originAndDestination;

      let TrafficModel;

      if (typeof google === "object" && typeof google.maps === "object") {
        TrafficModel = google.maps.TrafficModel;
      } else {
        console.log("Google Maps is not loaded");
      }

      directionsService
        .route(
          {
            origin,
            destination,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
            drivingOptions: {
              departureTime: new Date(),
              trafficModel: TrafficModel?.PESSIMISTIC,
            },
          },
          (response, status) => {
            if (status === "OK") {
              this.directionsRenderer?.setDirections(response);
              if (response) {
                const durations = this.calculateRouteDrivingDuration(response);
                const distances = this.calculateRouteDistances(response);
                resolve({ durations, distances });
              }
            } else {
              reject(new Error("Directions request failed due to " + status));
            }
          },
        )
        .then();
    });
  }

  calculateRouteDrivingDuration(
    response: google.maps.DirectionsResult,
  ): number[] {
    return response.routes[0].legs.map((leg) =>
      // * 1.8 to have a more accurate duration for truck speed
      leg.duration?.value ? Math.ceil((leg.duration.value * 1.8) / 60) : 0,
    );
  }

  calculateRouteDistances(response: google.maps.DirectionsResult): number[] {
    const route = response.routes[0];
    return route.legs.map((leg) =>
      leg.distance?.value ? Math.round(leg.distance?.value / 1000) : 0,
    );
  }

  updateTourPointsEstimateArrival(
    arrivalTimes: string[],
    tourPoints: TourPoint[],
    estimatedDistances: number[], // Add estimatedDistances as a parameter
  ): void {
    let updatePayload: UpdateRequestPayload<TourPoint> = { data: [] };

    tourPoints.forEach((tp, index) => {
      // Check if there is an estimated distance for the current tour point
      let hasEstimatedDistance = estimatedDistances.length > index;

      // Check if the new estimatedArrival or estimatedDistance is different from existing ones
      let isArrivalChanged = tp.estimatedArrival !== arrivalTimes[index];
      let isDistanceChanged =
        hasEstimatedDistance &&
        tp.estimatedDistance !== estimatedDistances[index];

      if (isArrivalChanged || (hasEstimatedDistance && isDistanceChanged)) {
        // Update the tourPoint's estimatedArrival and/or estimatedDistance locally
        if (isArrivalChanged) {
          tp.estimatedArrival = arrivalTimes[index];
        }
        if (hasEstimatedDistance && isDistanceChanged) {
          tp.estimatedDistance = estimatedDistances[index]; // Assuming tp.estimatedDistance exists
        }

        // Add the tourPoint to the updatePayload
        updatePayload.data.push({ id: tp.id, data: tp });
      }
    });
    
    // Update the tourPoints in the backend
    if (updatePayload.data.length > 0) {
      this.tourPointService.updateTourPointById(updatePayload).subscribe();
    }
  }
}

<!--Title-->

<h2 style="margin-top: 0">
  {{
    mode === "add"
      ? ("customer.create-price" | translate)
      : ("customer.edit-price" | translate)
  }}
</h2>

<!-- form -->
<form
  (ngSubmit)="form.valid && submit()"
  [formGroup]="form"
  class="flex-col-g1"
>
  <!-- product -->
  <app-dropdown
    (valueChange)="onProductChange($event)"
    [dropDownItems]="productDropdown"
    [hint]="'product.title'"
    [readOnly]="mode === 'edit'"
    formFieldName="productId"
  />

  <!-- quality -->
  @if (qualityVisible) {
    <!-- quality -->
    <app-dropdown
      [dropDownItems]="qualityDropdown"
      class="fx-grow1"
      formFieldName="quality"
      hint="order.quality"
    />
  }

  <!-- price -->
  <app-input-payment-amount fieldName="price" hint="order.price" size="l" />

  <div class="same-row-inputs">
    <!-- timeframeBegin -->
    <app-input-date
      (tuiValueChanges)="timeframeBeginChange($event)"
      [min]="today"
      fieldName="timeframeBegin"
      hint="price.start-validility"
    />

    <!-- timeframeEnd -->
    <app-input-date
      [min]="timeframeBegin"
      fieldName="timeframeEnd"
      hint="price.end-validility"
    />
  </div>

  <!-- buttons -->
  <div class="actions">
    <button [disabled]="form.invalid" class="fx-grow1" size="m" tuiButton>
      {{ "common.button-save" | translate }}
    </button>

    <button
      (click)="cancel()"
      appearance="secondary"
      class="fx-grow1"
      size="m"
      tuiButton
      type="button"
    >
      {{ "common.button-cancel" | translate }}
    </button>
  </div>
</form>

import { Component, Input } from "@angular/core";

/**
 * Component for displaying a title and some information.
 * Used primarily in the settings page.
 * @Input title - The title to display
 * @Input info - The information to display
 */
@Component({
  selector: "app-info-bit",
  templateUrl: "./info-bit.component.html",
  styleUrls: ["./info-bit.component.scss"],
})
export class InfoBitComponent {
  @Input() title: string = "";
  @Input() info: string = "";
}

<div class="button-wrapper">
  <button
    (click)="submit($event)"
    [disabled]="form.invalid || customValidatorBoolean"
    appearance="primary"
    class="saveBtn"
    size="m"
    tuiButton
    type="submit"
  >
    {{ "common.button-save" | translate }}
  </button>

  <button
    *ngIf="showDeleteBtn"
    (click)="onDelete.emit()"
    appearance="secondary-destructive"
    size="m"
    tuiButton
    type="button"
  >
    {{ "common.button-delete" | translate }}
  </button>

  <button
    (click)="cancel()"
    appearance="secondary"
    size="m"
    tuiButton
    type="button"
  >
    {{ "common.button-cancel" | translate }}
  </button>
</div>

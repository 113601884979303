<div class="aroundPadding" style="min-width: 750px">
  <!-- title -->
  <h1 style="padding-left: 1rem; margin-bottom: 2rem">
    {{ "dashboard.title" | translate }}
  </h1>

  @if (permissionService.readDashboardPermission().hasPermission) {
    <!-- inner container for left and right layout -->
    <div class="inner-container">
      <!--  child container, containing left content  -->
      <div class="left">
        <!-- dashboard -->
        <tui-island>
          <div class="left-dashboard-grid">
            <!-- Dashboard Chart     -->
            <app-dashboard-chart></app-dashboard-chart>
            <!-- Dashboard Widgets (e.g. orders today, ...)  -->
            <app-dashboard-widgets></app-dashboard-widgets>
            <!-- Refund Table -->
            <div class="table-wrapper">
              <div class="flex-row" style="justify-content: space-between">
                <h2 style="margin: 0.5rem 0 0.25rem 0">
                  {{ "dashboard.newest-refunds" | translate }}
                  @if (
                    !refundPermissionHelper.allPermissionsForReadAllRefundInfos()
                  ) {
                    <tui-svg
                      class="pointer"
                      (click)="openMissingPermissionsDialog()"
                      src="tuiIconInfoLarge"
                    ></tui-svg>
                  }
                </h2>
                <a
                  [routerLink]="'/' + NavRoutes.ORDER"
                  [queryParams]="{ tabVal: 'refunds' }"
                  style="color: var(--tui-text-02); text-decoration: none"
                  >{{ "dashboard.show-all-refunds" | translate }}
                  <tui-svg
                    src="tuiIconChevronRight"
                    style="color: var(--tui-text-02)"
                  ></tui-svg>
                </a>
              </div>
              @if (permissionService.readRefundPermission().hasPermission) {
                <app-table
                  (rowClickEvent)="routeToOrderRefunds($event)"
                  (sortEvent)="tableHelperService.sortTable($event)"
                  [columns]="columns"
                  [noSortColumns]="noSortColumns"
                  [cellTemplatesMap]="{
                    amount: amount,
                    'driver-fullname': driver,
                    createdFrom
                  }"
                  [showPagination]="false"
                  [tableColumnNames]="headers"
                  [tableData]="refunds"
                >
                  <ng-template #createdFrom let-refund="object">
                    {{ getCreatedFromForRefunds(refund) }}
                  </ng-template>
                  <ng-template #amount let-value>
                    {{ Math.abs(value) }}
                  </ng-template>
                  <ng-template #driver let-object="object">
                    {{ getDriverNameWithRefundId(object.id) }}
                  </ng-template>
                </app-table>
              } @else {
                <app-no-read-permission
                  [missingPermissions]="
                    permissionService.readRefundPermission().missingPermissions
                  "
                  [onlyInfoText]="true"
                ></app-no-read-permission>
              }
            </div>
          </div>
        </tui-island>
      </div>

      <!--  child container, containing right content  -->
      <app-dashboard-widgets-outside></app-dashboard-widgets-outside>
    </div>
  } @else {
    <app-no-read-permission
      [missingPermissions]="
        permissionService.readDashboardPermission().missingPermissions
      "
    ></app-no-read-permission>
  }
</div>

import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";

@Injectable({
  providedIn: "root",
})
export class WarehousePermissionHelperService {
  private permissionService = inject(PermissionService);

  get hasCreateWarehousePermission() {
    return this.permissionService.createWarehousePermission().hasPermission;
  }

  get hasReadWarehousePermission() {
    return this.permissionService.readWarehousePermission().hasPermission;
  }

  get hasUpdateWarehousePermission() {
    return this.permissionService.updateWarehousePermission().hasPermission;
  }

  hasCreateReadWarehousePermission(boolVal: boolean = true) {
    return (
      this.hasCreateWarehousePermission &&
      this.hasReadWarehousePermission &&
      boolVal
    );
  }

  hasEditReadWarehousePermission(boolVal: boolean = true) {
    return (
      this.hasUpdateWarehousePermission &&
      this.hasReadWarehousePermission &&
      boolVal
    );
  }
}

import { BaseModel } from "./BaseModel";
import LocationC from "./Location.class";
import ContactPerson from "./ContactPerson.class";

export default class Customer<T = any> extends BaseModel<Customer> {
  customerNumber: string;
  locations: LocationC[];
  name: string;
  phone: string;
  tenantId: string;
  note: string;
  contactPersons: ContactPerson[];
  inactiveSince: boolean;
  files: T[];
  MATCHC?: string;
  mobile: string; // unused - phone is used instead
  fax: string;
  email: string;
  billStreet: string | null;
  billStreetNumber: string | null;
  billCity: string | null;
  billCityDistrict: string | null;
  billPostalcode: string | null;
  LKZ: string;
  taxes: number;

  constructor(params: Partial<Customer>) {
    super(params);
    Object.assign(this, params);

    if (this.name) {
      this.name = this.trimCommas(this.name);
    }
    if (this.MATCHC) {
      this.MATCHC = this.trimCommas(this.MATCHC);
    }
  }

  private trimCommas(value: string): string {
    return value.replace(/,/g, "");
  }
}

import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";

@Injectable({
  providedIn: "root",
})
export class ProductPricePermissionHelperService {
  private permissionService = inject(PermissionService);

  get hasAllCreateProductPricePermissions(): boolean {
    return (
      // Create ProductPrice
      this.permissionService.createProductPricePermission().hasPermission &&
      // Read ProductPrice
      this.permissionService.readProductPricePermission().hasPermission &&
      // Read Product
      this.permissionService.readProductPermission().hasPermission
    );
  }

  get hasAllUpdateProductPricePermissions(): boolean {
    return (
      // Update ProductPrice
      this.permissionService.updateProductPricePermission().hasPermission &&
      // Read ProductPrice
      this.permissionService.readProductPricePermission().hasPermission &&
      // Read Product
      this.permissionService.readProductPermission().hasPermission
    );
  }
}

<div [ngClass]="{ container: showPadding, tableWrapper: !fullWidth }">
  <div
    [style.padding-bottom.rem]="showPadding ? 0 : 0.75"
    class="header-container"
  >
    <div class="title-content">
      <!-- title -->
      <h1 style="padding-left: 1rem; margin-right: 2.5rem">
        {{ ngxTitle | translate }}
      </h1>

      <!--  optional content right next to title   -->
      <ng-content select="[header-item-left]" />
    </div>

    <div class="flex-row">
      @if (showTable) {
        @if (hasTabs) {
          <div class="flex-row" style="gap: 1.25rem !important">
            @for (tab of tabs; track tab) {
              <tui-tabs [underline]="tab.isActive ?? false" id="filter-tabs">
                <button (click)="clickTab(tab)" tuiTab>
                  {{ tab.label | translate }}
                </button>
              </tui-tabs>
            }
          </div>
        }

        @if (showDatePicker) {
          <tui-input-date
            [tuiTextfieldSize]="'m'"
            [(ngModel)]="dateValue"
            [tuiTextfieldCleaner]="true"
            style="width: 12rem"
            class="table-layout-scoped"
            [items]="dateItems"
            (tuiValueChanges)="selectedDate($event)"
          >
            {{ dateHint | translate }}
          </tui-input-date>
        }

        <!-- custom header item e.g. used for search-field  -->
        <ng-content select="[header-item]" />
      }
      @if (showAddButton) {
        <!-- new button transparent style  -->
        <div *ngIf="newButtonTxt != ''">
          <ng-container *ngIf="transparentButton; else coloredButton">
            <app-transparent-btn
              (btnClickEvent)="clickBtn($event)"
              [i18StringText]="newButtonTxt"
              [iconValue]="icon"
            />
          </ng-container>

          <!-- new button different style  -->
          <ng-template #coloredButton>
            <button
              (click)="clickBtn($event)"
              [icon]="tuiIconPlus"
              appearance="primary"
              size="m"
              style="margin-left: 2rem"
              tuiButton
            >
              {{ newButtonTxt | translate }}
            </button>
          </ng-template>
        </div>
      }
    </div>
  </div>

  <!-- card with table -->
  @if (showTable) {
    <tui-island class="min-height" [class.island-fit-content]="!fullWidth">
      <ng-content></ng-content>
    </tui-island>
  } @else {
    <app-no-read-permission
      [missingPermissions]="missingReadPermissions"
    ></app-no-read-permission>
  }
</div>

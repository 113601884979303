import { Component, OnInit } from "@angular/core";
import Order from "../../../../models/Order.class";
import LocationC from "../../../../models/Location.class";
import { MapMarkersService } from "../../map-helper-services/map-markers.service";
import { MapOrdersService } from "../../map-helper-services/map-orders.service";
import { MapTpTplService } from "../../map-helper-services/map-tp-tpl.service";
import { PushService } from "../../../../services/push.service";
import { pushTypes } from "../../../../other/enums/push-types";
import { finalize } from "rxjs";
import { MapPermissionHelperService } from "../../../../services/permission-helper-services/map-permission-helper.service";

@Component({
  selector: "app-order-grid",
  templateUrl: "./order-grid.component.html",
  styleUrl: "./order-grid.component.scss",
})
export class OrderGridComponent implements OnInit {
  constructor(
    private mapMarkersService: MapMarkersService,
    public mapOrderService: MapOrdersService,
    private mapTPService: MapTpTplService,
    private pushService: PushService,
    public mapPermissionHelper: MapPermissionHelperService,
  ) {}

  ngOnInit(): void {
    this.mapOrderService.getOrdersWithCallback();
  }

  onHover(orderParam: Order) {
    const order = new Order(orderParam);
    const location = new LocationC(order.location);
    const markerWithItem = this.mapMarkersService.allMarkers.find(
      (m) => m.item.id === location.id,
    );
    if (!markerWithItem) return;

    this.mapMarkersService.mouseEnterOrderCard(markerWithItem);
  }

  onLeave(orderParam: Order) {
    const order = new Order(orderParam);
    const location = new LocationC(order.location);
    const markerWithItem = this.mapMarkersService.allMarkers.find(
      (m) => m.item.id === location.id,
    );
    if (!markerWithItem) return;

    this.mapMarkersService.mouseLeaveOrderCard(markerWithItem);
  }

  createTPLFromOrder(orderObj: Order) {
    const order = new Order(orderObj);
    const location = new LocationC(order.location);

    if (!this.mapTPService.addTourPoint(location)) {
      this.pushService.sendPush(
        pushTypes.INFO,
        "",
        "Tour Status muss 'Entwurf' sein, um Änderungen vorzunehmen!",
      );
    } else {
      this.mapTPService.addTourPoint(location)?.subscribe((tp) => {
        this.mapTPService
          .addTouPointLoads(order, tp)
          .pipe(
            finalize(() => {
              this.mapTPService.getTourPointsWithCallback(() => {
                this.mapMarkersService.getLocationsAndCreateMarkers();
                this.mapMarkersService.getWarehousesAndCreateMarkers();
              });
            }),
          )
          .subscribe();
      });
    }
  }

  allAmountsPlanned(order: Order): boolean {
    // Checking if all productOrders have calculatedAmount equal to 0
    return order.productOrders.every((pOrder) => pOrder.calculatedAmount === 0);
  }
}

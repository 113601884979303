<div class="grid-container">
  @for (location of locations; track location) {
    <tui-island
      class="half-transparent"
      [ngClass]="{
        'card-container': location.note,
        'no-note-card-container': !location.note
      }"
    >
      <div>
        <div class="address">
          <h3>
            {{ helperService.trimCommasFromStartAndEnd(location.name ?? "") }}
          </h3>

          <div>
            {{ location.address.street }} {{ location.address.houseNo }}
          </div>
          <div>
            {{ location.address.postalCode }} {{ location.address.city }}
          </div>
        </div>

        <div>
          <b>{{ "location.sideMounting" | translate }}:</b>
          {{
            location.sideMounting
              ? ("general.yes" | translate)
              : ("general.no" | translate)
          }}
        </div>
      </div>

      @if (location.note) {
        <div class="info">
          <b>{{ "general.note" | translate }}:</b> <br />
          {{ location.note }}
        </div>
      }

      @if (permissionService.updateLocationPermission().hasPermission) {
        <div class="edit">
          <tui-svg
            (click)="onEdit(location.id)"
            src="tuiIconEdit2Large"
            style="color: var(--tui-primary)"
            class="pointer"
          />
        </div>
      }
    </tui-island>
  } @empty {
    {{ "location.empty" | translate }}
  }
</div>

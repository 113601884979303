import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

export interface tabArray {
  icon: string;
  name: string;
}

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent {
  @Input({ required: true }) tabArray: tabArray[]; // tab array that manages the amount of tabs
  @Input() activeIndex: number = 0; // value to safe the active index to
  @Input() control: FormControl; // form control to bind the tabs to a form
  @Input() switchTabValue: boolean = false;
  @Input() underline: boolean = true;
  @Input() fontSize: string = "1.2rem";
  @Input() disabled: boolean = false;
  @Output() clickEvent = new EventEmitter();

  ngOnInit(): void {
    if (this.control != null) {
      this.activeIndex = this.control.value;
    }
  }

  onChange(index: number) {
    if (this.control != null) {
      this.control.setValue(index);
    }
    this.clickEvent.emit(index);
  }
}

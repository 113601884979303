import {
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { phoneValidator } from "../../../other/validators/phone-validator";
import { CustomerService } from "../../../api/customer.service";
import Customer from "../../../models/Customer.class";
import { PushService } from "../../../services/push.service";
import { forkJoin, Observable } from "rxjs";
import { pushTypes } from "../../../other/enums/push-types";
import { ContactPersonService } from "../../../api/contactPerson.service";
import { ContactPersonQueryParams } from "../../../interfaces/contact-person-query-params";
import { ResponseWithRecordsBody } from "../../../interfaces/response-with-recors-body";
import ContactPerson from "../../../models/ContactPerson.class";
import { Mode } from "../../../other/enums/mode";
import { CustomerFile } from "../../../interfaces/customer-file";
import { CustomerFileHelperService } from "../customer-details/customerFileHelper.service";
import { BaseDialogComponent } from "../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import { PermissionService } from "../../../services/permission.service";
import { CustomerPermissionHelperService } from "../../../services/permission-helper-services/customer-permission-helper.service";

@Component({
  selector: "app-add-edit-customer",
  templateUrl: "./add-edit-customer.component.html",
  styleUrls: ["./add-edit-customer.component.scss"],
})
export class AddEditCustomerComponent
  extends BaseDialogComponent
  implements OnInit
{
  form: FormGroup;
  fileControl: FormControl = new FormControl();
  toUpdateId: string = "";
  mode: Mode = Mode.ADD;
  isOpen: boolean = true;
  previousContactPersons: ContactPerson[] = [];
  customerId: string;
  navigatedBack: boolean = false; // to determine if already navigated back to products page (because the several requests are async and can lead to multiple navigations) todo check if still needed
  editorTextValue: string = "";
  fileDeleteIds: string[] = [];
  customerFiles: CustomerFile[] = [];
  fileUpdateNotifierService = inject(CustomerFileHelperService);

  constructor(
    public permissionService: PermissionService,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private pushService: PushService,
    private contactPersonService: ContactPersonService,
    @Inject(POLYMORPHEUS_CONTEXT) context: TuiDialogContext<any>,
    dialogService: TuiDialogHelperService,
    private cdr: ChangeDetectorRef,
    public customerPermissionHelper: CustomerPermissionHelperService,
  ) {
    super(context, dialogService);
  }

  get isAddMode() {
    return this.mode === Mode.ADD;
  }

  get contactPersons() {
    return this.form.get("contactPersons") as FormArray;
  }

  get hasContactPersonPermission() {
    return (
      (this.isAddMode &&
        this.permissionService.createContactPersonsPermission()
          .hasPermission) ||
      (!this.isAddMode &&
        (this.permissionService.updateContactPersonsPermission()
          .hasPermission ||
          this.permissionService.readContactPersonsPermission().hasPermission))
    );
  }

  ngOnInit(): void {
    // access url to check if the user wants to add or edit a customer
    this.getMode();
    this.navigatedBack = false;

    // init form
    this.initForm();

    // load customer data into the form in edit mode
    if (this.isAddMode || !this.toUpdateId) return;
    this.initCaseEdit(this.toUpdateId);
  }

  initForm() {
    this.form = this.fb.group({
      customerNumber: [null, Validators.required],
      name: ["", Validators.required],
      phone: ["", [phoneValidator()]],
      email: [""],
      MATCHC: ["", Validators.required],
      contactPersons: this.fb.array([]),
      fax: ["", [phoneValidator()]],
      billStreet: [""],
      billStreetNumber: [""],
      billCity: [""],
      billPostalcode: [""],
      billCityDistrict: [""],
      LKZ: [""],
      taxes: [null],
    });

    if (this.isAddMode) {
      this.generateUniqueCustomerNumber();
    }
  }

  generateUniqueCustomerNumber() {
    this.customerService.getAllCustomers().subscribe((res) => {
      if (res.records?.length) {
        // Sort the records by 'createdAt' field
        const sortedRecords = res.records.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );

        // Fetch the highest customerNumber from the sorted records (the one with the latest 'createdAt' date)
        const highestCustomerNumber = parseInt(
          sortedRecords[0]?.customerNumber,
          10,
        );

        // If the conversion successful, increment the customerNumber and set it in the form control
        if (!Number.isNaN(highestCustomerNumber)) {
          let newCustomerNumber = highestCustomerNumber + 1;
          let customerNumbers = res.records.map((record) =>
            parseInt(record.customerNumber, 10),
          );

          // If the new customer number already exists, increment it by 1 until it's unique
          while (customerNumbers.includes(newCustomerNumber)) {
            newCustomerNumber += 1;
          }

          // Set the new customerNumber in the form control
          this.form.controls["customerNumber"].setValue(newCustomerNumber);
        }
      }
    });
  }

  getFormGroupFromAbstractControl(abstractControl: AbstractControl): FormGroup {
    return abstractControl as FormGroup;
  }

  addContactPersonFormRow() {
    this.contactPersons.push(
      this.fb.group({
        contactName: ["", Validators.required],
        contactPhone: ["", [Validators.required, phoneValidator()]],
        contactEmail: ["", [Validators.email, Validators.required]],
        contactDescription: ["", Validators.required],
      }),
    );
  }

  deleteContactPersonFormRow(index: number) {
    this.contactPersons.removeAt(index);
  }

  // access url to check if the user wants to add or edit a customer
  getMode() {
    if (this.context.data) {
      this.mode = Mode.EDIT;
      this.toUpdateId = this.context.data;
    } else {
      this.mode = Mode.ADD;
    }
  }

  initCaseEdit(id: string) {
    this.customerService.getCustomerById(id).subscribe((res: Customer) => {
      this.form.controls["customerNumber"].setValue(res.customerNumber);
      this.form.controls["name"].setValue(res.name);
      this.form.controls["phone"].setValue(res.phone);
      this.form.controls["email"].setValue(res.email);
      this.form.controls["MATCHC"].setValue(res.MATCHC);
      this.form.controls["fax"].setValue(res.fax);
      this.form.controls["billStreet"].setValue(res.billStreet);
      this.form.controls["billStreetNumber"].setValue(res.billStreetNumber);
      this.form.controls["billCity"].setValue(res.billCity);
      this.form.controls["billPostalcode"].setValue(res.billPostalcode);
      this.form.controls["billCityDistrict"].setValue(res.billCityDistrict);
      this.form.controls["LKZ"].setValue(res.LKZ);
      this.form.controls["taxes"].setValue(res.taxes);
      this.editorTextValue = res.note;
      this.getCustomerFiles(res);
    });

    this.customerId = id;
    const queryParams: ContactPersonQueryParams = {
      customerId: id,
    };

    this.contactPersonService
      .getAllContactPersons(queryParams)
      .subscribe((res: ResponseWithRecordsBody) => {
        if (res.records.length === 0) return;
        const contactPersons: ContactPerson[] = res.records;
        this.previousContactPersons = [...contactPersons];
        this.contactPersons.removeAt(0);
        contactPersons.forEach((contactPerson: ContactPerson) => {
          this.contactPersons.push(
            this.fb.group({
              id: [contactPerson.id], // to identify contact persons that have to be patched or deleted
              contactName: [
                {
                  value: contactPerson.name,
                  disabled:
                    !this.permissionService.updateContactPersonsPermission()
                      .hasPermission,
                },
                Validators.required,
              ],
              contactPhone: [
                {
                  value: contactPerson.phone,
                  disabled:
                    !this.permissionService.updateContactPersonsPermission()
                      .hasPermission,
                },
                [Validators.required, phoneValidator()],
              ],
              contactEmail: [
                {
                  value: contactPerson.email,
                  disabled:
                    !this.permissionService.updateContactPersonsPermission()
                      .hasPermission,
                },
                [Validators.email, Validators.required],
              ],
              contactDescription: [
                {
                  value: contactPerson.description,
                  disabled:
                    !this.permissionService.updateContactPersonsPermission()
                      .hasPermission,
                },
                Validators.required,
              ],
            }),
          );
        });
      });

    this.markFormAsTouched();
  }

  markFormAsTouched() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.markAsTouched();
      this.form.updateValueAndValidity();
    });
    this.cdr.detectChanges();
  }

  submit() {
    if (this.form.invalid) return;

    // access the form object
    const formData = this.form.value;
    delete formData["contactPersons"];

    // create a customer object out of the formData object
    const customer: Customer = new Customer(formData);
    customer.note = this.editorTextValue;

    if (this.isAddMode) {
      this.addCustomer(customer);
    } else if (!this.isAddMode) {
      this.updateCustomer(customer);
      this.uploadFiles();
    }
  }

  addCustomer(customer: Customer) {
    this.customerService.addCustomer(customer).subscribe({
      next: (res) => {
        this.addContactPersons(res);
      },
      error: () => {
        // if request fails, do not add contact persons and close dialog
        this.cancel();
      },
    });
  }

  addContactPersons(res: Customer, err: boolean = false) {
    this.customerId = res.id;
    this.uploadFiles();

    const requestObservables: Observable<any>[] = [];

    if (this.permissionService.createContactPersonsPermission().hasPermission) {
      for (const contactPersonControl of this.contactPersons.controls) {
        const contactPerson: ContactPerson = new ContactPerson({
          name: contactPersonControl.get("contactName")?.value,
          phone: contactPersonControl.get("contactPhone")?.value,
          description: contactPersonControl.get("contactDescription")?.value,
          email: contactPersonControl.get("contactEmail")?.value,
          customerId: res.id,
        });
        requestObservables.push(
          this.contactPersonService.addContactPerson(contactPerson),
        );
      }
    }

    this.handleMultipleRequests(requestObservables, err);
  }

  updateCustomer(customer: Customer) {
    this.deleteGivenFiles();

    this.customerService
      .updateCustomerById(this.toUpdateId, customer)
      .subscribe({
        next: (res) => {
          this.handleCustomerUpdate();
        },
        error: () => {
          this.handleCustomerUpdate(true);
        },
      });
  }

  showSuccessMessage() {
    this.pushService.sendPush(
      pushTypes.SUCCESS,
      "",
      "Der Kunde wurde erfolgreich gespeichert.",
    );
  }

  showErrorMessage() {
    this.pushService.sendPush(
      pushTypes.ERROR,
      "",
      "Beim speichern des Kunden ist ein Fehler aufgetreten.",
    );
  }

  handleMultipleRequests(
    requestObservables: Observable<any>[],
    err: boolean = false, // indicates if the request that was made before "handleMultipleRequests" was an error
  ) {
    if (requestObservables.length <= 0) {
      this.fileUpdateNotifierService.fileUpdated();
      this.closeDialog();
      if (!err) {
        this.showSuccessMessage();
      }
    }

    // combines all observable for all requests, the subscription is only called when all requests are finished
    forkJoin(requestObservables).subscribe({
      next: () => {
        // Success handling
        this.showSuccessMessage();
        this.fileUpdateNotifierService.fileUpdated();
        this.closeDialog();
      },
      error: () => {
        // Error handling
        this.showErrorMessage();
      },
    });
  }

  cancel() {
    this.closeDialog();
  }

  editorChangeData($event: any) {
    this.editorTextValue = $event;
  }

  getCustomerFiles(customer: Customer) {
    customer.files.forEach((file) => {
      this.customerService
        .getCustomerFileById(file.id)
        .subscribe((res: any) => {
          this.customerFiles.push({ id: file.id, file: res.file });
        });
    });
  }

  uploadFiles() {
    if (this.fileControl.value) {
      // Array to hold all observables
      let observables: any[] = [];

      // Create observables for each file upload
      this.fileControl.value.forEach((file: File) => {
        const formData = new FormData();
        formData.append("file", file, file.name);

        const observable = this.customerService.addCustomerFileById(
          this.customerId,
          formData,
        );
        observables.push(observable);
      });

      if (!observables) return;
      // Use forkJoin to wait for all observables to complete
      forkJoin(observables).subscribe();
    }
  }

  deleteFiles(deleteIds: string[]) {
    this.fileDeleteIds = deleteIds;
  }

  deleteGivenFiles() {
    if (this.fileDeleteIds.length <= 0) return;

    this.fileDeleteIds.forEach((id) => {
      this.customerService.deleteCustomerFileById(id).subscribe(() => {
        this.fileDeleteIds = [];
      });
    });
  }

  handleCustomerUpdate(err: boolean = false) {
    const requestObservables: Observable<any>[] = [];

    if (
      this.permissionService.updateContactPersonsPermission().hasPermission ||
      this.permissionService.createContactPersonsPermission().hasPermission
    ) {
      for (const element of this.contactPersons.controls) {
        const contactPersonControl: AbstractControl = element;
        const contactPerson: ContactPerson = new ContactPerson({
          name: contactPersonControl.get("contactName")?.value,
          phone: contactPersonControl.get("contactPhone")?.value,
          description: contactPersonControl.get("contactDescription")?.value,
          email: contactPersonControl.get("contactEmail")?.value,
          customerId: this.toUpdateId,
        });
        // check if contact person already exists
        const contactPersonId = contactPersonControl.get("id")?.value;
        if (
          contactPersonId &&
          this.permissionService.updateContactPersonsPermission().hasPermission
        ) {
          // update contact person
          requestObservables.push(
            this.contactPersonService.updateContactPersonById(
              contactPersonId,
              contactPerson,
            ),
          );
        } else if (
          !contactPersonId &&
          this.permissionService.createContactPersonsPermission().hasPermission
        ) {
          // add contact person
          requestObservables.push(
            this.contactPersonService.addContactPerson(contactPerson),
          );
        }
      }
    }
    if (this.permissionService.deleteContactPersonsPermission().hasPermission) {
      for (const element of this.previousContactPersons) {
        const previousContactPerson = element;
        if (previousContactPerson.id == null) continue;
        // delete contact person if it is not in the form anymore
        const deleteObservable = this.contactPersons.controls.some(
          (contactPersonControl) => {
            return (
              contactPersonControl.get("id")?.value === previousContactPerson.id
            );
          },
        )
          ? null
          : this.contactPersonService.deleteContactPersonById(
              previousContactPerson.id,
            );
        if (deleteObservable != null) {
          requestObservables.push(deleteObservable);
        }
      }
    }
    this.handleMultipleRequests(requestObservables, err);
  }

  // Checks if the contact person is loaded from the api or if it is a new contact person created in the frontend form
  isExistingContactPerson(index: number) {
    return this.contactPersons.controls[index].get("id")?.value != null;
  }
}

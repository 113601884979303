import { inject, Injectable } from "@angular/core";
import { PermissionService } from "../permission.service";
import { NavRoutes } from "../../other/enums/nav-routes";

@Injectable({
  providedIn: "root",
})
export class PermissionGuardHelperService {
  permissionService = inject(PermissionService);
  // An ordered list view names (paths)
  private viewsOrder = [
    "dashboard",
    "order",
    "refunds",
    "tours",
    "delivery-receipt",
    "customers",
    "user",
    "settings",
  ];

  checkPermission(view: string): boolean {
    return Boolean(this.permissions()[view]);
  }

  getNextViewWithPermission(currentViewPath: string): string {
    let currentIndex = this.viewsOrder.indexOf(currentViewPath);

    // Looking for the next view starting from the next index
    for (let i = currentIndex + 1; i < this.viewsOrder.length; i++) {
      if (this.checkPermission(this.viewsOrder[i])) {
        return this.viewsOrder[i]; // Return the view path if permission is granted
      }
    }

    return NavRoutes.ERROR;
  }

  hasSettingsPermissions() {
    return (
      this.permissionService.readRolePermission().hasPermission ||
      this.permissionService.createRolePermission().hasPermission ||
      this.permissionService.readProductPermission().hasPermission ||
      this.permissionService.createProductPermission().hasPermission ||
      this.permissionService.readWarehousePermission().hasPermission ||
      this.permissionService.createWarehousePermission().hasPermission
    );
  }

  private permissions(): { [key: string]: boolean } {
    return {
      dashboard: this.permissionService.readDashboardPermission().hasPermission,
      order: this.permissionService.readOrderPermission().hasPermission,
      refunds: this.permissionService.readRefundPermission().hasPermission,
      tours: this.permissionService.readTourPermission().hasPermission,
      "delivery-receipt":
        this.permissionService.readDeliveryReceiptPermission().hasPermission,
      customers: this.permissionService.readCustomerPermission().hasPermission,
      user: this.permissionService.readUserPermission().hasPermission,
      settings: this.hasSettingsPermissions(),
    };
  }
}

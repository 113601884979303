import {
  Component,
  ElementRef,
  Inject,
  QueryList,
  signal,
  ViewChildren,
} from "@angular/core";
import { BaseDialogComponent } from "../../../common/base-dialog/base-dialog.component";
import { POLYMORPHEUS_CONTEXT } from "@tinkoff/ng-polymorpheus";
import { TuiDialogContext } from "@taiga-ui/core";
import { TuiDialogHelperService } from "../../../services/tui-dialog-helper.service";
import DeliveryReceipt from "../../../models/DeliveryReceipt.class";
import TourPoint from "../../../models/TourPoint.class";
import TourPointLoad from "../../../models/TourPointLoad.class";
import { Location } from "@angular/common";
import { DeliveryReceiptService } from "../../../api/deliveryReceipt.service";
import { TourPointService } from "../../../api/tourPoint.service";
import { UserService } from "../../../api/user.service";
import { ReceiptCarouselComponent } from "./receipt-carousel/receipt-carousel.component";
import { longFadeAnimation } from "../../../other/animations/long-fade-animation";
import { tuiIconArrowUpRight } from "@taiga-ui/icons";
import { FileType } from "../../../other/enums/file-type";

type SignatureWithName = {
  id: string;
  name: string;
  signature: string;
};

@Component({
  selector: "app-delivery-receipt-details-dialog",
  templateUrl: "./delivery-receipt-details-dialog.component.html",
  styleUrl: "./delivery-receipt-details-dialog.component.scss",
  animations: [longFadeAnimation],
})
export class DeliveryReceiptDetailsDialogComponent extends BaseDialogComponent {
  selectedReceipt = signal<DeliveryReceipt>(new DeliveryReceipt({}));
  tourPoint = signal<TourPoint>(new TourPoint({}));
  tourPointLoadsUnloaded = signal<TourPointLoad[]>([]);
  tourPointLoadsLoaded = signal<TourPointLoad[]>([]);
  @ViewChildren("imgElem") imgElems!: QueryList<ElementRef>;
  signatures: SignatureWithName[] = [];
  fileImages: string[] = [];
  fileDocuments: string[] = [];
  fileDocumentsNoImages: any[] = [];
  protected readonly Math = Math;
  protected readonly tuiIconArrowUpRight = tuiIconArrowUpRight;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    override readonly context: TuiDialogContext<any>,
    protected override dialogService: TuiDialogHelperService,
    public location: Location,
    private deliveryReceiptService: DeliveryReceiptService,
    private tourPointService: TourPointService,
    private userService: UserService,
  ) {
    super(context, dialogService);
  }

  get hasSignatures(): boolean {
    return this.signatures.length > 0;
  }

  get hasImageFiles(): boolean {
    return this.fileImages.length > 0;
  }

  get hasDocumentFiles(): boolean {
    return this.fileDocuments.length > 0;
  }

  get hasPdfs(): boolean {
    return (
      this.selectedReceipt().pdfs && this.selectedReceipt().pdfs.length > 0
    );
  }

  get truckNumber() {
    const tourPointInfo = this.tourPoint();
    const isTruckNumberExistInTour = tourPointInfo.tour?.truckNumber;
    const isTruckNumberExistInUnannouncedInfo =
      tourPointInfo.unannouncedInfos?.[0]?.truckNumber;

    return (
      isTruckNumberExistInTour ?? isTruckNumberExistInUnannouncedInfo ?? "-"
    );
  }

  get hasNotes(): boolean {
    return (
      this.selectedReceipt().note != null &&
      this.selectedReceipt().note.trim() != ""
    );
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if (!this.context.data) return;

    this.getReceiptsTourAndFilesAndImages(this.context.data);
  }

  getReceiptsTourAndFilesAndImages(receiptId: string) {
    this.signatures = [];
    this.fileImages = [];
    this.fileDocuments = [];

    this.deliveryReceiptService
      .getDeliveryReceiptById(receiptId)
      .subscribe((receipt) => {
        this.selectedReceipt.set(receipt);

        // get tourPoint data
        this.tourPointService
          .getTourPointById(receipt.tourPointId)
          .subscribe((tourPoint) => {
            this.tourPoint.set(tourPoint);

            // tourPointLoads with positive amount (loaded)
            this.tourPointLoadsUnloaded.set(
              tourPoint.tourPointLoads.filter((load) => load.amount < 0),
            );

            // tourPointLoads with negative amount (unloaded)
            this.tourPointLoadsLoaded.set(
              tourPoint.tourPointLoads.filter((load) => load.amount > 0),
            );
          });

        // get signatures and name of signedBy
        if (receipt.signatures.length > 0) {
          for (const element of receipt.signatures) {
            this.deliveryReceiptService
              .getSignatures(receiptId, element.id)
              .subscribe((res) => {
                const reader = new FileReader();
                let file: string;
                reader.onloadend = () => {
                  file = reader.result as string;

                  const signatureName = element.name;
                  if (signatureName) {
                    this.signatures.push({
                      id: element.id,
                      name: signatureName,
                      signature: file,
                    });
                  } else {
                    const userId = element.userId;
                    if (!userId) return;
                    // get name with userId
                    this.userService.getUserById(userId).subscribe((user) => {
                      this.signatures.push({
                        id: element.id,
                        name: user.name,
                        signature: file,
                      });
                    });
                  }
                };
                reader.readAsDataURL(res);
              });
          }
        }

        // get files
        if (receipt.files.length > 0) {
          for (const file of receipt.files) {
            this.deliveryReceiptService
              .getFiles(receiptId, file.id)
              .subscribe((res: Blob) => {
                if (file.mimetype.startsWith("image/")) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const fileString = reader.result as string;

                    if (file.fileType === FileType.DOCUMENT) {
                      this.fileDocuments.push(fileString);
                    } else {
                      this.fileImages.push(fileString);
                    }
                  };
                  reader.readAsDataURL(res);
                } else {
                  const fileObject = {
                    fileName: file.fileName,
                    blob: res,
                  };

                  this.fileDocumentsNoImages.push(fileObject);
                }
              });
          }
        }
      });
  }

  openFile(blob: Blob) {
    // Create an object URL for the blob
    const fileURL = URL.createObjectURL(blob);
    // Opens the object URL in a new tab
    window.open(fileURL);
  }

  openImageDialogWithFile(index: number): void {
    this.dialogService.openDialog(
      ReceiptCarouselComponent,
      {
        files: this.fileImages,
        index: index,
      },
      () => {},
      "transparent-dialog",
    );
  }

  openDocumentDialogWithFile(index: number): void {
    this.dialogService.openDialog(
      ReceiptCarouselComponent,
      {
        files: this.fileDocuments,
        index: index,
      },
      () => {},
      "transparent-dialog",
    );
  }

  getPdfById(deliveryReceiptId: string, pdfId: string) {
    this.deliveryReceiptService
      .getPdfByIds(deliveryReceiptId, pdfId)
      .subscribe({
        next: (res) => {
          const blob = new Blob([res], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
          window.URL.revokeObjectURL(url);
        },
      });
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import TourPoint from "../../../../../models/TourPoint.class";
import {
  UpdateItem,
  UpdateRequestPayload,
} from "../../../../../interfaces/request-array.interface";
import { TourPointService } from "../../../../../api/tourPoint.service";
import { MapTpTplService } from "../../../map-helper-services/map-tp-tpl.service";
import { TOUR_STATE } from "../../../../../models/Tour.class";
import { PushService } from "../../../../../services/push.service";
import { pushTypes } from "../../../../../other/enums/push-types";

@Component({
  selector: "app-tour-point-load-header",
  templateUrl: "./tour-point-load-header.component.html",
  styleUrl: "./tour-point-load-header.component.scss",
})
export class TourPointLoadHeaderComponent implements OnInit {
  @Input({ required: true }) tourPoint: TourPoint;

  estimatedTimeForm: FormGroup;

  constructor(
    private tourPointService: TourPointService,
    private fb: FormBuilder,
    private mapTPTPLService: MapTpTplService,
    private push: PushService,
  ) {}

  get isTourStateDraft() {
    return this.mapTPTPLService.tour.tourState === TOUR_STATE.DRAFT;
  }

  ngOnInit(): void {
    this.initEstimatedTimeForm();
  }

  initEstimatedTimeForm() {
    this.estimatedTimeForm = this.fb.group({
      estimatedStopDuration: [
        this.tourPoint.estimatedStopDuration,
        Validators.required,
      ],
    });
  }

  changeEstimatedTimeDuration() {
    const newEstimatedStopDuration: number = this.estimatedTimeForm.get(
      "estimatedStopDuration",
    )?.value;

    const updateTourPoint: TourPoint = new TourPoint({
      id: this.tourPoint.id,
      estimatedStopDuration: newEstimatedStopDuration,
    });

    const updateItem: UpdateItem<TourPoint> = {
      id: updateTourPoint.id,
      data: updateTourPoint,
    };

    const updatePayload: UpdateRequestPayload<TourPoint> = {
      data: [updateItem],
    };

    this.tourPointService.updateTourPointById(updatePayload).subscribe((tp) => {
      // Find matching tourPoint by id
      const index = this.mapTPTPLService.tourPoints.findIndex(
        (oneTp) => oneTp.id === tp[0].id,
      );

      // If found, replace it with the updated tourPoint
      if (index !== -1) {
        this.mapTPTPLService.tourPoints[index] = tp[0];
      }

      // TODO: message
      this.push.sendPush(pushTypes.SUCCESS);
      this.mapTPTPLService.setArrivalTimes();
    });
  }
}

import { Injectable, Signal, signal, WritableSignal } from "@angular/core";
import { HttpClient, HttpParams, HttpStatusCode } from "@angular/common/http";
import { environment } from "../environments/environment";
import { ApiRoutes } from "../other/enums/api-routes";
import { catchError, map, Observable, of, switchMap, tap } from "rxjs";
import { RoleQueryParams } from "../interfaces/role-query-params";
import { Role } from "../models/Role";
import { ResponseWithRecordsBody } from "../interfaces/response-with-recors-body";
import { PaginationFilterService } from "../services/pagination-filter.service";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { PushService } from "../services/push.service";
import { pushTypes } from "../other/enums/push-types";
import { Permissions } from "../other/enums/permissions";
import { ErrorMessagesService } from "../api-error-messages/error-messages.service";

@Injectable({
  providedIn: "root",
})
/**
 * Service Class for the getting or manipulating tenant data
 */
export class RoleService extends PaginationFilterService {
  private readonly baseUrl = environment.baseUrl;
  private _totalRoles = signal<number>(0);

  constructor(
    private http: HttpClient,
    private push: PushService,
    private errorHandler: ErrorMessagesService,
  ) {
    super();
  }

  private _roles: WritableSignal<Role[]> = signal<Role[]>([]);

  get roles() {
    return this._roles();
  }

  set roles(value: Role[]) {
    this._roles.set(value);
  }

  // when selectedRoleId changes, get the role with the new id and set it to selectedRole (unused atm)
  private _selectedRoleId = signal<string>("");

  private selectedRole$: Observable<Role> = toObservable(
    this._selectedRoleId,
  ).pipe(
    // switchMap is used to switch to a new observable ( getRoleId() ) when the source observable ( selectedRoleId ) emits a value
    switchMap((roleId) => {
      if (roleId === "") {
        return of(new Role({}));
      } else {
        return this.getRoleById(roleId);
      }
    }),
  );

  set selectedRoleId(value: string) {
    this._selectedRoleId.set(value);
  }

  private _selectedRole = toSignal(this.selectedRole$, {
    initialValue: new Role({}),
  });

  get selectedRole(): Signal<Role> {
    return this._selectedRole;
  }

  get total() {
    return this._totalRoles();
  }

  set total(value: number) {
    if (value !== undefined && value !== null && value >= 0) {
      this._totalRoles.set(value);
    }
  }

  /**
   * get the role data from the api
   * sets query params for the api call if they are passed in
   * sets the totalAmount property of the parent PaginationFilterService if data is returned
   * maps the response to an array of observable user objects with the Role.fromJson method
   * @param queryParams optional query params for the api call
   */
  getRoles(queryParams?: RoleQueryParams): Observable<Role[]> {
    let params: HttpParams = new HttpParams();

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    }

    params = super.setPaginationSortingParams(params);

    return this.http
      .get<ResponseWithRecordsBody>(this.baseUrl + ApiRoutes.ROLE, { params })
      .pipe(
        map((response) => {
          const roles = response.records.map((role: Role) => {
            return new Role(role);
          });
          this.roles = roles;
          this.total = response.total;
          return roles;
        }),
      );
  }

  /**
   * get detailed role data from the api with a role id
   * maps the response to an observable role object with the Role.fromJson method
   * @param id the id of the role to get
   */
  getRoleById(id: string): Observable<Role> {
    return this.http.get<any>(this.baseUrl + ApiRoutes.ROLE + "/" + id).pipe(
      map((role: Role) => {
        return new Role(role);
      }),
    );
  }

  /**
   * Sends a request to the api to create a new role
   * @param roleBody the role to create as RoleBody
   */
  createRole(roleBody: Role): Observable<boolean> {
    return this.http
      .post<any>(this.baseUrl + ApiRoutes.ROLE, roleBody, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        tap(() => {
          this.push.sendPush(
            pushTypes.SUCCESS,
            "",
            "Rolle erfolgreich erstellt",
          );
        }),
        catchError((err) => {
          this.errorHandler.sendErrorMessage("role", "CREATE", err);
          return of(false);
        }),
      );
  }

  /**
   *  Sends a patch request to the api to update a role
   *  @param roleId the role to update as RoleBody
   *  @param role the role to update as RoleBody
   */
  updateRole(roleId: string, role: Role): Observable<boolean> {
    return this.http
      .patch<any>(this.baseUrl + ApiRoutes.ROLE + "/" + roleId, role, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        tap(() => {
          this.push.sendPush(
            pushTypes.SUCCESS,
            "",
            "Rolle erfolgreich bearbeitet",
          );
        }),
        catchError((err) => {
          this.errorHandler.sendErrorMessage("role", "UPDATE", err);
          return of(false);
        }),
      );
  }

  /**
   *  Sends a delete request to the api to delete a role
   *  @param id the id of the role to delete
   */
  deleteRole(id: string): Observable<boolean> {
    return this.http
      .delete<any>(this.baseUrl + ApiRoutes.ROLE + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.status === HttpStatusCode.Ok;
        }),
        tap(() => {
          this.push.sendPush(
            pushTypes.SUCCESS,
            "",
            "Rolle erfolgreich gelöscht",
          );
        }),
        catchError((err) => {
          this.errorHandler.sendErrorMessage("role", "DELETE", err);
          return of(false);
        }),
      );
  }

  getPermissions(): Observable<Permissions[]> {
    return this.http.get<Permissions[]>(this.baseUrl + "permission");
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { slideAnimation } from "../../other/animations/slide-animation";
import { fadeAnimation } from "../../other/animations/fade-animation";

@Component({
  selector: "app-menubar",
  templateUrl: "./menubar.component.html",
  styleUrls: ["./menubar.component.scss"],
  animations: [slideAnimation, fadeAnimation],
})

/**
 * This component is used to display a menubar with a title and a description.
 * It can be closed by clicking on the backdrop. Therefore, it is required to communicate with the parent component
 * by emitting an event when the menubar is closed.
 *
 * @Input title - The title of the menubar
 * @Input description - The descriptive text displayed below the title
 * @Input open - Determines whether the menubar is open or not
 * @Output closingEvent - Emits an event when the menubar is closed
 *
 * Example:
 * <app-menubar
 *   [title]="'Titel'"
 *   [description]="'Beschreibung'"
 *   [open]="open"
 *   (closingEvent)="open = !open"
 * ></app-menubar>
 */
export class MenubarComponent {
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() open = false;
  @Output() closingEvent = new EventEmitter<boolean>();

  /**
   * If the backdrop is clicked, the menubar closes and the closingEvent is emitted
   * to tell the parent component that the menubar is closed.
   */
  closeSideBar() {
    this.open = !this.open;
    this.closingEvent.emit(true);
  }
}

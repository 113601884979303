import { Component, input } from "@angular/core";
import { TuiTooltipModule } from "@taiga-ui/core";
import { PermissionType } from "../base-missing-permissions-dialog/base-missing-permissions-dialog.component";
import { NgStyle } from "@angular/common";

@Component({
  selector: "app-tooltip",
  standalone: true,
  imports: [TuiTooltipModule, NgStyle],
  templateUrl: "./tooltip.component.html",
  styleUrl: "./tooltip.component.scss",
})
export class TooltipComponent {
  elementName = input.required<string>();
  methodType = input<string>();
  permissions = input.required<PermissionType[]>();
  iconColorWhite = input<boolean>(false);
}
